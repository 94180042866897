var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormulateInput", {
        attrs: {
          type: "email",
          name: "email",
          label: "Email",
          validation: "required|email",
          disabled: _vm.email,
        },
      }),
      _vm._v(" "),
      _c("FormulateInput", {
        attrs: { type: "password", name: "password", label: "Password" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formulate-input" },
        [
          _c(
            "inertia-link",
            { attrs: { href: _vm.$route("password.request") } },
            [_vm._v("\n            Forgot your password?\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }