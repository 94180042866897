var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "pdf-report": _vm.isDownload,
      },
    },
    [
      _c(
        "section",
        { staticClass: "pdf-item" },
        [
          _c(
            "page-header",
            {
              attrs: { title: _vm.title, icon: "reports" },
              scopedSlots: _vm._u([
                {
                  key: "page-header-center",
                  fn: function () {
                    return [
                      !_vm.isDownload
                        ? [
                            _c("FormulateInput", {
                              staticClass: "mb-0",
                              attrs: {
                                type: "daterange-plugin",
                                placeholder: "All dates",
                                name: "date",
                                "show-clear": "",
                              },
                              on: { input: _vm.filter },
                              model: {
                                value: _vm.dates,
                                callback: function ($$v) {
                                  _vm.dates = $$v
                                },
                                expression: "dates",
                              },
                            }),
                          ]
                        : [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.displayDates) +
                                "\n                "
                            ),
                          ],
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "page-header-bottom",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "center" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "tile-wrap flex-space-between tile-wrap-3",
                          },
                          _vm._l(_vm.tiles, function (tile) {
                            return _c(
                              "tile",
                              _vm._b({ key: tile.name }, "tile", tile, false)
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "link-wrap flex-wrap flex-space-between",
                          },
                          _vm._l(_vm.links, function (link) {
                            return _c(
                              "dashboard-link",
                              _vm._b(
                                { key: link.name },
                                "dashboard-link",
                                link,
                                false
                              )
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isDownload
                          ? _c(
                              "div",
                              { staticClass: "map-wrap flex-space-between" },
                              [
                                _c("report-map", {
                                  ref: "developmentsMap",
                                  attrs: {
                                    label: "Developments",
                                    type: "projects",
                                    zoom: 10,
                                    lpa: _vm.lpa,
                                    projects: _vm.mapProjects,
                                  },
                                }),
                                _vm._v(" "),
                                _c("report-map", {
                                  ref: "landParcelsMap",
                                  attrs: {
                                    type: "plots",
                                    label: "Land Parcels",
                                    zoom: 10,
                                    lpa: _vm.lpa,
                                    plots: _vm.mapPlots,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("div", {}, [
                _c("h3", [_vm._v("BNG Report")]),
                _vm._v(" "),
                _c("h3", [_vm._v("Overview Report")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isDownload
        ? [
            _c("div", { staticClass: "center" }, [
              _c("div", { staticClass: "map-wrap" }, [
                _c(
                  "section",
                  { staticClass: "pdf-item" },
                  [
                    _c("report-map", {
                      ref: "developmentsMap",
                      attrs: {
                        label: "Developments",
                        type: "projects",
                        zoom: 10,
                        center: _vm.center,
                        lpa: _vm.lpa,
                        "is-download": "",
                        projects: _vm.mapProjects,
                      },
                      on: { mapReady: _vm.mapReady },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  { staticClass: "pdf-item" },
                  [
                    _c("report-map", {
                      ref: "landParcelsMap",
                      attrs: {
                        plots: _vm.mapPlots,
                        type: "plots",
                        label: "Land Parcels",
                        zoom: 10,
                        center: _vm.center,
                        lpa: _vm.lpa,
                        "is-download": "",
                      },
                      on: { mapReady: _vm.mapReady },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "pdf-item" }, [
        _c(
          "div",
          { staticClass: "center" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.projectData && _vm.projectData.data.length > 0
              ? [
                  _c("project-table", {
                    staticClass: "mb-4",
                    attrs: {
                      "filter-route": _vm.$route("reports"),
                      projects: _vm.projectData,
                      statuses: _vm.$page.props.statuses,
                      "has-header": false,
                      "has-dropdown": false,
                      "has-footer": !_vm.isDownload,
                      "table-columns": _vm.projectTableColumns,
                    },
                    on: { onRowClick: _vm.onRowClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(lease_urn)",
                          fn: function ({ row }) {
                            return _vm._l(row.lease_urn, function (lease_urn) {
                              return _c("span", { key: lease_urn }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(lease_urn) +
                                    "\n                        "
                                ),
                              ])
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      257472145
                    ),
                  }),
                ]
              : [_c("p", [_vm._v("No Developments Created")])],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "pdf-item" }, [
        _c(
          "div",
          { staticClass: "center" },
          [
            _vm._m(1),
            _vm._v(" "),
            _vm.plotData && _vm.plotData.data.length > 0
              ? [
                  _c("plot-table", {
                    staticClass: "mb-4",
                    attrs: {
                      plots: _vm.plotData,
                      statuses: _vm.$page.props.statuses,
                      "allow-sorting": false,
                      "has-header": false,
                      "has-dropdown": false,
                      "table-columns": _vm.plotTableColumns,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(lease_urn)",
                          fn: function ({ row }) {
                            return _vm._l(row.lease_urn, function (lease_urn) {
                              return _c("span", { key: lease_urn }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(lease_urn) +
                                    "\n                        "
                                ),
                              ])
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      257472145
                    ),
                  }),
                ]
              : [_c("p", [_vm._v("No Land Parcels Created")])],
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dashboard-heading flex-space-between" }, [
      _c("h6", [_vm._v("Developments")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dashboard-heading flex-space-between" }, [
      _c("h6", [_vm._v("Land Parcels")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }