<!-- eslint-disable vue/no-mutating-props -->

<template>
    <div
        v-if="endpoint"
        class="user-search"
        :name="context.attributes.name"
    >
        <!-- <input
            v-model="context.model"
            :class="`formulate-input-element formulate-input-element--${context.type}`"
            type="hidden"
        > -->
        <div
            v-if="context.model"
            class="formulate-fake"
        >
            <input
                v-model="context.model"
                type="hidden"
            >
            <div>
                <input-tag
                    @click="handleRemove"
                >
                    {{ context.model.email }}
                </input-tag>
            </div>
        </div>
        <input
            v-else
            v-model="userSearch"
            class="user-search-input"
            type="text"
            @focus="handleFocus"
            @blur="handleBlur"
            @input="handleInput"
        >
        <div
            v-if="processing || (userList && showUserList)"
            class="user-search-list"
        >
            <user-card
                v-if="processing"
                v-slot="slotProps"
                initials="BP"
                first-name="████"
                last-name="██████"
                email="██████████@███████.com"
                company="██████ ██████"
            >
                <processing
                    :spinner="false"
                    :dots="false"
                    tag="div"
                    :style="{
                        opacity: slotProps.type !== 'initials' ? 0.3 : ''
                    }"
                >
                    {{ slotProps.content }}
                </processing>
            </user-card>
            <template v-else-if="showUserList">
                <ul>
                    <template v-if="userList.length">
                        <li
                            v-for="user in userList"
                            :key="user.agent_contact"
                            @mousedown="handleSelectUser($event, user)"
                        >
                            <user-card
                                :first-name="user.first_name"
                                :last-name="user.last_name"
                                :email="user.email"
                                :company="user.company"
                            />
                        </li>
                    </template>
                    <template v-else-if="searched">
                        <li
                            v-if="allowCustom && validateNew"
                            @mousedown="handleNewUser"
                        >
                            <user-card
                                initials="+"
                                :first-name="userSearch"
                                :email="customString"
                            />
                        </li>
                        <li v-else>
                            <user-card
                                initials="?"
                                :first-name="failMessage"
                            />
                        </li>
                    </template>
                </ul>
            </template>
        </div>
    </div>
    <div
        v-else
        class="formulate-fake"
    >
        <p>
            No endpoint set to get users.
            <br>
            This functionality uses inertia and only ['users'] to get information back.
        </p>
    </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Processing from 'Utilities/processing/processing';
import UserCard from 'Utilities/users/UserCard';
import InputTag from '../InputTag';
import { nextTick } from 'vue';

export default {
    name:'FormulateUserSearch',

    components: {
        Processing,
        UserCard,
        InputTag,
    },

    props: {
        allowCustom: {
            type: Boolean,
            default: true,
        },
        customValidation: {
            type: String,
            default: 'email',
            validator(value) {
                return ['email', null].includes(value);
            },
        },
        customString: {
            type: String,
            default: 'Add new user',
        },
        context: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: String,
            default: null,
            required: true,
        },
    },

    data() {
        return {
            userSearch: '',
            focused: false,
            processing: false,
            timeoutSearch: null,
            timeoutCards: null,
            showUserList: false,
            searched: false,
            userList: [],
        };
    },

    computed: {
        validateNew() {
            switch (this.customValidation) {
                case 'email':
                    return /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.userSearch);

                default:
                    return true;
            }
        },

        failMessage() {
            switch (this.customValidation) {
                case 'email':
                    return 'Please enter a valid email address';

                default:
                    return 'Unable to find User';
            }
        },
    },

    methods: {
        handleFocus() {
            clearTimeout(this.timeoutCards);
            this.focused = true;
            this.showUserList = true;
        },

        handleBlur() {
            this.focused = false;
            nextTick().then(() => {
                this.showUserList = false;
                this.searched = false;
            });
        },

        handleSelectUser(event, user) {
            if (event.which === 1) {
                this.context.model = user;
                this.userSearch = '';
            }
        },

        handleNewUser() {
            this.context.model = {
                email: this.userSearch,
            };
            this.userSearch = '';
        },

        handleRemove() {
            this.context.model = null;
        },

        handleInput() {
            if (this.userSearch.length < 3) {
                this.userList = [];

                return;
            }

            this.processing = true;
            this.showUserList = true;
            clearTimeout(this.timeoutSearch);
            this.timeoutSearch = setTimeout(() => {
                this.$inertia.get(this.endpoint, {
                    search: this.userSearch,
                }, {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['users'],
                    replace: true,
                    onSuccess: (data) => {
                        this.processing = false;
                        this.userList = data.props.users;
                        this.searched = true;

                        if (!this.focused) {
                            this.handleBlur();
                        }
                    },
                });
            }, 500);
        },
    },

};
</script>
