var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.context
    ? _c(
        "div",
        [
          _c("FormulateInput", {
            key: "development_type_3",
            attrs: {
              type: "select-plugin",
              name: "development_type",
              label: "Development Type",
              validation: "required",
              "validation-name": "Development type",
              options: _vm.developmentTypeOptions,
              placeholder: "Select Development Type",
              disabled: _vm.disabled,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-flex--tab" },
            [
              _vm.value.planning_status &&
              _vm.value.planning_status !== "pre-application"
                ? _c("FormulateInput", {
                    key: "planning_ref_3",
                    attrs: {
                      type: "text",
                      name: "planning_ref",
                      label: "Planning reference number",
                      validation: "required",
                      "validation-name": "Planning reference number",
                      disabled: _vm.disabled,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("FormulateInput", {
                key: "planning_status_3",
                attrs: {
                  type: "select-plugin",
                  name: "planning_status",
                  label: "Planning Status",
                  placeholder: "Select Planning Status",
                  options: _vm.planningStatusOptions,
                  validation: "required",
                  "validation-name": "Planning status",
                  disabled: _vm.disabled,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "land_status_3",
            attrs: {
              type: "select-plugin",
              name: "land_status",
              label: "Land status",
              validation: "required",
              "validation-name": "Land status",
              options: _vm.landStatusOptions,
              placeholder: "Select Land status",
              disabled: _vm.disabled,
            },
          }),
          _vm._v(" "),
          _c("form-desired", {
            attrs: {
              baseline: _vm.baselineHabitats,
              creation: _vm.creationHabitats,
              enhancement: _vm.enhancementsHabitats,
              "desired-uplift-percent": parseInt(
                _vm.value.onsite_desired_percentage ?? 0
              ),
              context: _vm.context,
            },
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "study_3",
            attrs: {
              type: "toggle-checkbox",
              name: "study",
              label: "Have you had a Baseline Study done?",
            },
          }),
          _vm._v(" "),
          !_vm.value.study
            ? _c("FormulateInput", {
                key: "study_requested_3",
                attrs: {
                  type: "toggle-checkbox",
                  name: "study_requested",
                  label: "Would you like a Baseline Study done?",
                  help: 'By selecting "Yes" we will send you an email to arrange a visit.',
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }