var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "tablegrid" },
    [
      _vm.hasHeader
        ? _c(
            "header",
            { staticClass: "flex-space-between" },
            [
              _vm._t("header-left", function () {
                return [_c("div")]
              }),
              _vm._v(" "),
              _vm._t("header-right", function () {
                return [
                  !_vm.mobile && !_vm.tablet && !_vm.laptop
                    ? [
                        _vm.output.length > 1
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "tablegrid-switcher btn-switcher-icons flex",
                              },
                              _vm._l(_vm.output, function (item) {
                                return _c(
                                  "button",
                                  {
                                    key: item,
                                    class: `${
                                      _vm.view === item ? "active" : ""
                                    }`,
                                    attrs: { value: item },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSwitch(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(!_vm.icons[item] ? item : "") +
                                        "\n                        "
                                    ),
                                    _c("SvgController", {
                                      attrs: { type: _vm.icons[item] },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.mobile && !_vm.tablet && !_vm.laptop
        ? [
            _vm.view === "table" && _vm.output.includes("table")
              ? _c(
                  "div",
                  { staticClass: "tablegrid-table tablegrid-item" },
                  [
                    _c("Table", {
                      attrs: {
                        columns: _vm.columnsFormat,
                        rows: _vm.rows,
                        "on-sort": _vm.onSort,
                        "has-row-click": _vm.hasRowClick,
                      },
                      on: { onRowClick: _vm.onRowClick },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.$scopedSlots, function (_, name) {
                            return {
                              key: name,
                              fn: function (slotData) {
                                return [
                                  name.indexOf("cell(") === 0
                                    ? _vm._t(name, null, null, slotData)
                                    : _vm._e(),
                                ]
                              },
                            }
                          }),
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              : _vm.view === "grid" && _vm.output.includes("grid")
              ? _c(
                  "div",
                  { staticClass: "tablegrid-grid tablegrid-item" },
                  [
                    _c("Grid", {
                      attrs: { layout: _vm.gridLayout, data: _vm.rows },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "card-header",
                            fn: function (props) {
                              return [
                                _vm._t("card-header", null, {
                                  item: props.item,
                                }),
                              ]
                            },
                          },
                          {
                            key: "card-title",
                            fn: function (props) {
                              return [
                                _vm._t("card-title", null, {
                                  item: props.item,
                                }),
                              ]
                            },
                          },
                          {
                            key: "card-description",
                            fn: function (props) {
                              return [
                                _vm._t("card-description", null, {
                                  item: props.item,
                                }),
                              ]
                            },
                          },
                          {
                            key: "card-footer",
                            fn: function (props) {
                              return [
                                _vm._t("card-footer", null, {
                                  item: props.item,
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : [
            _c(
              "div",
              { staticClass: "tablegrid-table tablegrid-item" },
              [
                _c("Grid", {
                  attrs: {
                    index: 2,
                    layout:
                      _vm.gridLayoutForTableFormat ||
                      _vm.gridLayoutFormat ||
                      _vm.gridLayoutForTableDefaultFormat,
                    data: _vm.rows,
                    includes: ["info", "footer", "dropdown"],
                    "full-width": true,
                  },
                }),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _vm.hasFooter ? _vm._t("footer") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }