<template>
    <app-layout section="settings">
        <page-header
            :title="event"
        />
        <div
            ref="container"
            class="center"
        >
            <div class="details">
                <div class="details-block details-bg">
                    <DocusignRow
                        v-for="item in data"
                        :key="item.title"
                        :data="item"
                    />
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import DocusignRow from 'Components/docusign/DocusignRow';

export default {
    name: 'DocusignList',
    components: {
        DocusignRow,
    },
    props: {
        docusign: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        event() {
            return this.capitalizeFirstLetter(this.docusign.event.replace(/-/g, ' '));
        },

        data() {
            return this.getObjectData(this.docusign.data).filter(item => {
                if (Array.isArray(item.data)) return item.data.length;

                return item.data;
            });
        },

        columns() {
            return [{
                label: 'Event',
                field: 'event',
                sortable: false,
            }, {
                label: 'ID',
                field: 'envelopeId',
                sortable: false,
            }, {
                label: 'Generated Date',
                field: 'generatedDateTime',
                sortable: false,
            }, {
                label: 'Authorized',
                field: 'authorized',
                sortable: false,
            }];
        },

        rows() {
            return this.docusign.related.map(document => ({...document, authorized: document.authorized ? 'Yes' : 'No'}));
        },
    },
    methods: {
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('docusign.show', row.id));
        },

        getArrayData(data, key = 'Additional Data') {
            return {
                title: key,
                data: data.map(data => this.getObjectData(data, key)),
            };
        },

        getObjectData(data, key = 'Additional Data') {
            if (data === null) return [];

            const strings = Object.keys(data).filter(key => typeof data[key] === 'string' || typeof data[key] === 'number');
            const objects = Object.keys(data).filter(key => typeof data[key] !== 'string' && !Array.isArray(data[key]));
            const arrays = Object.keys(data).filter(key => typeof data[key] !== 'string' && Array.isArray(data[key]));

            let dataReturn = [{
                title: key,
                data: strings.map(key => ({
                    title: key,
                    data: String(data[key]),
                })),
            }];

            arrays.forEach(key => {
                dataReturn = dataReturn.concat(this.getArrayData(data[key], key));
            });

            objects.forEach(key => {
                dataReturn = dataReturn.concat(this.getObjectData(data[key], key));
            });

            dataReturn = dataReturn.filter(key => {
                if (Array.isArray(data[key])) {
                    return data[key].length;
                }

                return true;
            });

            return dataReturn;
        },
    },
};
</script>
