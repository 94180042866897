<template>
    <div :class="`gridlayout${fullWidth ? ' full-width' : ''}`">
        <ul class="lst-reset gridlayout-list">
            <GridCard
                v-for="(item, indexLoop) in data"
                :key="`loop-${indexLoop}-${indexLoop}`"
                :index="indexLoop"
                :title="parseData(item, 'title')"
                :company="parseData(item, 'company')"
                :description="parseData(item, 'description')"
                :image="parseData(item, 'image')"
                :lat-long="parseData(item, 'latLong')"
                :show-map="parseData(item, 'showMap')"
                :type="layout.type"
                :fit-bounds="parseData(item, 'fitBounds')"
                :status="parseData(item, 'status')"
                :on-click="parseData(item, 'onClick')"
                :dropdown="parseData(item, 'dropdown')"
                :full-item="item"
                :includes="includes"
            >
                <template
                    slot="card-header"
                    slot-scope="props"
                >
                    <slot
                        name="card-header"
                        :item="props.item"
                    />
                </template>
                <template
                    slot="card-title"
                    slot-scope="props"
                >
                    <slot
                        name="card-title"
                        :item="props.item"
                    />
                </template>
                <template
                    slot="card-company"
                    slot-scope="props"
                >
                    <slot
                        name="card-company"
                        :item="props.item"
                    />
                </template>
                <template
                    slot="card-description"
                    slot-scope="props"
                >
                    <slot
                        name="card-description"
                        :item="props.item"
                    />
                </template>
                <template
                    slot="card-footer"
                    slot-scope="props"
                >
                    <slot
                        name="card-footer"
                        :item="props.item"
                    />
                </template>
            </GridCard>
        </ul>
    </div>
</template>

<script>
import GridCard from './GridCard';
export default {
    name: 'Grid',
    components: {
        GridCard,
    },
    props: {
        index: {
            type: Number,
            default: 1,
        },
        includes: {
            type: Array,
            default: undefined,
        },
        layout: {
            type: Object,
            default() {
                return {
                    title: 'title',
                    description: 'title',
                };
            },
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        parseData(objItem, strType, itemKey) {
            let rtnData,
                key = this.layout[strType];

            if (itemKey) {
                key = itemKey;
            }

            if (!key) {
                return null;
            }

            if (Array.isArray(key)) {
                return key.map((subKey) => {
                    return this.parseData(objItem, strType, subKey);
                });
            }

            rtnData = key;

            if (strType === 'plots') {
                rtnData = [];
            }

            if (typeof key === 'string') {
                let replacers = [key];
                const regex = new RegExp('\\${[a-z._]+}', 'g');

                if (regex.test(key)) {
                    replacers = key.match(regex);
                }

                replacers.forEach(replace => {
                    let data = '';
                    const replaceKey = replace.replace(/[${}]/g, '');

                    if (replaceKey.indexOf('.') > -1) {
                        const parts = replaceKey.split('.');

                        data = objItem[parts[0]];

                        for (let idx = 1; idx < parts.length; idx++) {
                            data = data[parts[idx]];
                        }
                    } else if (objItem[replaceKey]) {
                        data = objItem[replaceKey];
                    }

                    if (typeof rtnData === 'string' && typeof data === 'string') {
                        rtnData = rtnData.replace(replace, data);
                    } else {
                        rtnData = data;
                    }
                });

                if ((strType === 'latLong' || strType === 'path')) {
                    rtnData = JSON.parse(rtnData);
                }
            }

            return rtnData;
        },
    },
};
</script>
