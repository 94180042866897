var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasBaselineMetric && _vm.showBaseline
        ? [
            _c(
              "div",
              { staticClass: "details-block details-bg" },
              [
                _c("div", { staticClass: "formulate-heading" }, [
                  _c("h6", [_vm._v("Baseline Habitat Metrics")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formulate-heading-buttons" }, [
                    _vm.viewMetric.baseline
                      ? _c(
                          "button",
                          {
                            staticClass: "btn--link",
                            on: {
                              click: function ($event) {
                                _vm.viewMetric.baseline = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        See Metric Summary\n                    "
                            ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn--link",
                            on: {
                              click: function ($event) {
                                _vm.viewMetric.baseline = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        See Metric Data\n                    "
                            ),
                          ]
                        ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.viewMetric.baseline
                  ? _c("BNGCalculator", {
                      key: "currentMetric",
                      attrs: {
                        "form-type": "partial",
                        type: "baseline",
                        "show-units": false,
                        disabled: true,
                        "run-formula": false,
                        version: _vm.baselineLatestVersion,
                        pill: "",
                      },
                      model: {
                        value: _vm.baselineHabitats,
                        callback: function ($$v) {
                          _vm.baselineHabitats = $$v
                        },
                        expression: "baselineHabitats",
                      },
                    })
                  : _c("BngBaselineSummary", {
                      attrs: {
                        baseline: _vm.baselineHabitats,
                        creation: _vm.creationHabitats,
                        enhancement: _vm.enhancementsHabitats,
                        "include-chart": _vm.includeBaselineChart,
                      },
                    }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCreationMetric && _vm.showCreation
        ? [
            _c(
              "div",
              { staticClass: "details-block details-bg" },
              [
                _c("div", { staticClass: "formulate-heading" }, [
                  _c("h6", [_vm._v("Creation Habitat Metrics")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formulate-heading-buttons" }, [
                    _vm.viewMetric.creation
                      ? _c(
                          "button",
                          {
                            staticClass: "btn--link",
                            on: {
                              click: function ($event) {
                                _vm.viewMetric.creation = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        See Metric Summary\n                    "
                            ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn--link",
                            on: {
                              click: function ($event) {
                                _vm.viewMetric.creation = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        See Metric Data\n                    "
                            ),
                          ]
                        ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.viewMetric.creation
                  ? _c("BNGCalculator", {
                      key: "creationMetric",
                      attrs: {
                        type: "creation",
                        "form-type": "partial",
                        disabled: true,
                        "run-formula": false,
                        version: _vm.creationLatestVersion,
                        pill: "",
                        "max-area": _vm.model.size
                          ? parseFloat(_vm.model.size)
                          : null,
                      },
                      model: {
                        value: _vm.creationHabitats,
                        callback: function ($$v) {
                          _vm.creationHabitats = $$v
                        },
                        expression: "creationHabitats",
                      },
                    })
                  : _c("BngCreationSummary", {
                      attrs: {
                        baseline: _vm.baselineHabitats,
                        creation: _vm.creationHabitats,
                        enhancement: _vm.enhancementsHabitats,
                        "include-chart": _vm.includeCreationChart,
                      },
                    }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.hasEnhancementMetric && _vm.showEnhancement
        ? [
            _c(
              "div",
              { staticClass: "details-block details-bg" },
              [
                _c("div", { staticClass: "formulate-heading" }, [
                  _c("h6", [_vm._v("Enhancement Habitat Metrics")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "formulate-heading-buttons" }, [
                    _vm.viewMetric.enhancement
                      ? _c(
                          "button",
                          {
                            staticClass: "btn--link",
                            on: {
                              click: function ($event) {
                                _vm.viewMetric.enhancement = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        See Metric Summary\n                    "
                            ),
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn--link",
                            on: {
                              click: function ($event) {
                                _vm.viewMetric.enhancement = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        See Metric Data\n                    "
                            ),
                          ]
                        ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.viewMetric.enhancement
                  ? _c("BNGCalculator", {
                      key: "enhancementMetric",
                      attrs: {
                        type: "enhancement",
                        "form-type": "partial",
                        disabled: true,
                        "run-formula": false,
                        version: _vm.enhancementLatestVersion,
                        pill: "",
                        "max-area": _vm.model.size
                          ? parseFloat(_vm.model.size)
                          : null,
                      },
                      model: {
                        value: _vm.enhancementHabitats,
                        callback: function ($$v) {
                          _vm.enhancementHabitats = $$v
                        },
                        expression: "enhancementHabitats",
                      },
                    })
                  : _c("BngEnhancementSummary", {
                      attrs: {
                        baseline: _vm.baselineHabitats,
                        creation: _vm.creationHabitats,
                        enhancement: _vm.enhancementsHabitats,
                        "include-chart": _vm.includeEnhancementChart,
                      },
                    }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.desiredUpliftPercent !== null
        ? [
            _c("form-desired", {
              attrs: {
                baseline: _vm.baselineHabitats,
                creation: _vm.creationHabitats,
                enhancement: _vm.enhancementsHabitats,
                "desired-uplift-percent": _vm.desiredUpliftPercent,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }