<template>
    <transition name="modal">
        <div
            v-if="show"
            class="modal"
        >
            <div
                class="modal-mask"
                @click="maskClick"
            >
                <div
                    class="modal-container"
                    :style="{
                        width: width,
                        padding: padding ?? ''
                    }"
                    @click.stop
                >
                    <div
                        v-if="showHeader || showClose"
                        class="modal-header"
                        :style="{
                            margin: !showHeader && showClose ? 0 : '',
                            padding: padding ?? ''
                        }"
                    >
                        <slot name="header">
                            <h3 v-if="header">
                                {{ header }}
                            </h3>
                        </slot>
                        <button
                            v-if="showClose"
                            class="btn-reset modal-close-btn"
                            :class="{
                                'modal-close-btn--floating': closeFloating,
                            }"
                            @click.prevent
                            @click="closeModal()"
                        >
                            <SvgController type="times" />
                        </button>
                    </div>
                    <div
                        class="modal-body"
                        :style="{
                            padding: padding ?? ''
                        }"
                    >
                        <slot name="body">
                            Modal Content
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'Modal',
    components: {},
    props: {
        width: {
            type: String,
            default: null,
        },
        header: {
            type: String,
            default: null,
        },
        padding: {
            type: String,
            default: null,
        },
        closeMaskClick: {
            type: Boolean,
            default: true,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showClose: {
            type: Boolean,
            default: true,
        },
        closeFloating: {
            type: Boolean,
            default: false,
        },
        confirmClose: {
            type: String,
            default: null,
        },
        confirmCloseHeader: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            show: false,
        };
    },
    computed: {
        closeHeader() {
            if (this.confirmCloseHeader) {
                return this.confirmCloseHeader;
            }

            if (this.header) {
                return `Close ${this.confirmCloseHeader}`;
            }

            return 'Close Modal';
        },
    },
    methods:{
        closeModal() {
            if (this.confirmClose) {
                this.$confirm(
                    {
                        title: this.closeHeader,
                        message: this.confirmClose,
                        confirmBtnClass: 'btn--primary',
                        confirmBtnText: 'Close',
                        callback: confirm => {
                            if (confirm) {
                                this.show = false;
                                document.removeEventListener('keyup', this.escWindow);
                                this.$emit('close');
                            }
                        },
                    },
                );
            } else {
                this.show = false;
                document.removeEventListener('keyup', this.escWindow);
                this.$emit('close');
            }
        },
        openModal() {
            this.show = true;
            document.addEventListener('keyup', this.escWindow);
            this.$emit('open');
        },

        maskClick() {
            if (this.closeMaskClick) {
                this.closeModal();
            }
        },
        escWindow(event) {
            if (event.which === 27) {
                this.closeModal();
            }
        },
    },
};
</script>
