q<template>
    <div>
        <div class="formulate-heading flex-space-between">
            <h6>{{ title }}</h6>
            <button
                v-if="allowUpload"
                class="btn-reset btn--link btn--link--green btn--link--icon--left"
                @click="openFileModal()"
            >
                <SvgController type="plus" />
                {{ newText || `New ${title}` }}
            </button>
        </div>
        <TableGridLayout
            :columns="columnsList"
            :rows="contractList"
            :output="['table']"
            :total-records="contractList.length"
            :grid-layout-for-table="gridLayout"
            @onRowClick="onRowClick"
        />
        <contract-upload
            ref="fileModal"
            :parent="parent"
            @afterUpdate="afterUpdate"
        />
        <contract-upload
            ref="signedModal"
            :parent="parent"
            :is-signed="true"
            :contract="contract"
            @afterUpdate="afterUpdate"
        />
        <contract-delete
            ref="deleteModal"
            :parent="parent"
            :contract="contract"
            :attachments="attachmentHistory"
            @afterUpdate="afterUpdate"
        />
        <contract-edit
            ref="editModal"
            :contract="contract"
            @afterUpdate="afterUpdate"
        />
    </div>
</template>
<script>
import ContractUpload from 'Utilities/contract/Upload';
import ContractDelete from 'Utilities/contract/Delete';
import ContractEdit from 'Utilities/contract/Edit';

export default {
    name: 'ContactsTable',
    components: {
        ContractUpload,
        ContractDelete,
        ContractEdit,
    },
    props: {
        allowUpload: {
            type: Boolean,
            default: false,
        },
        allowDelete: {
            type: Boolean,
            default: false,
        },

        contracts: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => null,
        },
        parent: {
            type: Object,
            default: () => ({}),
        },

        title: {
            type: String,
            default: 'Contracts',
        },
        newText: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            gridLayout: null,
            contract: {},
            columnsList: null,
            contractList: [],
        };
    },
    computed: {
        attachmentHistory() {
            return this.contract.attachments;
        },
        attachmentLogs() {
            return this.contract.logs;
        },
    },
    watch: {
        contracts: {
            handler() {
                this.formatAttachments();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        if (!this.columns) {
            this.columnsList = [{
                label: 'Type',
                field: 'type',
            },{
                label: 'Last Edited On',
                field: 'last_edited_on',
                type: 'date',
                sortable: false,
            },{
                label: 'Last Edited By',
                field: 'last_edited_by',
                sortable: false,
            },{
                label: 'Status',
                field: 'status',
                spanClass: 'status-pill type-{status}',
                sortable: false,
            },{
                label: 'Editing',
                field: 'editing',
                spanClass: 'status-pill type-green',
                sortable: false,
            }];
        } else {
            this.columnsList = [...this.columns];
        }

        if (this.allowDelete) {
            this.dropdown = [
                {
                    name: 'Delete',
                    border: true,
                    onClick: (event, row, index) => {
                        this.deleteFile(this.contracts[index]);
                    },
                },
            ];
        }

        this.gridLayout = {
            title: 'type',
            description: 'Last edited on: ${last_edited_on}',
            status: ['status', 'editing'],
            onClick: (evt, row, index) => {
                this.onRowClick({
                    pageIndex: index,
                    row: this.contractList[index],
                });
            },
        };

        if (this.dropdown && this.dropdown.length) {
            const dropdown = {
                label: '',
                field: 'dropdown',
                type: 'dropdown',
                sortable: false,
                position: 'right',
                options: this.dropdown,
            };

            this.columnsList.push(dropdown);
            this.gridLayout.dropdown = this.dropdown;
        }
    },
    methods: {
        openFileModal() {
            this.$refs.fileModal.openModal();
        },
        formatAttachments() {
            this.contractList = this.contracts.map(contract => {
                if (contract.edited && contract.edited.length) {
                    contract.last_edited_on = contract.edited.at(-1).updated_at;
                    contract.last_edited_by = `${contract.edited.at(-1).user.first_name} ${contract.edited.at(-1).user.last_name}`;
                    contract.editing = contract.group.label;
                }

                if (contract.status === 'contract sent to docusign' || contract.status === 'contract signed') {
                    contract.editing = 'Complete';
                }

                return contract;
            });
        },
        deleteFile(contract) {
            this.$confirm(
                {
                    title: `Delete ${this.$options.filters.capitalize(contract.type)}`,
                    message: `Are you sure you want to delete this ${contract.type}? This action can't be undone.`,
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('contract.destroy', contract.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
        afterUpdate() {
            this.rental = this.$page.props.rental;
            this.formatAttachments();
        },
        openSignedModal() {
            this.$refs.signedModal.openModal();
        },
        openHistoryModal() {
            this.$refs.historyModal.openModal();
        },
        openLogsModal() {
            this.$refs.logsModal.openModal();
        },
        openDeleteModal() {
            this.$refs.deleteModal.openModal();
        },
        openEditModal() {
            this.$refs.editModal.openModal();
        },
        onRowClick(event) {
            this.$emit('onRowClick', event);
        },
    },
};
</script>
