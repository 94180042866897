<template>
    <app-layout section="agents">
        <page-header
            title="Agents"
            icon="tie"
            :count="agents.total"
        >
            <inertia-link
                v-if="loggedUser.can.includes('agent_invite')"
                type="button"
                class="btn btn--primary"
                :href="$route('agent.invite')"
            >
                <SvgController
                    type="plus"
                />
                Invite Agent
            </inertia-link>
        </page-header>

        <div
            class="center"
        >
            <empty
                v-if="!$page.props.hasItems"
                type="agents"
            />
            <div v-else>
                <div class="dashboard-heading flex-space-between">
                    <h6>Agents</h6>
                </div>
                <TableGridLayout
                    :columns="columns"
                    :rows="agents.data"
                    :output="['table']"
                    v-on="rowClickCheck ? {
                        onRowClick: onRowClick
                    } : {}"
                >
                    <template #header-left>
                        <list-filter
                            search-placeholder="Search Agents"
                            section="agents"
                        />
                    </template>

                    <template #footer>
                        <pagination
                            :links="agents.links"
                            section="agents"
                        />
                    </template>
                </TableGridLayout>
                <template v-if="invitations.length">
                    <div class="dashboard-heading flex-space-between mt-5">
                        <h6>Invitaitons</h6>
                    </div>
                    <TableGridLayout
                        :columns="invitationColumns"
                        :rows="formatInvitation"
                        :output="['table']"
                        v-on="rowClickCheck ? {
                            onRowClick: onRowClick
                        } : {}"
                    />
                </template>
            </div>
        </div>
    </app-layout>
</template>

<script>

import ListFilter from 'Utilities/list-filter/ListFilter.vue';
import Pagination from 'Utilities/pagination/Pagination.vue';
import Empty from 'Utilities/empty/Empty';

export default {
    name: 'Agents',
    components: {
        Empty,
        ListFilter,
        Pagination,
    },

    props: {
        agents: {
            type: Object,
            default: () => ({}),
        },
        invitations: {
            type: Array,
            default: () => ([]),
        },
        loggedUser: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            editMode: null,
            columns: [],
            invitationColumns: [],
            dropdown: [],
            editingUser: {},
        };
    },

    computed: {
        rowClickCheck() {
            return this.loggedUser.isSuperUser;
        },

        formatInvitation() {
            return this.invitations.map(invite => {
                return {
                    ...invite,
                    first_name: invite.first_name ? invite.first_name : ' - ',
                    last_name: invite.last_name ? invite.last_name : ' - ',
                    company_name: invite.company_name ? invite.company_name : ' - ',
                };
            });
        },
    },


    created() {
        const dropdown = [{
            name: 'Edit Agent',
            border: false,
            show: 'editable',
            onClick: (evt, row, index) => {
                this.$inertia.visit(this.$route('agent.edit', this.agents.data[index].id));
            },
        },{
            name: 'Delete Agent',
            show: 'deletable',
            color: 'red',
            onClick: (evt, row, index) => {
                this.deleteAgent(this.agents.data[index]);
            },
        }];

        this.columns = [{
            label: 'Company',
            'field': 'company_name',
        },{
            label: 'First Name',
            field: 'first_name',
        },{
            label: 'Surname',
            field: 'last_name',
        },{
            label: this.loggedUser.isSuperUser ? 'Date Joined' : 'Date Invited',
            field: 'date_invited',
        }];

        const invitationDropdown = [{
            name: 'Resend Invitation',
            show: 'resendInvite',
            onClick: (evt, row, index) => {
                this.$inertia.patch(this.$route('invite.resend', this.invitations[index].id));
            },
        },{
            name: 'Delete Agent',
            show: 'deletable',
            color: 'red',
            onClick: (evt, row, index) => {
                this.deleteInvite(this.invitations[index]);
            },
        }];

        this.invitationColumns = [{
            label: 'Company',
            'field': 'company_name',
        },{
            label: 'First Name',
            field: 'first_name',
        },{
            label: 'Surname',
            field: 'last_name',
        },{
            label: 'Email',
            field: 'email',
        },{
            label: 'Date Invited',
            field: 'date_invited',
        }];

        if (!this.loggedUser.isSuperUser) {
            this.columns.push({
                label: 'Status',
                field: 'status',
                spanClass: 'status-pill type-{status}',
            });

            this.columns.push({
                label: '',
                field: 'permissions',
                type: 'dropdown',
                sortable: false,
                position: 'right',
                options: dropdown,
            });

            this.invitationColumns.push({
                label: '',
                field: 'permissions',
                type: 'dropdown',
                sortable: false,
                position: 'right',
                options: invitationDropdown,
            });
        }
    },

    methods: {
        onRowClick({ row }) {
            if (this.loggedUser.isSuperUser) {
                this.$inertia.visit(this.$route('agent.show', row.company_id));
            }
        },

        deleteAgent(agent) {
            this.$confirm(
                {
                    title: 'Delete Agent',
                    message: 'Are you sure you want to delete this Agent? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('agent.delete', agent.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },

        deleteInvite(invitation) {
            this.$confirm(
                {
                    title: 'Delete Invite',
                    message: 'Are you sure you want to delete this Agent? This action can\'t be undone.',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Delete',
                    callback: confirm => {
                        if (confirm) {
                            this.$inertia.delete(this.$route('invite.destroy', invitation.id));
                            this.$confirm.close();
                        }
                    },
                },
            );
        },
    },
};
</script>
