<template>
    <div v-if="context">
        <FormulateInput
            key="development_type_3"
            type="select-plugin"
            name="development_type"
            label="Development Type"
            validation="required"
            validation-name="Development type"
            :options="developmentTypeOptions"
            placeholder="Select Development Type"
            :disabled="disabled"
        />
        <div class="form-flex--tab">
            <FormulateInput
                v-if="value.planning_status && value.planning_status !== 'pre-application'"
                key="planning_ref_3"
                type="text"
                name="planning_ref"
                label="Planning reference number"
                validation="required"
                validation-name="Planning reference number"
                :disabled="disabled"
            />
            <FormulateInput
                key="planning_status_3"
                type="select-plugin"
                name="planning_status"
                label="Planning Status"
                placeholder="Select Planning Status"
                :options="planningStatusOptions"
                validation="required"
                validation-name="Planning status"
                :disabled="disabled"
            />
        </div>
        <FormulateInput
            key="land_status_3"
            type="select-plugin"
            name="land_status"
            label="Land status"
            validation="required"
            validation-name="Land status"
            :options="landStatusOptions"
            placeholder="Select Land status"
            :disabled="disabled"
        />
        <form-desired
            :baseline="baselineHabitats"
            :creation="creationHabitats"
            :enhancement="enhancementsHabitats"
            :desired-uplift-percent="parseInt(value.onsite_desired_percentage ?? 0)"
            :context="context"
        />
        <FormulateInput
            key="study_3"
            type="toggle-checkbox"
            name="study"
            label="Have you had a Baseline Study done?"
        />
        <FormulateInput
            v-if="!value.study"
            key="study_requested_3"
            type="toggle-checkbox"
            name="study_requested"
            label="Would you like a Baseline Study done?"
            help="By selecting &quot;Yes&quot; we will send you an email to arrange a visit."
        />
    </div>
</template>

<script>
import FormDesired from 'Components/Metrics/Desired.vue';
import MetricMixin from 'Mixins/metric';

export default {
    name: 'ProjectFormPage3',

    components: { FormDesired },

    mixins: [MetricMixin],

    props: {
        context: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
        developmentTypeOptions: {
            type: Array,
            required: true,
        },
        baseline: {
            type: Array,
            default: null,
        },
        creation: {
            type: Array,
            default: null,
        },
        enhancement: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            baselineHabitats: null,
            creationHabitats: null,
            enhancementsHabitats: null,
        };
    },
    computed: {
        landStatusOptions() {
            return [{
                value: 'green field',
                label: 'Green Field',
            },{
                value: 'brown field',
                label: 'Brown Field',
            }];
        },

        planningStatusOptions() {
            return [{
                value:'pre-application',
                label:'Pre-application',
            },{
                value:'submitted',
                label:'Submitted',
            }, {
                value:'approved',
                label:'Approved',
            }, {
                value:'resolution to approve',
                label:'Resolution to Approve',
            }];
        },
    },

    created() {
        if (this.baseline && this.creation && this.enhancement) {
            const currentTargetVersion = this.getMetricLatestVersion(this.baseline);
            const creationTargetVersion = this.getMetricLatestVersion(this.creation);
            const enhancementTargetVersion = this.getMetricLatestVersion(this.creation);

            this.baselineHabitats = this.baseline.filter(habitat => parseFloat(habitat.version) === currentTargetVersion).map(habitat => habitat.associativeColumn);
            this.creationHabitats = this.creation.filter(habitat => parseFloat(habitat.version) === creationTargetVersion).map(habitat => habitat.associativeColumn);
            this.enhancementsHabitats = this.enhancement.filter(habitat => parseFloat(habitat.version) === enhancementTargetVersion).map(habitat => ({
                ...habitat.associativeColumn,
                baseline: habitat.baseline.associativeColumn,
            }));
        }
    },
};
</script>
