var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.project.address
      ? _c(
          "p",
          [
            _c("SvgController", { attrs: { type: "map-icon" } }),
            _vm._v("\n        " + _vm._s(_vm.project.address) + "\n    "),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "map-holder" },
      [
        _c(
          "mapping-tool",
          { attrs: { zoom: 16, "fit-bounds": "features" } },
          [_c("mapping-tool-schema", { attrs: { schema: _vm.toSchema } })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }