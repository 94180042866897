<template>
    <modal ref="edit">
        <template #header>
            <h3>New Landowner</h3>
        </template>
        <template #body>
            <FormulateForm
                ref="form"
                v-model="form"
                :errors="$page.props.errors"
                class="flex-col"
                @submit="submitForm"
            >
                <FormulateInput
                    type="text"
                    name="company_name"
                    label="Company Name"
                    validation="required"
                    validation-name="Company Name"
                />

                <FormulateInput
                    type="text"
                    name="company_number"
                    label="Company Number"
                    validation-name="Company Number"
                />

                <FormulateInput
                    type="text"
                    name="company_address"
                    label="Company Address"
                    validation-name="Company Address"
                />

                <FormulateInput
                    type="select-plugin"
                    name="country_code"
                    validation="required"
                    label="Country"
                    validation-name="Country"
                    reduce-key="code"
                    label-key="name"
                    :options="countries"
                    placeholder="Country"
                />

                <FormulateInput
                    type="email"
                    name="email"
                    label="Email Address"
                    validation="required|email"
                    validation-name="Email address"
                />

                <FormulateInput
                    type="text"
                    name="first_name"
                    label="First Name"
                    validation="required"
                    validation-name="First Name"
                />
                <FormulateInput
                    type="text"
                    name="last_name"
                    label="Surname"
                    validation="required"
                    validation-name="Surname"
                />
                <FormulateInput
                    type="toggle-button"
                    name="invite"
                    label="Send Invite"
                    description="Do you want to send the contact an invite to use the BNG Partnership system?"
                    :has-label="false"
                />
                <div class="formulate-input btn--group">
                    <a
                        href="#"
                        class="btn"
                        @click="$refs.edit.closeModal"
                    >
                        Cancel
                    </a>
                    <button
                        class="btn--reset btn btn--primary"
                        type="submit"
                    >
                        <SvgController type="tick" />
                        Save
                    </button>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    components: {
        Modal,
    },

    data() {
        return {
            form: this.$inertia.form({
                company_name: null,
                company_number: null,
                company_address: null,
                country_code: null,
                first_name: null,
                last_name: null,
                email: null,
                invite: false,
                type: 'landowner',
                company_id: this.$page.props.loggedUser.positions[0].company.id.toString(),
                group_id: 2,
                role_id: 1,
            }),
            countries: [],
        };
    },

    methods:{
        open() {
            window.axios.get(this.$route('countries'))
                .then((response) => {
                    this.countries = response.data;
                });
            this.$refs.edit.openModal();
        },

        submitForm() {
            let route = this.$route('landowner.store');

            if (this.form.invite) {
                route = this.$route('invite.send');
            }

            this.form.put(route, {
                onSuccess: () => {
                    this.$refs.edit.closeModal();
                },
            });
        },
    },
};
</script>
