var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasMetrics
        ? _c(
            "div",
            { staticClass: "details" },
            [
              _c("metricsPage", {
                attrs: {
                  baseline: _vm.baseline,
                  creation: _vm.creation,
                  enhancement: _vm.enhancement,
                  "show-baseline": _vm.viewMetric.baseline,
                  "show-creation": _vm.viewMetric.creation,
                  "show-enhancement": _vm.viewMetric.enhancement,
                  model: _vm.project,
                  "desired-uplift-percent":
                    _vm.project.onsite_desired_percentage,
                  "include-creation-chart": [
                    "creation-totals",
                    "creation-habitat-types",
                  ],
                },
              }),
            ],
            1
          )
        : _c(
            "Empty",
            { attrs: { type: "metric" } },
            [
              _vm.hasGroup(["sequest", "lpa", "agent", "landowner"]) &&
              _vm.hasPermission("project_save")
                ? _c(
                    "inertia-link",
                    {
                      staticClass: "btn-reset btn btn--primary",
                      attrs: {
                        href: _vm.$route("project.metric", _vm.project.id),
                      },
                    },
                    [_vm._v("\n            Create BNG Metric\n        ")]
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }