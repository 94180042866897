var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.endpoint
    ? _c(
        "div",
        {
          staticClass: "user-search",
          attrs: { name: _vm.context.attributes.name },
        },
        [
          _vm.context.model
            ? _c("div", { staticClass: "formulate-fake" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.context.model,
                      expression: "context.model",
                    },
                  ],
                  attrs: { type: "hidden" },
                  domProps: { value: _vm.context.model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.context, "model", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("input-tag", { on: { click: _vm.handleRemove } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.context.model.email) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                ),
              ])
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userSearch,
                    expression: "userSearch",
                  },
                ],
                staticClass: "user-search-input",
                attrs: { type: "text" },
                domProps: { value: _vm.userSearch },
                on: {
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.userSearch = $event.target.value
                    },
                    _vm.handleInput,
                  ],
                },
              }),
          _vm._v(" "),
          _vm.processing || (_vm.userList && _vm.showUserList)
            ? _c(
                "div",
                { staticClass: "user-search-list" },
                [
                  _vm.processing
                    ? _c("user-card", {
                        attrs: {
                          initials: "BP",
                          "first-name": "████",
                          "last-name": "██████",
                          email: "██████████@███████.com",
                          company: "██████ ██████",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (slotProps) {
                                return [
                                  _c(
                                    "processing",
                                    {
                                      style: {
                                        opacity:
                                          slotProps.type !== "initials"
                                            ? 0.3
                                            : "",
                                      },
                                      attrs: {
                                        spinner: false,
                                        dots: false,
                                        tag: "div",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(slotProps.content) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3329190405
                        ),
                      })
                    : _vm.showUserList
                    ? [
                        _c(
                          "ul",
                          [
                            _vm.userList.length
                              ? _vm._l(_vm.userList, function (user) {
                                  return _c(
                                    "li",
                                    {
                                      key: user.agent_contact,
                                      on: {
                                        mousedown: function ($event) {
                                          return _vm.handleSelectUser(
                                            $event,
                                            user
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("user-card", {
                                        attrs: {
                                          "first-name": user.first_name,
                                          "last-name": user.last_name,
                                          email: user.email,
                                          company: user.company,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                })
                              : _vm.searched
                              ? [
                                  _vm.allowCustom && _vm.validateNew
                                    ? _c(
                                        "li",
                                        {
                                          on: { mousedown: _vm.handleNewUser },
                                        },
                                        [
                                          _c("user-card", {
                                            attrs: {
                                              initials: "+",
                                              "first-name": _vm.userSearch,
                                              email: _vm.customString,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "li",
                                        [
                                          _c("user-card", {
                                            attrs: {
                                              initials: "?",
                                              "first-name": _vm.failMessage,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      )
    : _c("div", { staticClass: "formulate-fake" }, [_vm._m(0)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n        No endpoint set to get users.\n        "),
      _c("br"),
      _vm._v(
        "\n        This functionality uses inertia and only ['users'] to get information back.\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }