<template>
    <div
        :class="{
            'pdf-report' : isDownload
        }"
    >
        <section class="pdf-item">
            <page-header
                :title="title"
                icon="reports"
            >
                <template #page-header-center>
                    {{ date }}
                </template>
                <div class="">
                    <h3>BNG Agreement Site Report</h3>
                    <h3>{{ upperFirst(rental.status) }}</h3>
                </div>

                <template #page-header-bottom>
                    <div class="center">
                        <div class="tile-wrap flex-space-between">
                            <div class="tile">
                                <div class="tile-inner tile-inner--single">
                                    <div class="tile-text">
                                        <h4>
                                            <span>
                                                Offsite Location:
                                            </span>
                                            {{ rental.plot.address }}
                                        </h4>
                                        <h4>
                                            <span>
                                                URN:
                                            </span>
                                            {{ rental.unique_reference_number }}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="link-wrap flex-space-between">
                            <dashboard-link
                                title="Size"
                                color="#627086"
                                :text="rental.size"
                            />
                            <dashboard-link
                                title="Agreement Start Date"
                                color="#6967D5"
                                :text="rental.rental_startdate"
                            />
                            <dashboard-link
                                title="Agreement End Date"
                                color="#E47D44"
                                :text="rental.rental_enddate"
                            />
                            <dashboard-link
                                title="BNG Units"
                                color="#1782FF"
                                :text="rental.units"
                            />
                        </div>
                    </div>
                </template>
            </page-header>
        </section>
        <div class="center">
            <section
                class="pdf-item"
            >
                <div class="dashboard-heading flex-space-between">
                    <h6>Development</h6>
                </div>
                <div
                    class="flex-col"
                >
                    <div
                        class="flex"
                    >
                        <div class="report-dev-info">
                            <h4>
                                <span>Development Name:</span>
                                {{ rental.project.name }}
                            </h4>
                            <h4>
                                <span>URN:</span>
                                {{ rental.project.unique_reference_number }}
                            </h4>
                            <h4>
                                <span>Address:</span>
                                {{ rental.project.address }}
                            </h4>
                            <h4>
                                <span>Size:</span>
                                {{ floatOutput(rental.project.size) }}
                            </h4>
                            <h4>
                                <span>Planning No:</span>
                                {{ rental.project.planning_ref }}
                            </h4>
                            <h4>
                                <span>Status:</span>
                                {{ rental.project.status }}
                            </h4>
                            <h4>
                                <span>Dev Type:</span>
                                {{ rental.project.development_type }}
                            </h4>
                            <h4>
                                <span>Land Status:</span>
                                {{ rental.project.land_status }}
                            </h4>
                            <h4>
                                <span>Total Units:</span>
                                {{ floatOutput(rental.project.onsite_baseline_total_units) }}
                            </h4>
                            <h4>
                                <span>Desired Offsite Units:</span>
                                {{ floatOutput(rental.project.desired_total) }}
                            </h4>
                        </div>
                        <div class="report-dev-map">
                            <report-map
                                ref="rentalmap"
                                type="rental"
                                :zoom="10"
                                :rental="rental"
                                :projects="[rental.project]"
                                :is-download="isDownload"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import { DateTime } from 'luxon';
import { DashboardLink } from 'Components/Dashboard';

import ReportMap from 'Utilities/maps/ReportMap';

import { upperFirst } from 'lodash';

export default {
    name: 'RentalReport',
    components: {
        ReportMap,
        DashboardLink,
    },
    mixins: [{methods: {upperFirst}}],

    props: {
        rental: {
            type: Object,
            required: true,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
        };
    },

    computed: {
        date() {
            return DateTime.fromJSDate(new Date(this.rental.updated_at)).toLocaleString();
        },
    },

    mounted() {},

    methods:{},
};
</script>
