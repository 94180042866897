var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("auth-layout", { attrs: { component: _vm.$options.name } }, [
    _c(
      "div",
      [
        _c(
          "FormulateForm",
          {
            attrs: { errors: _vm.$page.props.errors },
            on: {
              validation: function ($event) {
                _vm.validation = $event
              },
              submit: _vm.onSubmit,
            },
            model: {
              value: _vm.form,
              callback: function ($$v) {
                _vm.form = $$v
              },
              expression: "form",
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.page === 1,
                    expression: "page === 1",
                  },
                ],
                staticClass: "form-page bg-white",
              },
              [
                _c("div", { staticClass: "form-page-info" }, [
                  _c("h3", [_vm._v("You've been invited")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.invitedBy) +
                        " has invited you to be " +
                        _vm._s(_vm.typeName) +
                        " on BNG Partnership platform. Please check if information below is correct\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "company_id",
                    label: "Company Name",
                    validation: "required",
                    "validation-name": "Company Name",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "company_number",
                    label: "Company Number",
                    "validation-name": "Company Number",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "company_address",
                    label: "Company Address",
                    "validation-name": "Company Address",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "first_name",
                    label: "First Name",
                    validation: "required",
                    "validation-name": "First Name",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "text",
                    name: "last_name",
                    label: "Surname",
                    validation: "required",
                    "validation-name": "Surname",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: {
                    type: "email",
                    name: "email",
                    label: "Email Address",
                    validation: "required|email",
                    "validation-name": "Email address",
                  },
                }),
                _vm._v(" "),
                _c("FormulateInput", {
                  attrs: { type: "button", label: "Looks good" },
                  on: { click: _vm.nextPage },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.page === 2,
                    expression: "page === 2",
                  },
                ],
                staticClass: "form-page bg-white",
              },
              [
                _c("page-two", {
                  on: {
                    back: function ($event) {
                      _vm.page = 1
                    },
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }