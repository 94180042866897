var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "loadingModal",
    staticClass: "modal-confirm",
    attrs: { "close-mask-click": false },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v(_vm._s(_vm.title))])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex-col justify-center items-center" },
              [
                _c("loading-progress", {
                  attrs: {
                    progress: _vm.progress / 100,
                    size: "64",
                    rotate: "",
                    "fill-duration": "2",
                    "rotation-duration": "1",
                  },
                }),
                _vm._v(" "),
                _vm.title
                  ? _c("h4", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.title) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.message
                  ? _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.message) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }