<template>
    <app-layout
        section="agents"
        class="agents"
    >
        <page-header
            title="Invite Agent"
            icon="tie"
            :back="{url: $route('agents'), text: 'All Agents'}"
            :background="false"
        >
            <inertia-link
                :href="$route('agents')"
                class="btn"
            >
                Cancel
            </inertia-link>
            <button
                id="form-submit"
                class="btn-reset btn btn--primary"
                @click="submitForm"
            >
                <SvgController type="tick" />
                <span v-if="!agent.id">Invite</span>
                <span v-else>Update</span>
            </button>
        </page-header>
        <div class="center">
            <FormulateForm
                ref="form"
                v-model="form"
                :errors="$page.props.errors"
                class="flex-col"
            >
                <section class="agent-info">
                    <h6 class="section-header">
                        Agent Details
                    </h6>
                    <div
                        class="flex-space-between field-row"
                    >
                        <FormulateInput
                            v-if="agent.id"
                            type="fake"
                            label="Company"
                            :disabled="editable"
                            :value="agent.company_name"
                        />
                        <FormulateInput
                            v-else
                            type="user-search"
                            name="user"
                            label="Search for user"
                            :endpoint="$route('agent.invite')"
                            validation="required"
                            validation-name="Email"
                            custom-string="Invite Agent"
                            :disabled="editable"
                        />
                    </div>
                </section>
                <section class="agent-permissions">
                    <h6 class="section-header">
                        Permissions
                    </h6>

                    <FormulateInput
                        type="group"
                        name="permissions"
                    >
                        <FormulateInput
                            type="toggle-button"
                            name="item_save"
                            :label="`Create New ${itemsTitle}`"
                            :description="`Agent is allowed to create new ${itemsName} on your behalf.`"
                            :has-label="false"
                        />
                        <FormulateInput
                            type="toggle-button"
                            name="item_all"
                            :label="`Access All ${itemsTitle}`"
                            :description="`Agent will be able to access and make changes to all your ${itemsName}. Turn this option off to select specific ${itemsName} for the Agent to access.`"
                            @input="updateItems"
                        />

                        <section
                            v-show="!form.permissions[0].item_all"
                            class="agent-projects"
                        >
                            <h6 class="section-header">
                                allowed {{ itemsName }}&nbsp;&nbsp;<span>|&nbsp;&nbsp;<span>mark {{ itemsName }} you want the agent to access</span>
                                </span>
                            </h6>
                            <FormulateInput
                                type="group"
                                name="items"
                            >
                                <plot-table
                                    v-if="agent.group === 2"
                                    :plots="itemList"
                                    :has-dropdown="false"
                                    :filter-route="filterRoute"
                                    :has-checkbox="true"
                                    :has-items="hasItems"
                                    :output="['table']"
                                />
                                <project-table
                                    v-else-if="agent.group === 3"
                                    :projects="itemList"
                                    :has-dropdown="false"
                                    :filter-route="filterRoute"
                                    :has-checkbox="true"
                                    :has-items="hasItems"
                                    :output="['table']"
                                />
                            </formulateinput>
                        </section>
                        <FormulateInput
                            type="toggle-button"
                            name="contract_view"
                            label="View Contracts"
                            description="Agent is allowed to view and edit all your contracts."
                        />
                        <FormulateInput
                            type="toggle-button"
                            name="contract_confirm"
                            label="Can Confirm Contracts"
                            description="Agent is allowed to confirm contracts of your behalf."
                        />
                    </FormulateInput>
                </section>
            </FormulateForm>
        </div>
    </app-layout>
</template>

<script>

import ProjectTable from '../projects/Table';
import PlotTable from '../plots/Table';

export default {
    name: 'AgentEdit',
    components: {
        ProjectTable,
        PlotTable,
    },

    props: {
        agent: {
            type: Object,
            default: () => ({}),
        },
        items: {
            type: Object,
            default: () => ({}),
        },
        hasItems: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            form: this.$inertia.form({
                permissions: [{
                    item_save: true,
                    item_all: true,
                    contract_view: true,
                    contract_confirm: true,
                    items: [{}],
                }],
                type: 'agent',
                company_id: this.$page.props.loggedUser.positions[0].company.id.toString(),
                group_id: 4,
                role_id: 1,
                ...this.agent,
            }),
        };
    },

    computed: {
        itemsTitle() {
            if (this.agent.group === 2) {
                return 'Land Parcels';
            }

            return 'Developments';
        },
        itemsName() {
            if (this.agent.group === 2) {
                return 'land parcels';
            }

            return 'developments';
        },

        itemList() {
            if (this.items.data) {

                const items = this.items;

                const itemPermissions = this.form.permissions[0].items;

                items.data = items.data.map((item) => {

                    if (itemPermissions && itemPermissions[0][`item_${item.id}`] !== undefined) {
                        item.selected = itemPermissions[0][`item_${item.id}`];
                    }

                    return item;
                });

                return items;
            }

            return {};
        },

        filterRoute() {
            if (this.agent.id) {
                return this.$route('agent.edit', this.agent.id);
            }

            return this.$route('agent.invite');
        },

        editable() {
            if (this.agent.id && this.agent.status === 'active') {
                return true;
            }

            return false;
        },
    },

    mounted() {
        this.updateItems(this.form.permissions[0].item_all);
    },

    methods:{
        submitForm() {
            if (this.agent.status === 'pending') {
                this.form.patch(this.$route('invite.update', this.agent.invitation_id));
            } else if (this.agent.id) {
                this.form.patch(this.$route('agent.update', this.agent.id));
            } else {
                const data = {...this.form};

                if (this.form.user.agent_contact && this.form.user.company_id) {
                    data.invite_user_id = this.form.user.agent_contact;
                    data.invite_company_id = this.form.user.company_id;
                } else {
                    data.email = this.form.user.email;
                }

                delete data.user;

                this.$inertia.put(this.$route('invite.send'), data);
            }
        },
        updateItems(value) {
            if (!value) {
                this.$inertia.get(this.filterRoute, {}, {
                    preserveState: true,
                    only: ['items', 'hasItems'],
                    replace: true,
                });
            }
        },
    },
};
</script>
