<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="formulate-fake">
        <input
            v-model="context.model"
            type="hidden"
            v-bind="context.attributes"
        >
        <p
            @click="context.rootEmit('click')"
        >
            {{ getParseValue }}
        </p>
        <FormulateSlot
            name="suffix"
            :context="context"
        >
            <component
                :is="context.slotComponents.suffix"
                v-if="context.slotComponents.suffix"
                :context="context"
            />
        </FormulateSlot>
    </div>
</template>

<script>
export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
        parseValue: {
            type: Function,
            default: null,
        },
    },
    computed: {
        getParseValue() {
            if (this.context.model === '' || this.context.model === undefined) {
                return '\xa0';
            } else if (this.parseValue) {
                return this.parseValue(this.context.model);
            }

            return this.context.model;
        },
    },
};
</script>
