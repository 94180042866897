<template>
    <div>
        <p v-if="project.address">
            <SvgController type="map-icon" />
            {{ project.address }}
        </p>
        <div class="map-holder">
            <mapping-tool
                :zoom="16"
                fit-bounds="features"
            >
                <mapping-tool-schema
                    :schema="toSchema"
                />
            </mapping-tool>
        </div>
    </div>
</template>

<script>
import mapMixin from 'Mixins/map.js';

export default {
    name: 'DevelopmentLocation',
    mixins: [
        mapMixin,
    ],
    props: {
        project: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        toSchema() {
            const schema = [];

            if (this.project.location_data) {
                schema.push(this.plotToSchema(this.project, {
                    markerZoom: null,
                }));
            }

            if (this.project.country_code === 'GB') {
                if (this.$page.props.loggedUser.isSuperUser && this.project.national_character_areas) {
                    const vectorData = this.mapServiceProperties['national_character_areas'];

                    const filter = [{
                        property: vectorData.properties.name,
                        compare: '=',
                        type: 'OR',
                        value: this.project.national_character_areas.map(nca => nca.name),
                    }];

                    const push = this.vectorToSchema({
                        name: 'national_character_areas',
                        filter: filter,
                        color: 'national_character_areas',
                        strokeColor: 'national_character_areas',
                    });

                    if (push) schema.push(push);
                }

                if (this.project.local_planning_authority) {
                    const name = this.project.local_planning_authority.name;
                    const vectorData = this.mapServiceProperties['lpa'];

                    const filter = [{
                        property: vectorData.properties.name,
                        compare: '=',
                        type: 'OR',
                        value: [name.replace(' LPA', ''), name, `${name} LPA`],
                    }];

                    const push = this.vectorToSchema({
                        name: 'lpa',
                        filter: filter,
                        color: 'lpa',
                        strokeColor: 'lpa',
                    });

                    if (push) schema.push(push);
                }

                if (this.project.river_basin_district) {
                    const name = this.project.river_basin_district.name;
                    const vectorData = this.mapServiceProperties['surface_water_operational_catchments'];

                    const filter = [{
                        property: vectorData.properties.name,
                        compare: '=',
                        type: 'OR',
                        value: name,
                    }];

                    const push = this.vectorToSchema({
                        name: 'surface_water_operational_catchments',
                        filter: filter,
                        color: 'river_basin_districts',
                        strokeColor: 'river_basin_districts',
                    });

                    if (push) schema.push(push);
                }
            }

            return schema;
        },
    },
};
</script>
