var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bng-summary" }, [
    _c(
      "ul",
      { staticClass: "bng-summary-lists" },
      _vm._l(_vm.charts, function (chart, index) {
        return _c("li", { key: `chart-${index}-${chart.title}` }, [
          _c(
            "div",
            { staticClass: "relative" },
            [
              _c("GChart", {
                attrs: {
                  type: chart.type,
                  data: chart.data,
                  options: chart.options,
                },
              }),
              _vm._v(" "),
              !chart.error.success
                ? _c(
                    "Alert",
                    {
                      staticClass: "absolute top-0 right-0 m-0",
                      attrs: { type: "warning" },
                    },
                    [_c("p", [_vm._v(_vm._s(chart.error.message))])]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-flex--tab" },
      [
        _c("FormulateInput", {
          attrs: {
            type: "fake",
            label: "Onsite habitat total",
            "parse-value": () => _vm.floatOutput(_vm.totals.habitat),
          },
          model: {
            value: _vm.totals.habitat,
            callback: function ($$v) {
              _vm.$set(_vm.totals, "habitat", $$v)
            },
            expression: "totals.habitat",
          },
        }),
        _vm._v(" "),
        _c("FormulateInput", {
          attrs: {
            type: "fake",
            label: "Onsite baseline hedgerow total",
            "parse-value": () => _vm.floatOutput(_vm.totals.hedgerow),
          },
          model: {
            value: _vm.totals.hedgerow,
            callback: function ($$v) {
              _vm.$set(_vm.totals, "hedgerow", $$v)
            },
            expression: "totals.hedgerow",
          },
        }),
        _vm._v(" "),
        _c("FormulateInput", {
          attrs: {
            type: "fake",
            label: "Onsite baseline watercourse total",
            "parse-value": () => _vm.floatOutput(_vm.totals.watercourse),
          },
          model: {
            value: _vm.totals.watercourse,
            callback: function ($$v) {
              _vm.$set(_vm.totals, "watercourse", $$v)
            },
            expression: "totals.watercourse",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-flex--tab" },
      [
        _c("FormulateInput", {
          attrs: {
            type: "fake",
            label: "Onsite baseline total",
            "parse-value": () => _vm.floatOutput(_vm.totals.total),
          },
          model: {
            value: _vm.totals.total,
            callback: function ($$v) {
              _vm.$set(_vm.totals, "total", $$v)
            },
            expression: "totals.total",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }