var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.data.data, function (item) {
      return _c(
        "div",
        { key: item.title },
        [
          Array.isArray(item)
            ? [
                _c("div", { staticClass: "formulate-heading" }, [
                  _c("h6", [_vm._v(_vm._s(_vm.data.title))]),
                ]),
                _vm._v(" "),
                _vm._l(item, function (subItem) {
                  return _c("DocusignRow", {
                    key: subItem.title,
                    attrs: { data: subItem },
                  })
                }),
              ]
            : [
                _c("h5", [_vm._v(_vm._s(item.title))]),
                _vm._v(" "),
                item.title.toLowerCase() !== "pdfbytes"
                  ? _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.data) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ],
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }