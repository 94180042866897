var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "agents" } },
    [
      _c(
        "page-header",
        { attrs: { title: "Agents", icon: "tie", count: _vm.agents.total } },
        [
          _vm.loggedUser.can.includes("agent_invite")
            ? _c(
                "inertia-link",
                {
                  staticClass: "btn btn--primary",
                  attrs: { type: "button", href: _vm.$route("agent.invite") },
                },
                [
                  _c("SvgController", { attrs: { type: "plus" } }),
                  _vm._v("\n            Invite Agent\n        "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center" },
        [
          !_vm.$page.props.hasItems
            ? _c("empty", { attrs: { type: "agents" } })
            : _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "dashboard-heading flex-space-between" },
                    [_c("h6", [_vm._v("Agents")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "TableGridLayout",
                    _vm._g(
                      {
                        attrs: {
                          columns: _vm.columns,
                          rows: _vm.agents.data,
                          output: ["table"],
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header-left",
                            fn: function () {
                              return [
                                _c("list-filter", {
                                  attrs: {
                                    "search-placeholder": "Search Agents",
                                    section: "agents",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c("pagination", {
                                  attrs: {
                                    links: _vm.agents.links,
                                    section: "agents",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm.rowClickCheck
                        ? {
                            onRowClick: _vm.onRowClick,
                          }
                        : {}
                    )
                  ),
                  _vm._v(" "),
                  _vm.invitations.length
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "dashboard-heading flex-space-between mt-5",
                          },
                          [_c("h6", [_vm._v("Invitaitons")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "TableGridLayout",
                          _vm._g(
                            {
                              attrs: {
                                columns: _vm.invitationColumns,
                                rows: _vm.formatInvitation,
                                output: ["table"],
                              },
                            },
                            _vm.rowClickCheck
                              ? {
                                  onRowClick: _vm.onRowClick,
                                }
                              : {}
                          )
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }