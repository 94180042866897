var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _vm.show
      ? _c("div", { staticClass: "modal" }, [
          _c(
            "div",
            { staticClass: "modal-mask", on: { click: _vm.maskClick } },
            [
              _c(
                "div",
                {
                  staticClass: "modal-container",
                  style: {
                    width: _vm.width,
                    padding: _vm.padding ?? "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _vm.showHeader || _vm.showClose
                    ? _c(
                        "div",
                        {
                          staticClass: "modal-header",
                          style: {
                            margin: !_vm.showHeader && _vm.showClose ? 0 : "",
                            padding: _vm.padding ?? "",
                          },
                        },
                        [
                          _vm._t("header", function () {
                            return [
                              _vm.header
                                ? _c("h3", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.header) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                          _vm._v(" "),
                          _vm.showClose
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn-reset modal-close-btn",
                                  class: {
                                    "modal-close-btn--floating":
                                      _vm.closeFloating,
                                  },
                                  on: {
                                    click: [
                                      function ($event) {
                                        $event.preventDefault()
                                      },
                                      function ($event) {
                                        return _vm.closeModal()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("SvgController", {
                                    attrs: { type: "times" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "modal-body",
                      style: {
                        padding: _vm.padding ?? "",
                      },
                    },
                    [
                      _vm._t("body", function () {
                        return [
                          _vm._v(
                            "\n                        Modal Content\n                    "
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }