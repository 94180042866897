var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "project.metric" } },
    [
      _c(
        "page-header",
        {
          attrs: { title: _vm.pageTitle, icon: "projects", background: false },
        },
        [
          _c(
            "inertia-link",
            { staticClass: "btn-reset btn", attrs: { href: _vm.cancelRoute } },
            [_vm._v("\n            Cancel\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bng-metric-form", {
        attrs: {
          model: _vm.project,
          "model-type": "project",
          disabled: _vm.disabled,
          desired: true,
          "desired-uplift-percent": _vm.project.onsite_desired_percentage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }