<template>
    <app-layout section="plots">
        <page-header
            :title="pageTitle"
            icon="plots"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="cancelRoute"
            >
                Cancel
            </inertia-link>
        </page-header>
        <FormulateForm
            ref="form"
            v-model="form"
            :errors="$page.props.errors"
            class="formulate-form--plot flex-col"
            :keep-model-data="true"
            @submit="submitForm"
        >
            <PagerContent
                ref="pager"
                class="center"
                :errors="pageErrors"
                :formulate="$refs.form"
                :formulate-errors="$page.props.errors"
                theme="bng"
                :pagination="{
                    type: 'nav',
                }"
            >
                <PagerPage
                    title="Land Parcel Details"
                >
                    <FormPage1
                        :value="form"
                        :context="formLoaded ? $refs.form : null"
                        :disabled="!editable"
                        :companies="companies"
                        :countries="countries"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        :editable="editable"
                    />
                </PagerPage>
                <PagerPage
                    name="nutrient_offset"
                    title="Nutrient Offset"
                    :enabled="form.offset_type.includes('nutrient_offset')"
                >
                    <FormPage2
                        ref="nutrient_offset"
                        :value="form"
                        :context="formLoaded ? $refs.form : null"
                        :edit="edit"
                        :animal-settings="animalSettings"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        :disabled="!editable"
                    />
                </PagerPage>
                <template #pagination-prev-button>
                    <SvgController type="arrow-left" />
                    <span>Previous</span>
                </template>
                <template #pagination-next-button>
                    <span>Next</span>
                    <SvgController type="arrow-right" />
                </template>
                <template #pagination-next-last>
                    <button
                        class="btn-reset btn btn--primary"
                        @click.prevent="submitFormButton"
                    >
                        <SvgController
                            v-if="!edit"
                            type="tick"
                        />
                        <span>{{ buttonText }}</span>
                    </button>
                </template>
            </PagerContent>
        </FormulateForm>
    </app-layout>
</template>
<script>
import FormPage1 from './form/FormPage1';
import FormPage2 from './form/FormPage2';
import mapMixin from 'Mixins/map.js';

import { PagerContent, PagerPage } from 'vue2-pager';

export default {
    name: 'PlotForm',
    components: {
        FormPage1,
        FormPage2,
        PagerContent,
        PagerPage,
    },
    mixins: [
        mapMixin,
    ],
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        plot: {
            type: Object,
            default: ()=>({}),
        },
        companies: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => ([]),
        },
        planningAuthorities: {
            type: Array,
            default: () => ([]),
        },
        riverBasinDistricts: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            form: null,
            zoom: 6,
            lpa: null,
            pageErrors: {},
            changingValue: false,
            animalSettings: null,
            formLoaded: false,
        };
    },
    computed: {
        pageTitle() {
            return this.edit ? 'Edit Land Parcel' : 'New Land Parcel';
        },
        buttonText() {
            return this.edit ? 'Save Changes' : 'Save';
        },
        cancelRoute() {
            return this.edit ? this.$route('plot.show', this.plot.id) : this.$route('plots');
        },
        editable() {
            if (this.hasGroup(['sequest'])) {
                return true;
            }

            if (!this.edit) {
                return true;
            }

            return (this.edit && this.plot.status === 'BNG Units available');
        },
        getPaths() {
            return this.parsePaths(this.plot.location_data);
        },
    },
    created() {
        const offsetTypes = this.edit && this.plot.offset_type ? this.plot.offset_type.map(offset => offset.name) : [];

        this.animalSettings = {
            'Dairy': {
                label: 'dairy cows',
                min: 0,
                max: 1000,
                inBetween: 100,
                allowMore: true,
            },
            'Beef': {
                label: 'beef cows',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
            'Sheep': {
                label: 'sheep',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
            'Indoor pigs': {
                label: 'indoor pigs',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
            'Outdoor pigs': {
                label: 'outdoor pigs',
                min: 0,
                max: 200,
                inBetween: 15,
                allowMore: true,
            },
        };

        const animalTypesNumbers = {};

        Object.keys(this.animalSettings).forEach(animal => {
            animalTypesNumbers[`livestock_${animal}`] = [this.animalSettings[animal].min + this.animalSettings[animal].inBetween, this.animalSettings[animal].min + (this.animalSettings[animal].inBetween * 2)];
            const findLivestock = this.plot && this.plot.livestock_data ? this.plot.livestock_data.filter(livestock => animal === livestock.name) : [];

            if (findLivestock.length) {
                animalTypesNumbers[`livestock_${animal}`] = [findLivestock[0].min_value, findLivestock[0].max_value];
            }
        });

        const mapLocation = {
            location: {address: this.plot.address},
            size: this.plot.size,
            country: this.plot.country_code,
        };

        if (this.plot.location_data) {
            const paths = this.getPaths;

            if (
                ((this.plot.location_data.layer && this.plot.location_data.layer.center) || this.plot.location_data.center) &&
                ((this.plot.location_data.layer && this.plot.location_data.layer.radius) || this.plot.location_data.radius)
            ) {
                mapLocation.center = this.plot.location_data.layer ? this.plot.location_data.layer.center : this.plot.location_data.center;
                mapLocation.radius = this.plot.location_data.layer ? this.plot.location_data.layer.radius : this.plot.location_data.radius;
            } else if (paths) {
                mapLocation.paths = paths;
            }
        } else {
            mapLocation.paths = [];
        }

        this.form = this.$inertia.form({
            // General
            name: this.plot.name,
            country_code: this.plot.country_code,
            offset_type: offsetTypes,
            auto_calculate: this.plot.size_user === null,
            size_map: this.plot.size_map,
            size_user: this.plot.size_user ? this.plot.size : null,
            size_units: this.plot.size_units,
            company_id: this.plot.company_id ? String(this.plot.company_id) : null,
            status: this.edit ? this.plot.status : 'Identified land',
            map_location: mapLocation,
            address: this.plot.address,
            address_autofill: this.plot.address_autofill ?? 1,
            location_data: [],

            // BNG
            planning_authority_id: offsetTypes.includes('bng') ? String(this.plot.planning_authority_id) : null,
            management_style: this.plot.management_style,
            potential_units: this.plot.potential_units,
            onsite_creation_total_units: this.plot.onsite_creation_total_units,
            study: this.plot.study === true || this.plot.study === 1 ? true : false,

            // RIVERS
            river_basin_district_id: offsetTypes.includes('nutrient_offset') ? String(this.plot.river_basin_district_id) : null,
            drainage: this.plot.drainage ? this.plot.drainage : null,
            soil_type: this.plot.soil_type ? this.plot.soil_type : null,
            river_running: this.plot.river_running ? this.plot.river_running : false,
            livestock: this.plot.livestock ? this.plot.livestock : false,
            livestock_type: this.plot.livestock_data ? this.plot.livestock_data.map(livestock => livestock.name) : [],
            ...animalTypesNumbers,
        });

        this.mapZoom();
    },
    mounted() {
        this.formLoaded = true;
    },
    methods: {
        async submitForm() {
            this.$refs.pager.submitForm(async ({ validated }) => {
                if (validated) {
                    const {
                        country,
                        location,
                        paths,
                        center,
                        radius,
                        size,
                    } = this.form.map_location;

                    this.form.address = location.address;
                    this.form.size = size;

                    if (center && radius) {
                        this.form.location_data = {
                            center,
                            radius,
                        };
                    } else {
                        this.form.location_data = paths;
                    }

                    const data = {...this.form};

                    if (data.auto_calculate) {
                        data.size_user = null;
                    } else {
                        switch (data.size_units) {
                            case 'm2':
                                data.size_user = this.metersSqToHectares(this.float(data.size_user));
                                break;

                            case 'km2':
                                data.size_user = this.kmSqToHectares(this.float(data.size_user));

                                break;

                            case 'acres':
                                data.size_user = this.acresToHectares(this.float(data.size_user));

                                break;

                            default:
                                break;
                        }
                    }

                    if (data.company_id) {
                        data.company_id = parseInt(data.company_id);
                    } else if (this.companies.length) {
                        data.company_id = parseInt(this.companies[0].id);
                    }

                    if (country) {
                        const countryList = this.countries.filter(countryItem => countryItem.code === country);

                        if (countryList.length) {
                            data.country_code = countryList[0].code;
                        }
                    }

                    if (!data.study) {
                        data.study = false;
                    }

                    delete data.map_location;

                    data.address_autofill = parseInt(data.address_autofill) === 1;

                    if (this.edit) {
                        this.$inertia.post(this.$route('plot.update', this.plot.id), {
                            _method: 'patch',
                            ...data,
                        });
                    } else {
                        this.$inertia.post(this.$route('plot.store'), {
                            _method: 'put',
                            ...data,
                        });
                    }
                }
            });
        },

        submitFormButton() {
            this.$refs.form.formSubmitted();
        },

        mapZoom() {
            this.zoom = this.form.map_location.points && this.form.map_location.points.length > 0 ? 13 : 6;
        },

        openCalculator(event) {
            event.preventDefault();
            this.$refs.calculator.open();
        },

        lpaLocation() {
            let value = this.edit && this.plot.planning_authority_id ? this.plot.planning_authority_id.toString() : null;

            if (this.hasGroup('lpa') && this.$page.props.loggedUser.LPAs.length === 1) {
                value = this.planningAuthorities.filter((item) => item.id === this.$page.props.loggedUser.LPAs[0])[0].id.toString();
            } else if (this.planningAuthorities.length === 1) {
                value = this.planningAuthorities[0].id.toString();
            }

            return value;
        },
    },
};
</script>
