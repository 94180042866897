require('./bootstrap');
import { App, plugin } from '@inertiajs/inertia-vue';
import { InertiaProgress } from '@inertiajs/progress';
import Vue from 'vue';

const axiosInstance = window.axios.create({
    baseURL: process.env.MIX_APP_URL,
});

InertiaProgress.init({
    color: '#5EBD8A',
});

// Global
import SvgController from 'Components/svg/svg';
Vue.component('SvgController', SvgController);
import DropdownMenu from 'Utilities/dropdown/Dropdown';
Vue.component('DropdownMenu', DropdownMenu);
import AppLayout from 'Components/Layouts/AppLayout';
Vue.component('AppLayout', AppLayout);
import PageHeader from 'Components/Header/PageHeader';
Vue.component('PageHeader', PageHeader);
import TableGridLayout from 'Utilities/table-grid/Controller';
Vue.component('TableGridLayout', TableGridLayout);

import ConformationDialog from 'Utilities/modal';
Vue.use(ConformationDialog);

import hasGroupMixin from 'Mixins/hasGroup';
Vue.mixin(hasGroupMixin);

import ParseValues from 'Mixins/parseValues';
Vue.mixin(ParseValues);

import 'vue-progress-path/dist/vue-progress-path.css';
import VueProgress from 'vue-progress-path';

Vue.use(VueProgress);


// Form
import VueFormulate from '@braid/vue-formulate/src/Formulate';
import FormulateVueInputPlugins from 'Utilities/vue-formulate/Imports';
import FormHelp from 'Utilities/vue-formulate/FormHelp';
import File from 'Utilities/vue-formulate/slots/File';
Vue.component('FormHelp', FormHelp);
Vue.component('File', File);

Vue.use(VueFormulate, {
    plugins: [FormulateVueInputPlugins],
    uploader: axiosInstance,
    rules: {
        map: ({ value }) => {
            if (value.points && value.points.length > 0 &&
                (!value.geoInputEnabled || (value.inputGeo && value.inputGeo !== ''))
            ) {
                return true;
            }

            return false;
        },

        mapMarker: ({value}) => {
            if (value.points &&
                (!value.geoInputEnabled || (value.inputGeo && value.inputGeo !== ''))
            ) {
                return true;
            }

            return false;
        },
        fileType: ({ value }, ...types) => {
            if (value instanceof FileUpload) {
                const fileList = value.getFiles();

                for (let idx = 0; idx < fileList.length; idx++) {
                    const file = fileList[idx].file;

                    const lastDot = file.name.lastIndexOf('.');
                    const ext = file.name.substring(lastDot);

                    if (!types.includes(ext)) {
                        return false;
                    }
                }
            }

            return true;
        },
    },
    locales: {
        en: {
            fileType({ args, name }) {
                return `${name} must be of the type: ${args[0] || 'No file formats allowed.'}`
                ;
            },
        },
    },
    slotComponents: {
        help: 'FormHelp',
        file: 'File',
    },
});

import confirmRequest from 'Lib/confirmRequest/confirmRequest';
Vue.prototype.$confirmRequest = null;

// Map
Vue.prototype.$mapApiKey = process.env.MIX_GOOGLE_APIKEY || 'AIzaSyBxkRLSUdYuEwLpIZTdhMfF2P07kUis-3w';
Vue.prototype.$mapService = process.env.MIX_MAP_SERVICE || 'https://map-service.sequest.eco';
Vue.prototype.$metricApi = process.env.MIX_METRIC_API || null;
import FileUpload from '@braid/vue-formulate/src/FileUpload';

import MappingTools from 'vue3-mapping-tools/v2';
Vue.use(MappingTools, {
    apiKeyGoogle: Vue.prototype.$mapApiKey,
    apiKeyMapbox: 'pk.eyJ1IjoibWF0dGhld2JuZyIsImEiOiJjbDJya2lrc2YwODl6M2Nyd2U3aDk1dmY0In0.tIfXVQ-yfrfTUEm978g5Hw',
}, {
    sidebar: 'map-sidebar',
    sidebarAccordion: 'map-sidebar-accordion',
    sidebarList: 'map-sidebar-list',
    sidebarListItem: 'map-sidebar-list-item',
    sidebarListItemInner: 'map-sidebar-list-item-inner',
    sidebarListItemFake: 'map-sidebar-list-item-fake',
    sidebarListItemInput: 'map-sidebar-list-item-input',
    sidebarListItemButton: 'map-sidebar-list-item-button',
    sidebarListItemExpand: 'map-sidebar-list-item-expand',
    sidebarListItemKey: 'map-sidebar-list-item-key',
});

Vue.use(plugin);

Vue.filter('capitalize', function(value) {
    if (!value) return '';
    const capitalized = [];

    value.split(' ').forEach(word => {
        capitalized.push(
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        );
    });

    return capitalized.join(' ');
});

const elm = document.getElementById('app');


Vue.prototype.$route = route;
Vue.prototype.$mapAttributes = {};

new Vue({
    created() {
        Vue.prototype.$confirmRequest = new confirmRequest(this.$inertia);
    },
    render: h => h(App, {
        props: {
            initialPage: JSON.parse(elm.dataset.page),
            resolveComponent: name => require(`./views/${name}`).default,
        },
    }),
}).$mount(elm);
