var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "auth-layout",
    {
      attrs: { component: _vm.$options.name },
      scopedSlots: _vm._u([
        {
          key: "button",
          fn: function () {
            return [
              _c("span", [_vm._v("Already have an account?")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn--primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.$inertia.visit(_vm.$route("login"))
                    },
                  },
                },
                [_vm._v("\n            Login\n        ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("terms", {
        attrs: { show: _vm.showTerms },
        on: {
          close: function ($event) {
            _vm.showTerms = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showTerms,
              expression: "!showTerms",
            },
          ],
        },
        [
          _c(
            "FormulateForm",
            {
              attrs: { errors: _vm.form.errors },
              on: { submit: _vm.onSubmit },
            },
            [
              _c("div", { staticClass: "form-page bg-green" }, [
                _c("h3", [
                  _c("span", [_vm._v("1")]),
                  _vm._v(" Select your account type"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "account-select" },
                  _vm._l(_vm.groups, function (group) {
                    return _c("FormulateInput", {
                      key: group.id,
                      staticClass: "account-select-item",
                      attrs: {
                        id: group.label,
                        value: group.id,
                        type: "radio",
                        name: "group",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("GroupLabel", {
                                  attrs: {
                                    group: group,
                                    selected: group.id === _vm.form.group_id,
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.form.group_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "group_id", $$v)
                        },
                        expression: "form.group_id",
                      },
                    })
                  }),
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-page bg-white" },
                [
                  _c("h3", [
                    _c("span", [_vm._v("2")]),
                    _vm._v(" Tell us about yourself"),
                  ]),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "text",
                      name: "company",
                      label: "Company Name",
                      validation: "required",
                      "validation-name": "Company Name",
                    },
                    model: {
                      value: _vm.form.company_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "company_id", $$v)
                      },
                      expression: "form.company_id",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "text",
                      name: "company_number",
                      label: "Company Number",
                      "validation-name": "Company Number",
                    },
                    model: {
                      value: _vm.form.company_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "company_number", $$v)
                      },
                      expression: "form.company_number",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "text",
                      name: "company_address",
                      label: "Company Address",
                      "validation-name": "Company Address",
                    },
                    model: {
                      value: _vm.form.company_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "company_address", $$v)
                      },
                      expression: "form.company_address",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "select-plugin",
                      name: "country_code",
                      validation: "required",
                      label: "Country",
                      "validation-name": "Country",
                      "reduce-key": "code",
                      "label-key": "name",
                      options: _vm.countries,
                      placeholder: "Country",
                    },
                    model: {
                      value: _vm.form.country_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "country_code", $$v)
                      },
                      expression: "form.country_code",
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.country_code === "GB" &&
                  (_vm.form.group_id === 4 || _vm.form.group_id === 3)
                    ? _c("FormulateInput", {
                        attrs: {
                          type: "select-plugin",
                          name: "associated_lpa",
                          label: "Associated Local Planning Authorities",
                          "validation-name": "Local Planning Authority",
                          "reduce-key": "auth_id",
                          "label-key": "name",
                          options: _vm.planningAuthoritiesSelect,
                          multiple: true,
                          placeholder: "Associated LPA's",
                        },
                        model: {
                          value: _vm.form.associated_lpa,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "associated_lpa", $$v)
                          },
                          expression: "form.associated_lpa",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "text",
                      name: "first_name",
                      label: "First Name",
                      validation: "required",
                      "validation-name": "First Name",
                    },
                    model: {
                      value: _vm.form.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "first_name", $$v)
                      },
                      expression: "form.first_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "text",
                      name: "last_name",
                      label: "Surname",
                      validation: "required",
                      "validation-name": "Surname",
                    },
                    model: {
                      value: _vm.form.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "last_name", $$v)
                      },
                      expression: "form.last_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "email",
                      name: "email",
                      label: "Email Address",
                      validation: "required|email",
                      "validation-name": "Email address",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "password",
                      name: "password",
                      label: "Password",
                      validation: "required|min:8,length",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      type: "password",
                      name: "password_confirmation",
                      label: "Password Confirmation",
                      validation: "required|min:8,length|confirm:password",
                      "validation-name": "Password confirmation",
                    },
                    model: {
                      value: _vm.form.password_confirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password_confirmation", $$v)
                      },
                      expression: "form.password_confirmation",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: {
                      id: "terms",
                      value: _vm.form.terms,
                      type: "checkbox",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("label", { attrs: { for: "terms" } }, [
                              _vm._v(
                                "\n                            I agree to the terms of the\n                            "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showTerms = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                BNG Partnership Customer Agreement\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.form.terms,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "terms", $$v)
                      },
                      expression: "form.terms",
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    attrs: { type: "submit", label: "Sign Up" },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.form.errors.email_exists
        ? [
            _c("p", [_vm._v("An account with that email already exists.")]),
            _vm._v(" "),
            _vm.form.errors.email_exists === "verified"
              ? _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn-reset",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.$inertia.visit(_vm.$route("login"))
                        },
                      },
                    },
                    [_vm._v("\n                Login\n            ")]
                  ),
                ])
              : _vm.form.errors.email_exists === "unverified"
              ? _c("p", [
                  _vm._v(
                    "\n            The account has not been verified.\n            "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n            You can verify your email address by clicking on the link we just emailed to you.\n        "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }