<template>
    <section class="vue-formulate-drawmap">
        <mapping-tool
            ref="map"
            :center="center"
            :zoom="zoom"
            :sidebar="sidebar"
            :upload="upload"
            :fit-bounds="fitBounds"
            fit-bounds-update="vectors"
            :sidebar-width="sidebarWidth"
        >
            <mapping-tool-schema
                :schema="toSchema"
                @input="updateSchema"
            />
            <template #sidebar>
                <div
                    class="vue-formulate-drawmap-sidebar"
                    :class="{
                        active: sidebarShow,
                    }"
                >
                    <mapping-tool-sidebar
                        sidebar-click-action="expand"
                        :width="sidebarWidth ? sidebarWidth : '100%'"
                        @toggleChecked="$emit('sidebarToggleChecked', feature)"
                    />
                </div>
            </template>
            <slot />
        </mapping-tool>
    </section>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';
import mapMixin from 'Mixins/map.js';

export default {
    name: 'FormulateMap',
    mixins: [
        FormulateInputMixin,
        mapMixin,
    ],

    props: {
        returnAdress: {
            type: Boolean,
            default: true,
        },
        returnSize: {
            type: Boolean,
            default: true,
        },
        sidebarWidth: {
            type: Number,
            default: 320,
        },
        sidebar: {
            type: Boolean,
            default: false,
        },
        upload: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: null,
        },
        opacity: {
            type: String,
            default: null,
        },
        stroke: {
            type: String,
            default: null,
        },
        strokeColor: {
            type: String,
            default: null,
        },
        strokeOpacity: {
            type: String,
            default: null,
        },
        pattern: {
            type: String,
            default: null,
        },
        patternColor: {
            type: String,
            default: null,
        },
        patternColorAlt: {
            type: String,
            default: null,
        },
        vectors: {
            type: Array,
            default: null,
        },
        schema: {
            type: Array,
            default: () => [],
        },
        fitBounds: {
            type: [Array, String],
            default: null,
        },
        fitBoundsUpdate: {
            type: [Array, String],
            default: null,
        },
    },

    data() {
        return {
            sidebarShow: '',
            zoom: 6,
            updating: false,
            center: {
                lat: 53.428720,
                lng: -1.659736,
            },
        };
    },

    computed: {
        toSchema() {
            const paths = this.context.model.paths ? this.context.model.paths : this.context.model;
            const points = this.pointsToSchema(paths);
            let vectors = [];

            if (!points.type) {
                points.type = 'shape';
            }

            if (!points.coordinates && !points.center && !points.radius) {
                points.coordinates = [];
            }

            if (this.vectors) {
                vectors = this.vectors.map(vector => {
                    return this.vectorToSchema(vector);
                });
            }

            const schema = [
                ...vectors,
                ...this.schema,
                {
                    ...points,
                    properties: {
                        edit: true,
                        editable: true,
                        sidebar: false,
                        color: this.color ?? null,
                        opacity: this.opacity ?? null,
                        stroke: this.stroke ?? null,
                        strokeColor: this.strokeColor ?? null,
                        strokeOpacity: this.strokeOpacity ?? null,
                        pattern: this.pattern ?? null,
                        patternColor: this.patternColor ?? null,
                        patternColorAlt: this.patternColorAlt ?? null,
                    },
                },
            ];

            return schema;
        },
    },

    methods: {
        async updateSchema(value, type) {
            this.updating = true;
            let newValue = Array.isArray(this.context.model) ? [...this.context.model] : {...this.context.model};

            if (type === 'paste') {
                const scheamItemProps = value.schema.properties;

                type = 'paths';

                value = this.$refs.map.mapObject.find(scheamItemProps.id);
            }

            if (type === 'paths' && ['shape'].includes(value.type)) {
                if (this.returnAdress || this.returnSize) {
                    newValue.paths = value.data.paths;
                    delete newValue.radius;
                    delete newValue.center;
                } else {
                    newValue = value.data.paths;
                }
            } else if ((type === 'radius' || type === 'center') && ['circle'].includes(value.type)) {
                newValue.radius = value.data.radius;
                newValue.center = value.data.center;
                delete newValue.paths;
            }

            if (this.returnAdress) {
                const location = await this.$refs.map.getGeoLocation(value.getCenter());

                newValue.country = location.country ? location.country.short_name : null;
                newValue.location = {
                    address: location.formatted_address,
                    postcode: location.postcode,
                };
            }

            if (this.returnSize) {
                const size = value.data.size.hectares;

                newValue.size = size;
            }

            if (JSON.stringify(newValue) !== JSON.stringify(this.context.model)) {
                this.context.model = newValue;
                this.$emit('input', newValue, value, type);
            }
        },
    },
};
</script>
