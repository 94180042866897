var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "plots" } },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "Agreement Details",
            icon: "plots",
            back: {
              url: _vm.returnUrl,
              text: "All BNG Agreements",
            },
          },
          scopedSlots: _vm._u([
            {
              key: "page-header-bottom",
              fn: function () {
                return [
                  _c("tabs", {
                    attrs: { tabs: _vm.tabs },
                    model: {
                      value: _vm.selectedTab,
                      callback: function ($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.deletable
            ? _c(
                "button",
                {
                  staticClass: "btn-reset btn btn--red--secondary",
                  on: { click: _vm.deleteRental },
                },
                [
                  _c("SvgController", { attrs: { type: "trashcan" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Delete Agreement")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasGroup(["sequest", "lpa"]) && _vm.hasPermission("rental_save")
            ? _c(
                "inertia-link",
                {
                  staticClass: "btn-reset btn btn--primary",
                  attrs: { href: _vm.$route("rental.edit", _vm.rental.id) },
                },
                [_vm._v("\n            Edit Agreement\n        ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedTab === "information"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "center" },
              [
                _vm.rental.update_overdue
                  ? _c("alert", { attrs: { type: "warning" } }, [
                      _c("p", [
                        _vm._v(
                          "Audit is overdue " +
                            _vm._s(_vm.rental.next_audit_date)
                        ),
                      ]),
                    ])
                  : _vm.rental.next_audit_date !== ""
                  ? _c("alert", { attrs: { type: "info" } }, [
                      _c("p", [
                        _vm._v(
                          "Next audit is due " +
                            _vm._s(_vm.rental.next_audit_date)
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "details" }, [
              _c(
                "div",
                { staticClass: "center" },
                [
                  _c("div", { staticClass: "form-flex--lap" }, [
                    _c("div", { staticClass: "details-block details-bg" }, [
                      _c("div", { staticClass: "formulate-heading" }, [
                        _c("h6", [_vm._v("Agreement Details")]),
                      ]),
                      _vm._v(" "),
                      _c("h5", [_vm._v("Unique Reference Number")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.rental.unique_reference_number)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-flex--tab" }, [
                        _c("div", [
                          _c("h5", [_vm._v("Date Agreement Started")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.rental.rental_startdate)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("h5", [_vm._v("Agreement Period Remaining")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.rental.rental_period))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-flex--tab" }, [
                        _c("div", [
                          _c("h5", [_vm._v("Annual Payment")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.rental.annual_rent))]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("h5", [_vm._v("Agreement Status")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.rental.status))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("h5", [_vm._v("BNG Units")]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.rental.units))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "details-block" }, [
                      _c("div", { staticClass: "formulate-heading" }, [
                        _c("h6", [_vm._v("Location")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "details-map" },
                        [
                          _c(
                            "mapping-tool",
                            { attrs: { "fit-bounds": "polygons" } },
                            [
                              _c("mapping-tool-schema", {
                                attrs: { schema: _vm.toSchema },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("Updates"),
                  _vm._v(" "),
                  _c("file-label", {
                    attrs: {
                      id: "management-lan",
                      label: "Management Plan",
                      text: "Upload Management Plan",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openFileModal.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("files", {
                    attrs: {
                      files: _vm.documents,
                      filetype: "document",
                      deletable: true,
                      "item-type": "agreement",
                    },
                  }),
                  _vm._v(" "),
                  _c("file-label", {
                    attrs: {
                      id: "media",
                      label: "Media",
                      text: "Upload Media",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openMediaModal.apply(null, arguments)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("gallery", {
                    attrs: { images: _vm.rental.media, deletable: true },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTab === "report"
        ? _c("div", [_c("rental-report", { attrs: { rental: _vm.rental } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedTab === "contracts" && _vm.showContractsTab
        ? _c("div", [
            _c(
              "div",
              { staticClass: "center" },
              [
                _c("contracts", {
                  attrs: {
                    "contracts-plot": _vm.contractsPlot ?? [],
                    "contracts-project": _vm.contractsProject ?? [],
                    contracts: _vm.contracts,
                    parent: {
                      id: _vm.rental.id,
                      name: "Rental",
                      type: "rental",
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("modal", {
        ref: "fileModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("Add Management Plan")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("add-files", {
                  attrs: {
                    "file-type": "document",
                    parent: {
                      id: _vm.rental.id,
                      type: "rental",
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("modal", {
        ref: "mediaModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("Add Media")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("add-media", {
                  attrs: {
                    "file-type": "media",
                    parent: {
                      id: _vm.rental.id,
                      type: "rental",
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }