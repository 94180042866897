<template>
    <section class="tablegrid">
        <header
            v-if="hasHeader"
            class="flex-space-between"
        >
            <slot name="header-left">
                <div />
            </slot>
            <slot name="header-right">
                <template v-if="!mobile && !tablet && !laptop">
                    <div
                        v-if="output.length > 1"
                        class="tablegrid-switcher btn-switcher-icons flex"
                    >
                        <button
                            v-for="item in output"
                            :key="item"
                            :class="`${view===item ? 'active' : ''}`"
                            :value="item"
                            @click="handleSwitch(item)"
                        >
                            {{ !icons[item] ? item : '' }}
                            <SvgController :type="icons[item]" />
                        </button>
                    </div>
                </template>
            </slot>
        </header>

        <template v-if="!mobile && !tablet && !laptop">
            <div
                v-if="view==='table' && output.includes('table')"
                class="tablegrid-table tablegrid-item"
            >
                <Table
                    :columns="columnsFormat"
                    :rows="rows"
                    :on-sort="onSort"
                    :has-row-click="hasRowClick"
                    @onRowClick="onRowClick"
                >
                    <template
                        v-for="(_, name) in $scopedSlots"
                        :slot="name"
                        slot-scope="slotData"
                    >
                        <slot
                            v-if="name.indexOf('cell(') === 0"
                            :name="name"
                            v-bind="slotData"
                        />
                    </template>
                </Table>
            </div>
            <div
                v-else-if="view==='grid' && output.includes('grid')"
                class="tablegrid-grid tablegrid-item"
            >
                <Grid
                    :layout="gridLayout"
                    :data="rows"
                >
                    <template
                        slot="card-header"
                        slot-scope="props"
                    >
                        <slot
                            name="card-header"
                            :item="props.item"
                        />
                    </template>
                    <template
                        slot="card-title"
                        slot-scope="props"
                    >
                        <slot
                            name="card-title"
                            :item="props.item"
                        />
                    </template>
                    <template
                        slot="card-description"
                        slot-scope="props"
                    >
                        <slot
                            name="card-description"
                            :item="props.item"
                        />
                    </template>
                    <template
                        slot="card-footer"
                        slot-scope="props"
                    >
                        <slot
                            name="card-footer"
                            :item="props.item"
                        />
                    </template>
                </Grid>
            </div>
        </template>
        <template v-else>
            <div
                class="tablegrid-table tablegrid-item"
            >
                <Grid
                    :index="2"
                    :layout="gridLayoutForTableFormat||gridLayoutFormat||gridLayoutForTableDefaultFormat"
                    :data="rows"
                    :includes="['info', 'footer', 'dropdown']"
                    :full-width="true"
                />
            </div>
        </template>
        <slot
            v-if="hasFooter"
            name="footer"
        />
    </section>
</template>

<script>

/*
How to use
    // General props
    output: Array, - What items to include ['table', 'grid']
    defaultView: String, Default view to show. Defaults to first item in output
    onSort: Function(objCol, strDirection) - callback function for sort being pressed within table

    // Table require props:
    columns: [
        label: STRING, - Column Label
        field: STRING, - Row item to target and pull out
        icon: STRING, - SvgController Icon
        onClick: Function(event, objCol, index) - Function to run on click of row column
        sortable: Boolean, - Set table sortable on or off
        type: STRING, - Set type: number, decimal, percentage, boolean, date, dropdown
        // Only for type dropdown
        position: String, - position of dropdown ("left" or "right")
        options: Array, - Array list of dropdown options
    ]
    // Grid require props:
    gridLayout: {
        title: String, - Field name or display title
        description: String, - Field name or display description
        status: String, - Field name or display status
        image: String, - Field name or display image
        dropdown: Array, - Array list of dropdown options
        latLong: ARRAY, - Row item to target and pull out
        onClick: Function(event, objCol, index) - Function to run on click of grid card
    }
    gridLayoutForTable : same as gridLayout
                         This is used for the mobile view of the table. If not set defaults to gridLayout then to first item in row for title

    Grid and Table require props
    rows: [
        {
            // Object of fields referenced in columns.
            // Accepts String or object
            String - Field name
            Object - {
                label: String, - Field Value
                icon: String - Icon override
            }
            // Example
            { id: 1, title: 'Tincidunt integer aenean integer', Location: {label: 'Hello', icon: 'logo'}, date: '2011-10-31', status: 'leased' },
        },
    ]

    // Dropdown Array Example
    [{
        name: 'Edit Development',
        border: false,
        onClick: (e, row, index) => {}
    }]

    Slots
    header-left - Header left content
    header-right - Header right content
    footer - footer content within container
    card-header - Grid card header (slot-scope {props -> item})
    card-title - Grid card title (slot-scope {props -> item})
    card-description - Grid card description (slot-scope {props -> item})
    card-footer - Grid card footer (slot-scope {props -> item})
*/

import Table from '../table/Table';
import Grid from '../grid/Grid';
import { DateTime } from 'luxon';

export default {
    name: 'TableGridLayout',
    components: {
        Table,
        Grid,
    },
    props: {
        columns: {
            type: Array,
            default() {
                return [];
            },
        },
        gridLayout: {
            type: Object,
            default: null,
        },
        gridLayoutForTable: {
            type: Object,
            default: null,
        },
        rows: {
            type: Array,
            default() {
                return [];
            },
        },
        output: {
            type: Array,
            default() {
                return ['table', 'grid'];
            },
        },
        defaultView: {
            type: String,
            default: '',
        },
        onSort: {
            type: Function,
            default: () => {},
        },
        hasHeader: {
            type: Boolean,
            default: true,
        },
        hasFooter: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            view: this.defaultView || this.output[0],
            icons: {
                table: 'list',
                grid: 'dashboard',
                resizeTimeout: null,
            },
            mobile: false,
            tablet: false,
            laptop: false,
            gridLayoutForTableDefault: {},
        };
    },

    computed: {
        hasRowClick() {
            return this.$listeners && (typeof this.$listeners.onRowClick === 'function');
        },

        columnsFormat() {
            return [...this.columns].map(layout => {
                return this.formatItem(layout);
            });
        },

        gridLayoutForTableFormat() {
            return this.gridLayoutForTable;
        },

        gridLayoutFormat() {
            return this.formatItem(this.gridLayout);
        },

        gridLayoutForTableDefaultFormat() {
            return this.formatItem(this.gridLayoutForTableDefault);
        },
    },
    created() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        let objDropdown;

        this.columns.forEach((objColumn) => {
            if (objColumn.type === 'dropdown') {
                objDropdown = objColumn.options;
            }
        });

        if (this.rows.length > 0) {
            this.gridLayoutForTableDefault = {
                title: this.rows[0][this.columns[0].field],
                dropdown: objDropdown,
            };
        }
    },

    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        formatItem(item) {
            const returnItem = {...item};

            if (returnItem.type === 'date') {
                this.rows.forEach(data => {
                    let inFormat = 'ISO',
                        outFormat = 'dd/MM/yyyy',
                        date;

                    if (returnItem.dateInputFormat) {
                        inFormat = returnItem.dateInputFormat;
                    }

                    if (returnItem.dateOutputFormat) {
                        outFormat = returnItem.dateOutputFormat;
                    }

                    if (data[returnItem.field]) {

                        date = DateTime.fromFormat(data[returnItem.field], inFormat);

                        if (inFormat === 'ISO') {
                            date = DateTime.fromISO(data[returnItem.field]);
                        }

                        if (date.isValid) {
                            data[returnItem.field] = date.toFormat(outFormat);
                        }
                    }
                });
                returnItem.type = 'string';
            }

            return returnItem;
        },
        handleSwitch(strView) {
            this.view = strView;
        },
        handleResize() {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                const intWidth = (window.innerWidth || document.documentElement.clientWidth);

                this.mobile = intWidth <= 500;
                this.tablet = !this.mobile && intWidth < 768;
                this.laptop = !this.tablet && !this.mobile && intWidth < 1024;

                this.resizeTime = 100;
            }, this.resizeTime);
        },

        onRowClick(params) {
            this.$emit('onRowClick', params);
        },
    },
};
</script>
