var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "commentsModal",
    attrs: { width: "50em" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("\n            Comments\n        ")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("CommentList", {
              staticClass: "contract-comments",
              attrs: {
                comments: _vm.comments,
                "allow-editing": false,
                "allow-post": true,
                "allow-reply": false,
                "current-user": _vm.user,
                "button-text": "Post",
              },
              on: { comment: _vm.handleComment },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }