<template>
    <modal
        ref="historyModal"
        width="50em"
    >
        <template #header>
            <h3>
                Signatures
            </h3>
        </template>
        <template #body>
            <template v-if="formatData.length">
                <TableGridLayout
                    :columns="columns"
                    :rows="formatData"
                    :output="['table']"
                    mode="remote"
                />
            </template>
            <TableGridLayout
                v-if="stillRequired.length"
                :columns="columnsRequired"
                :rows="stillRequired"
                :output="['table']"
                mode="remote"
            />
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';

export default {
    name: 'ContractSignatures',
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        signatures: {
            type: Array,
            default: () => ([]),
        },
        required: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            user: this.$page.props.loggedUser,
            columns: [{
                label: 'User',
                field: 'user',
                sortable: false,
            }, {
                label: 'Company',
                field: 'company',
                sortable: false,
            }, {
                label: 'Position',
                field: 'group',
                sortable: false,
            }, {
                label: 'Date',
                field: 'date',
                type: 'date',
                dateOutputFormat: 'dd/MM/yyyy t a',
                sortable: false,
            },{
                label: 'Status',
                field: 'accepted',
                sortable: false,
                spanClass: 'status-pill type-{color}',
            }],
            columnsRequired: [{
                label: 'Signatures Required',
                field: 'group',
                sortable: false,
            }],
        };
    },
    computed: {
        formatData() {
            return this.signatures.map(signature => ({
                user: `${signature.user.first_name} ${signature.user.last_name}`,
                group: signature.group.label,
                company: signature.company.name,
                date: signature.date,
                accepted: signature.accepted ? 'Accepted' : 'Rejected',
                color: signature.accepted ? 'green' : 'red',
            }));
        },

        stillRequired() {
            const alreadySigned = this.signatures.filter(signature => signature.accepted).map(signature => signature.group.id);

            return this.required.filter(required => !alreadySigned.includes(required.id)).map(required => ({
                group: required.label,
            }));
        },
    },
    watch: {
        show(value) {
            if (value) {
                this.$refs.historyModal.openModal();
            } else {
                this.$refs.historyModal.closeModal();
            }
        },
    },
    methods: {
        openModal() {
            this.$refs.historyModal.openModal();
        },
        closeModal() {
            this.$refs.historyModal.closeModal();
        },
    },
};
</script>
