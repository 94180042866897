var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `gridlayout${_vm.fullWidth ? " full-width" : ""}` },
    [
      _c(
        "ul",
        { staticClass: "lst-reset gridlayout-list" },
        _vm._l(_vm.data, function (item, indexLoop) {
          return _c("GridCard", {
            key: `loop-${indexLoop}-${indexLoop}`,
            attrs: {
              index: indexLoop,
              title: _vm.parseData(item, "title"),
              company: _vm.parseData(item, "company"),
              description: _vm.parseData(item, "description"),
              image: _vm.parseData(item, "image"),
              "lat-long": _vm.parseData(item, "latLong"),
              "show-map": _vm.parseData(item, "showMap"),
              type: _vm.layout.type,
              "fit-bounds": _vm.parseData(item, "fitBounds"),
              status: _vm.parseData(item, "status"),
              "on-click": _vm.parseData(item, "onClick"),
              dropdown: _vm.parseData(item, "dropdown"),
              "full-item": item,
              includes: _vm.includes,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "card-header",
                  fn: function (props) {
                    return [_vm._t("card-header", null, { item: props.item })]
                  },
                },
                {
                  key: "card-title",
                  fn: function (props) {
                    return [_vm._t("card-title", null, { item: props.item })]
                  },
                },
                {
                  key: "card-company",
                  fn: function (props) {
                    return [_vm._t("card-company", null, { item: props.item })]
                  },
                },
                {
                  key: "card-description",
                  fn: function (props) {
                    return [
                      _vm._t("card-description", null, { item: props.item }),
                    ]
                  },
                },
                {
                  key: "card-footer",
                  fn: function (props) {
                    return [_vm._t("card-footer", null, { item: props.item })]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }