var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "modal" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (_vm.selectedContract.interface_type === "upload" ||
                  _vm.editor) &&
                !_vm.loading,
              expression:
                "(selectedContract.interface_type === 'upload' || editor) && !loading",
            },
          ],
          staticClass: "contract-toolbar",
          attrs: { id: "document-contract-toolbar" },
        },
        [
          _c("DocumentToolbar", {
            attrs: {
              disabled: _vm.disabled,
              refs: _vm.documentRefs,
              editor: _vm.editor,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "toolbar-enabled-right",
                  fn: function () {
                    return [
                      _vm.selectedContract.interface_type === "editor"
                        ? [
                            _c("DocumentToolbarButton", {
                              attrs: {
                                icon: "fa-solid fa-floppy-disk",
                                title: "Save",
                                text: "Save",
                              },
                              on: { click: _vm.submitForm },
                            }),
                            _vm._v(" "),
                            _c("DocumentToolbarButton", {
                              attrs: {
                                icon: "fa-solid fa-trash-can",
                                title: "Discard",
                                text: "Discard",
                              },
                              on: { click: _vm.handleDiscardChanges },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "toolbar-disabled",
                  fn: function () {
                    return [
                      _c("DocumentToolbarButton", {
                        attrs: {
                          icon: "fa-regular fa-folder-open",
                          title: "Open Document",
                          text: "Open",
                        },
                        on: { click: _vm.openFiles },
                      }),
                      _vm._v(" "),
                      _vm.selectedContract.interface_type === "editor"
                        ? _c("DocumentToolbarButton", {
                            attrs: {
                              icon: "fa-solid fa-layer-group",
                              title: "Compare Versions",
                              text: "Compare",
                            },
                            on: { click: _vm.openCompare },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.allAccepted && _vm.isLatest && _vm.isNextToSign
                        ? [
                            _vm.selectedContract.interface_type === "editor"
                              ? _c("DocumentToolbarButton", {
                                  attrs: {
                                    icon: "fa-regular fa-pen-to-square",
                                    title: "Edit",
                                    text: "Edit",
                                  },
                                  on: { click: _vm.handleMakeChanges },
                                })
                              : _vm.selectedContract.interface_type ===
                                  "upload" && !_vm.upload
                              ? _c("DocumentToolbarButton", {
                                  attrs: {
                                    icon: "fa-solid fa-upload",
                                    title: "Upload",
                                    text: "Upload",
                                  },
                                  on: { click: _vm.handleUpload },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("DocumentToolbarButton", {
                        attrs: {
                          icon: "fa-solid fa-book-open",
                          title: "Audit Log",
                          text: "Audit Log",
                        },
                        on: { click: _vm.openAuditLog },
                      }),
                    ]
                  },
                  proxy: true,
                },
                _vm.allowChanges
                  ? {
                      key: "toolbar-disabled-right",
                      fn: function () {
                        return [
                          _vm.selectedContract.interface_type === "upload" &&
                          _vm.upload
                            ? [
                                _c("DocumentToolbarButton", {
                                  attrs: {
                                    icon: "fa-solid fa-floppy-disk",
                                    title: "Save",
                                    text: "Save",
                                  },
                                  on: { click: _vm.submitUploadForm },
                                }),
                                _vm._v(" "),
                                _c("DocumentToolbarButton", {
                                  attrs: {
                                    icon: "fa-solid fa-trash-can",
                                    title: "Discard",
                                    text: "Discard",
                                  },
                                  on: { click: _vm.handleDiscardChanges },
                                }),
                              ]
                            : [
                                _c("DocumentToolbarButton", {
                                  attrs: {
                                    icon: "fa-solid fa-signature",
                                    title: "Show Signatures",
                                    text: "Show Signatures",
                                  },
                                  on: { click: _vm.openSignatures },
                                }),
                                _vm._v(" "),
                                _vm.contract.status === "contract signed" &&
                                _vm.contract.docusign_id
                                  ? _c("DocumentToolbarButton", {
                                      attrs: {
                                        icon: "fa-solid fa-file-pdf",
                                        title: "View PDF",
                                        text: "View PDF",
                                      },
                                      on: { click: _vm.handlePdfView },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.contract.status === "contract signed" &&
                                _vm.contract.docusign_id &&
                                _vm.selectedContract.interface_type ===
                                  "editor" &&
                                _vm.user.isSuperUser
                                  ? _c("DocumentToolbarButton", {
                                      attrs: {
                                        icon: "fa-solid fa-cloud-arrow-down",
                                        title: "Fetch PDF",
                                        text: "Fetch PDF",
                                      },
                                      on: { click: _vm.handleFetchPdf },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isNextToSign
                                  ? [
                                      !_vm.hasUserAccepted
                                        ? _c("DocumentToolbarButton", {
                                            attrs: {
                                              icon: "fa-regular fa-circle-check",
                                              title: "Accept Contract",
                                              text: "Accept Contract",
                                            },
                                            on: {
                                              click: _vm.handleAcceptChanges,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.hasUserAccepted
                                        ? _c("DocumentToolbarButton", {
                                            attrs: {
                                              icon: "fa-regular fa-circle-xmark",
                                              title: "Reject Contract",
                                              text: "Reject Contract",
                                            },
                                            on: {
                                              click: _vm.handleRejectChanges,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("DocumentEditor", {
        ref: "editor",
        attrs: {
          loading: _vm.loading,
          prefix: _vm.prefix,
          "prefix-height": _vm.quillHeights,
          "prefix-watermark": _vm.quillPrefixWatermark,
          "prefix-hover-text": _vm.quillPrefixHoverText,
          suffix: _vm.suffix,
          "suffix-height": _vm.quillHeights,
          "suffix-watermark": _vm.quillSuffixWatermark,
          "suffix-hover-text": _vm.quillSuffixHoverText,
          compare: _vm.selectedCompareContent,
          "current-user": _vm.user,
          disabled: _vm.disabled,
          "allow-zoom": false,
          "allow-comments": !_vm.allAccepted && _vm.isLatest,
          "toolbar-container": "#document-contract-toolbar",
        },
        on: {
          load: _vm.handleLoad,
          comment: _vm.submitComment,
          clickChange: _vm.handleClickChange,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "footer-left",
              fn: function () {
                return [
                  _vm.hasUserAccepted
                    ? _c("div", { staticClass: "status-pill type-green" }, [
                        _vm._v(
                          "\n                You have accepted this contract version.\n            "
                        ),
                      ])
                    : _vm.hasUserRejected
                    ? _c(
                        _vm.isNextToSign ? "button" : "div",
                        {
                          tag: "component",
                          staticClass: "status-pill type-red",
                          on: { click: _vm.handleNextTo },
                        },
                        [
                          _vm._v(
                            "\n                You have rejected this contract version.\n            "
                          ),
                        ]
                      )
                    : !_vm.isLatest
                    ? _c(
                        "div",
                        {
                          staticClass: "status-pill type-green",
                          on: { click: _vm.handleNextTo },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.notLatestText) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "footer-right",
              fn: function () {
                return [
                  _vm.nextToText && !_vm.allAccepted
                    ? _c(
                        _vm.isNextToSign &&
                          _vm.nextAfterGroup() &&
                          _vm.nextAfterGroup().id !==
                            _vm.selectedContract.nextToSign.id
                          ? "button"
                          : "div",
                        {
                          tag: "component",
                          staticClass: "status-pill type-blue",
                          on: { click: _vm.handleNextTo },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.nextToText) +
                              "\n            "
                          ),
                        ]
                      )
                    : !_vm.isLatest
                    ? _c(
                        "button",
                        {
                          staticClass: "status-pill type-blue",
                          on: {
                            click: function ($event) {
                              return _vm.loadContract({
                                id: _vm.contract.id,
                                item_id: _vm.contract.item_id,
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Click to view the latest document.\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            _vm.selectedContract.interface_type === "upload"
              ? {
                  key: "content",
                  fn: function () {
                    return [
                      _vm.upload
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "document-contract-upload-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "document-contract-upload-center-document",
                                  },
                                  [
                                    _c("FormulateForm", {
                                      staticClass: "flex-col",
                                      attrs: {
                                        schema: _vm.scemaForm,
                                        errors: _vm.form.errors,
                                      },
                                      on: { submit: _vm.submitForm },
                                      model: {
                                        value: _vm.form,
                                        callback: function ($$v) {
                                          _vm.form = $$v
                                        },
                                        expression: "form",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : _vm.documentUrl
                        ? _c("object", {
                            attrs: {
                              data: _vm.documentUrl,
                              type: "application/pdf",
                              width: "100%",
                              height: "700px",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticClass:
                                "document-contract-upload-center document-contract-upload-empty",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "document-contract-upload-center-document",
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.nextToText) +
                                        "\n                    "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.documentValue,
          callback: function ($$v) {
            _vm.documentValue = $$v
          },
          expression: "documentValue",
        },
      }),
      _vm._v(" "),
      _c("modal", {
        ref: "fileModal",
        attrs: { width: "1024px" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.capitalizeFirstLetter(_vm.fileType)) +
                      " " +
                      _vm._s(_vm.contract.type) +
                      " Version"
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("TableGridLayout", {
                  attrs: {
                    columns: _vm.columnsList,
                    rows: _vm.rowList,
                    output: ["table"],
                    mode: "remote",
                    "total-records": _vm.rowList.length,
                  },
                  on: { onRowClick: _vm.onRowClick },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("modal", {
        ref: "changeModal",
        attrs: { width: "1024px" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("Detailed Change")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("DocumentDetailedChange", {
                  attrs: {
                    changes: _vm.currentChanges.changes,
                    "ending-content": _vm.currentChanges.endingContent,
                    "starting-content": _vm.currentChanges.startingContent,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("Signatures", {
        ref: "signaturesModal",
        attrs: { signatures: _vm.signed, required: _vm.required },
      }),
      _vm._v(" "),
      _c("Comments", {
        ref: "commentsModal",
        attrs: { comments: _vm.comments, contract: _vm.contract },
        on: { click: _vm.handleCommentClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }