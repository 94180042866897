import { getTheme } from './mapThemes';

const colors = {
    black: '#000000',
    blue: '#1D5DDB',
    blueBaby: '#2CEFF6',
    blueBright: '#0000FF',
    brown: '#B25300',
    lime: '#A2F268',
    grayDark: '#B1B1AE',
    grayLight: '#D5D5D0',
    green: '#3AAA32',
    pink: '#FD86F0',
    purple: '#8D73DB',
    red: '#E71C1E',
    orange: '#FF8A00',
    white: '#FFFFFF',
    whiteLime: '#D1FFBE',
    whiteGreen: '#D1FFBE',
    yellow: '#FBFD80',
};

const parseTheme = (theme, background, foreground, options) => {
    return {
        fill: colors[background],
        'theme': getTheme(theme, {
            fill: colors[foreground],
            ...options,
        }),
    };
};

const habitatData = {
    'cropland': [
        {
            'value': 'arable-field-margins-cultivated-annually',
            'label': 'Arable field margins cultivated annually',
            'theme': parseTheme('circles', 'white', 'orange'),
        }, {
            'value': 'arable-field-margins-game-bird-mix',
            'label': 'Arable field margins game bird mix',
            'theme': parseTheme('circles', 'white', 'orange'),
        }, {
            'value': 'arable-field-margins-pollen-&-nectar',
            'label': 'Arable field margins pollen & nectar',
            'theme': parseTheme('circles', 'white', 'orange'),
        }, {
            'value': 'arable-field-margins-tussocky',
            'label': 'Arable field margins tussocky',
            'theme': parseTheme('circles', 'white', 'orange'),
        }, {
            'value': 'cereal-crops',
            'label': 'Cereal crops',
            'theme': parseTheme('lines', 'white', 'orange'),
        }, {
            'value': 'cereal-crops-winter-stubble',
            'label': 'Cereal crops winter stubble',
            'theme': parseTheme('lines', 'white', 'orange'),
        }, {
            'value': 'horticulture',
            'label': 'Horticulture',
            'theme': parseTheme('dimonds', 'white', 'orange'),
        }, {
            'value': 'intensive-orchards',
            'label': 'Intensive orchards',
            'theme': parseTheme('dimonds', 'white', 'orange'),
        }, {
            'value': 'non-cereal-crops',
            'label': 'Non-cereal crops',
            'theme': parseTheme('circles', 'orange', 'white'),
        }, {
            'value': 'temporary-grass-and-clover-leys',
            'label': 'Temporary grass and clover leys',
            'theme': parseTheme('linesVertical', 'orange', 'white'),
        },
    ],
    'grassland': [
        {
            'value': 'traditional-orchards',
            'label': 'Traditional orchards',
            'theme': parseTheme('dimonds', 'white', 'orange'),
        }, {
            'value': 'bracken',
            'label': 'Bracken',
            'theme': parseTheme('lines', 'white', 'lime'),
        }, {
            'value': 'floodplain-wetland-mosaic-cfgm',
            'label': 'Floodplain Wetland Mosaic (CFGM)',
            'theme': parseTheme('solid', 'lime'),
        }, {
            'value': 'lowland-calcareous-grassland',
            'label': 'Lowland calcareous grassland',
            'theme': parseTheme('lines45', 'lime', 'yellow'),
        }, {
            'value': 'lowland-dry-acid-grassland',
            'label': 'Lowland dry acid grassland',
            'theme': parseTheme('lines45', 'lime', 'white'),
        }, {
            'value': 'lowland-meadows',
            'label': 'Lowland meadows',
            'theme': parseTheme('lines45', 'lime', 'whiteLime'),
        }, {
            'value': 'modified-grassland',
            'label': 'Modified grassland',
            'theme': parseTheme('solid', 'lime'),
        }, {
            'value': 'other-lowland-acid-grassland',
            'label': 'Other lowland acid grassland',
            'theme': parseTheme('dimonds', 'white', 'green'),
        }, {
            'value': 'other-neutral-grassland',
            'label': 'Other neutral grassland',
            'theme': parseTheme('lines', 'whiteGreen', 'green'),
        }, {
            'value': 'tall-herb-communities',
            'label': 'Tall herb communities',
            'theme': parseTheme('solid', 'grayDark'),
        }, {
            'value': 'upland-acid-grassland',
            'label': 'Upland acid grassland',
            'theme': parseTheme('linesVertical', 'lime', 'white'),
        }, {
            'value': 'upland-calcareous-grassland',
            'label': 'Upland calcareous grassland',
            'theme': parseTheme('linesVertical', 'lime', 'white'),
        }, {
            'value': 'upland-hay-meadows',
            'label': 'Upland hay meadows',
            'theme': parseTheme('linesVertical', 'whiteLime', 'lime'),
        },
    ],
    'heathland-and-shrub': [
        {
            'value': 'blackthorn-scrub',
            'label': 'Blackthorn scrub',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'bramble-scrub',
            'label': 'Bramble scrub',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'gorse-scrub',
            'label': 'Gorse scrub',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'hawthorn-scrub',
            'label': 'Hawthorn scrub',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'hazel-scrub',
            'label': 'Hazel scrub',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'lowland-heathland',
            'label': 'Lowland Heathland',
            'theme': parseTheme('lines45', 'purple', 'white'),
        }, {
            'value': 'mixed-scrub',
            'label': 'Mixed scrub',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'mountain-heaths-and-willow-scrub',
            'label': 'Mountain heaths and willow scrub',
            'theme': parseTheme('solid', 'purple'),
        }, {
            'value': 'rhododendron-scrub',
            'label': 'Rhododendron scrub',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'sea-buckthorn-scrub-annex-1',
            'label': 'Sea buckthorn scrub (Annex 1)',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'sea-buckthorn-scrub-other',
            'label': 'Sea buckthorn scrub (other)',
            'theme': parseTheme('lines-45', 'purple', 'red'),
        }, {
            'value': 'upland-heathland',
            'label': 'Upland Heathland',
            'theme': parseTheme('linesVertical', 'purple', 'white'),
        },
    ],
    'lakes': [
        {
            'value': 'aquifer-fed-naturally-fluctuating-water-bodies',
            'label': 'Aquifer fed naturally fluctuating water bodies',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'ornamental-lake-or-pond',
            'label': 'Ornamental lake or pond',
            'theme': parseTheme('lines-45', 'white', 'blueBaby'),
        }, {
            'value': 'high-alkalinity-lakes',
            'label': 'High alkalinity lakes',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'low-alkalinity-lakes',
            'label': 'Low alkalinity lakes',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'marl-lakes',
            'label': 'Marl Lakes',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'moderate-alkalinity-lakes',
            'label': 'Moderate alkalinity lakes',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'peat-lakes',
            'label': 'Peat Lakes',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'ponds-priority-habitat',
            'label': 'Ponds (Priority Habitat)',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'ponds-non-priority-habitat',
            'label': 'Ponds (Non-Priority Habitat)',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'reservoirs',
            'label': 'Reservoirs',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'temporary-lakes-ponds-and-pools',
            'label': 'Temporary lakes, ponds and pools',
            'theme': parseTheme('solid', 'blueBaby'),
        },
    ],
    'sparsely-vegetated-land': [
        {
            'value': 'calaminarian-grasslands',
            'label': 'Calaminarian grasslands',
            'theme': parseTheme('solid', 'lime'),
        }, {
            'value': 'coastal-sand-dunes',
            'label': 'Coastal sand dunes',
            'theme': parseTheme('lines45', 'grayDark', 'grayLight'),
        }, {
            'value': 'coastal-vegetated-shingle',
            'label': 'Coastal vegetated shingle',
            'theme': parseTheme('linesVertical', 'grayDark', 'grayLight'),
        }, {
            'value': 'ruderal-ephemeral',
            'label': 'Ruderal/Ephemeral',
            'theme': parseTheme('solid', 'grayDark'),
        }, {
            'value': 'inland-rock-outcrop-and-scree-habitats',
            'label': 'Inland rock outcrop and scree habitats',
            'theme': parseTheme('lines45', 'grayDark', 'white'),
        }, {
            'value': 'limestone-pavement',
            'label': 'Limestone pavement',
            'theme': parseTheme('solid', 'grayDark'),
        }, {
            'value': 'maritime-cliff-and-slopes',
            'label': 'Maritime cliff and slopes',
            'theme': parseTheme('lines45', 'grayDark', 'yellow'),
        }, {
            'value': 'other-inland-rock-and-scree',
            'label': 'Other inland rock and scree',
            'theme': parseTheme('circles', 'white', 'grayDark'),
        },
    ],
    'urban': [
        {
            'value': 'allotments',
            'label': 'Allotments',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'artificial-unvegetated-unsealed-surface',
            'label': 'Artificial unvegetated, unsealed surface',
            'theme': parseTheme('circles', 'red', 'white'),
        }, {
            'value': 'bioswale',
            'label': 'Bioswale',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'other-green-roof',
            'label': 'Other green roof',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'built-linear-features',
            'label': 'Built linear features',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'cemeteries-and-churchyards',
            'label': 'Cemeteries and churchyards',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'developed-land-sealed-surface',
            'label': 'Developed land-sealed surface',
            'theme': parseTheme('linesVertical', 'red', 'white'),
        }, {
            'value': 'facade-bound-green-wall',
            'label': 'Façade-bound green wall',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'ground-based-green-wall',
            'label': 'Ground based green wall',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'ground-level-planters',
            'label': 'Ground level planters',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'intensive-green-roof',
            'label': 'Intensive green roof',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'introduced-shrub',
            'label': 'Introduced shrub',
            'theme': parseTheme('solid', 'purple'),
        }, {
            'value': 'open-mosaic-habitats-on-previously-developed-land',
            'label': 'Open Mosaic Habitats on Previously Developed Land',
            'theme': parseTheme('lines45', 'red', 'white'),
        }, {
            'value': 'rain-garden',
            'label': 'Rain garden',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'sand-pit-quarry-or-open-cast-mine',
            'label': 'Sand pit quarry or open cast mine',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'urban-tree',
            'label': 'Urban Tree',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'sustainable-urban-drainage-feature',
            'label': 'Sustainable urban drainage feature',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'un-vegetated-garden',
            'label': 'Un-vegetated garden',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'vacant-derelict-land-bareground',
            'label': 'Vacant/derelict land/ bareground',
            'theme': parseTheme('solid', 'red'),
        }, {
            'value': 'vegetated-garden',
            'label': 'Vegetated garden',
            'theme': parseTheme('solid', 'red'),
        },
    ],
    'wetland': [
        {
            'value': 'blanket-bog',
            'label': 'Blanket bog',
            'theme': parseTheme('lines45', 'pink', 'white'),
        }, {
            'value': 'depressions-on-peat-substrates-h7150',
            'label': 'Depressions on Peat substrates (H7150)',
            'theme': parseTheme('solid', 'pink'),
        }, {
            'value': 'fens-upland-and-lowland',
            'label': 'Fens (upland and lowland)',
            'theme': parseTheme('lines45', 'pink', 'yellow'),
        }, {
            'value': 'lowland-raised-bog',
            'label': 'Lowland raised bog',
            'theme': parseTheme('linesVertical', 'pink', 'white'),
        }, {
            'value': 'oceanic-valley-mire1-d21',
            'label': 'Oceanic Valley Mire[1] (D2.1)',
            'theme': parseTheme('solid', 'pink'),
        }, {
            'value': 'purple-moor-grass-and-rush-pastures',
            'label': 'Purple moor grass and rush pastures',
            'theme': parseTheme('circles', 'lime', 'whiteLime'),
        }, {
            'value': 'reedbeds',
            'label': 'Reedbeds',
            'theme': parseTheme('lines', 'yellow', 'pink'),
        }, {
            'value': 'transition-mires-and-quaking-bogs-h7140',
            'label': 'Transition mires and quaking bogs (H7140)',
            'theme': parseTheme('solid', 'pink'),
        },
    ],
    'woodland-and-forest': [
        {
            'value': 'felled',
            'label': 'Felled',
            'theme': parseTheme('solid', 'green'),
        }, {
            'value': 'lowland-beech-and-yew-woodland',
            'label': 'Lowland beech and yew woodland',
            'theme': parseTheme('lines', 'white', 'green'),
        }, {
            'value': 'lowland-mixed-deciduous-woodland',
            'label': 'Lowland mixed deciduous woodland',
            'theme': parseTheme('lines', 'brown', 'green'),
        }, {
            'value': 'native-pine-woodlands',
            'label': 'Native pine woodlands',
            'theme': parseTheme('lines45', 'green', 'yellow'),
        }, {
            'value': 'other-coniferous-woodland',
            'label': 'Other coniferous woodland',
            'theme': parseTheme('lines', 'yellow', 'green'),
        }, {
            'value': 'other-scots-pine-woodland',
            'label': "Other Scot's Pine woodland",
            'theme': parseTheme('linesVertical', 'green', 'yellow'),
        }, {
            'value': 'other-woodland-broadleaved',
            'label': 'Other woodland broadleaved',
            'theme': parseTheme('circles', 'green', 'brown'),
        }, {
            'value': 'other-woodland-mixed',
            'label': 'Other woodland mixed',
            'theme': parseTheme('circles', 'brown', 'green'),
        }, {
            'value': 'upland-birchwoods',
            'label': 'Upland birchwoods',
            'theme': parseTheme('linesVertical', 'green', 'brown'),
        }, {
            'value': 'upland-mixed-ashwoods',
            'label': 'Upland mixed ashwoods',
            'theme': parseTheme('linesVertical', 'green', 'white'),
        }, {
            'value': 'upland-oakwood',
            'label': 'Upland oakwood',
            'theme': parseTheme('lines45', 'green', 'white'),
        }, {
            'value': 'wet-woodland',
            'label': 'Wet woodland',
            'theme': parseTheme('lines45', 'green', 'brown'),
        }, {
            'value': 'wood-pasture-and-parkland',
            'label': 'Wood-pasture and parkland',
            'theme': parseTheme('circles', 'white', 'green', {width: 25, height: 25, space: 12, stroke2: 'lime'}),
        },
    ],
    'coastal-lagoons': [
        {
            'value': 'coastal-lagoons',
            'label': 'Coastal lagoons',
            'theme': parseTheme('lines', 'white', 'orange'),
        },
    ],
    'rocky-shore': [
        {
            'value': 'high-energy-littoral-rock',
            'label': 'High energy littoral rock',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        }, {
            'value': 'high-energy-littoral-rock-on-peat-clay-or-chalk',
            'label': 'High energy littoral rock - on peat, clay or chalk',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        }, {
            'value': 'moderate-energy-littoral-rock',
            'label': 'Moderate energy littoral rock',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        }, {
            'value': 'moderate-energy-littoral-rock-on-peat-clay-or-chalk',
            'label': 'Moderate energy littoral rock - on peat, clay or chalk',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        }, {
            'value': 'low-energy-littoral-rock',
            'label': 'Low energy littoral rock',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        }, {
            'value': 'low-energy-littoral-rock-on-peat-clay-or-chalk',
            'label': 'Low energy littoral rock - on peat, clay or chalk',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        }, {
            'value': 'features-of-littoral-rock',
            'label': 'Features of littoral rock',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        }, {
            'value': 'features-of-littoral-rock-on-peat-clay-or-chalk',
            'label': 'Features of littoral rock - on peat, clay or chalk',
            'theme': parseTheme('lines-45', 'white', 'blueBright'),
        },
    ],
    'coastal-saltmarsh': [
        {
            'value': 'saltmarshes-and-saline-reedbeds',
            'label': 'Saltmarshes and saline reedbeds',
            'theme': parseTheme('lines45', 'blue', 'yellow'),
        }, {
            'value': 'artificial-saltmarshes-and-saline-reedbeds',
            'label': 'Artificial saltmarshes and saline reedbeds',
            'theme': parseTheme('lines45', 'blue', 'yellow'),
        },
    ],
    'intertidal-sediment': [
        {
            'value': 'littoral-coarse-sediment',
            'label': 'Littoral coarse sediment',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-mud',
            'label': 'Littoral mud',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-mixed-sediments',
            'label': 'Littoral mixed sediments',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-seagrass',
            'label': 'Littoral seagrass',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-seagrass-on-peat-clay-or-chalk',
            'label': 'Littoral seagrass on peat, clay or chalk',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-biogenic-reefs-mussels',
            'label': 'Littoral biogenic reefs - Mussels',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-biogenic-reefs-sabellaria',
            'label': 'Littoral biogenic reefs - Sabellaria',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'features-of-littoral-sediment',
            'label': 'Features of littoral sediment',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'artificial-littoral-coarse-sediment',
            'label': 'Artificial littoral coarse sediment',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'artificial-littoral-mud',
            'label': 'Artificial littoral mud',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'artificial-littoral-muddy-sand',
            'label': 'Artificial littoral muddy sand',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'artificial-littoral-mixed-sediments',
            'label': 'Artificial littoral mixed sediments',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'artificial-littoral-seagrass',
            'label': 'Artificial littoral seagrass',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'artificial-littoral-biogenic-reefs',
            'label': 'Artificial littoral biogenic reefs',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-sand',
            'label': 'Littoral sand',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        }, {
            'value': 'littoral-muddy-sand',
            'label': 'Littoral muddy sand',
            'theme': parseTheme('lines-45', 'yellow', 'blueBright'),
        },
    ],
    'intertidal-hard-structures': [
        {
            'value': 'artificial-hard-structures',
            'label': 'Artificial hard structures',
            'theme': parseTheme('solid', 'grayDark'),
        }, {
            'value': 'artificial-features-of-hard-structures',
            'label': 'Artificial features of hard structures',
            'theme': parseTheme('solid', 'grayDark'),
        }, {
            'value': 'artificial-hard-structures-with-integrated-greening-of-grey-infrastructure-iggi',
            'label': 'Artificial hard structures with Integrated Greening of Grey Infrastructure (IGGI)',
            'theme': parseTheme('solid', 'grayDark'),
        },
    ],
    'hedgerows': [
        {
            'value': 'native-species-rich-hedgerow-with-trees-associated-with-bank-or-ditch',
            'label': 'Native Species Rich Hedgerow with trees - Associated with bank or ditch',
            'theme': parseTheme('squares', 'purple', 'black'),
        }, {
            'value': 'native-species-rich-hedgerow-with-trees',
            'label': 'Native Species Rich Hedgerow with trees',
            'theme': parseTheme('squares', 'purple', 'black'),
        }, {
            'value': 'native-species-rich-hedgerow-associated-with-bank-or-ditch',
            'label': 'Native Species Rich Hedgerow - Associated with bank or ditch',
            'theme': parseTheme('squares', 'purple', 'black'),
        }, {
            'value': 'native-hedgerow-with-trees-associated-with-bank-or-ditch',
            'label': 'Native Hedgerow with trees - Associated with bank or ditch',
            'theme': parseTheme('squares', 'yellow', 'purple'),
        }, {
            'value': 'native-species-rich-hedgerow',
            'label': 'Native Species Rich Hedgerow',
            'theme': parseTheme('squares', 'purple', 'black'),
        }, {
            'value': 'native-hedgerow-associated-with-bank-or-ditch',
            'label': 'Native Hedgerow - Associated with bank or ditch',
            'theme': parseTheme('squares', 'yellow', 'purple'),
        }, {
            'value': 'native-hedgerow-with-trees',
            'label': 'Native Hedgerow with trees',
            'theme': parseTheme('squares', 'yellow', 'purple'),
        }, {
            'value': 'line-of-trees-ecologically-valuable',
            'label': 'Line of Trees (Ecologically Valuable)',
            'theme': parseTheme('squares', 'green', 'black'),
        }, {
            'value': 'line-of-trees-ecologically-valuable-with-bank-or-ditch',
            'label': 'Line of Trees (Ecologically Valuable) - with Bank or Ditch',
            'theme': parseTheme('squares', 'green', 'black'),
        }, {
            'value': 'native-hedgerow',
            'label': 'Native Hedgerow',
            'theme': parseTheme('squares', 'yellow', 'purple'),
        }, {
            'value': 'line-of-trees',
            'label': 'Line of Trees',
            'theme': parseTheme('squares', 'green', 'black'),
        }, {
            'value': 'line-of-trees-associated-with-bank-or-ditch',
            'label': 'Line of Trees - Associated with bank or ditch',
            'theme': parseTheme('squares', 'green', 'black'),
        }, {
            'value': 'hedge-ornamental-non-native',
            'label': 'Hedge Ornamental Non Native',
            'theme': parseTheme('squares', 'purple', 'red'),
        },
    ],
    'rivers': [
        {
            'value': 'priority-habitat',
            'label': 'Priority Habitat',
            'theme': parseTheme('solid', 'blueBaby'),
        }, {
            'value': 'other-rivers-and-streams',
            'label': 'Other Rivers and Streams',
            'theme': parseTheme('circles', 'grayLight', 'green'),
        }, {
            'value': 'ditches',
            'label': 'Ditches',
            'theme': parseTheme('squares', 'grayLight', 'yellow'),
        }, {
            'value': 'canals',
            'label': 'Canals',
            'theme': parseTheme('circles', 'blueBaby', 'grayLight'),
        }, {
            'value': 'culvert',
            'label': 'Culvert',
            'theme': parseTheme('circles', 'black', 'grayLight'),
        },
    ],
};


const orderedData = (habitats = habitatData) => {
    const sorted = {};

    Object.keys(habitats).sort().forEach(category => {
        sorted[category] = habitats[category].sort((value1, value2) => {
            if (value1.label > value2.label) return 1;
            if (value1.label < value2.label) return -1;

            return 0;
        }).map(habitat => habitat);
    });

    return sorted;
};

const addAdditionalFields = (additionalFields) => {
    if (!additionalFields) return {...habitatData};
    const habitats = {...habitatData};

    if (additionalFields.land) {
        Object.keys(additionalFields.land).forEach(category => {
            if (!habitats[category]) {
                habitats[category] = [];
            }

            habitats[category] = habitats[category].concat(additionalFields.land[category]);
        });
    }

    if (additionalFields.hedgerows) {
        habitats.hedgerows = habitats.hedgerows.concat(additionalFields.hedgerows);
    }

    if (additionalFields.rivers) {
        habitats.rivers = habitats.rivers.concat(additionalFields.rivers);
    }

    return orderedData(habitats);
};

const orderedDataReturn = orderedData();

export default {...habitatData};

export { orderedDataReturn as orderedData, addAdditionalFields };
