<template>
    <div>
        <FormulateInput
            type="email"
            name="email"
            label="Email"
            validation="required|email"
            :disabled="email"
        />
        <FormulateInput
            type="password"
            name="password"
            label="Password"
        />
        <div class="formulate-input">
            <inertia-link :href="$route('password.request')">
                Forgot your password?
            </inertia-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',

    props: {
        email: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            errors: this.$page.props.errors,
        };
    },
};
</script>
