var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-card" }, [
    _c("div", { staticClass: "user-card-left" }, [
      _c(
        "div",
        { staticClass: "user-card-pic" },
        [
          _vm.initialsOutput
            ? _vm._t(
                "default",
                function () {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.initialsOutput) +
                        " \n            "
                    ),
                  ]
                },
                { content: _vm.initials, type: "initials" }
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "user-card-right" }, [
      _c(
        "div",
        { staticClass: "user-card-row" },
        [
          _vm._t(
            "default",
            function () {
              return [
                _vm.firstName || _vm.lastName
                  ? _c(
                      "span",
                      { staticClass: "user-card-large" },
                      [
                        _vm.firstName
                          ? [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.firstName) +
                                  " \n                    "
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lastName
                          ? [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.lastName) +
                                  "\n                    "
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.email
                          ? _c("div", { staticClass: "user-card-sep" })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.email
                  ? _c(
                      "span",
                      { staticClass: "user-card-faded" },
                      [
                        _vm._t(
                          "default",
                          function () {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.email) +
                                  "\n                    "
                              ),
                            ]
                          },
                          { content: _vm.email, type: "email" }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
            {
              content:
                (_vm.firstName ? `${_vm.firstName} ` : "") + _vm.lastName ?? "",
              type: "name",
            }
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "user-card-row" },
        [
          _vm.company
            ? _vm._t(
                "default",
                function () {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.company) +
                        "\n            "
                    ),
                  ]
                },
                { content: _vm.company, type: "company" }
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }