var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("auth-layout", { attrs: { component: _vm.$options.name } }, [
    _c(
      "div",
      { staticClass: "form-page bg-white" },
      [
        _c("FormulateForm", [
          _c("div", { staticClass: "form-page" }, [
            _c("div", { staticClass: "form-page-info" }, [
              _c("h3", [_vm._v("Unable to accept invite")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.message) +
                    "\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }