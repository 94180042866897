<template>
    <div class="user-card">
        <div class="user-card-left">
            <div class="user-card-pic">
                <slot
                    v-if="initialsOutput"
                    :content="initials"
                    type="initials"
                >
                    {{ initialsOutput }}&nbsp;
                </slot>
            </div>
        </div>
        <div class="user-card-right">
            <div class="user-card-row">
                <slot
                    :content="(firstName ? `${firstName} ` : '') + lastName ?? ''"
                    type="name"
                >
                    <span
                        v-if="firstName || lastName"
                        class="user-card-large"
                    >
                        <template v-if="firstName">
                            {{ firstName }}&nbsp;
                        </template>
                        <template v-if="lastName">
                            {{ lastName }}
                        </template>
                        <div
                            v-if="email"
                            class="user-card-sep"
                        />
                    </span>
                    <span
                        v-if="email"
                        class="user-card-faded"
                    >
                        <slot
                            :content="email"
                            type="email"
                        >
                            {{ email }}
                        </slot>
                    </span>
                </slot>
            </div>
            <div class="user-card-row">
                <slot
                    v-if="company"
                    :content="company"
                    type="company"
                >
                    {{ company }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserCard',

    props: {
        initials: {
            type: String,
            default: null,
        },
        firstName: {
            type: String,
            default: null,
        },
        lastName: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        company: {
            type: String,
            default: null,
        },
    },

    computed: {
        initialsOutput() {
            if (this.initials) {
                return `${this.initials[0] ?? ''}${this.initials[1] ?? ''}`;
            }

            return `${this.firstName[0] ?? ''}${this.lastName[0] ?? ''}`;
        },
    },
};
</script>
