var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.tag, { tag: "component", staticClass: "processing" }, [
    _vm.spinner ? _c("div", { staticClass: "processing-spinner" }) : _vm._e(),
    _vm._v(" "),
    _c(
      "span",
      {
        class: {
          dots: _vm.dots,
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }