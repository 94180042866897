<template>
    <auth-layout :component="$options.name">
        <div
            class="form-page bg-white"
        >
            <FormulateForm>
                <div class="form-page">
                    <div class="form-page-info">
                        <h3>Unable to accept invite</h3>
                        <p>
                            {{ message }}
                        </p>
                    </div>
                </div>
            </FormulateForm>
        </div>
    </auth-layout>
</template>

<script>
import AuthLayout from 'Components/Layouts/AuthLayout';

export default {
    name: 'InviteForm',

    components: {
        AuthLayout,
    },

    props: {
        message: {
            type: String,
            default: null,
        },
    },
};
</script>
