var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "vue-formulate-drawmap vue-formulate-drawmap--multi" },
    [
      _c(
        "mapping-tool",
        {
          ref: "map",
          staticClass: "form-flex--lap",
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            "fit-bounds": "features",
            "fit-bounds-update": "edit",
            "sidebar-width": 0,
            sidebar: _vm.sidebar,
            upload: _vm.upload,
          },
          scopedSlots: _vm._u([
            {
              key: "mapHolder",
              fn: function () {
                return [
                  _c("HeaderSmall", { attrs: { title: "Location" } }),
                  _vm._v(" "),
                  _c("alert", { attrs: { type: "info" } }, [
                    _c("p", [
                      _vm._v(
                        "Use the map below to draw your habitat - please be as accurate as possible."
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "sidebar",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "vue-formulate-drawmap-sidebar w-1/3" },
                    [
                      _c("HeaderSmall", {
                        attrs: {
                          title: "Habitats",
                          button: {
                            svg: "filter",
                            click: () => (_vm.showFilters = !_vm.showFilters),
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "vue-formulate-drawmap-sidebar-content",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "vue-formulate-drawmap-sidebar-scroll",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vue-formulate-drawmap-sidebar-bng",
                                },
                                [
                                  _c("BNGCalculator", {
                                    key: "bng_calculator",
                                    ref: "calculator",
                                    attrs: {
                                      "fields-enabled": _vm.disabledFields,
                                      "form-type": "partial",
                                      "run-calculations": false,
                                      photo: true,
                                      "photo-upload-url":
                                        _vm.$route("habitat.upload"),
                                      pill: "",
                                      "show-fields": _vm.getShowFields,
                                    },
                                    on: {
                                      switch: _vm.changeActive,
                                      input: _vm.updateHabitat,
                                      "submit-start": function ($event) {
                                        return _vm.$emit("submit-start")
                                      },
                                      submit: function ($event) {
                                        return _vm.$emit("submit", $event)
                                      },
                                    },
                                    model: {
                                      value: _vm.habitatFormData,
                                      callback: function ($$v) {
                                        _vm.habitatFormData = $$v
                                      },
                                      expression: "habitatFormData",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showFilters,
                                  expression: "showFilters",
                                },
                              ],
                              staticClass:
                                "vue-formulate-drawmap-sidebar-filter",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vue-formulate-drawmap-sidebar-scroll",
                                },
                                [
                                  _c("mapping-tool-sidebar", {
                                    attrs: {
                                      "sidebar-click-action": "expand",
                                      width: "100%",
                                    },
                                    on: {
                                      toggleChecked: (feature) =>
                                        _vm.$emit(
                                          "sidebarToggleChecked",
                                          feature
                                        ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("mapping-tool-schema", {
            attrs: { schema: _vm.schema },
            on: { input: _vm.updateSchema },
          }),
          _vm._v(" "),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }