var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-tag",
      class: {
        "has-click": _vm.hasClick,
      },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.hasClick
        ? _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [_c("svg-controller", { attrs: { type: "times" } })],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }