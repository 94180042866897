<template>
    <div>
        <div
            v-if="hasMetric"
            class="form-flex--tab"
        >
            <FormulateInput
                key="onsite_baseline_total_units"
                v-model="onsite_baseline_total_units"
                type="fake"
                label="Onsite baseline total"
                validation-name="Onsite baseline total"
                :parse-value="() => floatOutput(onsite_baseline_total_units)"
            />
            <FormulateInput
                key="onsite_post_intervention_total"
                v-model="onsite_post_intervention_total"
                type="fake"
                label="Onsite Post Intervention total"
                validation-name="Onsite Post Intervention total"
                :parse-value="() => floatOutput(onsite_post_intervention_total)"
            />
        </div>
        <div class="form-flex--tab">
            <FormulateInput
                key="onsite_desired_percentage"
                v-model="onsite_desired_percentage"
                :type="context ? 'number' : 'fake'"
                name="onsite_desired_percentage"
                label="Desired net gain percentage"
                placeholder="Desired net gain percentage (%)"
                validation="required|number|between:9,201"
                validation-name="Desired net gain percentage"
                @input="calculatePercentages"
            />
            <FormulateInput
                v-if="hasMetric"
                key="desired_total"
                v-model="desired_total"
                type="fake"
                label="Desired offsite BNG units"
                validation-name="Desired offsite BNG units"
                :parse-value="() => floatOutput(desired_total)"
            />
        </div>
    </div>
</template>


<script>
import MetricMixin from 'Mixins/metric';

export default {
    name: 'FormDesired',

    mixins: [MetricMixin],

    props: {
        baseline: {
            type: Array,
            default: null,
        },
        creation: {
            type: Array,
            default: null,
        },
        enhancement: {
            type: Array,
            default: null,
        },
        desiredUpliftPercent: {
            type: Number,
            default: 10,
        },
        context: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            fetchTimeout: null,
            onsite_baseline_total_units: 0,
            onsite_post_intervention_total: 0,
            onsite_desired_percentage: this.desiredUpliftPercent,
            desired_total: 0,
            habitatsUnits: {},
        };
    },

    computed: {
        hasMetric() {
            return this.baseline !== null && this.creation !== null && this.enhancement !== null;
        },

        componentType() {
            return this.isForm ? '' : 'div';
        },

        totals() {
            return {
                baseline: {
                    total: this.getMetricValueSum(['baseline.Total habitat units', 'baseline.Total hedgerow units', 'baseline.Total watercourse units']),
                    habitat: this.getMetricValueSum('baseline.Total habitat units', 'habitat'),
                    hedgerow: this.getMetricValueSum('baseline.Total hedgerow units', 'hedgerow'),
                    watercourse: this.getMetricValueSum('baseline.Total watercourse units', 'watercourse'),
                },
                creation: {
                    total: this.getMetricValueSum(['creation.Habitat units delivered', 'creation.Hedge units delivered', 'creation.Watercourse units delivered']),
                    habitat: this.getMetricValueSum('creation.Habitat units delivered', 'habitat'),
                    hedgerow: this.getMetricValueSum('creation.Hedge units delivered', 'hedgerow'),
                    watercourse: this.getMetricValueSum('creation.Watercourse units delivered', 'watercourse'),
                },
                enhancement: {
                    total: this.getMetricValueSum(['enhancement.Habitat units delivered', 'enhancement.Hedge units delivered', 'enhancement.Watercourse units delivered']),
                    habitat: this.getMetricValueSum('enhancement.Habitat units delivered', 'habitat'),
                    hedgerow: this.getMetricValueSum('enhancement.Hedge units delivered', 'hedgerow'),
                    watercourse: this.getMetricValueSum('enhancement.Watercourse units delivered', 'watercourse'),
                },
            };
        },
    },

    watch: {
        baseline: {
            handler() {
                this.updateHabitatData();
                this.calculatePercentages();
            },
            deep: true,
        },
        creation: {
            handler() {
                this.updateHabitatData();
                this.calculatePercentages();
            },
            deep: true,
        },
        enhancement: {
            handler() {
                this.updateHabitatData();
                this.calculatePercentages();
            },
            deep: true,
        },
    },

    created() {
        if (this.hasMetric) {
            this.onsite_baseline_total_units = this.totals.baseline.total;
        }

        this.updateHabitatData();
    },

    methods: {
        updateHabitatData(ref) {
            if (!this.hasMetric) {
                return;
            }

            clearTimeout(this.fetchTimeout);
            this.$emit('fetched', ref);

            this.fetchTimeout = setTimeout(() => {
                let onsite_baseline_total_units = 0,
                    onsite_creation_total_units = 0,
                    onsite_enhancement_total_units = 0,
                    onsite_baseline_habitat_units = 0,
                    onsite_baseline_hedgerow_units = 0,
                    onsite_baseline_river_units = 0,
                    onsite_creation_habitat_units = 0,
                    onsite_creation_hedgerow_units = 0,
                    onsite_creation_river_units = 0,
                    onsite_enhancement_habitat_units = 0,
                    onsite_enhancement_hedgerow_units = 0,
                    onsite_enhancement_river_units = 0;

                if (this.totals.baseline) {
                    onsite_baseline_habitat_units = this.totals.baseline.habitat;
                    onsite_baseline_hedgerow_units = this.totals.baseline.hedgerow;
                    onsite_baseline_river_units = this.totals.baseline.watercourse;
                    onsite_baseline_total_units = onsite_baseline_habitat_units + onsite_baseline_hedgerow_units + onsite_baseline_river_units;
                }

                if (this.totals.creation) {
                    onsite_creation_habitat_units = this.totals.creation.habitat;
                    onsite_creation_hedgerow_units = this.totals.creation.hedgerow;
                    onsite_creation_river_units = this.totals.creation.watercourse;
                    onsite_creation_total_units = onsite_creation_habitat_units + onsite_creation_hedgerow_units + onsite_creation_river_units;
                }

                if (this.totals.enhancement) {
                    onsite_enhancement_habitat_units = this.totals.enhancement.habitat;
                    onsite_enhancement_hedgerow_units = this.totals.enhancement.hedgerow;
                    onsite_enhancement_river_units = this.totals.enhancement.watercourse;
                    onsite_enhancement_total_units = onsite_enhancement_habitat_units + onsite_enhancement_hedgerow_units + onsite_enhancement_river_units;
                }

                this.habitatsUnits = {
                    baseline: {
                        total: onsite_baseline_total_units,
                        habitat: onsite_baseline_habitat_units,
                        hedgerows: onsite_baseline_hedgerow_units,
                        rivers: onsite_baseline_river_units,
                    },
                    creation: {
                        total: onsite_creation_total_units,
                        habitat: onsite_creation_habitat_units,
                        hedgerows: onsite_creation_hedgerow_units,
                        rivers: onsite_creation_river_units,
                    },
                    enhancement: {
                        total: onsite_enhancement_total_units,
                        habitat: onsite_enhancement_habitat_units,
                        hedgerows: onsite_enhancement_hedgerow_units,
                        rivers: onsite_enhancement_river_units,
                    },
                    retained: {
                        total: this.getMetricValueSum(['baseline.Area retained', 'baseline.Length retained']),
                        habitat: this.getMetricValueSum('baseline.Area retained', 'habitat'),
                        hedgerows: this.getMetricValueSum('baseline.Length retained', 'hedgerow'),
                        rivers: this.getMetricValueSum('baseline.Length retained', 'river'),
                    },
                };

                this.$nextTick(() => {
                    this.calculatePercentages();
                    this.$emit('change', this.value);
                });
            }, 100);
        },

        calculatePercentages(calcPercentage = this.onsite_desired_percentage) {
            this.onsite_post_intervention_total = 0;
            this.onsite_baseline_total_units = this.totals.baseline.total;
            this.desired_total = 0;

            if (this.hasMetric && this.habitatsUnits.baseline && this.habitatsUnits.creation && this.habitatsUnits.enhancement && this.habitatsUnits.retained) {
                this.onsite_post_intervention_total = parseFloat(this.habitatsUnits.creation.total + this.habitatsUnits.enhancement.total + this.habitatsUnits.retained.total);
                const onsite_baseline_total_units = parseFloat(this.onsite_baseline_total_units);
                const onsite_desired_percentage = parseFloat(calcPercentage);

                if (this.context) this.context.setFieldValue('onsite_post_intervention_total', this.onsite_post_intervention_total);

                if (!isNaN(this.onsite_post_intervention_total) && !isNaN(onsite_desired_percentage) && !isNaN(onsite_baseline_total_units)) {
                    const output = (onsite_baseline_total_units + ((onsite_baseline_total_units / 100) * onsite_desired_percentage)) - this.onsite_post_intervention_total;

                    if (this.context) this.context.setFieldValue('desired_total', isNaN(output) ? 0 : output);
                    this.desired_total = output;
                }
            }
        },
    },
};
</script>
