const mapService = process.env.MIX_MAP_SERVICE || 'https://map-service.sequest.eco';

// All items will require at least id and a name
const returnData = (type) => {
    switch(type) {
        case 'local_nature_reserves':
            return {
                url: mapService + '/local_nature_reserves/{z}/{x}/{y}.pbf',
                attributes: mapService + '/local_nature_reserves.attributes.json',
                properties: {
                    id: 'GlobalID',
                    object_id: 'OBJECTID',
                    name: '${LNR_NAME}',
                    nnr_area: 'NNR_AREA',
                    reference_id: 'REFERENCE',
                    status: 'STATUS',
                    g_id: 'GID',
                    g_id_file: 'GIS_FILE',
                    number: 'NUMBER',
                    area: 'AREA',
                    date: 'GIS_DATE',
                    version: 'VERSION',
                },
            };

        case 'lpa':
            return {
                url: mapService + '/lpa/{z}/{x}/{y}.pbf',
                attributes: mapService + '/lpa.attributes.json',
                properties: {
                    id: 'LPA23CD',
                    name: '${LPA23NM}',
                },
            };

        case 'national_character_areas_england':
            return {
                url: mapService + '/national_character_areas_england/{z}/{x}/{y}.pbf',
                attributes: mapService + '/national_character_areas_england.attributes.json',
                properties: {
                    id: 'GlobalID',
                    name: '${NCA_Name}',
                    area: 'NANAME',
                },
            };

        case 'national_nature_reserves':
            return {
                url: mapService + '/national_nature_reserves/{z}/{x}/{y}.pbf',
                attributes: mapService + '/national_nature_reserves.attributes.json',
                properties: {
                    id: 'GlobalID',
                    object_id: 'OBJECTID',
                    name: '${NNR_NAME}',
                    nnr_area: 'NNR_AREA',
                    reference_id: 'REFERENCE',
                    status: 'STATUS',
                    g_id: 'GID',
                    g_id_file: 'GIS_FILE',
                    number: 'NUMBER',
                    area: 'AREA',
                    date: 'GIS_DATE',
                    version: 'VERSION',
                },
            };

        case 'national_parks':
            return {
                url: mapService + '/national_parks/{z}/{x}/{y}.pbf',
                attributes: mapService + '/national_parks.attributes.json',
                properties: {
                    object_id: 'OBJECTID',
                    code_id: 'CODE',
                    name: '${NAME}',
                    measure: 'MEASURE',
                    date: 'DESIG_DATE',
                    link: 'HOTLINK',
                    status: 'STATUS',
                },
            };

        case 'potential_special_protection_areas':
            return {
                url: mapService + '/potential_special_protection_areas/{z}/{x}/{y}.pbf',
                attributes: mapService + '/potential_special_protection_areas.attributes.json',
                properties: {
                    object_id: 'OBJECTID',
                    name: '${SPA_NAME}',
                    code_id: 'SPA_CODE',
                    spa_area: 'SPA_AREA',
                    grid_id: 'GRID_REF',
                    name_2: 'NAME',
                    status: 'STATUS',
                    id: 'ID',
                    area: 'AREA',
                    date: 'GIS_DATE',
                    version: 'VERSION',
                },
            };

        case 'proposed_ramsar_sites':
            return {
                url: mapService + '/proposed_ramsar_sites/{z}/{x}/{y}.pbf',
                attributes: mapService + '/proposed_ramsar_sites.attributes.json',
                properties: {
                    id: 'ID',
                    f_id: 'FID',
                    name: '${NAME}',
                    code_id: 'CODE',
                    area: 'AREA',
                    grid_id: 'GRID_REF',
                    status: 'STATUS',
                    date: 'GIS_DATE',
                    version: 'VERSION',
                },
            };

        case 'ramsar_sites':
            return {
                url: mapService + '/ramsar_sites/{z}/{x}/{y}.pbf',
                attributes: mapService + '/ramsar_sites.attributes.json',
                properties: {
                    object_id: 'OBJECTID',
                    name: '${NAME}',
                    CODE: 'CODE',
                    area: 'AREA',
                    grid_id: 'GRID_REF',
                    NAME_2: 'NAME0',
                    status: 'STATUS',
                    id: 'ID',
                    date: 'GIS_DATE',
                    version: 'VERSION',
                },
            };

        case 'sites_of_special_scientific_interest_units':
            return {
                url: mapService + '/sites_of_special_scientific_interest_units/{z}/{x}/{y}.pbf',
                attributes: mapService + '/sites_of_special_scientific_interest_units.attributes.json',
                properties: {
                    object_id: 'OBJECTID',
                    name: "${AllConsult ? '<h5>All Consultations</h5> ${AllConsult}' : ''}${Infrastruc ? '<h5>Infrastructure</h5> ${Infrastruc}' : ''}${WindSolar ? '<h5>Wind & Solar Energy</h5> ${WindSolar}' : ''}${MinOilGas ? '<h5>Minerals, Oil & Gas</h5> ${MinOilGas}' : ''}${RuralNonRe ? '<h5>Rural Non-Residential</h5> ${RuralNonRe}' : ''}${Residentia ? '<h5>Residential</h5> ${Residentia}' : ''}${RuralResid ? '<h5>Rural Residential</h5> ${RuralResid}' : ''}${AirPolluti ? '<h5>Air Pollution</h5> ${AirPolluti}' : ''}${Combustion ? '<h5>Combustion</h5> ${Combustion}' : ''}${Waste ? '<h5>Waste</h5> ${Waste}' : ''}${Compost ? '<h5>Composting</h5> ${Compost}' : ''}${Discharge ? '<h5>Discharge</h5> ${Discharge}' : ''}${Water_Sply ? '<h5>Water Supply</h5> ${Water_Sply}' : ''}${NOTES_1 ? '<h5>Notes 1</h5> ${NOTES_1}' : ''}${NOTES_2 ? '<h5>Notes 2</h5> ${NOTES_2}' : ''}",
                    all_consult: 'AllConsult',
                    infrastructure: 'Infrastruc',
                    wind_solar: 'WindSolar',
                    min_oil_gas: 'MinOilGas',
                    rural_non_residential: 'RuralNonRe',
                    residential: 'Residentia',
                    rural_residential: 'RuralResid',
                    air_pollution: 'AirPolluti',
                    combustion: 'Combustion',
                    waste: 'Waste',
                    compost: 'Compost',
                    discharge: 'Discharge',
                    water_supply: 'Water_Sply',
                    notes_1: 'NOTES_1',
                    notes_2: 'NOTES_2',
                },
            };

        case 'special_areas_of_conservation':
            return {
                url: mapService + '/special_areas_of_conservation/{z}/{x}/{y}.pbf',
                attributes: mapService + '/special_areas_of_conservation.attributes.json',
                properties: {
                    object_id: 'OBJECTID',
                    name: '${SAC_NAME}',
                    code_id: 'SAC_CODE',
                    SAC_AREA: 'SAC_AREA',
                    grid_id: 'GRID_REF',
                    name_2: 'NAME',
                    status: 'STATUS',
                    id: 'ID',
                    area: 'AREA',
                    date: 'GIS_DATE',
                    version: 'VERSION',
                },
            };

        case 'special_protection_areas':
            return {
                url: mapService + '/special_protection_areas/{z}/{x}/{y}.pbf',
                attributes: mapService + '/special_protection_areas.attributes.json',
                properties: {
                    object_id: 'OBJECTID',
                    name: '${SPA_NAME}',
                    code_id: 'SPA_CODE',
                    spa_area: 'SPA_AREA',
                    grid_id: 'GRID_REF',
                    name_2: 'NAME',
                    status: 'STATUS',
                    id: 'ID',
                    area: 'AREA',
                    date: 'GIS_DATE',
                    version: 'VERSION',
                },
            };

        case 'sssi_impact_risk_zones':
            return {
                url: mapService + '/sssi_impact_risk_zones/{z}/{x}/{y}.pbf',
                attributes: mapService + '/sssi_impact_risk_zones.attributes.json',
                properties: {
                    object_id: 'OBJECTID',
                    name: "${AllConsult ? '<h5>All Consultations</h5> ${AllConsult}' : ''}${Infrastruc ? '<h5>Infrastructure</h5> ${Infrastruc}' : ''}${WindSolar ? '<h5>Wind & Solar Energy</h5> ${WindSolar}' : ''}${MinOilGas ? '<h5>Minerals, Oil & Gas</h5> ${MinOilGas}' : ''}${RuralNonRe ? '<h5>Rural Non-Residential</h5> ${RuralNonRe}' : ''}${Residentia ? '<h5>Residential</h5> ${Residentia}' : ''}${RuralResid ? '<h5>Rural Residential</h5> ${RuralResid}' : ''}${AirPolluti ? '<h5>Air Pollution</h5> ${AirPolluti}' : ''}${Combustion ? '<h5>Combustion</h5> ${Combustion}' : ''}${Waste ? '<h5>Waste</h5> ${Waste}' : ''}${Compost ? '<h5>Composting</h5> ${Compost}' : ''}${Discharge ? '<h5>Discharge</h5> ${Discharge}' : ''}${Water_Sply ? '<h5>Water Supply</h5> ${Water_Sply}' : ''}${NOTES_1 ? '<h5>Notes 1</h5> ${NOTES_1}' : ''}${NOTES_2 ? '<h5>Notes 2</h5> ${NOTES_2}' : ''}",
                    all_consult: 'AllConsult',
                    infrastructure: 'Infrastruc',
                    wind_solar: 'WindSolar',
                    min_oil_gas: 'MinOilGas',
                    rural_non_residential: 'RuralNonRe',
                    residential: 'Residentia',
                    rural_residential: 'RuralResid',
                    air_pollution: 'AirPolluti',
                    combustion: 'Combustion',
                    waste: 'Waste',
                    compost: 'Compost',
                    discharge: 'Discharge',
                    water_supply: 'Water_Sply',
                    notes_1: 'NOTES_1',
                    notes_2: 'NOTES_2',
                },
            };

        case 'surface_water_operational_catchments':
            return {
                url: mapService + '/surface_water_operational_catchments/{z}/{x}/{y}.pbf',
                attributes: mapService + '/surface_water_operational_catchments.attributes.json',
                properties: {
                    id: 'OPCAT_ID',
                    name: '${OPCAT_NAME}',
                    management_id: 'MANCAT_ID',
                    management: 'MNCAT_NAME',
                    district_id: 'RBD_ID',
                    district: 'RBD_NAME',
                    sub_type: 'sub-type',
                },
            };


        default: {
            return null;
        }
    }
};

export default {
    local_nature_reserves: returnData('local_nature_reserves'),
    lpa: returnData('lpa'),
    national_character_areas: returnData('national_character_areas_england'),
    national_character_areas_england: returnData('national_character_areas_england'),
    national_nature_reserves: returnData('national_nature_reserves'),
    national_parks: returnData('national_parks'),
    potential_special_protection_areas: returnData('potential_special_protection_areas'),
    proposed_ramsar_sites: returnData('proposed_ramsar_sites'),
    ramsar_sites: returnData('ramsar_sites'),
    sites_of_special_scientific_interest_units: returnData('sites_of_special_scientific_interest_units'),
    special_areas_of_conservation: returnData('special_areas_of_conservation'),
    special_protection_areas: returnData('special_protection_areas'),
    sssi_impact_risk_zones: returnData('sssi_impact_risk_zones'),
    surface_water_operational_catchments: returnData('surface_water_operational_catchments'),
};
