import MetricApi from 'Lib/metric/metricApi.js';

export default {
    data() {
        return {
            defaultType: 'baseline',
            convertVersion: null,
            fieldInformationMetric: {},
            formInformationMetric: {},
            excelCells: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            progressConvertingFunctions: {},
            progressConverting: {},
            processingEventCount: 5,
            excelConvertMetricApi: new MetricApi(this.$metricApi),
        };
    },

    methods: {
        getExcelNextLetter(letter) {
            let nextLetter = null;

            if (letter !== null) {
                const lastLetter = letter.at(-1);

                if (lastLetter === this.excelCells.at(-1)) {
                    let letterBefore = letter.substring(0, letter.length - 1).at(-1);

                    if (letterBefore) {
                        letterBefore = this.getExcelNextLetter(letterBefore);
                    } else {
                        letterBefore = 'A';
                    }

                    nextLetter = letter.substring(0, letter.length - 2) + letterBefore + this.excelCells[0];
                } else {
                    this.excelCells.forEach((current, index) => {
                        if (current === lastLetter && this.excelCells[index + 1] !== undefined) {
                            nextLetter = letter.substring(0, letter.length - 1) + this.excelCells[index + 1];
                        }
                    });
                }
            }

            return nextLetter;
        },

        getExcelLettersBetween(start, end) {
            let currentLetter = start;
            const letters = [];

            while (currentLetter !== this.getExcelNextLetter(end) && currentLetter !== null) {
                letters.push(currentLetter);
                currentLetter = this.getExcelNextLetter(currentLetter);
            }

            return letters;
        },

        processEventExcelRun(type) {
            if (!this.progressConverting[type]) this.progressConverting[type] = 0;

            this.progressConverting[type] ++;

            if (this.progressConvertingFunctions && this.progressConvertingFunctions[type]) {
                if (!this.progressConverting[type]) this.progressConverting[type] = 0;
                const percent = (this.progressConverting[type] / this.processingEventCount) * 100;

                this.progressConvertingFunctions[type](percent);
            }
        },

        detectVersion(workbook) {
            if (workbook.Sheets.Start && workbook.Sheets.Start.D8) {
                const valuePart = String(workbook.Sheets.Start.D8.v).split('.');

                if (valuePart[1] && valuePart[1].length === 1) {
                    valuePart[1] = `0${valuePart[1]}`;
                }

                return parseFloat(`${valuePart[0]}.${valuePart[1]}`);
            }

            return 3.01;
        },

        async onSiteBaselineConvert(workbook, version = null) {
            this.processEventExcelRun('baseline');
            if (!version) version = this.detectVersion(workbook);
            this.processEventExcelRun('baseline');
            this.convertVersion = version;

            const pages = await this.getPageInformation(version, 'baseline', 'onsite');

            this.processEventExcelRun('baseline');

            const { habitats } = await this.pullValues(pages, workbook);

            this.processEventExcelRun('baseline');

            return {version, habitats};
        },

        async onSiteCreatedConvert(workbook, version = null) {
            this.processEventExcelRun('creation');
            if (!version) version = this.detectVersion(workbook);
            this.processEventExcelRun('creation');
            this.convertVersion = version;

            const pages = await this.getPageInformation(version, 'creation', 'onsite');

            this.processEventExcelRun('creation');

            const { habitats } = await this.pullValues(pages, workbook);

            this.processEventExcelRun('creation');

            return {version, habitats};
        },

        async onSiteEnhancementConvert(workbook, version = null) {
            this.processEventExcelRun('enhancement');
            if (!version) version = this.detectVersion(workbook);
            this.processEventExcelRun('enhancement');
            this.convertVersion = version;

            const pages = await this.getPageInformation(version, 'enhancement', 'onsite');

            this.processEventExcelRun('enhancement');

            const { habitats } = await this.pullValues(pages, workbook);

            this.processEventExcelRun('enhancement');

            return {version, habitats};
        },

        async onSiteBaselineEnhancementConvert(workbook) {
            const version = this.detectVersion(workbook);

            this.convertVersion = version;

            const pages = await this.getPageInformation(version, 'baseline');

            const { habitats } = await this.pullValues(pages, workbook);

            return { version, habitats };
        },

        async getPageInformation(version, type = this.defaultType, location = 'onsite') {
            if (!this.fieldInformationMetric[version]) {
                this.fieldInformationMetric[version] = await this.excelConvertMetricApi.getUserFieldLayout(version);
            }

            this.processEventExcelRun(type);

            const categories = {
                'A-1': {
                    category: 'habitat',
                    type: 'baseline',
                    location: 'onsite',
                },
                'A-2': {
                    category: 'habitat',
                    type: 'creation',
                    location: 'onsite',
                },
                'A-3': {
                    category: 'habitat',
                    type: 'enhancement',
                    location: 'onsite',
                },
                'B-1': {
                    category: 'hedgerow',
                    type: 'baseline',
                    location: 'onsite',
                },
                'B-2': {
                    category: 'hedgerow',
                    type: 'creation',
                    location: 'onsite',
                },
                'B-3': {
                    category: 'hedgerow',
                    type: 'enhancement',
                    location: 'onsite',
                },
                'C-1': {
                    category: 'watercourse',
                    type: 'baseline',
                    location: 'onsite',
                },
                'C-2': {
                    category: 'watercourse',
                    type: 'creation',
                    location: 'onsite',
                },
                'C-3': {
                    category: 'watercourse',
                    type: 'enhancement',
                    location: 'onsite',
                },
                'D-1': {
                    category: 'habitat',
                    type: 'baseline',
                    location: 'offsite',
                },
                'D-2': {
                    category: 'habitat',
                    type: 'creation',
                    location: 'offsite',
                },
                'D-3': {
                    category: 'habitat',
                    type: 'enhancement',
                    location: 'offsite',
                },
                'E-1': {
                    category: 'hedgerow',
                    type: 'baseline',
                    location: 'offsite',
                },
                'E-2': {
                    category: 'hedgerow',
                    type: 'creation',
                    location: 'offsite',
                },
                'E-3': {
                    category: 'hedgerow',
                    type: 'enhancement',
                    location: 'offsite',
                },
                'F-1': {
                    category: 'watercourse',
                    type: 'baseline',
                    location: 'offsite',
                },
                'F-2': {
                    category: 'watercourse',
                    type: 'creation',
                    location: 'offsite',
                },
                'F-3': {
                    category: 'watercourse',
                    type: 'enhancement',
                    location: 'offsite',
                },
            };
            const pages = [];

            if (this.fieldInformationMetric[version].success) {
                const sheets = Object.keys(this.fieldInformationMetric[version].data);
                const getData = [];

                sheets.forEach(async (sheet) => {
                    const categoryKey = Object.keys(categories).filter((sheetShort) => sheet.includes(sheetShort));
                    let category = '';

                    if (categoryKey.length) {
                        category = categoryKey[0];
                    }

                    getData.push(category);
                });

                for (let index = 0; index < getData.length; index++) {
                    const category = getData[index];

                    if (!this.formInformationMetric[category]) {
                        const formInformation = await this.excelConvertMetricApi.getForm(version, category);

                        if (formInformation.success) {
                            this.formInformationMetric[category] = formInformation.data.columns;
                        }
                    }
                }

                sheets.forEach(async (sheet) => {
                    const page = this.fieldInformationMetric[version].data[sheet];
                    const categoryKey = Object.keys(categories).filter((sheetShort) => sheet.includes(sheetShort));
                    let category = '';

                    if (categoryKey.length) {
                        category = categoryKey[0];
                    }

                    if (page.length && categories[category].type === type && categories[category].location === location) {
                        pages.push({
                            category: categories[category].category,
                            type: categories[category].type,
                            sheet: sheet,
                            sheetShort: sheet.split(' ')[0],
                            tableEndX: page[0].tableEndX,
                            tableEndY: page[0].tableEndY,
                            tableHeader: page[0].tableHeader,
                            tableStartX: page[0].tableStartX,
                            tableStartY: page[0].tableStartY,
                            fieldData: this.formInformationMetric[category],
                        });
                    }
                });
            }

            return pages;
        },

        async pullValues(pages, workbook) {
            const sheetData = [];

            pages.forEach(item => {
                const {sheet, category, tableEndX, tableEndY, tableHeader, tableStartX, tableStartY, fieldData} = item;
                const letters = this.getExcelLettersBetween(tableStartX, tableEndX);

                const headers = {};

                if (workbook.Sheets[sheet]) {
                    const worksheet = workbook.Sheets[sheet];

                    for (let index = 0; index < letters.length; index++) {
                        const letter = letters[index];

                        if (worksheet[`${letter}${tableHeader}`] && worksheet[`${letter}${tableHeader}`].v !== undefined && worksheet[`${letter}${tableHeader}`].v !== '') {
                            headers[letter] = worksheet[`${letter}${tableHeader}`].v.trim();
                        } else if (worksheet[`${letter}${tableHeader - 1}`] && worksheet[`${letter}${tableHeader - 1}`].v !== undefined && worksheet[`${letter}${tableHeader - 1}`].v !== '') {
                            headers[letter] = worksheet[`${letter}${tableHeader - 1}`].v.trim();
                        }
                    }

                    for (let indexRow = tableStartY; indexRow < tableEndY; indexRow++) {
                        const push = {
                            sheet: sheet,
                            sheetShort: sheet.split(' ')[0],
                            category: category,
                            missing: [],
                            fields: {},
                            data: item,
                        };
                        let hasData = false;

                        for (let index = 0; index < letters.length; index++) {
                            const letter = letters[index];
                            const header = headers[letter];
                            const cell = `${letter}${indexRow}`;

                            if (worksheet[cell] && worksheet[cell].v !== undefined && worksheet[cell].v !== '') {
                                let value = worksheet[cell].v ?? '';
                                const fieldFilter = fieldData.filter((field) => field.column === letter);
                                const field = fieldFilter.length ? fieldFilter[0] : null;

                                if (typeof value === 'string') {
                                    value = value.trim();

                                    if (value.includes('.') && !isNaN(parseInt(value, 10))) {
                                        value = parseFloat(value);
                                    } else if (!isNaN(parseInt(value, 10))) {
                                        value = parseInt(value, 10);
                                    }
                                }

                                push.fields[letter] = {
                                    header: header ?? '',
                                    letter: letter,
                                    value: value,
                                    field,
                                };

                                const keyNotToCheck = [
                                    'user comments',
                                    'consenting body comments',
                                    'line number',
                                    'length match',
                                    'hedge number',
                                    'ref',
                                    'baseline ref',
                                ];

                                if (
                                    field &&
                                    field.type === 'user' &&
                                    value &&
                                    !keyNotToCheck.includes(push.fields[letter].header.toLowerCase()) &&
                                    String(push.fields[letter].value).toLowerCase() !== 'yes' &&
                                    String(push.fields[letter].value).toLowerCase() !== 'no'
                                ) {
                                    hasData = true;
                                }
                            } else {
                                push.missing.push({
                                    header: header,
                                    letter: letter,
                                });
                            }

                        }

                        if (hasData) {
                            sheetData.push(push);
                        }
                    }
                }

            });

            const habitatsIds = [];

            for (let index = 0; index < sheetData.length; index++) {
                const habitat = sheetData[index];
                const push = await this.parseHabitatId(workbook, habitat);

                if (Object.keys(push).length) {
                    habitatsIds.push(push);
                }
            }


            return { sheetData, habitats: habitatsIds };
        },

        async parseHabitatId(workbook, habitat) {
            const fields = {
                category: habitat.category,
            };
            const letterLocations = Object.keys(habitat.fields);

            let baselineRef = null;

            letterLocations.forEach((letter) => {
                const field = habitat.fields[letter];

                if (field.field) {
                    fields[`${field.header}:${field.field.column}`] = field.value;

                    if (field.header.includes('Baseline ref')) {
                        baselineRef = field;
                    }
                }
            });

            if (habitat.data.type === 'enhancement') {
                const baselineCompare = await this.onSiteBaselineEnhancementConvert(workbook);
                const habitatBaseline = baselineCompare.habitats;

                habitatBaseline.forEach((habitatBaselineItem) => {
                    const refKey = Object.keys(habitatBaselineItem).filter((key) => key.includes('Ref:'));

                    if (refKey.length && baselineRef && baselineRef.value === habitatBaselineItem[refKey[0]] && fields.category === habitatBaselineItem.category) {
                        fields.baseline = habitatBaselineItem;
                    }
                });

                if (fields.baseline) {
                    Object.keys(fields).forEach((key) => {
                        if (key.includes('Baseline ')) {
                            delete fields[key];
                        }
                    });
                }

            }


            return fields;
        },
    },
};
