import axios from 'axios';

class MetricApi {
    constructor(baseUrl) {
        this.aborters = {};

        this.baseUrl = (baseUrl ?? window.location.protocol + '//' + window.location.host + '/api/');
    }

    getVersions() {
        return this.makeRequest('');
    }

    cancelVersions() {
        this.cancelRequest('');
    }

    getVersion(version, abortIndex = null) {
        return this.makeRequest(abortIndex, `${version}`);
    }

    cancelVersion(version, abortIndex = null) {
        this.cancelRequest(abortIndex, `${version}`);
    }

    getForms(version, abortIndex = null) {
        return this.makeRequest(abortIndex, `${version}/form`);
    }

    cancelForms(version, abortIndex = null) {
        this.cancelRequest(abortIndex, `${version}/form`);
    }

    getForm(version, formName, abortIndex = null) {
        return this.makeRequest(abortIndex, `${version}/form/${encodeURIComponent(formName)}`);
    }

    cancelForm(version, formName, abortIndex = null) {
        this.cancelRequest(abortIndex, `${version}/form/${encodeURIComponent(formName)}`);
    }

    calculateFormula(version, formName, data, abortIndex = null) {
        return this.makeRequest(abortIndex, `${version}/form/${encodeURIComponent(formName)}/formula`, {
            form: data,
        }, 'POST');
    }

    cancelCalculateFormula(version, formName, abortIndex = null) {
        this.cancelRequest(abortIndex, `${version}/form/${encodeURIComponent(formName)}/formula`);
    }

    getUserFieldLayout(version, abortIndex = null) {
        return this.makeRequest(abortIndex, `${version}/user-layout`);
    }

    cancelUserFieldLayout(version, formName, abortIndex = null) {
        this.cancelRequest(abortIndex, `${version}/user-layout`);
    }

    async makeRequest(abortIndex, endpoint, data = {}, method = 'GET') {
        return new Promise((resolve, reject) => {
            const urlParams = method === 'GET' ? `?${new URLSearchParams(data)}` : '';
            const abort = new AbortController();

            this.aborters[`${endpoint}-${abortIndex}`] = abort;

            if (method === 'POST') {
                axios.post(`${this.baseUrl}${endpoint}`, data, {
                    signal: abort.signal,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(response => {
                    this.aborters[`${endpoint}-${abortIndex}`] = null;
                    resolve(response.data);
                }).catch(() => {
                    if (abort && abort.signal.aborted) {
                        return;
                    }

                    // eslint-disable-next-line no-console
                    console.error(`Unable to fetch ${endpoint}. Retrying in 1 second...`);
                    setTimeout(() => {
                        this.makeRequest(abortIndex, endpoint, data, method).then((response) => {
                            resolve(response);
                        }).catch((error) => {
                            reject(error);
                        });
                    }, 1000);
                });

                return;
            }

            axios.get(`${this.baseUrl}${endpoint}${urlParams}`, {
                signal: this.aborters[`${endpoint}-${abortIndex}`].signal,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                this.aborters[`${endpoint}-${abortIndex}`] = null;
                resolve(response.data);
            }).catch(() => {
                if (abort && abort.signal.aborted) {
                    return;
                }

                // eslint-disable-next-line no-console
                console.error(`Unable to fetch ${endpoint}. Retrying in 1 second...`);
                setTimeout(() => {
                    this.makeRequest(abortIndex, endpoint, data, method).then((response) => {
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                    });
                }, 1000);
            });
        });
    }

    cancelRequest(abortIndex, endpoint) {
        if (this.aborters[`${endpoint}-${abortIndex}`]) {
            this.aborters[`${endpoint}-${abortIndex}`].abort('Request no longer needed.');
        }
    }
}

export default MetricApi;
