<template>
    <div>
        <div
            v-for="item in data.data"
            :key="item.title"
        >
            <template v-if="Array.isArray(item)">
                <div class="formulate-heading">
                    <h6>{{ data.title }}</h6>
                </div>
                <DocusignRow
                    v-for="subItem in item"
                    :key="subItem.title"
                    :data="subItem"
                />
            </template>
            <template v-else>
                <h5>{{ item.title }}</h5>
                <p v-if="item.title.toLowerCase() !== 'pdfbytes'">
                    {{ item.data }}
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'DocusignRow',
    components: {
        DocusignRow: defineAsyncComponent(() => import('./DocusignRow')),
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
