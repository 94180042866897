<template>
    <app-layout section="projects">
        <page-header
            :title="pageTitle"
            :icon="pageIcon"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="cancelRoute"
            >
                Cancel
            </inertia-link>
        </page-header>
        <FormulateForm
            ref="form"
            v-model="form"
            :errors="$page.props.errors"
            class="formulate-form--project flex-col"
            :keep-model-data="true"
            @submit="submitForm"
        >
            <PagerContent
                ref="pager"
                class="center"
                :errors="pageErrors"
                :formulate="$refs.form"
                :formulate-errors="$page.props.errors"
                theme="bng"
                :validation="{
                    site_details: () => form.offset_type.includes('bng') || (form.offset_type.includes('carbon') && !form.study) ? validateCustomHabitats('site_details') : true,
                    baseline_study: () => validateCustomHabitats('baseline_study'),
                }"
                :pagination="{
                    type: 'nav',
                }"
                @change="pageChange"
            >
                <PagerPage
                    name="development_details"
                    title="Development Details"
                >
                    <alert
                        v-if="project && project.location_data && project.location_data.lat"
                        type="warning"
                        class="mb-5"
                    >
                        The map functionality has been updated to use area polygons instead of a single point. Please plot the development again.
                    </alert>
                    <FormPage1
                        :value="form"
                        :context="formLoaded ? $refs.form : null"
                        :companies="companies"
                        :countries="countries"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        :development-type-options="developmentTypeOptions"
                        :editable="editable"
                    />
                </PagerPage>
                <PagerPage
                    name="nutrient_offset"
                    title="Nutrient Offset"
                    :enabled="form.offset_type.includes('nutrient_offset')"
                >
                    <FormPage2
                        ref="nutrient_offset"
                        :value="form"
                        :context="formLoaded ? $refs.form : null"
                        :edit="edit"
                        :editable="editable"
                        :planning-authorities="planningAuthorities"
                        :river-basin-districts="riverBasinDistricts"
                        @file-upload="fileUpload"
                    />
                </PagerPage>
                <PagerPage
                    name="site_details"
                    title="Site Details"
                    :enabled="form.offset_type.includes('bng')"
                >
                    <FormPage3
                        ref="site_details"
                        :value="form"
                        :context="formLoaded ? $refs.form : null"
                        :edit="edit"
                        :editable="editable"
                        :development-type-options="developmentTypeOptions"
                        :baseline="baseline"
                        :creation="creation"
                        :enhancement="enhancement"
                    />
                </PagerPage>
                <template #pagination-prev-button>
                    <SvgController type="arrow-left" />
                    <span>Previous</span>
                </template>
                <template #pagination-next-button>
                    <span>Next</span>
                    <SvgController type="arrow-right" />
                </template>
                <template #pagination-next-last>
                    <button
                        class="btn-reset btn btn--primary"
                        @click.prevent="submitFormButton"
                    >
                        <SvgController
                            v-if="!edit"
                            type="tick"
                        />
                        <span>{{ buttonText }}</span>
                    </button>
                </template>
            </PagerContent>
        </FormulateForm>
    </app-layout>
</template>
<script>
import FormPage1 from './form/FormPage1';
import FormPage2 from './form/FormPage2';
import FormPage3 from './form/FormPage3';
import mapMixin from 'Mixins/map.js';
import Alert from 'Utilities/alert/Alert';

import { PagerContent, PagerPage } from 'vue2-pager';

export default {
    name: 'ProjectForm',
    components: {
        FormPage1,
        FormPage2,
        FormPage3,
        PagerContent,
        PagerPage,
        Alert,
    },
    mixins: [
        mapMixin,
    ],
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        project: {
            type: Object,
            default: ()=>({}),
        },
        developmentTypeOptions: {
            type: Array,
            required: true,
        },
        companies: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => ([]),
        },
        planningAuthorities: {
            type: Array,
            default: () => ([]),
        },
        riverBasinDistricts: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            form: null,
            currentPage: 'development_details',
            zoom: 6,
            landStatusOptions: [],
            planningStatusOptions: [],
            pageErrors: {},
            files: {},
            formLoaded: false,
            baseline: this.project.onsite_baseline_habitats ?? null,
            creation: this.project.onsite_creation_habitats ?? null,
            enhancement: this.project.onsite_enhancement_habitats ?? null,
        };
    },
    computed: {
        pageTitle() {
            return this.edit ? 'Edit Development' : 'New Development';
        },
        pageIcon() {
            return this.edit ? 'files' : 'file-plus';
        },
        buttonText() {
            return this.edit ? 'Save Changes' : 'Save';
        },
        cancelRoute() {
            return this.edit ? this.$route('project.show', this.project.id) : this.$route('projects');
        },
        attachments() {
            if (!this.project.attachments) {
                return null;
            }

            const attachments = this.project.attachments;

            return attachments;
        },
        statusOptions() {
            return [{
                value: 'created',
                label: 'Created',
            },{
                value: 'applied',
                label: 'Applied',
            },{
                value: 'land matched',
                label: 'Land Matched',
            },{
                value: 'contract issued',
                label: 'Contract Issued',
            },{
                value: 'land implemented',
                label: 'Land Implemented',
            }];
        },
        editable() {
            if (this.hasGroup(['sequest'])) {
                return true;
            }

            if (!this.edit) {
                return true;
            }

            return (this.edit && this.project.status === 'created' || this.project.status === 'confirmed');
        },
        getPaths() {
            return this.parsePaths(this.project.location_data);
        },
    },
    mounted() {
        this.formLoaded = true;
    },
    created() {
        const offsetTypes = this.edit && this.project.offset_type ? this.project.offset_type.map(offset => offset.name) : [];
        const nutrientTypes = {
            nutrient_types: [],
        };

        if (this.project && this.project.nutrient_type) {
            this.project.nutrient_type.forEach(type => {
                nutrientTypes.nutrient_types.push(type.name);
                nutrientTypes[`nutrient_types_pre_${type.name}_type`] = type.pre;
                nutrientTypes[`nutrient_types_post_${type.name}_type`] = type.post;
            });
        }

        const nutrientCalculator = this.project.attachments ? this.project.attachments
            .filter(attachment => attachment.type === 'nutrient_calculator')
            .map(attachment => ({
                name: attachment.display_name,
                url: attachment.filepath,
            })) : [];

        const mapLocation = {
            location: {address: this.project.address},
            size: this.project.size,
            country: this.project.country_code,
        };

        if (this.project.location_data) {
            const paths = this.getPaths;

            if (
                ((this.project.location_data.layer && this.project.location_data.layer.center) || this.project.location_data.center) &&
                ((this.project.location_data.layer && this.project.location_data.layer.radius) || this.project.location_data.radius)
            ) {
                mapLocation.center = this.project.location_data.layer ? this.project.location_data.layer.center : this.project.location_data.center;
                mapLocation.radius = this.project.location_data.layer ? this.project.location_data.layer.radius : this.project.location_data.radius;
            } else if (paths) {
                mapLocation.paths = paths;
            }
        } else {
            mapLocation.paths = [];
        }

        this.form = this.$inertia.form({
            // General
            name: this.project.name,
            offset_type: offsetTypes,
            country_code: this.project.country_code,
            company_id: this.edit ? this.project.company_id.toString() : null,
            status: this.project.status,
            map_location: mapLocation,
            address: this.project.address,
            address_autofill: this.project.address_autofill ?? 1,
            location_data: [],
            auto_calculate: this.project.size_user === null,
            size_map: this.project.size_map,
            size_user: this.project.size_user ? this.project.size : null,
            size_units: this.project.size_units,


            // BNG
            development_type: this.project.development_type,
            planning_authority_id: offsetTypes.includes('bng') ? this.lpaLocation() : null,
            planning_ref: this.project.planning_ref ?? '',
            planning_status: this.project.planning_status,
            land_status: this.project.land_status,
            study: this.project.study ? this.project.study : true,
            study_requested: this.project.study_requested ? this.project.study_requested : true,
            onsite_desired_percentage: this.project.onsite_desired_percentage ? this.project.onsite_desired_percentage : 10,

            // RIVERS
            river_basin_district_id: offsetTypes.includes('nutrient_offset') ? String(this.project.river_basin_district_id) : null,
            nutrient_development_type: this.project.nutrient_development_type ? this.project.nutrient_development_type : null,
            nutrient_development_amount: this.project.nutrient_development_amount ? this.project.nutrient_development_amount : 0,
            nutrient_planning_status: this.project.nutrient_planning_status ? this.project.nutrient_planning_status : null,
            nutrient_study: this.project.nutrient_study ? this.project.nutrient_study : 0,
            nutrient_calculator: nutrientCalculator,
            ...nutrientTypes,
        });

        this.landStatusOptions = [{
            value: 'green field',
            label: 'Green Field',
        },{
            value: 'brown field',
            label: 'Brown Field',
        }];
        this.planningStatusOptions = [{
            value:'submitted',
            label:'Submitted',
        }, {
            value:'approved',
            label:'Approved',
        }, {
            value:'resolution to approve',
            label:'Resolution to Approve',
        }];
        this.mapZoom();

        if (this.form.nutrient_calculator.length) {
            this.form.nutrient_calculator.forEach(attachment => {
                const name = attachment.name.replace(/\..+$/, '');

                this.files[name] = {
                    filename: name,
                    extension: attachment.url.replace(/^.+\./, ''),
                    url: attachment.url,
                };
            });
        }
    },
    methods: {
        pageChange() {
            this.currentPage = this.$refs.pager.pageSelected;
        },

        submitForm() {
            this.$refs.pager.submitForm(async ({ validated }) => {
                if (validated) {
                    const {
                        country,
                        paths,
                        center,
                        radius,
                        size,
                    } = this.form.map_location;

                    this.form.size = size;

                    if (center && radius) {
                        this.form.location_data = {
                            center,
                            radius,
                        };
                    } else {
                        this.form.location_data = paths;
                    }

                    const data = {...this.form};

                    if (data.auto_calculate) {
                        data.size_user = null;
                    } else {
                        switch (data.size_units) {
                            case 'm2':
                                data.size_user = this.metersSqToHectares(this.float(data.size_user));
                                break;
                            case 'km2':
                                data.size_user = this.kmSqToHectares(this.float(data.size_user));
                                break;
                            case 'acres':
                                data.size_user = this.acresToHectares(this.float(data.size_user));
                                break;
                            default:
                                break;
                        }
                    }

                    if (data.company_id) {
                        data.company_id = parseInt(data.company_id);
                    } else if (this.companies.length) {
                        data.company_id = parseInt(this.companies[0].id);
                    }

                    if (country) {
                        const countryList = this.countries.filter(countryItem => countryItem.code === country);

                        if (countryList.length) {
                            data.country_code = countryList[0].code;
                        }
                    }

                    delete data.map_location;

                    if (data.nutrient_types && data.nutrient_types.length) {
                        const nutrientTypes = [];

                        data.nutrient_types.forEach(type => {
                            nutrientTypes.push({
                                name: type,
                                pre: data[`nutrient_types_pre_${type}_type`],
                                post: data[`nutrient_types_post_${type}_type`],
                            });
                        });

                        data.nutrient_types = nutrientTypes;
                    }

                    const fileNames = Object.keys(this.files);

                    fileNames.forEach(name => {
                        data[name] = this.files[name];
                    });

                    data.address_autofill = parseInt(data.address_autofill) === 1;

                    if (this.edit) {
                        this.$inertia.post(this.$route('project.update', this.project.id), {
                            _method: 'put',
                            ...data,
                        });
                    } else {
                        this.$inertia.post(this.$route('project.store'), {
                            ...data,
                        });
                    }
                }
            });
        },

        submitFormButton() {
            this.$refs.form.formSubmitted();
        },

        openFileModal() {
            this.$refs.fileModal.openModal();
        },

        mapZoom() {
            this.zoom = this.form.map_location.points && this.form.map_location.points.length > 0 ? 13 : 6;
        },

        validateCustomHabitats(ref) {
            delete this.pageErrors[ref];

            return true;
        },

        fileUpload(name, event) {
            this.files[name] = event.path;
        },

        lpaLocation() {
            let value = this.edit && this.project.planning_authority_id ? this.project.planning_authority_id.toString() : null;

            if (this.hasGroup('lpa') && this.$page.props.loggedUser.LPAs.length === 1) {
                value = this.planningAuthorities.filter((item) => item.id === this.$page.props.loggedUser.LPAs[0])[0].id.toString();
            } else if (this.planningAuthorities.length === 1) {
                value = this.planningAuthorities[0].id.toString();
            }

            return value;
        },
    },
};
</script>
