<template>
    <app-layout section="settings">
        <page-header
            title="Docusign"
        />
        <div
            ref="container"
            class="center"
        >
            <TableGridLayout
                :columns="columns"
                :rows="rows"
                :output="['table']"
                @onRowClick="onRowClick"
            />
        </div>
    </app-layout>
</template>

<script>
export default {
    name: 'DocusignList',
    props: {
        docusign: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        columns() {
            return [{
                label: 'Event',
                field: 'event',
                sortable: false,
            }, {
                label: 'ID',
                field: 'envelopeId',
                sortable: false,
            }, {
                label: 'Generated Date',
                field: 'generatedDateTime',
                sortable: false,
            }, {
                label: 'Authorized',
                field: 'authorized',
                sortable: false,
            }];
        },

        rows() {
            return this.docusign.map(document => ({...document, authorized: document.authorized ? 'Yes' : 'No'}));
        },
    },
    methods: {
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('docusign.show', row.id));
        },
    },
};
</script>
