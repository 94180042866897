<template>
    <section :class="`convert-file convert-file--${state}`">
        <label v-if="label">
            {{ label }}
        </label>
        <div class="convert-file-input">
            <DropZone
                :document-name="documentName"
            >
                <processing
                    v-if="state !== 'done' && state !== 'waiting'"
                    :spinner="true"
                    :dots="true"
                    tag="div"
                >
                    {{ state }}
                </processing>
            </DropZone>
            <input
                type="file"
                accept=".xls,.xlsx,.xlsm,.xlsb"
                @change="documentChange"
            >
        </div>
    </section>
</template>

<script>
import { read } from 'xlsx';
import DropZone from './DropZone';
import Processing from 'Utilities/processing/processing';

export default {
    name: 'ExcelConvert',
    components: {
        DropZone,
        Processing,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        iconWaiting: {
            type: String,
            default: 'arrow-up',
        },
        iconUploading: {
            type: String,
            default: 'dots',
        },
        iconDone: {
            type: String,
            default: 'tick',
        },
    },
    data() {
        return {
            documentName: '',
            state: 'waiting',
            eventCount: 5,
            eventRunningCount: 0,
        };
    },
    methods: {
        documentChange(event) {
            this.$emit('init');
            this.eventRunningCount = 0;
            this.state = 'uploading';
            this.processing();
            const selectedFile = event.target.files[0];

            this.documentName = selectedFile.name;

            setTimeout(() => {
                if (selectedFile) {
                    const fileReader = new FileReader();

                    fileReader.addEventListener('loadstart', this.loadstart);
                    fileReader.addEventListener('loadend', this.progress);
                    fileReader.addEventListener('error', this.error);
                    fileReader.addEventListener('abort', this.abort);
                    fileReader.addEventListener('progress', this.processing);

                    fileReader.onload = (event) => {
                        const data = event.target.result;

                        const workbook = read(data, {
                            type: 'binary',
                        });

                        this.$emit('input', workbook, selectedFile);
                        this.state = 'done';
                        this.processing();
                    };

                    fileReader.readAsBinaryString(selectedFile);
                }
            }, 500);
        },

        loadstart() {
            this.$emit('start');
            this.processing();
        },

        load() {
            this.$emit('loaded');
            this.processing();
            this.state = 'processing';
        },

        progress() {
            this.processing();

            if (this.eventRunningCount !== this.eventCount) {
                this.state = 'processing';
            }
        },

        error() {
            this.$emit('error');
        },

        abort() {
            this.$emit('abort');
        },

        processing() {
            this.eventRunningCount++;
            let percent = (this.eventRunningCount / this.eventCount) * 100;

            if (percent >= 100) {
                percent = 100;
                this.state = 'done';
            }

            this.$emit('processing', percent);
        },
    },
};
</script>
