<template>
    <div class="bng-summary">
        <ul class="bng-summary-lists">
            <li
                v-for="(chart, index) in charts"
                :key="`chart-${index}-${chart.title}`"
            >
                <GChart
                    :type="chart.type"
                    :data="chart.data"
                    :options="chart.options"
                />
                <Alert
                    v-if="!chart.error.success"
                    type="warning"
                    class="absolute top-0 right-0 m-0"
                >
                    <p>{{ chart.error.message }}</p>
                </Alert>
            </li>
        </ul>
        <div class="form-flex--tab">
            <FormulateInput
                v-model="totals.habitat"
                type="fake"
                label="Onsite habitat total"
                :parse-value="() => floatOutput(totals.habitat)"
            />
            <FormulateInput
                v-model="totals.hedgerow"
                type="fake"
                label="Onsite baseline hedgerow total"
                :parse-value="() => floatOutput(totals.hedgerow)"
            />
            <FormulateInput
                v-model="totals.watercourse"
                type="fake"
                label="Onsite baseline watercourse total"
                :parse-value="() => floatOutput(totals.watercourse)"
            />
        </div>
        <div class="form-flex--tab">
            <FormulateInput
                v-model="totals.total"
                type="fake"
                label="Onsite baseline total"
                :parse-value="() => floatOutput(totals.total)"
            />
        </div>
    </div>
</template>

<script>
import { GChart, loadGoogleCharts } from 'vue-google-charts/legacy';
import MetricMixin from 'Mixins/metric';

loadGoogleCharts('current', { packages: ['corechart'] });

export default {
    name: 'BNGSummary',
    components: {
        GChart,
    },

    mixins: [
        MetricMixin,
    ],

    props: {
        baseline: {
            type: Array,
            default: () => ([]),
        },
        creation: {
            type: Array,
            default: () => ([]),
        },
        enhancement: {
            type: Array,
            default: () => ([]),
        },
        includeChart: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            chartData: [{
                id: 'baseline-totals',
                title: 'Onsite Totals',
                type: 'PieChart',
                data: 'baseline-totals',
            }, {
                id: 'baseline-habitat-types',
                title: 'Onsite habitat types',
                type: 'PieChart',
                data: 'baseline-habitat-types',
            }, {
                title: 'Onsite Condition',
                id: 'baseline-condition',
                type: 'PieChart',
                category: 'habitat',
                data: 'baseline-condition',
            }],
            totals: {
                total: 0,
                habitat: 0,
                hedgerow: 0,
                watercourse: 0,
            },
        };
    },

    computed: {
        charts() {
            return this.chartData.filter((chart) => this.includeChart === null || this.includeChart.includes(chart.id)).map(chart => this.getChartData(chart)).filter(chart => chart);
        },
    },

    created() {
        this.totals = {
            total: this.getMetricValueSum(['baseline.Total habitat units', 'baseline.Total hedgerow units', 'baseline.Total watercourse units']),
            habitat: this.getMetricValueSum('baseline.Total habitat units', 'habitat'),
            hedgerow: this.getMetricValueSum('baseline.Total hedgerow units', 'hedgerow'),
            watercourse: this.getMetricValueSum('baseline.Total watercourse units', 'watercourse'),
        };
    },
};

</script>
