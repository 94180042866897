var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("terms", {
        attrs: { show: _vm.showTerms },
        on: {
          close: function ($event) {
            _vm.showTerms = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showTerms,
              expression: "!showTerms",
            },
          ],
        },
        [
          _c("FormulateInput", {
            attrs: {
              type: "password",
              name: "password",
              label: "Password",
              validation: "required|min:8,length",
            },
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              type: "password",
              name: "password_confirmation",
              label: "Password Confirmation",
              validation: "confirm:password",
              "validation-name": "Password confirmation",
            },
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            attrs: {
              id: "terms",
              type: "checkbox",
              name: "terms",
              validation: "required",
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c("label", { attrs: { for: "terms" } }, [
                      _vm._v(
                        "\n                    I agree to the terms of the\n                    "
                      ),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showTerms = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        BNG Partnership Customer Agreement\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }