var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _vm.contracts.length > 0
        ? _c(
            "div",
            [
              _c("contracts-table", {
                attrs: {
                  title: "",
                  "new-text": "New Contract",
                  "allow-upload": _vm.$page.props.loggedUser.isSuperUser,
                  "allow-delete": _vm.$page.props.loggedUser.isSuperUser,
                  contracts: _vm.contracts,
                  parent: _vm.parent,
                },
                on: { onRowClick: _vm.openEditorModal },
              }),
            ],
            1
          )
        : _c("empty", { attrs: { type: "contracts" } }, [
            _vm.$page.props.loggedUser.isSuperUser
              ? _c(
                  "button",
                  {
                    staticClass: "btn-reset btn btn--primary",
                    on: { click: _vm.openFileModal },
                  },
                  [_vm._v("\n            New Contract\n        ")]
                )
              : _vm._e(),
          ]),
      _vm._v(" "),
      _c("modal", {
        ref: "editor",
        attrs: {
          width: "1300px",
          padding: "0",
          "confirm-close": _vm.confirmClose,
          "confirm-close-header": "Close Contract",
          "show-header": false,
          "close-floating": true,
        },
        on: { close: _vm.handleModalClose },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("ContractEditor", {
                  ref: "contractEditor",
                  attrs: { contract: _vm.selectedContract },
                  on: {
                    comment: _vm.submitComment,
                    toggleEditor: _vm.handleToggleEditor,
                    save: _vm.submitForm,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("contract-upload", {
        ref: "fileModal",
        attrs: { parent: _vm.parent },
      }),
      _vm._v(" "),
      _c("modal", {
        ref: "payment",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v("Payment Required")])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "Thank you for uploading your signed contract. In order to complete the transaction you now need to make the agreed payment to our account."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("Our bank details are:")]),
                _vm._v(" "),
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.settings["bank-details"].value),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }