<template>
    <div>
        <div
            v-if="hasMetrics"
            class="details"
        >
            <metricsPage
                :baseline="baseline"
                :creation="creation"
                :enhancement="enhancement"
                :show-baseline="viewMetric.baseline"
                :show-creation="viewMetric.creation"
                :show-enhancement="viewMetric.enhancement"
                :model="project"
                :desired-uplift-percent="project.onsite_desired_percentage"
                :include-creation-chart="[
                    'creation-totals',
                    'creation-habitat-types',
                ]"
            />
        </div>
        <Empty
            v-else
            type="metric"
        >
            <inertia-link
                v-if="hasGroup(['sequest', 'lpa', 'agent', 'landowner']) && hasPermission('project_save')"
                class="btn-reset btn btn--primary"
                :href="$route('project.metric', project.id)"
            >
                Create BNG Metric
            </inertia-link>
        </Empty>
    </div>
</template>

<script>
import Empty from 'Utilities/empty/Empty';
import MetricsPage from 'Components/Metrics/BngMetricSummary.vue';
import MetricMixin from 'Mixins/metric';

export default {
    name: 'ProjectMetric',
    components: {
        Empty,
        MetricsPage,
    },

    mixins: [
        MetricMixin,
    ],

    props: {
        baseline: {
            type: Array,
            default: () => ([]),
        },
        creation: {
            type: Array,
            default: () => ([]),
        },
        enhancement: {
            type: Array,
            default: () => ([]),
        },
        project: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            viewMetric: {
                baseline: true,
                creation: true,
                enhancement: true,
            },
        };
    },

    computed: {
        hasMetrics() {
            return this.hasBaselineMetric || this.hasCreationMetric;
        },
        hasBaselineMetric() {
            return this.baseline && this.baseline.length;
        },
        hasCreationMetric() {
            return this.creation && this.creation.length;
        },
    },
};

</script>
