<template>
    <div v-if="context">
        <FormulateInput
            key="name_1"
            type="text"
            name="name"
            label="Name"
            validation="required"
            validation-name="Name"
            :disabled="disabled"
        />
        <div class="form-flex--tab form-flex--vcenter">
            <FormulateInput
                key="offset_type_1"
                type="select-plugin"
                name="offset_type"
                label="Offset Type"
                validation="required"
                validation-name="Offset Type"
                :options="offsetOptions"
                placeholder="Select Offset Type"
                :disabled="disabled"
                :multiple="true"
                @input="handleOffsetChange"
            />
            <template v-if="isSuperUser">
                <FormulateInput
                    key="status_1"
                    type="select-plugin"
                    name="status"
                    label="Status"
                    validation="required"
                    validation-name="Status"
                    :options="statusOptions"
                    placeholder="Select Status"
                    :disabled="disabled"
                />
            </template>
        </div>
        <div
            class="form-flex--tab form-flex--vcenter"
        >
            <template v-if="value.offset_type.includes('bng')">
                <FormulateInput
                    key="planning_authority_id_1"
                    :type="planningAuthorities.length > 1 ? 'select-plugin' : 'fake'"
                    name="planning_authority_id"
                    label="Planning Authority"
                    validation="required"
                    validation-name="Planning Authority"
                    :options="planningAuthoritiesSelect"
                    placeholder="Select Planning Authority"
                    :disabled="disabled"
                    :parse-value="parseLpaValue"
                    @input="handlePlanningChange"
                />
            </template>
            <template v-if="value.offset_type.includes('nutrient_offset')">
                <FormulateInput
                    key="river_basin_district_id_1"
                    :type="riverBasinDistricts.length > 1 ? 'select-plugin' : 'fake'"
                    name="river_basin_district_id"
                    label="River Catchment Area"
                    validation="required"
                    validation-name="River Catchment Area"
                    :options="riverBasinDistrictSelect"
                    placeholder="Select River Catchment Area"
                    :disabled="disabled"
                    :parse-value="parseRiverValue"
                />
            </template>
            <template v-if="companies && companies.length > 1">
                <FormulateInput
                    v-if="hasGroup(['sequest','agent'])"
                    key="company_id_1"
                    type="select-plugin"
                    name="company_id"
                    label="Company"
                    validation="required"
                    placeholder="Select Company"
                    :options="companiesSelect"
                    :disabled="disabled"
                />
            </template>
        </div>
        <InputSuffix>
            <FormulateInput
                key="address_1"
                type="text"
                name="address"
                label="Address"
                :disabled="parseInt(value.address_autofill) ? true : false"
            />
            <template #suffix>
                <FormulateInput
                    key="address_1_autofill"
                    type="select"
                    name="address_autofill"
                    :options="autofillOptions"
                    @input="updateMap(value.map_location, parseInt($event))"
                />
            </template>
        </InputSuffix>
        <FormulateInput
            ref="map"
            key="map_1"
            type="map"
            name="map_location"
            validation="required|location"
            :validation-rules="{
                location: validateMap
            }"
            :validation-messages="{
                location: mapMessageTextError
            }"
            :errors="[$page.props.errors.address,$page.props.errors.location_data]"
            :disabled="disabled"
            :fit-bounds="editable ? 'polygons' : 'vectors'"
            :color="mapColors.project"
            :stroke-color="mapColors.project"
            :vectors="mapSchema"
            @input="updateMap($event, parseInt(value.address_autofill))"
        />
        <div class="form-flex--tab form-flex--vcenter">
            <FormulateInput
                name="auto_calculate"
                type="toggle-checkbox"
                label="Auto calculate Size"
                :wrapper-class="['tab-w-60']"
            />
            <InputSuffix
                :style="{
                    flex: '10 0 auto',
                }"
            >
                <FormulateInput
                    v-show="value.auto_calculate"
                    :type="value.auto_calculate ? 'fake' : 'hidden'"
                    name="size_map"
                    label="Size"
                    validation="required"
                    :disabled="disabled"
                    :parse-value="value.auto_calculate ? () => parseSizeValue(value.size_map) : null"
                />
                <FormulateInput
                    v-show="!value.auto_calculate"
                    :type="value.auto_calculate ? 'hidden' : 'number'"
                    name="size_user"
                    label="Size"
                    :disabled="disabled"
                />
                <template #suffix>
                    <FormulateInput
                        type="select"
                        name="size_units"
                        validation="required"
                        :options="sizeUnits"
                        :disabled="disabled"
                    />
                </template>
            </InputSuffix>
        </div>
        <FormulateInput
            v-if="invalidCountryCode"
            key="country_code"
            type="select-plugin"
            name="country_code"
            validation="required"
            label="We are unable to find a country from the map. Please choose a country"
            validation-name="Country"
            reduce-key="code"
            label-key="name"
            :options="countries"
            placeholder="Country"
        />
    </div>
</template>

<script>
import { mapColors } from 'Lib/defaults';
import mapMixin from 'Mixins/map.js';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';

export default {
    name: 'ProjectFormPage1',
    components: {
        InputSuffix,
    },
    mixins: [
        mapMixin,
    ],
    props: {
        context: {
            type: Object,
            default: null,
        },
        companies: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        planningAuthorities: {
            type: Array,
            default: () => [],
        },
        riverBasinDistricts: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Object,
            default: () => {},
        },
        developmentTypeOptions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            invalidCountryCode: false,
            currentLpa: null,
            mapMessageTextError: 'Please use the map above to plot your development.',
            zoom: 6,
            isSuperUser: this.$page.props.loggedUser.isSuperUser,
            mapColors: mapColors,
        };
    },
    computed: {
        sizeUnits() {
            return [{
                value: 'hectares',
                label: 'Hectares',
            },{
                value: 'm2',
                label: 'M2',
            },{
                value: 'km2',
                label: 'Km2',
            },{
                value: 'acres',
                label: 'Acres',
            }];
        },

        offsetOptions() {
            const options = {
                'bng': 'BNG',
            };

            if (this.riverBasinDistricts.length) {
                options['nutrient_offset'] = 'Nutrient Offset';
            }

            return options;
        },

        statusOptions() {
            return [{
                value: 'created',
                label: 'Created',
            },{
                value: 'applied',
                label: 'Applied',
            },{
                value: 'land matched',
                label: 'Land Matched',
            },{
                value: 'contract issued',
                label: 'Contract Issued',
            },{
                value: 'land implemented',
                label: 'Land Implemented',
            }];
        },

        companiesSelect() {
            return this.companies.map(item => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
        },

        planningAuthoritiesSelect() {
            const listReturn = [];

            this.planningAuthorities.forEach((item) => {
                if (!this.hasGroup('lpa') || (this.$page.props.loggedUser.LPAs.includes(item.id))) {
                    listReturn.push({
                        value: item.id,
                        label: item.name,
                    });
                }
            });

            return listReturn;
        },

        riverBasinDistrictSelect() {
            const listReturn = [];
            let riverBasinDistricts = this.riverBasinDistricts;

            if (this.value.offset_type.includes('bng') && this.value.planning_authority_id) {
                const riverBasinDistrictsTmp = riverBasinDistricts.filter(river => {
                    const lpaIDs = river.planning_authority.map(lpa => lpa.id);

                    return lpaIDs.includes(parseInt(this.value.planning_authority_id));
                });

                if (riverBasinDistrictsTmp.length) {
                    riverBasinDistricts = riverBasinDistrictsTmp;
                }
            }

            riverBasinDistricts.forEach((item) => {
                listReturn.push({
                    value: parseInt(item.id),
                    label: item.name,
                });
            });

            return listReturn;
        },

        mapSchema() {
            const schema = [];

            if (this.value.planning_authority_id && this.planningAuthorities) {
                schema.push(this.parseVectorByID('planningAuthorities', this.value.planning_authority_id, {
                    name: 'lpa',
                    color: 'lpa',
                    strokeColor: 'lpa',
                }));
            }

            if (this.value.river_basin_district_id && this.riverBasinDistricts) {
                schema.push(this.parseVectorByID('riverBasinDistricts', this.value.river_basin_district_id, {
                    name: 'surface_water_operational_catchments',
                    color: 'river_basin_districts',
                    strokeColor: 'river_basin_districts',
                }));
            }

            return schema;
        },

        autofillOptions() {
            return [{
                label: 'Autofill Address',
                value: 1,
            }, {
                label: 'User Specific Address',
                value: 0,
            }];
        },
    },
    watch: {
        context(newValue, oldValue) {
            if (newValue !== null && oldValue === null) {
                if (this.value.offset_type.includes('bng')) {
                    this.context.setFieldValue('planning_authority_id', this.lpaLocation());
                }
            }
        },
    },
    methods: {
        mapChange(value) {
            let countryInvalid = true;

            if (this.value && value) {
                const {
                    inputCountry,
                } = value;


                if (inputCountry) {
                    const country = this.countries.filter(country => country.code === inputCountry);

                    if (country.length) {
                        countryInvalid = false;
                    }
                }
            }

            this.invalidCountryCode = countryInvalid;
        },

        validateMap(value) {
            if (value.value && ((value.value.paths && value.value.paths.length) || (value.value.center && value.value.radius))) {
                return true;
            }

            return false;
        },

        lpaLocation() {
            let value = !this.disabled && this.value.planning_authority_id ? this.value.planning_authority_id.toString() : null;

            if (this.hasGroup('lpa') && this.$page.props.loggedUser.LPAs.length === 1) {
                value = this.planningAuthorities.filter((item) => item.id === this.$page.props.loggedUser.LPAs[0])[0].id.toString();
            } else if (this.planningAuthorities.length === 1) {
                value = this.planningAuthorities[0].id.toString();
            }

            return value;
        },

        handleOffsetChange(event) {
            let planningAuthorityIdValue = '',
                riverBasinDistrictIdValue = '';

            if (event.includes('bng') && this.planningAuthorities.length === 1) {
                planningAuthorityIdValue = String(this.planningAuthorities[0].id);
            }

            if (event.includes('nutrient_offset') && this.riverBasinDistricts.length === 1) {
                riverBasinDistrictIdValue = String(this.riverBasinDistricts[0].id);
            }

            this.context.setFieldValue('planning_authority_id', planningAuthorityIdValue);
            this.context.setFieldValue('river_basin_district_id', riverBasinDistrictIdValue);
        },

        handlePlanningChange(event) {
            if (this.value.offset_type.includes('bng') && event && this.value.planning_authority_id && event !== this.value.planning_authority_id) {
                this.context.setFieldValue('river_basin_district_id', null);
            }
        },

        async updateMap(event, autofill) {
            if (((event.paths && event.paths.length) || (event.center && event.radius)) && autofill) {
                let countryInvalid = true;
                const country = this.countries.filter(country => country.code === event.country);

                if (country.length) {
                    countryInvalid = false;
                }

                this.invalidCountryCode = countryInvalid;
                this.context.setFieldValue('address', event.location.address);

                if (event.size) {
                    this.context.setFieldValue('size_map', event.size);
                }
            }

            if (event.size) {
                this.context.setFieldValue('size', event.size);
            }

            this.$emit('input', this.value);
        },
        parseLpaValue(lpaID) {

            const lpa = this.planningAuthorities.filter(lpa => lpa.id === parseInt(lpaID));

            if (lpa.length) {
                return lpa[0].name;
            }

            return 'Unknown Local Planning Authority';
        },

        parseRiverValue(riverID) {
            const river = this.riverBasinDistricts.filter(river => river.id === parseInt(riverID));

            if (river.length) {
                return river[0].name;
            }

            return 'Unknown River Basin District';
        },

        parseSizeValue(size) {
            switch (this.value.size_units) {
                case 'm2':
                    size = this.hectaresToMetersSq(size);
                    break;
                case 'km2':
                    size = this.hectaresToKmSq(size);
                    break;
                case 'acres':
                    size = this.hectaresToAcres(size);
                    break;
                default:
                    break;
            }

            return this.floatOutput(size);
        },

    },
};
</script>
