<!-- eslint-disable vue/no-v-html -->
<template>
    <div>
        <div
            v-if="pill"
            :class="`status-pill type-${pillClass}`"
            v-html="sanitisedPill"
        />
        <FormulateForm
            ref="form"
            key="form"
            v-model="form"
            name="calculator"
            class="flex-col margin-0"
            @submit="submitForm"
        >
            <p class="align-right">
                <template v-if="showUnits">
                    Total Units: {{ numberCommas(getUnits('all')) }}
                </template>
                <template v-if="maxArea">
                    <br>Area used: {{ numberCommas(getUsedSize) }} Hectares
                    <br>Area remaining: {{ numberCommas(getRemainingSize) }} Hectares
                </template>
            </p>
            <FormulateInput
                key="habitats"
                name="habitats"
                add-label="+ Add Additional Habitat"
                type="group"
                :repeatable="!disabled && repeatable"
                element-class="formulate-input-element formulate-input-element--collapsable-group"
                outer-class="formulate-input collapsable-group"
                @repeatableAdded="showIndex = $event.length -1"
                @repeatableRemoved="(event) => removeRow(event)"
            >
                <template #repeatable="context">
                    <collapsable-group-item
                        :key="`group-collapse-${context.index}`"
                        :context="context"
                        :title="habitatGroupLabel(context.index)"
                        :show-index="showIndex"
                        @open="showIndex = $event"
                    >
                        <FormulateInput
                            :key="`category-${context.index}`"
                            type="select-button"
                            name="category"
                            :options="categoryOptions"
                            element-class="formulate-input-element formulate-input-element--select-button formulate-input-element-habitat-btn"
                            :disabled="disabled || formLoading[context.index]"
                            @click="(event) => disabled ? {} : setCategory(context.index, event.target.value)"
                        />
                        <processing v-if="formLoading[context.index] || formLoading['all']">
                            Loading
                        </processing>
                        <template v-else-if="context.model[context.index].category">
                            <FormulateInput
                                :key="`id-${context.index}`"
                                name="id"
                                type="hidden"
                            />
                            <FormulateInput
                                v-for="field in getFormFieldFiltered(context.model[context.index].category)"
                                :key="`field-${context.index}-${context.model[context.index].category}-${field.id}`"
                                ref="input"
                                v-model="form.habitats[context.index][fieldNameID(field)]"
                                :type="fieldType(context.index, field)"
                                :name="fieldNameID(field)"
                                :label="fieldLabelName(context.index, field)"
                                :disabled="disabled || field.hasFormula || isEnabled[fieldName(field)] === false"
                                validation="required"
                                :validation-name="field.header"
                                :placeholder="`Select ${field.header}`"
                                :options="field.options ?? []"
                                @change="() => formChange(context.index)"
                            />
                            <FormulateInput
                                v-if="photo && photoUploadUrl"
                                :key="`photo-${context.index}`"
                                v-model="form.habitats[context.index].photos"
                                name="photos"
                                label="Photo"
                                type="image"
                                :required="true"
                                validation="mime:image/jpeg,image/png,image/gif"
                                :upload-url="photoUploadUrl"
                                upload-behavior="delayed"
                                @input="changePhoto"
                                @file-removed="changePhoto"
                            />
                        </template>
                        <template v-else>
                            No Category Set
                        </template>
                    </collapsable-group-item>
                </template>
            </FormulateInput>
        </FormulateForm>
    </div>
</template>

<script>
import CollapsableGroupItem from 'Utilities/vue-formulate/CollapsableGroupItem';
import Processing from 'Utilities/processing/processing';
import MetricApi from 'Lib/metric/metricApi.js';

export default {
    name: 'BNGCalculator',
    components: {
        CollapsableGroupItem,
        Processing,
    },
    props: {
        version: {
            // Version to get from API
            type: Number,
            default: 4.01,
        },
        runCalculations: {
            // If to run the metric unit calculations or not
            type: Boolean,
            default: true,
        },
        runFormula: {
            // If to run the formula calculations or not
            type: Boolean,
            default: true,
        },
        disabled: {
            // Entire form disable
            type: Boolean,
            default: false,
        },
        maxArea: {
            // Defined an area that user cannot go over
            type: Number,
            default: null,
        },
        showUnits: {
            // Check if to show the calculated total units or not
            type: Boolean,
            default: true,
        },
        pill: {
            // Pill content
            type: String,
            default: '',
        },
        pillClass: {
            // Pill text class
            type: String,
            default: 'red',
        },
        fieldLabelTemplate: {
            // How to output the label
            // See fieldLabelName for more information
            type: String,
            default: '{header}',
        },
        fieldLabelSeperator: {
            // How to output the label
            // See fieldLabelName for more information
            type: String,
            default: ': ',
        },
        repeatable: {
            // Allows repeatable form fields
            type: Boolean,
            default: true,
        },
        formType: {
            // What fields to include
            // full - Shows all filds from the API
            // partial - Shows a select number of curated fields
            type: String,
            default: 'partial',
            validator: function(value) {
                return ['full', 'partial'].indexOf(value) !== -1;
            },
        },
        showFields: {
            // Override whats shows on formType
            type: Object,
            default: null,
        },
        fieldsEnabled: {
            // Toggle on if the field is enabled or disabled.
            // Field still shows but are greyed out
            type: Object,
            default: () => ({}),
        },
        location: {
            // Type of form offset
            // This is different pages inside the spreadsheet
            type: String,
            default: 'on-site',
            validator: function(value) {
                return ['off-site', 'on-site'].indexOf(value) !== -1;
            },
        },
        type: {
            // Type of form
            // This is different pages inside the spreadsheet
            type: String,
            default: 'baseline',
            validator: function(value) {
                return ['baseline', 'creation', 'enhancement'].indexOf(value) !== -1;
            },
        },
        photo: {
            // If to include the photo or not
            type: Boolean,
            default: false,
        },
        photoUploadUrl: {
            // Url for photo to upload to
            type: String,
            default: null,
        },
        value: {
            // v-model value
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            formLoading: {},
            formFetching: {},
            form: {
                habitats: [],
            },
            showIndex: 0,
            formFields: {},
            formFieldsLoading: {},
            storedCache: {},
            MetricApi: new MetricApi(this.$metricApi),
            returnFieldTypes: {},
            baselines: null,
            categoryOptions: [{
                label: 'Habitat',
                value: 'habitat',
            }, {
                label: 'Hedgerow',
                value: 'hedgerow',
            }, {
                label: 'Watercourse',
                value: 'watercourse',
            }],
        };
    },
    computed: {
        /**
        * Get pill content and replace unwanted content
        * @return {Void}
        */
        sanitisedPill() {
            return this.pill.replace(/<script[\d\D]*?>[\d\D]*?<\/script>/gi, '');
        },

        /**
        * Returns enabled variable
        * @return {Object}
        */
        isEnabled() {
            return {
                ...this.fieldsEnabled,
            };
        },


        /**
        * Calculates used size based on hectares/KM
        * @return {Void}
        */
        getUsedSize() {
            const sizes = (this.form.habitats ?? []).map(habitat => {
                let sizeKey = '';
                const category = habitat.category ?? '';

                if (category) {
                    switch (category.toLowerCase()) {
                        case 'habitat':
                            sizeKey = 'area_(hectares)';
                            break;

                        case 'hedgerow':
                        case 'watercourse':
                            sizeKey = 'length_(km)';
                            break;

                        default:
                            break;
                    }

                    const fields = this.getFormField(category);

                    if (fields) {
                        const field = fields.filter(field => field.header.replaceAll(' ', '_').toLowerCase() === sizeKey);

                        if (field.length && habitat[this.fieldNameID(field[0])]) {
                            let size = parseFloat(habitat[this.fieldNameID(field[0])]);

                            if (sizeKey === 'length_(km)') {
                                size = this.kmSqToHectares(size);
                            }

                            return size;
                        }
                    }
                }

                return 0;

            });

            return sizes.reduce((val1, val2) => val1 + val2, 0);
        },


        /**
        * Calculates remaining size
        * @return {Float}
        */
        getRemainingSize() {
            const used = this.getUsedSize;

            return this.maxArea - used;
        },
    },
    watch: {
        /**
        * Show index change when accordion changes
        * @return {Void}
        */
        showIndex() {
            this.$emit('switch', this.showIndex, this.form.habitats[this.showIndex]);
        },

        value() {
            this.$refs.form.setValues({habitats: this.value});
        },
    },
    created() {
        this.baselines = this.value.filter(habitat => habitat.baseline).map(habitat => {
            let sheet = '';

            switch (habitat.category.toLowerCase()) {
                case 'habitat':
                    sheet = 'A-1';
                    break;

                case 'hedgerow':
                    sheet = 'B-1';
                    break;

                case 'watercourse':
                    sheet = 'C-1';
                    break;

                default:
                    break;
            }

            return {...habitat.baseline, sheet};
        });
        this.form = {
            habitats: this.value.map((habitat) => {
                habitat.photos = [{url: habitat.photo}];

                return habitat;
            }),
        };
        this.init();
    },
    methods: {
        /**
        * Init function
        * @return {Void}
        */
        async init() {
            this.setLoading('all', true);

            const categories = this.value.length ? this.value.map(value => value.category).filter(this.uniqueArray) : ['habitat'];


            for (let index = 0; index < categories.length; index++) {
                await this.setFormField(categories[index]);
            }

            this.setLoading('all', false);

            const values = this.form.habitats;

            if (!values.length) {
                this.getFormula(0);
            }

            values.forEach((habitat, index) => {
                this.setSelectedIndex(habitat, index, true);
                this.getFormula(index);
            });
        },

        /**
        * Gets field name with underscores from field
        * @param {Object} field - Field object
        * @return {String}
        */
        fieldName(field) {
            return field.header.replaceAll(' ', '_').toLowerCase();
        },

        /**
        * Gets field section with underscores from field
        * @param {Object} field - Field object
        * @return {String}
        */
        fieldSection(field) {
            return field.section.replaceAll(' ', '_').toLowerCase();
        },

        /**
        * Gets field name with underscores and combined with the ID from field
        * @param {Object} field - Field object
        * @return {String}
        */
        fieldNameID(field) {
            return `${field.header}:${field.column}`;
        },

        /**
        * Works out field type
        * @param {Object} field - Field object
        * @return {String}
        */
        fieldType(index, field) {
            if (this.disabled) {
                return 'text';
            }

            const habitat = this.getSelectedIndex(index);
            const allowedFields = this.getShowFieldsData(habitat.category);

            if (allowedFields) {
                const name = field.header.toLowerCase();
                const column = field.column;
                const allowedField = allowedFields.filter(allowed => allowed.name.toLowerCase() === name && (!column || column === allowed.column));

                if (
                    allowedField.length &&
                    allowedField[0].hidden === true
                ) {
                    return 'hidden';
                }
            }

            if (field.hasDropdown) {
                return 'select-plugin';
            }

            return 'text';
        },

        /**
         * Gets field name without ID attatched
         * @param {String} name - Name of field
         * @return {String}
         */
        simpleName(name) {
            return name.replace(/([a-z_)]+)(_[0-9]+)/gi, '$1');
        },

        /**
        * Gets field label
        * The functionality will auto add the "seperator" between templates tags
        * Options in string are
        * {header} - Header of field
        * {label} - Same as header
        * {section} - Field section
        * @param {Object} field - Field object
        * @return {String}
        */
        fieldLabelName(index, field, seperator = this.fieldLabelSeperator) {
            if (this.fieldType(index, field) === 'hidden') {
                return '';
            }

            const values = {
                header: field.header ?? '',
                label: field.header ?? '',
                section: field.section ?? '',
            };

            const template = this.fieldLabelTemplate
                .replaceAll('{header}', `{${values.header}}`)
                .replaceAll('{label}', `{${values.header}}`)
                .replaceAll('{section}', `{${values.section}}`)
                .replaceAll('}{', seperator)
                .replaceAll('{', '')
                .replaceAll('}', '');

            return template;
        },

        /**
        * Gets the label for accordion title
        * {index} - Index of habitat
        * @param {Object} field - Field object
        * @return {String}
        */
        habitatGroupLabel(index) {
            const selected = this.form.habitats[index];

            if (selected) {
                let title = '',
                    fieldValues = null;

                if (selected.category) {
                    const option = this.categoryOptions.filter((option) => option.value === selected.category);

                    if (option.length) {
                        title += option[0].label;
                    }
                }

                switch (this.type) {
                    case 'baseline':
                        fieldValues = this.getShowFieldsDataBaseline();

                        break;
                    case 'creation':
                        fieldValues = this.getShowFieldsDataCreation();

                        break;
                    case 'enhancement':
                        fieldValues = this.getShowFieldsDataEnhancement();

                        break;

                    default:
                        break;
                }

                if (fieldValues) {
                    Object.keys(selected).forEach((key) => {
                        const keyPart = key.split(':');
                        const fields = fieldValues[selected.category ?? 'habitat'].map(field => field.column);
                        const nameLower = keyPart[0].toLowerCase();

                        switch (selected.category.toLowerCase()) {
                            case 'habitat':
                                if (nameLower.includes('broad habitat') && fields.includes(keyPart[1])) {
                                    title += ` - ${selected[key]}`;
                                }

                                if ((nameLower.includes('habitat type') || nameLower.includes('proposed habitat')) && fields.includes(keyPart[1])) {
                                    title += ` - ${selected[key]}`;
                                }

                                break;

                            case 'hedgerow':
                                if ((nameLower.includes('hedgerow type') || nameLower.includes('habitat type')) && fields.includes(keyPart[1])) {
                                    title += ` - ${selected[key]}`;
                                }

                                break;

                            case 'watercourse':
                                if ((nameLower.includes('watercourse type') || nameLower.includes('river type')) && fields.includes(keyPart[1])) {
                                    title += ` - ${selected[key]}`;
                                }

                                break;

                            default:
                                title += 'Habitat';
                                break;
                        }
                    });
                }

                return title;
            }

            return 'Habitat';
        },

        /**
        * On form change event
        * @param {Integer} index - Index to set category on
        * @param {String} category - Category to set
        * @return {Void}
        */
        formChange(index) {
            const selected = this.getSelectedIndex(index);

            if (!selected) return;

            this.setLoading(this.showIndex, true);

            this.getFormula(index);

            this.getReturnedValues(this.form.habitats);

            this.setLoading(this.showIndex, false);
        },

        /**
        * On form change event
        * @param {Integer} index - Index to set category on
        * @param {String} category - Category to set
        * @return {Void}
        */
        removeRow(event) {
            this.showIndex = event.length -1;

            this.$refs.form.setValues(this.form);

            this.getReturnedValues(this.form.habitats);

            const removedItems = JSON.parse(JSON.stringify(this.form.habitats));

            setTimeout(() => {
                this.$refs.form.setValues({
                    habitats: removedItems,
                });
            }, 100);
        },

        /**
        * On photo change
        * @return {Void}
        */
        changePhoto() {
            this.form.habitats[this.showIndex].photo_upload = true;
            this.formChange();
        },

        /**
        * Get form fields from saved variable or from server
        * This function auto sets up cache for that category
        * @param {String} category
        * @return {Array} fields
        */
        getFormField(category) {
            return this.formFields[category];
        },

        /**
        * Get form fields from server
        * This function auto sets up cache for that category
        * @param {String} category
        * @return {Array} fields
        */
        async getFormFieldApi(category) {
            if (!this.formFieldsLoading[category] && !this.formFields[category]) {
                this.formFieldsLoading[category] = true;
                this.storedCache[category] = {};
                const formName = this.getFormEndpoint(category);

                if (formName) {
                    this.setFetching(formName, true);
                    const response = await this.MetricApi.getForm(this.version, formName);

                    this.formFields[category] = response.data.columns;
                    this.formFields = {...this.formFields};
                    this.setFetching(formName, false);

                    return response.data.columns;
                }
            }

            return this.formFields[category];
        },

        /**
        * Set form fields from server API
        * Sets Cache Variable
        * Fields Variable
        * @param {Integer} index
        * @param {String} category
        * @return {Promise(Null|Array)}
        */
        async setFormField(category) {
            return new Promise((resolve) => {
                this.getFormFieldApi(category).then(fields => {
                    if (!fields) {
                        resolve(null);

                        return;
                    }

                    fields.forEach((formField) => {
                        if (formField.cached) {
                            this.storedCache[category][this.fieldNameID(formField)] = formField.cached;
                        }
                    });
                    resolve(fields);
                });
            });
        },

        /**
        * Get filtered form fields from saved variable or from server
        * This is based on if the bng form is going to be full or partial
        * @param {String} category
        * @return {Array} fields
        */
        getFormFieldFiltered(category) {
            const fieldsData = this.getShowFieldsData(category);
            const formFields = this.formFields[category];

            if (!formFields || !fieldsData.length) {
                return [];
            }

            const returnT = formFields.filter(field => {
                const column = field.column;

                if (
                    fieldsData.filter((include) => !column || column === include.column).length
                ) {
                    return true;
                }

                return false;
            });

            return returnT;
        },

        /**
        * Get formula outcome from index
        * @param {Integer} index
        * @return {Promise} fields
        */
        async getFormula(index) {
            return new Promise((resolve) => {
                if (!this.getSelectedIndex(index) || !this.runFormula) {
                    resolve();

                    return;
                }

                resolve();

                const selected = {...this.getSelectedIndex(index)};
                const category = selected.category;
                const fieldsFiltered = this.getFormFieldFiltered(category);
                const columns = fieldsFiltered.map(field => field.column);
                const formFields = this.getFormField(category);

                fieldsFiltered.forEach(field => {
                    let fieldIndex = null;

                    formFields.forEach((fieldFromAll, index) => {
                        if (fieldFromAll.id === field.id) {
                            fieldIndex = index;
                        }
                    });

                    if (fieldIndex) {
                        const {cached} = this.getCahcedData(field, index);

                        if (cached && field.hasDropdown) {
                            this.formFields[category][fieldIndex].options = cached;
                        }
                    }
                });

                this.formFields = {...this.formFields};

                delete selected.photos;

                const data = {...selected};

                if (this.baselines[index]) {
                    const newHabitat = {};

                    Object.keys(this.baselines[index]).filter(key => key !== 'category' && key !== 'photo').forEach((key) => {
                        newHabitat[this.baselines[index].category ?? 'habitat'] = this.baselines[index][key];
                    });
                    const baseline = {
                        ref: 1,
                        ...newHabitat,
                    };

                    data[baseline.sheet] = [baseline];
                }

                this.setFetching(`${this.getFormEndpoint(category)}:${index}`, true);
                this.MetricApi.cancelCalculateFormula(this.version, this.getFormEndpoint(category), index);
                this.MetricApi.calculateFormula(this.version, this.getFormEndpoint(category), data, index).then(response => {
                    const formDataField = {};

                    Object.keys(response.data).forEach(id => {
                        let formField = null,
                            fieldIndex = null;

                        formFields.forEach((field, index) => {
                            if (parseInt(id) === field.id) {
                                formField = field;
                                fieldIndex = index;
                            }
                        });

                        if (formField && fieldIndex !== null) {
                            if (formField.field === 'select' && this.formFields[category][fieldIndex].hasDropdown && response.data[id].options && this.formFields[category][fieldIndex]) {
                                this.formFields[category][fieldIndex].options = response.data[id].options;
                            }


                            if (response.data[id].output !== undefined && columns.includes(formField.column)) {

                                if (this.runCalculations) {
                                    formDataField[this.fieldNameID(formField)] = response.data[id].output;
                                }
                            }

                            if (formField.dropdownFormula) {
                                formField.cache = response.data[id].cache;
                            }
                        }
                    });

                    this.formFields = {...this.formFields};
                    this.setSelectedIndex(formDataField, index);
                    this.setFetching(`${this.getFormEndpoint(category)}:${index}`, false);

                    resolve();
                });
            });
        },

        /**
        * Gets a list of cached data from field
        * @param {Object} field - field data item from getFormField or getFormFieldFiltered
        * @param {Integer} index
        * @return {Void}
        */
        getCahcedData(field, index) {
            const returnData = {
                cached: null,
                missingData: false,
            };

            if (field.cached) {
                const formValues = this.getSelectedIndex(index);
                const formData = this.getFormField(formValues.category);

                let cacheFormula = field.cacheFormula;

                formData.forEach((field) => {
                    const name = this.fieldNameID(field);

                    const pattern = `\\\${${name}}`;
                    const regex = new RegExp(pattern, 'g');


                    if (regex.test(cacheFormula)) {
                        if (formValues[name]) {
                            cacheFormula = cacheFormula.replace(regex, `\${${formValues[name]}}`);
                        } else {
                            returnData.missingData = true;
                        }
                    }
                });

                const cacheData = field.cached.filter((cache) => cache.formula === cacheFormula);

                if (cacheData.length) {
                    returnData.cached = cacheData[0].result;
                }
            }

            return returnData;
        },

        /**
        * Sets a group item to loading
        * @param {Integer|String} index
        * @param {Boolean} value
        * @return {Void}
        */
        setLoading(index, value) {
            const loading = {...this.formLoading};

            loading[index] = value;

            this.formLoading = {...loading};

            if (Object.keys(this.formLoading).filter(key => this.formLoading[key]).length === 0) {
                this.$emit('loading');

                return;
            }

            this.$emit('loaded');
        },

        /**
        * Sets a group item to fetching data
        * @param {Integer|String} index
        * @param {Boolean} value
        * @return {Void}
        */
        setFetching(request, value) {
            const loading = {...this.formFetching};

            loading[request] = value;

            this.formFetching = {...loading};

            if (Object.keys(this.formFetching).filter(key => this.formFetching[key]).length === 0) {
                this.$emit('fetched');

                return;
            }

            this.$emit('fetching');
        },

        /**
        * Get selected form habitat from form value
        * @param {Integer} index
        * @return {Array|Null} fields
        */
        getSelectedIndex(index) {
            if (this.form.habitats && this.form.habitats[index]) {
                return this.form.habitats[index];
            }

            return null;
        },

        /**
        * Set category value for index
        * This will auto replace the index habitat
        * @param {Integer} index - Index to set category on
        * @param {String} category - Category to set
        * @return {Void}
        */
        async setCategory(index, category) {
            this.setLoading(this.showIndex, true);

            this.setSelectedIndex({
                category: category,
            }, index);

            await this.setFormField(category);
            await this.getFormula(index);

            this.getReturnedValues(this.form.habitats);

            this.setLoading(this.showIndex, false);

            this.$emit('category');
        },

        /**
        * Set form habitat value
        * @param {Object} saveValue - What is required to save
        * @param {Integer} index - Index of show item
        * @param {Boolean} replace - If we require the entire object to be replaced by the new value.
        * @return {Void}
        */
        setSelectedIndex(saveValue, index = this.showIndex, replace = false) {
            if (!this.$refs.form || !this.getSelectedIndex(index)) return;

            const values = [...this.form.habitats];
            const photos = {};

            if (this.form.habitats[index].photos) {
                photos.photos = this.form.habitats[index].photos;
            }

            values[index] = {...this.form.habitats[index], ...saveValue, ...photos};

            if (replace) {
                values[index] = {...saveValue, ...photos};
            }

            const setValue = {
                ...this.form,
                habitats: values,
            };

            this.$refs.form.setValues(setValue);

            this.getReturnedValues(this.form.habitats);
        },

        /**
        * Gets endpoint name for the form based on category
        * @param {String} category
        * @return {String} endpoint
        */
        getFormEndpoint(category) {
            const formNames = {
                'on-site:habitat:baseline': 'A-1',
                'on-site:habitat:creation': 'A-2',
                'on-site:habitat:enhancement': 'A-3',
                'off-site:habitat:baseline': 'D-1',
                'off-site:habitat:creation': 'D-2',
                'off-site:habitat:enhancment': 'D-3',
                'on-site:hedgerow:baseline': 'B-1',
                'on-site:hedgerow:creation': 'B-2',
                'on-site:hedgerow:enhancement': 'B-3',
                'off-site:hedgerow:baseline': 'E-1',
                'off-site:hedgerow:creation': 'E-2',
                'off-site:hedgerow:enhancement': 'E-3',
                'on-site:watercourse:baseline': 'C-1',
                'on-site:watercourse:creation': 'C-2',
                'on-site:watercourse:enhancement': 'C-3',
                'off-site:watercourse:baseline': 'F-1',
                'off-site:watercourse:creation': 'F-2',
                'off-site:watercourse:enhancement': 'F-3',
            };

            const key = (`${this.location}:${category}:${this.type}`).toLowerCase();

            return formNames[key];
        },

        /**
        * Get filtered form information
        * This may get from a pre-defined layout or use the showFields prop to get the user set list
        * @param {String} category
        * @return {Array} fields
        */
        getShowFieldsData(category) {
            const formFields = this.getFormField(category) ?? [];
            const fields = Object.keys(formFields).map(key => ({
                name: `${formFields[key].header}:${formFields[key].column}`,
                editable: false,
                hidden: true,
                type: formFields[key].type,
                column: formFields[key].column,
            }));
            let allowedFields = [];

            if (this.showFields) {
                allowedFields = this.showFields[category.toLowerCase()] ?? [];
            } else if (category) {
                if (this.formType === 'partial') {
                    if (this.type === 'baseline') {
                        allowedFields = this.getShowFieldsDataBaseline()[category.toLowerCase()] ?? [];
                    } else if (this.type === 'creation') {
                        allowedFields = this.getShowFieldsDataCreation()[category.toLowerCase()] ?? [];
                    } else if (this.type === 'enhancement') {
                        allowedFields = this.getShowFieldsDataEnhancement()[category.toLowerCase()] ?? [];
                    }
                }
            }

            allowedFields.forEach(allowed => {
                fields.forEach((field, index) => {
                    if (allowed.column === field.column) {
                        fields[index] = {...field, ...allowed};
                    }
                });
            });

            return fields.filter(field => field.hidden === false);
        },

        getShowFieldsDataBaseline() {
            const fields = {
                habitat: [{
                    editable: true,
                    hidden: false,
                    column: 'E', // broad habitat
                }, {
                    editable: true,
                    hidden: false,
                    column: 'F', // habitat type
                }, {
                    editable: true,
                    hidden: false,
                    column: 'K', // condition
                }, {
                    editable: true,
                    hidden: false,
                    column: 'H', // area (hectares)
                }, {
                    editable: true,
                    hidden: false,
                    column: 'Q', // total habitat units
                }],
                hedgerow: [{
                    editable: true,
                    hidden: false,
                    column: 'D', // hedgerow type
                }, {
                    editable: true,
                    hidden: false,
                    column: 'E', // length (km)
                }, {
                    editable: true,
                    hidden: false,
                    column: 'H', // condition
                }, {
                    editable: false,
                    hidden: false,
                    column: 'N', // total hedgerow units
                }],
                watercourse: [{
                    editable: true,
                    hidden: false,
                    column: 'D', // watercourse type
                }, {
                    editable: true,
                    hidden: false,
                    column: 'E', // length (km)
                }, {
                    editable: true,
                    hidden: false,
                    column: 'H', // condition
                }, {
                    editable: false,
                    hidden: false,
                    column: 'R', // total watercourse units
                }],
            };

            return fields;
        },
        getShowFieldsDataCreation() {
            const fields = {
                habitat: [{
                    editable: true,
                    hidden: false,
                    column: 'D', // 'broad habitat'
                }, {
                    editable: true,
                    hidden: false,
                    column: 'E', // 'proposed habitat'
                }, {
                    editable: true,
                    hidden: false,
                    column: 'G', // 'area (hectares)'
                }, {
                    editable: true,
                    hidden: false,
                    column: 'J', // condition
                }, {
                    editable: true,
                    hidden: false,
                    column: 'Y', // total habitat units
                }],
                hedgerow: [{
                    editable: true,
                    hidden: false,
                    column: 'D', // 'habitat type'
                }, {
                    editable: true,
                    hidden: false,
                    column: 'E', // 'length (km)'
                }, {
                    editable: true,
                    hidden: false,
                    column: 'H', // condition
                }, {
                    editable: false,
                    hidden: false,
                    column: 'W', // total hedgerow units
                }],
                watercourse: [{
                    editable: true,
                    hidden: false,
                    column: 'C', // 'watercourse type'
                }, {
                    editable: true,
                    hidden: false,
                    column: 'D', // 'length (km)'
                }, {
                    editable: true,
                    hidden: false,
                    column: 'G', // condition
                }, {
                    editable: false,
                    hidden: false,
                    column: 'Z', // total watercourse units
                }],
            };

            return fields;
        },
        getShowFieldsDataEnhancement() {
            const fields = {
                'habitat': [{
                    name: 'proposed broad habitat',
                    editable: true,
                    hidden: false,
                    column: 'Q',
                }, {
                    name: 'proposed habitat',
                    editable: true,
                    hidden: false,
                    column: 'R',
                }, {
                    name: 'area (hectares)',
                    editable: true,
                    hidden: false,
                    column: 'V',
                }],
                'hedgerow': [{
                    name: 'proposed (pre-populated but can be overridden)',
                    editable: true,
                    hidden: false,
                    column: 'M',
                }, {
                    name: 'length (km)',
                    editable: false,
                    hidden: false,
                    column: 'P',
                }],
                'watercourse': [{
                    name: 'proposed watercourse type(pre-populated can be overridden)',
                    editable: true,
                    hidden: false,
                    column: 'N',
                }, {
                    name: 'length (km)',
                    editable: false,
                    hidden: false,
                    column: 'Q',
                }],
            };

            return fields;
        },

        /**
        * Get the total units
        * @param {String} data - Gets category or "all" units from the list
        * @param {Integer} data - Targets one index and gets units from that
        * @return {Void}
        */
        getUnits(index) {
            if (!this.form.habitats) {
                return 0;
            }

            let valueList = this.form.habitats,
                units = 0;

            if (typeof index === 'string') {
                valueList = valueList.map((habitat, idx) => ({...habitat, ...{index: idx}}));

                if (index !== 'all') {
                    valueList = valueList.filter(habitat => habitat.category === index);
                }

                valueList = valueList.map((habitat) => this.getUnits(habitat.index));

                if (valueList.length) {
                    return valueList.reduce((accumulator, currentValue) => accumulator + currentValue);
                }

                return 0;
            }

            const habitat = valueList[index];
            const category = habitat.category ?? '';

            if (this.formFields[category]) {
                let unitFieldName = '';

                switch (category.toLowerCase()) {
                    case 'habitat':
                        unitFieldName = ['total habitat units', 'habitat units delivered'];
                        break;

                    case 'hedgerow':
                        unitFieldName = ['total hedgerow units', 'hedge units delivered'];
                        break;

                    case 'watercourse':
                        unitFieldName = ['total watercourse units', 'watercourse units delivered'];
                        break;

                    default:
                        unitFieldName = [];
                        break;
                }

                const unitField = this.formFields[category].filter(field => unitFieldName.includes(field.header.toLowerCase()));

                if (unitField.length) {
                    const fieldName = this.fieldNameID(unitField[0]);

                    if (habitat[fieldName]) {
                        units = parseFloat(habitat[fieldName]);
                    }
                }
            }

            return isNaN(units) ? 0 : units;
        },

        /**
        * Returns value with numbered columns
        * Example 2000 becomes 2,000
        * @param {Float} number
        * @return {Void}
        */
        numberCommas(number) {
            const converted = Number(parseFloat(number).toFixed(2)).toLocaleString('en').replace(/\.00$/g, '');

            return converted.indexOf('∞') !== -1 ? 0 : converted;
        },

        /**
        * Return a list of values back to this.value
        * @param {Array} value - List of habitats
        * @return {Void}
        */
        getReturnedValues(values) {
            this.$emit('input', values);
        },

        /**
        * Submit form
        * @param {Object} data
        * @return {Void}
        */
        async submitForm(data) {
            this.$emit('submit-start', data);

            if (this.photo) {
                let index = 0;

                for await(const habitat of this.form.habitats) {
                    if (habitat.photos) {
                        try {
                            const photo = await habitat.photos.upload();

                            if (photo.length) {
                                data.habitats[index].photo = photo[0].url;
                            }

                            delete data.habitats[index].photos;
                        } catch (err) {
                        }
                    }

                    index++;
                }
            }

            this.$refs.form.setValues(this.form);

            this.$emit('submit', data);
        },

        /**
        * Rerun formula and value functionality
        * @return {Void}
        */
        rerunFormula() {
            if (!this.formLoading.all && this.showIndex !== undefined) {
                const values = this.form.habitats;

                values.forEach((habitat, index) => {
                    this.setSelectedIndex(habitat, index);
                    if (this.runCalculations) this.getFormula(index);
                });
            }
        },

        /**
        * Runs validation for front end
        * @return {Void}
        */
        runValidation() {
            const issues = this.form.habitats.filter(habitat => Object.keys(habitat).length > 1).filter(habitat => {
                const fields = this.getFormFieldFiltered(habitat.category);

                if (fields) {
                    const userFields = fields.filter((field) => field.type === 'user');

                    return userFields.filter((field) => {
                        return habitat[`${field.header}:${field.column}`] === undefined || habitat[`${field.header}:${field.column}`] === '';
                    }).length !== 0;
                }

                return false;
            });

            return issues;
        },
    },
};
</script>
