var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "details-block details-bg" }, [
    _vm.title
      ? _c("div", { staticClass: "formulate-heading mb-3" }, [
          _c("h3", { staticClass: "m-0" }, [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
          ]),
        ])
      : _vm._e(),
    _vm._v("\n    " + _vm._s(_vm.itemType) + "\n    "),
    _vm.itemType === "plots"
      ? _c(
          "div",
          [
            _c("div", { staticClass: "status-pill" }, [
              _vm._v("\n            " + _vm._s(_vm.item.status) + "\n        "),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Unique Reference Number: " +
                  _vm._s(_vm.item.unique_reference_number)
              ),
            ]),
            _vm._v(" "),
            _vm.offsetType.includes("bng")
              ? _c("div", [
                  _c("h6", [_vm._v("BNG Details")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Current Management Style: " +
                        _vm._s(_vm.item.management_style)
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.item.onsite_baseline_total_units &&
                  _vm.item.onsite_creation_total_units
                    ? _c("p", [
                        _vm._v(
                          "\n                Available Units: " +
                            _vm._s(
                              _vm.floatOutput(
                                _vm.item.onsite_creation_total_units -
                                  _vm.item.onsite_baseline_total_units
                              )
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm.item.onsite_creation_total_units
                    ? _c("p", [
                        _vm._v(
                          "\n                Potential Units: " +
                            _vm._s(
                              _vm.floatOutput(
                                _vm.item.onsite_creation_total_units
                              )
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm.item.onsite_baseline_total_units
                    ? _c("p", [
                        _vm._v(
                          "\n                Current Units: " +
                            _vm._s(
                              _vm.floatOutput(
                                _vm.item.onsite_baseline_total_units
                              )
                            ) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.offsetType.includes("nutrient_offset")
              ? _c("div", [
                  _c("h6", [_vm._v("Nutrient Offset Details")]),
                  _vm._v(" "),
                  _vm.item.drainage
                    ? _c("p", [
                        _vm._v(
                          "\n                Drainage: " +
                            _vm._s(_vm.item.drainage) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.soil_type
                    ? _c("p", [
                        _vm._v(
                          "\n                Soil Type: " +
                            _vm._s(_vm.item.soil_type) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.river_running
                    ? _c("p", [
                        _vm._v(
                          "\n                Stream or river on the land " +
                            _vm._s(_vm.item.river_running ? "Yes" : "No") +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.item.habitats && _vm.item.habitats.length > 0
              ? _vm._l(_vm.item.habitats, function (habitat, idx) {
                  return _c("p", { key: `habitat-${idx}` }, [
                    habitat.broad_habitat
                      ? _c("span", [_vm._v(_vm._s(habitat.broad_habitat))])
                      : _c("span", [_vm._v(_vm._s(habitat.category))]),
                    _vm._v(
                      "\n                - " +
                        _vm._s(_vm.formatString(habitat.type)) +
                        "\n            "
                    ),
                  ])
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("plot_view")
              ? _c(
                  "inertia-link",
                  {
                    attrs: {
                      href: _vm.$route(
                        "plot.show",
                        _vm.item.id.replace(/[^0-9.]/g, "")
                      ),
                    },
                  },
                  [_vm._v("\n            View Land Parcel\n        ")]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm.itemType === "rental"
      ? _c(
          "div",
          [
            _c("p", [
              _vm._v(
                "\n            Land Stage: " +
                  _vm._s(_vm.item.plot.status) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Unique Reference Number: " +
                  _vm._s(_vm.item.unique_reference_number)
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Units: " + _vm._s(_vm.item.units))]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Years since implementation: " + _vm._s(_vm.implementedSince)
              ),
            ]),
            _vm._v(" "),
            _vm.hasGroup(["sequest", "lpa"]) && _vm.hasPermission("rental_view")
              ? _c(
                  "inertia-link",
                  {
                    attrs: {
                      href: _vm.$route(
                        "rental.show",
                        _vm.item.id.replace(/[^0-9.]/g, "")
                      ),
                    },
                  },
                  [_vm._v("\n            View BNG Agreement\n        ")]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm.itemType === "projects"
      ? _c(
          "div",
          [
            _c("p", [
              _vm._v("Development Type: " + _vm._s(_vm.item.development_type)),
            ]),
            _vm._v(" "),
            _vm.item.planning_ref
              ? _c("p", [
                  _vm._v(
                    "\n            Planning Status: " +
                      _vm._s(_vm.item.planning_status) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.offsetType.includes("bng")
              ? [
                  _vm.item.local_planning_authority
                    ? _c("p", [
                        _vm._v(
                          "\n                LPA: " +
                            _vm._s(_vm.item.local_planning_authority.name) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.land_status
                    ? _c("p", [
                        _vm._v(
                          "\n                Land Status: " +
                            _vm._s(_vm.item.land_status) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.planning_ref
                    ? _c("p", [
                        _vm._v(
                          "\n                Pning Reference Number: " +
                            _vm._s(_vm.item.planning_ref) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  parseFloat(_vm.item.onsite_desired_percentage) > 0
                    ? _c("p", [
                        _vm._v(
                          "\n                Desired Net Gain Percentage: " +
                            _vm._s(_vm.item.onsite_desired_percentage) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  parseFloat(_vm.item.onsite_baseline_total_units) > 0
                    ? _c("p", [
                        _vm._v(
                          "\n                Total Baseline Units: " +
                            _vm._s(_vm.item.onsite_baseline_total_units) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  parseFloat(_vm.item.onsite_post_intervention_total) > 0
                    ? _c("p", [
                        _vm._v(
                          "\n                Total Post Intervention Units: " +
                            _vm._s(_vm.item.onsite_post_intervention_total) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$page.props.loggedUser.isSuperUser
                    ? [
                        _c("p", [
                          _vm._v(_vm._s(_vm.item.study ? "Study Done" : "")),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.item.study_requested ? "Study Requested" : ""
                            )
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.offsetType.includes("nutrient_offset")
              ? [
                  _vm.item.river_basin_district
                    ? _c("p", [
                        _vm._v(
                          "\n                River District: " +
                            _vm._s(_vm.item.river_basin_district.name) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.river_basin_district
                    ? _c("p", [
                        _vm._v(
                          "\n                Type of development: " +
                            _vm._s(_vm.item.nutrient_development_type) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeNumberAmount && _vm.item.nutrient_development_amount
                    ? _c("p", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.typeNumberAmount) +
                            ": " +
                            _vm._s(_vm.item.nutrient_development_amount) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.item.unique_reference_number
              ? _c("p", [
                  _vm._v(
                    "\n            Unique Reference Number: " +
                      _vm._s(_vm.item.unique_reference_number) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("project_view")
              ? _c(
                  "inertia-link",
                  {
                    attrs: {
                      href: _vm.$route(
                        "project.show",
                        _vm.item.id.replace(/[^0-9.]/g, "")
                      ),
                    },
                  },
                  [_vm._v("\n            View Development\n        ")]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm.itemType === "vectors"
      ? _c(
          "div",
          _vm._l(_vm.item.properties, function (value, key) {
            return _c("p", { key: key }, [
              _vm._v(
                "\n            " +
                  _vm._s(key) +
                  ": " +
                  _vm._s(value) +
                  "\n        "
              ),
            ])
          }),
          0
        )
      : _vm.itemType === "habitats"
      ? _c("div", { staticStyle: { width: "500px" } }, [
          _c("div", { staticClass: "form-flex--lap m-0" }, [
            _c(
              "div",
              {
                class: {
                  "w-full": !_vm.item.photo,
                },
              },
              [
                _vm.item.item_type === "plot"
                  ? [
                      _c("p", [
                        _vm._v("Habitat Type: " + _vm._s(_vm.item.type)),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.item.description) },
                      }),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Unique Reference Number: " +
                            _vm._s(_vm.item.unique_reference_number)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("Size: " + _vm._s(_vm.item.size) + " ha"),
                      ]),
                    ]
                  : [
                      _c("p", [
                        _vm._v("Category: " + _vm._s(_vm.item.category)),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Habitat Type: " +
                            _vm._s(
                              _vm._f("capitalize")(
                                _vm.item["habitat-type"].replace(/-/g, " ")
                              )
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.item.broad_habitat
                        ? _c("p", [
                            _vm._v(
                              "\n                        Broad Habitat: " +
                                _vm._s(
                                  _vm._f("capitalize")(
                                    _vm.item.broad_habitat.replace(/-/g, " ")
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("Size: " + _vm._s(_vm.item.size) + " ha"),
                      ]),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _vm.item.photo
              ? _c("div", [
                  _c("img", {
                    staticClass: "w-full",
                    attrs: { src: _vm.item.photo },
                    on: { click: _vm.clickImage },
                  }),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.item.item_type !== "plot"
            ? _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "inertia-link",
                    {
                      attrs: {
                        href:
                          _vm.item.related_type === "plot-habitat"
                            ? `${_vm.$route(
                                "plot.show",
                                _vm.item.related_id
                              )}#habitats`
                            : _vm.$route(
                                "habitat.edit",
                                _vm.item.id.replace(/[^0-9.]/g, "")
                              ),
                      },
                    },
                    [_vm._v("\n                Edit\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "ml-3",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteHabitat(_vm.item)
                        },
                      },
                    },
                    [_vm._v("\n                Delete\n            ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }