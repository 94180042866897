<template>
    <div>
        <FormulateInput
            type="button"
            name="accept"
            :label="acceptText"
            @click.prevent="$emit('accept')"
        />
        <FormulateInput
            type="button"
            name="reject"
            label="Reject"
            @click.prevent="$emit('reject')"
        />
    </div>
</template>

<script>
export default {
    name: 'InviteFormAccept',

    data() {
        return {
            acceptText: this.getAcceptText(),
        };
    },

    watch: {
        '$page.props.loggedUser'() {
            this.acceptText = this.getAcceptText();
        },
    },

    methods: {
        getAcceptText() {
            const loggedUser = this.$page.props.loggedUser;

            if (loggedUser) {
                return 'Accept';
            }

            return 'Accept and create account';
        },
    },
};
</script>
