var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "settings" } },
    [
      _c("page-header", { attrs: { title: _vm.event } }),
      _vm._v(" "),
      _c("div", { ref: "container", staticClass: "center" }, [
        _c("div", { staticClass: "details" }, [
          _c(
            "div",
            { staticClass: "details-block details-bg" },
            _vm._l(_vm.data, function (item) {
              return _c("DocusignRow", {
                key: item.title,
                attrs: { data: item },
              })
            }),
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }