var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "historyModal",
    attrs: { width: "50em" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v("\n            Signatures\n        ")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.formatData.length
              ? [
                  _c("TableGridLayout", {
                    attrs: {
                      columns: _vm.columns,
                      rows: _vm.formatData,
                      output: ["table"],
                      mode: "remote",
                    },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.stillRequired.length
              ? _c("TableGridLayout", {
                  attrs: {
                    columns: _vm.columnsRequired,
                    rows: _vm.stillRequired,
                    output: ["table"],
                    mode: "remote",
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }