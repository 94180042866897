<template>
    <section class="vue-formulate-drawmap vue-formulate-drawmap--multi">
        <mapping-tool
            ref="map"
            :center="center"
            :zoom="zoom"
            fit-bounds="features"
            fit-bounds-update="edit"
            :sidebar-width="0"
            :sidebar="sidebar"
            :upload="upload"
            class="form-flex--lap "
        >
            <template #mapHolder>
                <HeaderSmall
                    title="Location"
                />
                <alert
                    type="info"
                >
                    <p>Use the map below to draw your habitat - please be as accurate as possible.</p>
                </alert>
            </template>
            <mapping-tool-schema
                :schema="schema"
                @input="updateSchema"
            />
            <template #sidebar>
                <div
                    class="vue-formulate-drawmap-sidebar w-1/3"
                >
                    <HeaderSmall
                        title="Habitats"
                        :button="{
                            svg: 'filter',
                            click: () => showFilters = !showFilters,
                        }"
                    />
                    <div class="vue-formulate-drawmap-sidebar-content">
                        <div class="vue-formulate-drawmap-sidebar-scroll">
                            <div class="vue-formulate-drawmap-sidebar-bng">
                                <BNGCalculator
                                    key="bng_calculator"
                                    ref="calculator"
                                    v-model="habitatFormData"
                                    :fields-enabled="disabledFields"
                                    form-type="partial"
                                    :run-calculations="false"
                                    :photo="true"
                                    :photo-upload-url="$route('habitat.upload')"
                                    pill=""
                                    :show-fields="getShowFields"
                                    @switch="changeActive"
                                    @input="updateHabitat"
                                    @submit-start="$emit('submit-start')"
                                    @submit="$emit('submit', $event)"
                                />
                            </div>
                        </div>
                        <div
                            v-show="showFilters"
                            class="vue-formulate-drawmap-sidebar-filter"
                        >
                            <div class="vue-formulate-drawmap-sidebar-scroll">
                                <mapping-tool-sidebar
                                    sidebar-click-action="expand"
                                    width="100%"
                                    @toggleChecked="(feature) => $emit('sidebarToggleChecked', feature)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <slot />
        </mapping-tool>
    </section>
</template>

<script>
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin';
import mapMixin from 'Mixins/map.js';
import BNGCalculator from 'Components/Metrics/Bng';
import { mapColors } from 'Lib/defaults';
import Alert from 'Utilities/alert/Alert';
import HeaderSmall from 'Components/Header/HeaderSmallButtons';

export default {
    name: 'FormulateMapBNG',
    components: {
        BNGCalculator,
        Alert,
        HeaderSmall,
    },
    mixins: [
        FormulateInputMixin,
        mapMixin,
    ],

    props: {
        sidebarWidth: {
            type: Number,
            default: 320,
        },
        sidebar: {
            type: Boolean,
            default: true,
        },
        upload: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            zoom: 6,
            center: {
                lat: 53.428720,
                lng: -1.659736,
            },
            formData: [],
            schema: [],
            showIndex: 0,
            currentSelected: null,
            showFilters: false,
        };
    },

    computed: {
        disabledFields() {
            return {
                'length_(km)': false,
                'area_(hectares)': false,
            };
        },

        habitatFormData: {
            get() {
                return this.formData.map((data) => ({...data.habitat, id: data.index}));
            },
            set(value) {
                const newData = [...this.formData];

                value.forEach((habitat) => {
                    newData.forEach((data, index) => {
                        if (data.index === habitat.id) {
                            newData[index].habitat = habitat;
                        }
                    });
                });

                this.formData = newData.filter((data) => value.filter((habitat) => habitat.id === data.index).length);
            },
        },

        mapFormData: {
            get() {
                return this.formData.map((data) => ({location_data: data.location_data, id: data.index}));
            },
            set(value) {
                const newData = [...this.formData];
                let polyData = [];

                if (value.polygon.type === 'shape') {
                    polyData = value.polygon.data.paths;
                } else if (value.polygon.type === 'circle') {
                    polyData = {
                        center: value.polygon.data.center,
                        radius: value.polygon.data.radius,
                    };
                }

                newData.forEach((data, index) => {
                    if (data.index === value.id) {
                        newData[index].location_data = polyData;

                        if (newData[index].habitat.category === 'habitat') {
                            newData[index].habitat['Area (hectares):H'] = value.polygon.data.size ? value.polygon.data.size.hectares : 0;
                        } else {
                            newData[index].habitat['length (km):E'] = value.polygon.data.size ? value.polygon.data.size.hectares : 0;
                        }
                    }
                });
            },
        },

        getShowFields() {
            const fields = {
                habitat: [{
                    editable: true,
                    hidden: false,
                    column: 'E', // broad habitat
                }, {
                    editable: true,
                    hidden: false,
                    column: 'F', // habitat type
                }, {
                    editable: true,
                    hidden: false,
                    column: 'H', // area (hectares)
                }],
                hedgerow: [{
                    editable: true,
                    hidden: false,
                    column: 'D', // hedgerow type
                }, {
                    editable: true,
                    hidden: false,
                    column: 'E', // length (km)
                }],
                watercourse: [{
                    editable: true,
                    hidden: false,
                    column: 'D', // watercourse type
                }, {
                    editable: true,
                    hidden: false,
                    column: 'E', // length (km)
                }],
            };

            return fields;
        },
    },

    created() {
        this.formData = this.context.model.map((habitat, index) => {
            const tmpHabitat = {...habitat};

            delete tmpHabitat.id;
            delete tmpHabitat.location_data;

            return {
                id: habitat.id ?? null,
                index: habitat.id ?? parseInt(new Date().getTime() + '' + index, 10),
                habitat: tmpHabitat,
                location_data: habitat.location_data,
            };
        });

        this.schema = this.toSchema();

    },

    mounted() {
        this.changeActive(0);
    },

    methods: {
        newFormData() {
            return {
                id: null,
                index: parseInt(new Date().getTime(), 10),
                habitat: {
                    category: 'habitat',
                },
                location_data: [],
            };
        },

        changeActive(showIndex) {
            this.showIndex = showIndex;

            this.currentSelected = this.formData[this.showIndex];

            if (!this.currentSelected && showIndex > -1) {
                this.currentSelected = this.newFormData();
                this.formData.push(this.currentSelected);
            }

            this.schema = this.toSchema();
        },
        updateHabitat() {
            this.updateModel();

            this.toSchema();

        },
        updateSchema(value) {
            if (this.showIndex !== undefined) {
                this.mapFormData = {
                    polygon: value,
                    id: this.formData[this.showIndex].index,
                };
                this.$refs.calculator.rerunFormula();

                this.updateModel();
            }
        },

        updateModel() {
            this.context.model = this.formData.map((data) => ({
                ...data.habitat,
                id: data.id ?? null,
                location_data: data.location_data,
            }));
            this.$emit('input', this.context.model);
        },


        toSchema() {
            return this.formData.map((data) => this.toSchemaSingle({...data}));
        },

        toSchemaSingle(data) {
            const habitatColors = this.getMapFillColor(data);
            const theme = {
                color: mapColors.default,
                strokeColor: mapColors.default,
                pattern: null,
                patternColor: null,
                patternColorAlt: null,
            };

            if (habitatColors.fillColor) {
                theme.color = habitatColors.fillColor;
                theme.strokeColor = habitatColors.fillColor;
            }

            if (habitatColors.theme) {
                if (habitatColors.theme.type) theme.pattern = habitatColors.theme.type;
                if (habitatColors.theme.fill) theme.patternColor = habitatColors.theme.fill;
                if (habitatColors.theme.fill) theme.patternColorAlt = habitatColors.theme.fill;
            }


            return {
                ...this.pointsToSchema(data.location_data),
                properties: {
                    name: `${data.habitat.category ?? ''} ${data.habitat.broad_habitat ?? ''} ${data.habitat.type ?? ''}`,
                    markerZoom: null,
                    edit: this.currentSelected && this.currentSelected.index === data.index,
                    editable: true,
                    visible: true,
                    color: theme.color,
                    strokeColor: theme.strokeColor,
                    pattern: theme.pattern,
                    patternColor: theme.patternColor,
                    patternColorAlt: theme.patternColorAlt,
                },
            };
        },

        getMapFillColor(data) {
            if (!data) data = {habitat: {}, location_data: []};
            const typeLower = (data.habitat.type ?? '').toLowerCase().replaceAll(' ', '-');
            let color = mapColors[typeLower] ?? null,
                theme = null;

            if (mapColors[typeLower] && typeof color === 'object') {
                color = mapColors[typeLower].fill;
                theme = mapColors[typeLower].theme;
            }

            return {
                ...data,
                type: data.habitat['data-type'],
                'habitat-type': typeLower ?? null,
                location_data: data.location_data ?? [],
                fillColor: color,
                theme: theme,
            };
        },
    },
};
</script>
