var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.context
    ? _c(
        "div",
        [
          _c("FormulateInput", {
            key: "name_1",
            attrs: {
              type: "text",
              name: "name",
              label: "Name",
              validation: "required",
              "validation-name": "Name",
              disabled: _vm.disabled,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-flex--tab form-flex--vcenter" },
            [
              _c("FormulateInput", {
                key: "offset_type_1",
                attrs: {
                  type: "select-plugin",
                  name: "offset_type",
                  label: "Offset Type",
                  validation: "required",
                  "validation-name": "Offset Type",
                  options: _vm.offsetOptions,
                  placeholder: "Select Offset Type",
                  disabled: _vm.disabled,
                  multiple: true,
                },
                on: { input: _vm.handleOffsetChange },
              }),
              _vm._v(" "),
              _vm.isSuperUser
                ? [
                    _c("FormulateInput", {
                      key: "status_1",
                      attrs: {
                        type: "select-plugin",
                        name: "status",
                        label: "Status",
                        validation: "required",
                        "validation-name": "Status",
                        options: _vm.statusOptions,
                        placeholder: "Select Status",
                        disabled: _vm.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-flex--tab form-flex--vcenter" },
            [
              _vm.value.offset_type.includes("bng")
                ? [
                    _c("FormulateInput", {
                      key: "planning_authority_id_1",
                      attrs: {
                        type:
                          _vm.planningAuthorities.length > 1
                            ? "select-plugin"
                            : "fake",
                        name: "planning_authority_id",
                        label: "Planning Authority",
                        validation: "required",
                        "validation-name": "Planning Authority",
                        options: _vm.planningAuthoritiesSelect,
                        placeholder: "Select Planning Authority",
                        disabled: _vm.disabled,
                        "parse-value": _vm.parseLpaValue,
                      },
                      on: { input: _vm.handlePlanningChange },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.value.offset_type.includes("nutrient_offset")
                ? [
                    _c("FormulateInput", {
                      key: "river_basin_district_id_1",
                      attrs: {
                        type:
                          _vm.riverBasinDistricts.length > 1
                            ? "select-plugin"
                            : "fake",
                        name: "river_basin_district_id",
                        label: "River Catchment Area",
                        validation: "required",
                        "validation-name": "River Catchment Area",
                        options: _vm.riverBasinDistrictSelect,
                        placeholder: "Select River Catchment Area",
                        disabled: _vm.disabled,
                        "parse-value": _vm.parseRiverValue,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.companies && _vm.companies.length > 1
                ? [
                    _vm.hasGroup(["sequest", "agent"])
                      ? _c("FormulateInput", {
                          key: "company_id_1",
                          attrs: {
                            type: "select-plugin",
                            name: "company_id",
                            label: "Company",
                            validation: "required",
                            placeholder: "Select Company",
                            options: _vm.companiesSelect,
                            disabled: _vm.disabled,
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "InputSuffix",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "suffix",
                    fn: function () {
                      return [
                        _c("FormulateInput", {
                          key: "address_1_autofill",
                          attrs: {
                            type: "select",
                            name: "address_autofill",
                            options: _vm.autofillOptions,
                          },
                          on: {
                            input: function ($event) {
                              _vm.updateMap(
                                _vm.value.map_location,
                                parseInt($event)
                              )
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2558803110
              ),
            },
            [
              _c("FormulateInput", {
                key: "address_1",
                attrs: {
                  type: "text",
                  name: "address",
                  label: "Address",
                  disabled: parseInt(_vm.value.address_autofill) ? true : false,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "map_1",
            ref: "map",
            attrs: {
              type: "map",
              name: "map_location",
              validation: "required|location",
              "validation-rules": {
                location: _vm.validateMap,
              },
              "validation-messages": {
                location: _vm.mapMessageTextError,
              },
              errors: [
                _vm.$page.props.errors.address,
                _vm.$page.props.errors.location_data,
              ],
              disabled: _vm.disabled,
              "fit-bounds": _vm.editable ? "polygons" : "vectors",
              color: _vm.mapColors.project,
              "stroke-color": _vm.mapColors.project,
              vectors: _vm.mapSchema,
            },
            on: {
              input: function ($event) {
                _vm.updateMap($event, parseInt(_vm.value.address_autofill))
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-flex--tab form-flex--vcenter" },
            [
              _c("FormulateInput", {
                attrs: {
                  name: "auto_calculate",
                  type: "toggle-checkbox",
                  label: "Auto calculate Size",
                  "wrapper-class": ["tab-w-60"],
                },
              }),
              _vm._v(" "),
              _c(
                "InputSuffix",
                {
                  style: {
                    flex: "10 0 auto",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "suffix",
                        fn: function () {
                          return [
                            _c("FormulateInput", {
                              attrs: {
                                type: "select",
                                name: "size_units",
                                validation: "required",
                                options: _vm.sizeUnits,
                                disabled: _vm.disabled,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    127026368
                  ),
                },
                [
                  _c("FormulateInput", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.value.auto_calculate,
                        expression: "value.auto_calculate",
                      },
                    ],
                    attrs: {
                      type: _vm.value.auto_calculate ? "fake" : "hidden",
                      name: "size_map",
                      label: "Size",
                      validation: "required",
                      disabled: _vm.disabled,
                      "parse-value": _vm.value.auto_calculate
                        ? () => _vm.parseSizeValue(_vm.value.size_map)
                        : null,
                    },
                  }),
                  _vm._v(" "),
                  _c("FormulateInput", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.value.auto_calculate,
                        expression: "!value.auto_calculate",
                      },
                    ],
                    attrs: {
                      type: _vm.value.auto_calculate ? "hidden" : "number",
                      name: "size_user",
                      label: "Size",
                      disabled: _vm.disabled,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.invalidCountryCode
            ? _c("FormulateInput", {
                key: "country_code",
                attrs: {
                  type: "select-plugin",
                  name: "country_code",
                  validation: "required",
                  label:
                    "We are unable to find a country from the map. Please choose a country",
                  "validation-name": "Country",
                  "reduce-key": "code",
                  "label-key": "name",
                  options: _vm.countries,
                  placeholder: "Country",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }