var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "pdf-report": _vm.isDownload,
      },
    },
    [
      _c(
        "section",
        { staticClass: "pdf-item" },
        [
          _c(
            "page-header",
            {
              attrs: { title: _vm.title, icon: "reports" },
              scopedSlots: _vm._u([
                {
                  key: "page-header-center",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.date) +
                          "\n            "
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "page-header-bottom",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "center" }, [
                        _c(
                          "div",
                          { staticClass: "tile-wrap flex-space-between" },
                          [
                            _c("div", { staticClass: "tile" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "tile-inner tile-inner--single",
                                },
                                [
                                  _c("div", { staticClass: "tile-text" }, [
                                    _c("h4", [
                                      _c("span", [
                                        _vm._v(
                                          "\n                                            Development Name:\n                                        "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.project.name) +
                                          "\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("h4", [
                                      _c("span", [
                                        _vm._v(
                                          "\n                                            URN:\n                                        "
                                        ),
                                      ]),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.project.unique_reference_number
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("h4", [
                                      _c("span", [_vm._v("Address:")]),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.project.address) +
                                          "\n                                    "
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "link-wrap flex-space-between" },
                          [
                            _c("dashboard-link", {
                              attrs: {
                                title: "Size",
                                color: "#627086",
                                text: _vm.floatOutput(_vm.project.size),
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Planning No",
                                color: "#6967D5",
                                text: _vm.project.planning_ref,
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Status",
                                color: "#E47D44",
                                text: _vm.project.status,
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Dev Type",
                                color: "#1782FF",
                                text: _vm.project.development_type,
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Land Status",
                                color: "#5EBD8A",
                                text: _vm.project.land_status,
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Total Units",
                                color: "#E34B5D",
                                text: _vm.floatOutput(
                                  _vm.project.onsite_baseline_total_units
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("dashboard-link", {
                              attrs: {
                                title: "Desired Offsite Units",
                                color: "#6967D5",
                                text: _vm.floatOutput(
                                  _vm.project.desired_total
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("div", {}, [
                _c("h3", [_vm._v("Dev Site Report")]),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(_vm.upperFirst(_vm.project.status)))]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _c("section", { staticClass: "pdf-item" }, [
          _c(
            "div",
            { staticClass: "report-lease-map" },
            [
              _c("report-map", {
                ref: "rentalmap",
                attrs: {
                  type: "projects",
                  zoom: 10,
                  projects: [_vm.project],
                  "is-download": _vm.isDownload,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }