<template>
    <div>
        <template
            v-if="hasBaselineMetric && showBaseline"
        >
            <div class="details-block details-bg">
                <div class="formulate-heading">
                    <h6>Baseline Habitat Metrics</h6>
                    <div class="formulate-heading-buttons">
                        <button
                            v-if="viewMetric.baseline"
                            class="btn--link"
                            @click="viewMetric.baseline = false"
                        >
                            See Metric Summary
                        </button>
                        <button
                            v-else
                            class="btn--link"
                            @click="viewMetric.baseline = true"
                        >
                            See Metric Data
                        </button>
                    </div>
                </div>
                <BNGCalculator
                    v-if="viewMetric.baseline"
                    key="currentMetric"
                    v-model="baselineHabitats"
                    form-type="partial"
                    type="baseline"
                    :show-units="false"
                    :disabled="true"
                    :run-formula="false"
                    :version="baselineLatestVersion"
                    pill=""
                />
                <BngBaselineSummary
                    v-else
                    :baseline="baselineHabitats"
                    :creation="creationHabitats"
                    :enhancement="enhancementsHabitats"
                    :include-chart="includeBaselineChart"
                />
            </div>
        </template>
        <template
            v-if="hasCreationMetric && showCreation"
        >
            <div class="details-block details-bg">
                <div class="formulate-heading">
                    <h6>Creation Habitat Metrics</h6>
                    <div class="formulate-heading-buttons">
                        <button
                            v-if="viewMetric.creation"
                            class="btn--link"
                            @click="viewMetric.creation = false"
                        >
                            See Metric Summary
                        </button>
                        <button
                            v-else
                            class="btn--link"
                            @click="viewMetric.creation = true"
                        >
                            See Metric Data
                        </button>
                    </div>
                </div>
                <BNGCalculator
                    v-if="viewMetric.creation"
                    key="creationMetric"
                    v-model="creationHabitats"
                    type="creation"
                    form-type="partial"
                    :disabled="true"
                    :run-formula="false"
                    :version="creationLatestVersion"
                    pill=""
                    :max-area="model.size ? parseFloat(model.size) : null"
                />
                <BngCreationSummary
                    v-else
                    :baseline="baselineHabitats"
                    :creation="creationHabitats"
                    :enhancement="enhancementsHabitats"
                    :include-chart="includeCreationChart"
                />
            </div>
        </template>
        <template
            v-if="hasEnhancementMetric && showEnhancement"
        >
            <div class="details-block details-bg">
                <div class="formulate-heading">
                    <h6>Enhancement Habitat Metrics</h6>
                    <div class="formulate-heading-buttons">
                        <button
                            v-if="viewMetric.enhancement"
                            class="btn--link"
                            @click="viewMetric.enhancement = false"
                        >
                            See Metric Summary
                        </button>
                        <button
                            v-else
                            class="btn--link"
                            @click="viewMetric.enhancement = true"
                        >
                            See Metric Data
                        </button>
                    </div>
                </div>
                <BNGCalculator
                    v-if="viewMetric.enhancement"
                    key="enhancementMetric"
                    v-model="enhancementHabitats"
                    type="enhancement"
                    form-type="partial"
                    :disabled="true"
                    :run-formula="false"
                    :version="enhancementLatestVersion"
                    pill=""
                    :max-area="model.size ? parseFloat(model.size) : null"
                />
                <BngEnhancementSummary
                    v-else
                    :baseline="baselineHabitats"
                    :creation="creationHabitats"
                    :enhancement="enhancementsHabitats"
                    :include-chart="includeEnhancementChart"
                />
            </div>
        </template>
        <template v-if="desiredUpliftPercent !== null">
            <form-desired
                :baseline="baselineHabitats"
                :creation="creationHabitats"
                :enhancement="enhancementsHabitats"
                :desired-uplift-percent="desiredUpliftPercent"
            />
        </template>
    </div>
</template>

<script>
import BNGCalculator from 'Components/Metrics/Bng';
import BngBaselineSummary from 'Components/Metrics/BngBaselineSummary.vue';
import BngCreationSummary from 'Components/Metrics/BngCreationSummary.vue';
import BngEnhancementSummary from 'Components/Metrics/BngEnhancementSummary.vue';
import MetricMixin from 'Mixins/metric';
import FormDesired from 'Components/Metrics/Desired.vue';

export default {
    name: 'MetricSummary',
    components: {
        BNGCalculator,
        BngBaselineSummary,
        BngCreationSummary,
        BngEnhancementSummary,
        FormDesired,
    },

    mixins: [
        MetricMixin,
    ],

    props: {
        baseline: {
            type: Array,
            default: () => ([]),
        },
        creation: {
            type: Array,
            default: () => ([]),
        },
        enhancement: {
            type: Array,
            default: () => ([]),
        },
        model: {
            type: Object,
            required: true,
        },
        showBaseline: {
            type: Boolean,
            default: false,
        },
        showCreation: {
            type: Boolean,
            default: false,
        },
        showEnhancement: {
            type: Boolean,
            default: false,
        },
        desiredUpliftPercent: {
            type: Number,
            default: null,
        },
        includeBaselineChart: {
            type: Array,
            default: null,
        },
        includeCreationChart: {
            type: Array,
            default: null,
        },
        includeEnhancementChart: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            baselineHabitats: [],
            creationHabitats: [],
            enhancementHabitats: [],
            viewMetric: {
                baseline: false,
                creation: false,
                enhancement: false,
            },
        };
    },
    computed: {
        hasBaselineMetric() {
            return this.baseline && this.baseline.length;
        },
        hasCreationMetric() {
            return this.creation && this.creation.length;
        },
        hasEnhancementMetric() {
            return this.enhancement && this.enhancement.length;
        },
        baselineLatestVersion() {
            return this.getMetricLatestVersion(this.baseline);
        },
        creationLatestVersion() {
            return this.getMetricLatestVersion(this.creation);
        },
        enhancementLatestVersion() {
            return this.getMetricLatestVersion(this.enhancement);
        },
    },
    created() {
        const currentTargetVersion = this.getMetricLatestVersion(this.baseline);
        const creationTargetVersion = this.getMetricLatestVersion(this.creation);
        const enhancementTargetVersion = this.getMetricLatestVersion(this.creation);

        this.baselineHabitats = this.baseline.filter(habitat => parseFloat(habitat.version) === currentTargetVersion).map(habitat => habitat.associativeColumn);
        this.creationHabitats = this.creation.filter(habitat => parseFloat(habitat.version) === creationTargetVersion).map(habitat => habitat.associativeColumn);
        this.enhancementsHabitats = this.enhancement.filter(habitat => parseFloat(habitat.version) === enhancementTargetVersion).map(habitat => ({
            ...habitat.associativeColumn,
            baseline: habitat.baseline.associativeColumn,
        }));
    },
};

</script>
