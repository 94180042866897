<template>
    <div
        :class="{
            'pdf-report' : isDownload
        }"
    >
        <section class="pdf-item">
            <page-header
                :title="title"
                icon="reports"
            >
                <template #page-header-center>
                    <template v-if="!isDownload">
                        <FormulateInput
                            v-model="dates"
                            class="mb-0"
                            type="daterange-plugin"
                            placeholder="All dates"
                            name="date"
                            show-clear
                            @input="filter"
                        />
                    </template>
                    <template v-else>
                        {{ displayDates }}
                    </template>
                </template>
                <div class="">
                    <h3>BNG Report</h3>
                    <h3>Overview Report</h3>
                </div>

                <template #page-header-bottom>
                    <div class="center">
                        <div
                            class="tile-wrap flex-space-between tile-wrap-3"
                        >
                            <tile
                                v-for="(tile) in tiles"
                                :key="tile.name"
                                v-bind="tile"
                            />
                        </div>
                        <div class="link-wrap flex-wrap flex-space-between">
                            <dashboard-link
                                v-for="(link) in links"
                                :key="link.name"
                                v-bind="link"
                            />
                        </div>
                        <div
                            v-if="!isDownload"
                            class="map-wrap flex-space-between"
                        >
                            <report-map
                                ref="developmentsMap"
                                label="Developments"
                                type="projects"
                                :zoom="10"
                                :lpa="lpa"
                                :projects="mapProjects"
                            />
                            <report-map
                                ref="landParcelsMap"
                                type="plots"
                                label="Land Parcels"
                                :zoom="10"
                                :lpa="lpa"
                                :plots="mapPlots"
                            />
                        </div>
                    </div>
                </template>
            </page-header>
        </section>

        <template v-if="isDownload">
            <div class="center">
                <div class="map-wrap">
                    <section class="pdf-item">
                        <report-map
                            ref="developmentsMap"
                            label="Developments"
                            type="projects"
                            :zoom="10"
                            :center="center"
                            :lpa="lpa"
                            is-download
                            :projects="mapProjects"
                            @mapReady="mapReady"
                        />
                    </section>
                    <section class="pdf-item">
                        <report-map
                            ref="landParcelsMap"
                            :plots="mapPlots"
                            type="plots"
                            label="Land Parcels"
                            :zoom="10"
                            :center="center"
                            :lpa="lpa"
                            is-download
                            @mapReady="mapReady"
                        />
                    </section>
                </div>
            </div>
        </template>

        <section class="pdf-item">
            <div class="center">
                <div class="dashboard-heading flex-space-between">
                    <h6>Developments</h6>
                </div>
                <template v-if="projectData && projectData.data.length > 0">
                    <project-table
                        class="mb-4"
                        :filter-route="$route('reports')"
                        :projects="projectData"
                        :statuses="$page.props.statuses"
                        :has-header="false"
                        :has-dropdown="false"
                        :has-footer="!isDownload"
                        :table-columns="projectTableColumns"
                        @onRowClick="onRowClick"
                    >
                        <template #cell(lease_urn)="{row}">
                            <span
                                v-for="lease_urn in row.lease_urn"
                                :key="lease_urn"
                            >
                                {{ lease_urn }}
                            </span>
                        </template>
                    </project-table>
                </template>
                <template v-else>
                    <p>No Developments Created</p>
                </template>
            </div>
        </section>

        <section class="pdf-item">
            <div class="center">
                <div class="dashboard-heading flex-space-between">
                    <h6>Land Parcels</h6>
                </div>
                <template v-if="plotData && plotData.data.length > 0">
                    <plot-table
                        class="mb-4"
                        :plots="plotData"
                        :statuses="$page.props.statuses"
                        :allow-sorting="false"
                        :has-header="false"
                        :has-dropdown="false"
                        :table-columns="plotTableColumns"
                    >
                        <template #cell(lease_urn)="{row}">
                            <span
                                v-for="lease_urn in row.lease_urn"
                                :key="lease_urn"
                            >
                                {{ lease_urn }}
                            </span>
                        </template>
                    </plot-table>
                </template>
                <template v-else>
                    <p>No Land Parcels Created</p>
                </template>
            </div>
        </section>
    </div>
</template>

<script>

import { Tile, DashboardLink } from 'Components/Dashboard';
import PlotTable from '../../views/plots/Table';
import ProjectTable from '../../views/projects/Table';

import { DateTime } from 'luxon';

import {
    tileDefaults,
    linkDefaults,
} from 'Lib/defaults';
import ReportMap from 'Utilities/maps/ReportMap';

export default {
    name: 'OverviewReport',
    components: {
        DashboardLink,
        Tile,
        PlotTable,
        ProjectTable,
        ReportMap,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        lpa: {
            type: Object,
            default: null,
        },
        tileValues: {
            type: Object,
            default: null,
        },
        linkValues: {
            type: Object,
            default: null,
        },
        companyId: {
            type: Number,
            default: null,
        },
        plots: {
            type: Object,
            default: null,
        },
        projects: {
            type: Object,
            default: null,
        },
        mapPlots: {
            type: Array,
            default: null,
        },
        mapProjects: {
            type: Array,
            default: null,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dates: this.$route().params.date ? this.$route().params.date : {
                startDate: null,
                endDate: null,
            },
            mapsReady: [],
            projectTableColumns: [{
                label: 'Title',
                field: 'name',
                sortable: false,
            }, {
                label: 'URN',
                field: 'unique_reference_number',
                sortable: false,
            }, {
                label: 'Development Type',
                field: 'development_type',
                sortable: false,
            }, {
                label: 'Total Units',
                field: 'onsite_baseline_total_units',
                sortable: false,
            }, {
                label: 'Desired Offsite Units',
                field: 'desired_total',
                sortable: false,
            }, {
                label: 'Date',
                field: 'created_at',
                sortable: false,
            }, {
                label: 'Hectares',
                field: 'size',
                sortable: false,
            }, {
                label: 'Agreement URN',
                field: 'lease_urn',
                sortable: false,
            }, {
                label: 'Status',
                field: 'status',
                sortable: false,
                spanClass: 'status-pill type-{status}',
            }],

            plotTableColumns: [{
                label: 'Title',
                field: 'name',
                sortable: false,
            }, {
                label: 'URN',
                field: 'unique_reference_number',
                sortable: false,
            }, {
                label: 'Hectares',
                field: 'size',
                sortable: false,
            }, {
                label: 'Units',
                field: 'units',
                sortable: false,
            }, {
                label: 'Date',
                field: 'created_at',
                sortable: false,
            }, {
                label: 'Agreement URN',
                field: 'lease_urn',
                sortable: false,
            }, {
                label: 'Status',
                field: 'status',
                sortable: false,
            }],
        };
    },

    computed: {
        tiles() {
            return this.setItems(this.tileValues, tileDefaults);
        },

        links() {
            return this.setItems(this.linkValues, linkDefaults);
        },

        displayDates() {
            if (this.dates.startDate) {
                let startDate = this.dates.startDate,
                    endDate = this.dates.endDate;

                if (typeof startDate === 'string') {
                    startDate = new Date(startDate);
                }

                if (typeof endDate === 'string') {
                    endDate = new Date(endDate);
                }

                startDate = DateTime.fromJSDate(startDate).toLocaleString();
                endDate = DateTime.fromJSDate(endDate).toLocaleString();

                return `${startDate} - ${endDate}`;
            }

            return 'All Time';
        },

        plotData() {
            if (this.isDownload) {
                return {
                    data: this.mapPlots,
                    total: this.mapPlots.length,
                };
            }

            return this.plots;
        },
        projectData() {
            if (this.isDownload) {
                return {
                    data: this.mapProjects,
                    total: this.mapProjects.length,
                };
            }

            return this.projects;
        },
    },

    mounted() {
        // this.$root.$children[0]
    },

    methods:{
        setItems(items, defaults) {
            if (items) {
                return Object.entries(items)
                    .map(([key, value]) => {
                        const item = defaults.find(({name}) => name === key);

                        item.count = value;

                        if (this.companyId) {
                            item.routeName = `agent.${item.routeName}`;

                            item.href = this.$route(item.routeName, this.companyId);
                        } else {
                            item.href = this.$route(item.routeName, item.query);
                        }

                        return item;
                    });
            }

            return [];
        },

        filter() {
            if (this.dates.startDate) {
                this.clear = true;
            } else {
                this.clear = false;
            }

            let props;

            if (this.dates.startDate) {
                props = {
                    date: this.dates,
                };
            }

            this.$inertia.get(this.$route('reports'), props, {
                preserveState: true,
                replace: true,
            });
        },

        resetFilter() {
            this.dates = {
                startDate: null,
                endDate: null,
            };
        },

        mapReady(type) {
            this.mapsReady.push(type);

            if (this.mapsReady.length === 2) {
                this.$emit('mapsReady');
            }
        },
        onRowClick({ row }) {
            this.$inertia.visit(this.$route('project.show', row.id)+'#report');
        },


    },
};
</script>
