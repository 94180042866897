var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _vm._l(_vm.links, function (link, key) {
        return [
          link.label === "..."
            ? _c(
                "span",
                { key: key, staticClass: "btn", attrs: { disabled: "" } },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.paginationLabel(link.label)) +
                      "\n        "
                  ),
                ]
              )
            : _c(
                "inertia-link",
                {
                  key: key,
                  staticClass: "btn",
                  class: {
                    "btn--prev": _vm.paginationLabel(link.label) === "Previous",
                    "btn--next": _vm.paginationLabel(link.label) === "Next",
                    "btn--active": link.active,
                  },
                  attrs: {
                    href: link.url ? link.url : "",
                    only: _vm.reloadSections,
                    disabled: link.url === null,
                    "preserve-state": true,
                    replace: true,
                  },
                },
                [
                  _vm.paginationLabel(link.label) === "Previous"
                    ? _c("SvgController", { attrs: { type: "arrow-left" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.paginationLabel(link.label)))]),
                  _vm._v(" "),
                  _vm.paginationLabel(link.label) === "Next"
                    ? _c("SvgController", { attrs: { type: "arrow-right" } })
                    : _vm._e(),
                ],
                1
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }