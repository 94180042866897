export default {
    data() {
        return {
            metricData: {
                baseline: this.baseline ?? [],
                creation: this.creation ?? [],
                enhancement: this.enhancement ?? [],
            },
        };
    },

    watch: {
        baseline: {
            handler() {
                this.metricData = {
                    baseline: this.baseline ?? [],
                    creation: this.creation ?? [],
                    enhancement: this.enhancement ?? [],
                };
            },
            deep: true,
        },
        creation: {
            handler() {
                this.metricData = {
                    baseline: this.baseline ?? [],
                    creation: this.creation ?? [],
                    enhancement: this.enhancement ?? [],
                };
            },
            deep: true,
        },
        enhancement: {
            handler() {
                this.metricData = {
                    baseline: this.baseline ?? [],
                    creation: this.creation ?? [],
                    enhancement: this.enhancement ?? [],
                };
            },
            deep: true,
        },
    },

    methods: {
        getMetricLatestVersion(metric) {
            return Math.max(...metric.map(habitat => parseFloat(habitat.version)));
        },

        filterMetricOutput(data) {
            return data.map(data => {
                const dataCopy = {...data};

                const newData = {
                    version: this.version,
                    category: data.category,
                    data: {},
                };

                if (dataCopy.baseline) {
                    newData.baseline = data.baseline;
                    delete dataCopy.baseline;
                }

                if (dataCopy.photo !== undefined) {
                    newData.photo = data.photo;
                }

                if (dataCopy.photo_upload !== undefined) {
                    newData.photo_upload = data.photo_upload;
                }

                if (dataCopy.id) {
                    newData.id = data.id;
                }

                if (dataCopy.location_data) {
                    newData.location_data = data.location_data;
                }

                delete dataCopy.version;
                delete dataCopy.category;
                delete dataCopy.photos;
                delete dataCopy.id;
                delete dataCopy.photo;
                delete dataCopy.photo_upload;
                delete dataCopy.location_data;

                Object.keys(dataCopy).forEach((fieldName, index) => {
                    const fieldPart = fieldName.split(':');

                    if (fieldPart[0] !== 'category') {
                        newData.data[fieldPart[0] ? fieldName : `heading-${index}:${fieldPart[1]}`] = {
                            column: fieldPart[1] ?? null,
                            value: data[fieldName],
                        };
                    }
                });

                return newData;
            });
        },

        getChartData(layout) {
            const getLayout = this.getMetricSummaryData(layout.data);

            if (!getLayout) return null;

            getLayout.countField = JSON.parse(JSON.stringify(getLayout.countField).replaceAll('${metric}', layout.metric));

            const newLayout = {
                ...layout,
                ...getLayout,
            };
            const options = {
                ...(layout.options ?? {}),
                ...(newLayout.options ?? {}),
                title: layout.title,
            };

            let data = [];

            if (getLayout.countField.type === 'count') {
                const allItems = this.getMetricValue(newLayout.countField, newLayout.category, '', '', '').flat();

                data = [
                    newLayout.titles,
                    ...newLayout.filter.map((filter) => {
                        return filter.value.map((filterValue) => {
                            return [
                                this.capitalizeFirstLetter(filterValue), allItems.filter((value) => value === filterValue).length,
                            ];
                        });
                    }).flat(),
                ];
            } else {
                data = [
                    newLayout.titles,
                    ...newLayout.filter.map((filter) => {
                        return filter.value.map((filterValue) => {
                            return [
                                this.capitalizeFirstLetter(filterValue), this.getMetricValueSum(newLayout.countField, newLayout.category, filter.name, filterValue),
                            ];
                        });
                    }).flat(),
                ];
            }


            switch (newLayout.type) {
                case 'PieChart':
                    options.backgroundColor = 'transparent';
                    options.pieHole = 0.4;
                    options.width = 400;
                    options.height = 400;
                    options.sliceVisibilityThreshold = 0;
                    options.legend = {
                        position: 'bottom',
                    };
                    break;

                default:
                    break;
            }

            const issues = this.metricCheckCalcIssues(data);

            return {
                type: newLayout.type,
                data: issues.data,
                options,
                error: {
                    success: issues.success,
                    message: issues.message,
                },
            };
        },

        getMetricColourData(type) {
            let colours = null;

            switch (type) {
                case 'baseline-totals':
                case 'creation-totals':
                case 'enhancement-totals':
                case 'onsite-net-change':
                    colours = ['#2a6319', '#5f360f', '#1232c2'];

                    break;
                case 'baseline-habitat-lost':

                    break;

                default:
                    break;
            }

            return colours;
        },

        getMetricSummaryData(type) {
            let returnData = null;
            const savedData = {
                baseline: {
                    habitat: this.getMetricSingleData({baseline: this.metricData.baseline.filter((metric) => metric.category === 'habitat')}, 'baseline.Broad Habitat').filter(this.uniqueArray),
                    hedgerow: this.getMetricSingleData({baseline: this.metricData.baseline.filter((metric) => metric.category === 'hedgerow')}, 'baseline.Habitat type').filter(this.uniqueArray),
                    watercourse: this.getMetricSingleData({baseline: this.metricData.baseline.filter((metric) => metric.category === 'watercouse')}, 'baseline.Watercourse type').filter(this.uniqueArray),
                },
                creation: {
                    habitat: this.getMetricSingleData({creation: this.metricData.creation.filter((metric) => metric.category === 'habitat')}, 'creation.Broad Habitat').filter(this.uniqueArray),
                    hedgerow: this.getMetricSingleData({creation: this.metricData.creation.filter((metric) => metric.category === 'hedgerow')}, 'creation.Habitat type').filter(this.uniqueArray),
                    watercourse: this.getMetricSingleData({creation: this.metricData.creation.filter((metric) => metric.category === 'watercouse')}, 'creation.Watercourse type').filter(this.uniqueArray),
                },
                enhancement: {
                    habitat: this.getMetricSingleData({enhancement: this.metricData.enhancement.filter((metric) => metric.category === 'habitat')}, 'enhancement.Proposed Broad Habitat').filter(this.uniqueArray),
                    hedgerow: this.getMetricSingleData({enhancement: this.metricData.enhancement.filter((metric) => metric.category === 'hedgerow')}, 'enhancement.Proposed habitat').filter(this.uniqueArray),
                    watercourse: this.getMetricSingleData({enhancement: this.metricData.enhancement.filter((metric) => metric.category === 'watercouse')}, 'enhancement.Proposed habitat').filter(this.uniqueArray),
                },
            };

            switch (type) {
                // Baseline charts
                case 'baseline-totals':
                    returnData = {
                        countField: {
                            fields: [
                                'baseline.Total habitat units', 'baseline.Total hedgerow units', 'baseline.Total watercourse units',
                            ],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'category',
                            value: ['habitat', 'hedgerow', 'watercourse'],
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;

                case 'baseline-habitat-lost':
                    returnData = {
                        countField: {
                            fields: ['baseline.Area habitat lost', 'baseline.Length lost', 'baseline.Length Lost'],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'Distinctiveness',
                            value: ['V.High', 'High', 'Medium', 'Low', 'V.Low'],
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;

                case 'baseline-condition':
                    returnData = {
                        countField: {
                            fields: [
                                'baseline.Total habitat units', 'baseline.Total hedgerow units', 'baseline.Total watercourse units',
                            ],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'Condition',
                            value: ['Good', 'Fairly Good', 'Moderate', 'Fairly Poor', 'Poor', 'Condition Assessment N/A'],
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;

                case 'baseline-habitat-types':
                    returnData = {
                        countField: {
                            fields: [
                                'baseline.Total habitat units', 'baseline.Total hedgerow units', 'baseline.Total watercourse units',
                            ],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'Broad Habitat',
                            value: savedData.baseline.habitat,
                        }, {
                            name: 'Habitat type',
                            value: savedData.baseline.hedgerow,
                        }, {
                            name: 'Watercourse type',
                            value: savedData.baseline.watercourse,
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;

                // Creation charts
                case 'creation-totals':
                    returnData = {
                        countField: {
                            fields: [
                                'creation.Habitat units delivered', 'creation.Hedge units delivered', 'creation.Watercourse units delivered',
                            ],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'category',
                            value: ['habitat', 'hedgerow', 'watercourse'],
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;

                case 'creation-habitat-types':
                    returnData = {
                        countField: {
                            fields: [
                                'creation.Habitat units delivered', 'creation.Hedge units delivered', 'creation.Watercourse units delivered',
                            ],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'Broad Habitat',
                            value: savedData.creation.habitat,
                        }, {
                            name: 'Habitat type',
                            value: savedData.creation.hedgerow,
                        }, {
                            name: 'Watercourse type',
                            value: savedData.creation.watercourse,
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;

                // Enhancement
                case 'enhancement-totals':
                    returnData = {
                        countField: {
                            fields: [
                                'enhancement.Habitat units delivered', 'enhancement.Hedge units delivered', 'enhancement.Watercourse units delivered',
                            ],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'category',
                            value: ['habitat', 'hedgerow', 'watercourse'],
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;
                case 'enhancement-habitat-types':
                    returnData = {
                        countField: {
                            fields: [
                                'enhancement.Habitat units delivered', 'enhancement.Hedge units delivered', 'enhancement.Watercourse units delivered',
                            ],
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'Broad Habitat',
                            value: savedData.enhancement.habitat,
                        }, {
                            name: 'Habitat type',
                            value: savedData.enhancement.hedgerow,
                        }, {
                            name: 'Watercourse type',
                            value: savedData.enhancement.watercourse,
                        }],
                        options: {
                            colors: this.getMetricColourData(type),
                        },
                    };
                    break;

                // Mixed
                case 'onsite-net-change':
                    returnData = {
                        countField: {
                            fields: [
                                [
                                    'baseline.Total habitat units', 'baseline.Total hedgerow units', 'baseline.Total watercourse units',
                                ],
                                [
                                    'baseline.Baseline units retained', 'baseline.Units retained', 'baseline.Total watercourse units',
                                ],
                                [
                                    'baseline.VHDH bespoke compensation units',
                                ],
                                [
                                    'creation.Habitat units delivered', 'creation.Hedge units delivered', 'creation.Watercourse units delivered',
                                ],
                                [
                                    'enhancement.Habitat units delivered', 'enhancement.Hedge units delivered', 'enhancement.Watercourse units delivered',
                                ],
                            ],
                            calculation: '(${1} + ${2} + ${3} + ${4}) - ${0}',
                        },
                        titles: ['Type', 'Value'],
                        filter: [{
                            name: 'category',
                            value: ['habitat', 'hedgerow', 'watercourse'],
                        }],
                    };
                    break;

                default:
                    break;
            }

            return returnData;
        },

        getMetricValue(countField, category = '', filter = '', filterValue = '', filterColumn = '') {
            let returnData = {...this.metricData};

            if (Array.isArray(countField) || typeof countField === 'string') {
                countField = {fields: countField};
            }

            if (typeof countField.fields === 'string') {
                countField.fields = [countField.fields];
            }

            if (typeof countField.fields[0] === 'string') {
                countField.fields = [countField.fields];
            }

            if (category) {
                returnData = {
                    baseline: this.getMetricFilterDataBy(returnData.baseline, 'category', category),
                    creation: this.getMetricFilterDataBy(returnData.creation, 'category', category),
                    enhancement: this.getMetricFilterDataBy(returnData.enhancement, 'category', category),
                };
            }

            if (filter) {
                returnData = {
                    baseline: this.getMetricFilterDataBy(returnData.baseline, filter, filterValue, filterColumn),
                    creation: this.getMetricFilterDataBy(returnData.creation, filter, filterValue, filterColumn),
                    enhancement: this.getMetricFilterDataBy(returnData.enhancement, filter, filterValue, filterColumn),
                };
            }

            const valueCalculate = [];

            countField.fields.forEach((field) => {
                valueCalculate.push(this.getMetricSingleData(returnData, field));
            });

            return valueCalculate;
        },

        getMetricValueSum(countField, category = '', filter = '', filterValue = '', filterColumn = '') {
            const values = this.getMetricValue(countField, category, filter, filterValue, filterColumn);

            if (Array.isArray(countField)) {
                countField = {fields: countField};
            }

            if (countField.calculation && countField.fields.length) {
                let calcString = countField.calculation;

                values.forEach((value, index) => {
                    calcString = calcString.replaceAll(`$\{${index}}`, this.getMetricNumberSumUp(value));
                });

                try {
                    return eval(calcString);
                } catch (error) {
                    return 0;
                }
            }


            return this.getMetricNumberSumUp(values[0]);
        },

        getMetricFilterDataBy(metrics, field, value, column = '') {
            return metrics.filter((metric) => {
                return Object.keys(metric).filter((key) => (key === field || key.includes(`${field}:${column}`)) && metric[key] === value).length > 0;
            });
        },

        getMetricSingleData(metricData, fields) {
            if (typeof fields === 'string') {
                fields = [fields];
            }

            const valueReturn = [];


            fields.forEach((field) => {
                const fieldPart = field.split('.');
                let metrics = [];

                if (['baseline', 'creation', 'enhancement'].includes(fieldPart[0])) {
                    metrics = metricData[fieldPart[0]];
                    fieldPart.shift();
                    field = fieldPart.join('.');
                }

                metrics.forEach((metric) => {
                    let keys = [],
                        arrayTarget = metric;

                    const fieldPart = field.split('.');
                    const fieldTmpPart = fieldPart.at(-1).split(':');
                    const fieldColumn = [
                        fieldTmpPart[0],
                        fieldTmpPart[1] ?? '',
                    ];

                    if (field.includes('.')) arrayTarget = this.getSubObjectFromArray(metric, field);

                    if (arrayTarget) {
                        keys = keys.concat(Object.keys(arrayTarget).filter((key) => (key === fieldPart.at(-1) || key.includes(`${fieldColumn[0]}:${fieldColumn[1]}`)) && arrayTarget[key]).map((key) => ({
                            key,
                            array: arrayTarget,
                        })));
                    }

                    if (keys.length) {
                        return keys.map((key) => {
                            const keyString = key.key;
                            const arrayTarget = key.array;
                            let value = arrayTarget[keyString];


                            if (typeof value === 'string' && value.includes('.') && !isNaN(parseFloat(value))) {
                                value = parseFloat(value);
                            } else if (typeof value === 'string' && !isNaN(parseInt(value))) {
                                value = parseInt(value);
                            }

                            valueReturn.push(value);
                        });
                    }
                });

            });


            return valueReturn;
        },

        getSubObjectFromArray(array, field) {
            let arrayTarget = array;
            const fieldParts = field.split('.');

            fieldParts.forEach((fieldPart, index) => {
                if (index < fieldParts.length - 1 && arrayTarget) {
                    const fieldColumn = fieldPart.split(':');

                    const arrayTmp = this.getMetricSingleData([array], fieldColumn[0], fieldColumn[1]);

                    if (arrayTmp.length && typeof arrayTmp[0] === 'object') {
                        arrayTarget = arrayTmp[0];
                    }
                }
            });

            return arrayTarget;
        },

        getMetricNumberSumUp(numbers) {
            return numbers.reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            },0);
        },

        metricCheckCalcIssues(data) {
            const returnData = {
                success: true,
                message: '',
                data: [],
            };

            const negativeValues = [];

            returnData.data = data.map((row) => {
                if (!isNaN(parseInt(row[1]), 10) && row[1] < 0) {
                    returnData.success = false;
                    negativeValues.push(row[0]);

                    row[1] = 0;
                }

                return row;
            });

            const names = negativeValues.join(', ').replace(/,([^,]*)$/, ' and $1');

            returnData.message += `Pie chart does not support negative values for ${names}. `;

            return returnData;
        },
    },
};
