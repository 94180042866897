<template>
    <div
        class="input-tag"
        :class="{
            'has-click': hasClick,
        }"
    >
        <slot />
        <button
            v-if="hasClick"
            @click="$emit('click')"
        >
            <svg-controller type="times" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'InputTag',

    computed: {
        hasClick() {
            return this.$listeners && this.$listeners.click;
        },
    },
};
</script>
