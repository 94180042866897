<template>
    <auth-layout :component="$options.name">
        <template
            v-if="formAllow.login"
            #button
        >
            <template v-if="page === 'accept'">
                <template v-if="loggedUser">
                    <button
                        type="button"
                        class="btn btn--primary"
                        @click="onLogout"
                    >
                        Logout
                    </button>
                </template>
                <template v-else>
                    <span>Already have an account?</span>
                    <button
                        type="button"
                        class="btn btn--primary"
                        @click="page = 'login'"
                    >
                        Login
                    </button>
                </template>
            </template>
            <template v-else>
                <button
                    v-if="formAllow.user_creation"
                    type="button"
                    class="btn btn--primary"
                    @click="backPage"
                >
                    Go Back
                </button>
            </template>
        </template>
        <div v-if="!page">
            <form>
                <div class="form-page">
                    <p>Unable to find invite information</p>
                </div>
            </form>
        </div>
        <form v-else-if="!formAllow.login && loggedUser">
            <div class="form-page">
                <div class="form-page-info">
                    <p>You are unable to accept this invite while logged in. Please Logout to accept this invite.</p>
                    <button
                        type="button"
                        class="btn btn--primary"
                        @click="onLogout"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </form>
        <div v-else>
            <div
                v-if="page === 'accept' || page === 'login'"
                class="form-page bg-white"
            >
                <FormulateForm
                    v-model="formLogin"
                    :errors="$page.props.errors"
                    @submit="onLoginSubmit"
                >
                    <div class="form-page">
                        <description
                            v-if="page === 'accept' || page === 'login'"
                            :data="data"
                        />
                        <accept
                            v-if="page === 'accept'"
                            @accept="() => loggedUser ? onAccept() : nextPage()"
                            @reject="onReject"
                        />
                        <login
                            v-else-if="page === 'login'"
                            :email="!formAllow.user_creation ? data.email : null"
                        />

                        <FormulateInput
                            type="group"
                        >
                            <FormulateInput
                                v-if="isLastPage"
                                type="submit"
                                label="Login and accept invite"
                            />
                        </FormulateInput>
                    </div>
                </FormulateForm>
            </div>
            <FormulateForm
                v-else
                v-model="formRegister"
                :errors="$page.props.errors"
                @validation="validation = $event"
                @submit="onRegisterSubmit"
            >
                <div class="form-page">
                    <create-account
                        :form-allow="formAllow"
                    />
                    <create-account-password
                        :form-allow="formAllow"
                    />

                    <FormulateInput
                        type="group"
                    >
                        <FormulateInput
                            v-if="isLastPage && formAllow.user_creation"
                            type="submit"
                            label="Create account"
                        />
                        <FormulateInput
                            v-else-if="!isLastPage"
                            type="button"
                            label="Next"
                            @click.prevent="nextPage"
                        />
                    </FormulateInput>
                </div>
            </FormulateForm>
        </div>
    </auth-layout>
</template>

<script>
import AuthLayout from 'Components/Layouts/AuthLayout';
import Description from './Description.vue';
import Accept from './Accept.vue';
import Login from './Login.vue';
import CreateAccount from './CreateAccount.vue';
import CreateAccountPassword from './CreateAccountPassword.vue';

export default {
    name: 'InviteForm',

    components: {
        AuthLayout,
        Description,
        Accept,
        Login,
        CreateAccount,
        CreateAccountPassword,
    },

    props: {
        formAllow: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Object,
            required: true,
        },
        loggedUser: {
            type: Object,
            default: null,
        },
        rejectUrl: {
            type: String,
            required: true,
        },
        acceptUrl: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            formLogin: null,
            formRegister: this.$inertia.form({
                ...this.data,
                password: null,
                password_confirmation: null,
                terms: null,
                token: this.$route().params.token,
                invite: true,
                company_id: this.companyID(),
                type: 'user',
            }),
            page: this.initPage(),
        };
    },

    computed: {
        isLastPage() {
            if (this.getNextPage() === null) {
                return true;
            }

            return false;
        },
    },

    watch: {
        loggedUser(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.page = 'accept';
            }
        },
    },

    created() {
        this.formLogin = this.$inertia.form({
            email: this.data.email,
            password: null,
            redirect: window.location.href,
        });
    },

    methods: {
        initPage() {
            if (this.formAllow.user_creation || this.loggedUser) {
                return 'accept';
            }

            if (this.formAllow.login) {
                return 'login';
            }

            return null;
        },

        companyID() {
            if (this.formAllow.company_creation) {
                return this.data.company_name;
            } else if (this.data.company_id) {
                return this.data.company_id;
            }

            return null;
        },

        backPage() {
            const page = this.getBackPage();

            if (page) {
                this.page = this.getBackPage();
            }
        },

        getBackPage() {
            if (this.page === 'login') {
                return 'accept';
            } else if (this.page === 'create-account') {
                return 'accept';
            } else if (this.page === 'create-account-password') {
                return 'create-account';
            }

            return null;
        },

        nextPage() {
            const page = this.getNextPage();

            if (page) {
                this.page = this.getNextPage();
            }
        },

        getNextPage() {
            if (this.page === 'accept') {
                return 'create-account';
            }

            return null;
        },

        onAccept() {
            this.formLogin.post(this.acceptUrl);
        },

        onReject() {
            this.$inertia.post(this.rejectUrl);
        },

        onLoginSubmit() {
            this.formLogin.post(this.$route('login'));
        },

        async onLogout() {
            await this.$inertia.post(this.$route('logout'), {
                redirect: window.location.href,
            });
            this.page = this.initPage();
        },

        onRegisterSubmit() {
            this.formRegister.post(this.$route('register'));
        },
    },
};
</script>
