var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "vue-formulate-drawmap" },
    [
      _c(
        "mapping-tool",
        {
          ref: "map",
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            sidebar: _vm.sidebar,
            upload: _vm.upload,
            "fit-bounds": _vm.fitBounds,
            "fit-bounds-update": "vectors",
            "sidebar-width": _vm.sidebarWidth,
          },
          scopedSlots: _vm._u([
            {
              key: "sidebar",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "vue-formulate-drawmap-sidebar",
                      class: {
                        active: _vm.sidebarShow,
                      },
                    },
                    [
                      _c("mapping-tool-sidebar", {
                        attrs: {
                          "sidebar-click-action": "expand",
                          width: _vm.sidebarWidth ? _vm.sidebarWidth : "100%",
                        },
                        on: {
                          toggleChecked: function ($event) {
                            return _vm.$emit(
                              "sidebarToggleChecked",
                              _vm.feature
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("mapping-tool-schema", {
            attrs: { schema: _vm.toSchema },
            on: { input: _vm.updateSchema },
          }),
          _vm._v(" "),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }