<!-- eslint-disable vue/no-v-html -->
<template>
    <modal
        ref="confirmModal"
        class="modal-confirm"
    >
        <template #header>
            <h3>{{ title }}</h3>
        </template>
        <template #body>
            <p
                v-html="message"
            />
            <div v-if="reason">
                <quillEditor
                    ref="quilEditor"
                    v-model="reasonText"
                    :options="{
                        modules: {
                            toolbar: false,
                        },
                    }"
                />
            </div>
            <div class="btn--group">
                <button
                    id="cancel"
                    class="btn-reset btn"
                    :class="cancelBtnClass"
                    @click="_cancel"
                >
                    {{ cancelBtnText }}
                </button>
                <button
                    id="confirm"
                    class="btn-reset btn"
                    :class="confirmBtnClass"
                    @click="_confirm"
                >
                    {{ confirmBtnText }}
                </button>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import { quillEditor } from 'vue-quill-editor';
import { events } from './events';

export default {
    name: 'Confirm',
    components: {
        Modal,
        quillEditor,
    },
    props: {

    },
    data() {
        return {
            title: null,
            message: null,
            cancelBtnText: 'Cancel',
            cancelBtnClass: null,
            confirmBtnText: 'Confirm',
            confirmBtnClass: 'btn--primary',
            reason: false,
            reasonText: null,
        };
    },

    mounted() {
        events.$on('open', this.show);
        events.$on('close', this.close);
    },
    methods: {
        show(opts = {}) {
            this.opts = opts;
            this.title = opts.title;
            this.message = opts.message;
            this.reason = false;
            this.reasonText = null;

            if (opts.cancelBtnText) {
                this.cancelBtnText = opts.cancelBtnText;
            }

            if (opts.cancelBtnClass) {
                this.cancelBtnClass = opts.cancelBtnClass;
            }

            if (opts.confirmBtnText) {
                this.confirmBtnText = opts.confirmBtnText;
            }

            if (opts.confirmBtnClass) {
                this.confirmBtnClass = opts.confirmBtnClass;
            }

            if (opts.reason) {
                this.reason = opts.reason ? true : false;
            }

            if (this.$refs.confirmModal) {
                this.$refs.confirmModal.openModal();
            }

            if (!opts.callback) {
                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                });
            }
        },
        _confirm() {
            if (!this.opts.callback) {
                if (this.$refs.confirmModal) {
                    this.$refs.confirmModal.closeModal();
                }

                this.resolvePromise(true);
            } else {
                this.close(true);
            }
        },
        _cancel() {
            if (!this.opts.callback) {
                if (this.$refs.confirmModal) {
                    this.$refs.confirmModal.closeModal();
                }

                this.rejectPromise(new Error('User cancelled the dialog'));
            } else {
                this.close(false);
            }
        },

        close(confirm) {
            if (this.opts.callback) {
                const reason = this.parsedContent(this.reasonText, ['p', 'br']);

                this.opts.callback(confirm, reason);
            }

            this.$refs.confirmModal.closeModal();
        },
    },

};
</script>
