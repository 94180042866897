<template>
    <app-layout section="plots">
        <page-header
            :title="pageTitle"
            icon="plots"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="$route('plot.show', plot.id)"
                :data="{selectedTab}"
            >
                Cancel
            </inertia-link>
            <button
                id="form-submit"
                class="btn-reset btn btn--primary"
                @click="submitFormButton"
            >
                <SvgController
                    type="tick"
                />
                <span>Save Agreement</span>
            </button>
        </page-header>
        <div class="center">
            <FormulateForm
                ref="form"
                v-model="form"
                :errors="form.errors"
                class="formulate-form--plot flex-col"
                @submit="submitForm"
            >
                <div class="form-flex--lap">
                    <div>
                        <div class="formulate-heading">
                            <h6>Agreement Details</h6>
                        </div>
                        <div class="form-flex--tab">
                            <FormulateInput
                                type="text"
                                name="units"
                                label="BNG units required"
                                validation="required"
                                validation-name="BNG units required"
                            />
                            <FormulateInput
                                type="text"
                                name="per_unit_price"
                                label="Agreement price per unit"
                                validation="required"
                                validation-name="Agreement price per unit"
                            />
                        </div>
                        <FormulateInput
                            type="select-plugin"
                            label="Development"
                            name="project_id"
                            :options="projects"
                            validation="required"
                            validation-name="Project"
                        />
                        <InputSuffix>
                            <FormulateInput
                                type="text"
                                name="size"
                                label="Size"
                                validation="required"
                                outer-class="formulate-input formulate-size"
                                :disabled="true"
                            />
                            <template #suffix>
                                <div class="formulate-input-info">
                                    <p>Hectares</p>
                                </div>
                            </template>
                        </InputSuffix>
                        <FormulateInput
                            type="select"
                            name="status"
                            label="Status"
                            validation="required"
                            validation-name="Status"
                            :options="statusOptions"
                            placeholder="Select Agreement status"
                        />
                        <FormulateInput
                            type="datetime-plugin"
                            name="rental_startdate"
                            label="Agreement Start Date"
                            validation="required"
                            validation-name="Agreement Start Date"
                        />

                        <FormulateInput
                            v-if="$page.props.loggedUser.isSuperUser"
                            type="datetime-plugin"
                            name="audit_date"
                            label="Audit Date"
                            validation-name="Audit Date"
                            format="dd MMMM"
                            class="hide-year"
                            :flow="['month', 'date']"
                        />
                    </div>
                    <div>
                        <div class="formulate-heading">
                            <h6>Location</h6>
                        </div>
                        <alert type="info">
                            <p>Use the map below to draw around the part of the plot to be implemented, please be as accurate as possible.</p>
                        </alert>
                        <FormulateInput
                            ref="map"
                            key="map_1"
                            type="map"
                            class="formulate-map-small"
                            name="map_location"
                            validation="required|location"
                            :validation-rules="{
                                location: validateMap
                            }"
                            :validation-messages="{
                                location: mapMessageTextError
                            }"
                            :errors="[$page.props.errors.address,$page.props.errors.location_data]"
                            :fit-bounds="'polygons'"
                            :fit-bounds-update="['features']"
                            :color="mapColors.lease"
                            :stroke-color="mapColors.lease"
                            :schema="mapSchema"
                            @input="handleMapUpdate"
                        />
                    </div>
                </div>
            </FormulateForm>
        </div>
    </app-layout>
</template>
<script>
import Alert from 'Utilities/alert/Alert';
import { mapColors } from 'Lib/defaults';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';
import mapMixin from 'Mixins/map.js';

export default {
    name: 'RentalForm',
    components: {
        Alert,
        InputSuffix,
    },
    mixins: [
        mapMixin,
    ],
    props: {
        rental: {
            type: Object,
            default: () => ({}),
        },
        plot: {
            type: Object,
            required: true,
        },
        selectedTab: {
            type: Number,
            default: 1,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        leases: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            form: null,
            zoom: 6,
            statusOptions: [],
            mapColors: mapColors,
            mapMessageTextError: 'Please use the map above to plot your land parcel.',
        };
    },
    computed: {
        projects() {
            const projects = [];

            this.$page.props.projects.forEach((project) => {
                projects.push({
                    value: project.id.toString(),
                    label: project.name + ' (' + project.companyName + ')',
                });
            });

            return projects;
        },
        pageTitle() {
            return this.edit ? 'Edit Agreement' : 'Create Agreement';
        },
        getPaths() {
            return this.parsePaths(this.rental.location_data);
        },
        mapSchema() {
            const schema = [];
            const plot = this.plot;

            if (plot) {
                const plotSchema = this.plotToSchema(plot, {
                    allowCopy: true,
                    markerZoom: null,
                }, false);

                schema.push(plotSchema);

                if (plot.country_code === 'GB') {
                    if (this.$page.props.loggedUser.isSuperUser && plot.national_character_areas) {
                        const vectorData = this.mapServiceProperties['national_character_areas'];

                        const filter = [{
                            property: vectorData.properties.name,
                            compare: '=',
                            type: 'OR',
                            value: plot.national_character_areas.map(nca => nca.name),
                        }];

                        const push = this.vectorToSchema({
                            name: 'national_character_areas',
                            filter: filter,
                            color: 'national_character_areas',
                            strokeColor: 'national_character_areas',
                        });

                        if (push) schema.push(push);
                    }

                    if (plot.local_planning_authority) {
                        const name = plot.local_planning_authority.name;
                        const vectorData = this.mapServiceProperties['lpa'];

                        const filter = [{
                            property: vectorData.properties.name,
                            compare: '=',
                            type: 'OR',
                            value: [name.replace(' LPA', ''), name, `${name} LPA`],
                        }];

                        const push = this.vectorToSchema({
                            name: 'lpa',
                            filter: filter,
                            color: 'lpa',
                            strokeColor: 'lpa',
                        });

                        if (push) schema.push(push);
                    }

                    if (plot.river_basin_district) {
                        const name = plot.river_basin_district.name;
                        const vectorData = this.mapServiceProperties['surface_water_operational_catchments'];

                        const filter = [{
                            property: vectorData.properties.name,
                            compare: '=',
                            type: 'OR',
                            value: name,
                        }];

                        const push = this.vectorToSchema({
                            name: 'surface_water_operational_catchments',
                            filter: filter,
                            color: 'river_basin_districts',
                            strokeColor: 'river_basin_districts',
                        });

                        if (push) schema.push(push);
                    }
                }
            }

            return schema;
        },
    },
    created() {
        const mapLocation = {
            size: this.plot.size,
        };

        if (this.rental.location_data) {
            const paths = this.getPaths;

            if (
                ((this.rental.location_data.layer && this.rental.location_data.layer.center) || this.rental.location_data.center) &&
                ((this.rental.location_data.layer && this.rental.location_data.layer.radius) || this.rental.location_data.radius)
            ) {
                mapLocation.center = this.rental.location_data.layer ? this.rental.location_data.layer.center : this.rental.location_data.center;
                mapLocation.radius = this.rental.location_data.layer ? this.rental.location_data.layer.radius : this.rental.location_data.radius;
            } else if (paths) {
                mapLocation.paths = paths;
            }
        } else {
            mapLocation.paths = [];
        }

        this.form = this.$inertia.form({
            units: this.rental.units,
            per_unit_price: this.rental.per_unit_price,
            project_id: this.edit ? this.rental.project_id.toString() : null,
            size: this.rental.size,
            plot_id: this.plot.id,
            status: this.rental.status,
            rental_startdate: this.rental.startdate,
            location_data: [],
            map_location: mapLocation,
            audit_date: this.rental.audit_date,
        });
        this.statusOptions = ['Pending', 'Contract Issued', 'Contract Signed', 'Agreement Started'];
    },
    methods: {
        submitFormButton() {
            this.$refs.form.formSubmitted();
        },
        validateMap(value) {
            if (value.value && ((value.value.paths && value.value.paths.length) || (value.value.center && value.value.radius))) {
                return true;
            }

            return false;
        },
        handleMapUpdate() {
            const {
                size,
            } = this.form.map_location;

            if (this.$refs.form) this.$refs.form.setFieldValue('size', size);
        },
        submitForm() {
            const data = {...this.form};

            if (data.project_id) {
                data.project_id = parseInt(data.project_id);
            }

            const {
                paths,
                center,
                radius,
                size,
            } = data.map_location;

            data.size = size;

            if (center && radius) {
                data.location_data = {
                    center,
                    radius,
                };
            } else {
                data.location_data = paths;
            }

            delete data.map_location;

            if (this.edit) {
                data.put(this.$route('rental.update', this.rental.id));
            } else {
                data.post(this.$route('rental.store'));
            }
        },
    },
};
</script>
