<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <vSelect
            v-model="context.model"
            :options="options"
            :class="`formulate-input-element formulate-input-element--${context.type} formulate-input-element--${context.type}-${multiple ? 'multi-select' : 'single-select'} ${multiple ? 'multi-select' : 'single-select'}`"
            v-bind="context.attributes"
            :reduce="x => x[reduceKey]"
            :label="labelKey"
            autocomplete="nope"
            append-to-body
            :multiple="multiple"
            @option:selected="selectChange"
        />
        <FormulateSlot
            name="suffix"
            :context="context"
        >
            <component
                :is="context.slotComponents.suffix"
                v-if="context.slotComponents.suffix"
                :context="context"
            />
        </FormulateSlot>
    </div>
</template>

<script>
import vSelect from 'vue-select';
export default {
    components: {
        vSelect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
        reduceKey: {
            type: String,
            default: 'value',
        },
        labelKey: {
            type: String,
            default: 'label',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        options() {
            const options = this.context.options.map((option) => {
                option.id = `${this.context.id}_${option[this.reduceKey] || option[this.labelKey]}`;

                return option;
            });

            return options;
        },
    },

    methods: {
        selectChange() {
            this.$emit('change');
        },
    },
};
</script>
