<template>
    <app-layout section="plot.metric">
        <page-header
            :title="pageTitle"
            icon="plots"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="cancelRoute"
            >
                Cancel
            </inertia-link>
        </page-header>

        <bng-metric-form
            :model="plot"
            model-type="plot"
            :disabled="disabled"
            :desired="false"
        />
    </app-layout>
</template>

<script>
import BngMetricForm from 'Components/Metrics/BngMetricForm.vue';

export default {
    name: 'PlotFormMetric',

    components: {
        BngMetricForm,
    },

    props: {
        plot: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        pageTitle() {
            return 'BNG Metrics';
        },
        cancelRoute() {
            return this.$route('plot.show', this.plot.id);
        },
    },
};
</script>
