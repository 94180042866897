var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vSelect",
        _vm._b(
          {
            class: `formulate-input-element formulate-input-element--${
              _vm.context.type
            } formulate-input-element--${_vm.context.type}-${
              _vm.multiple ? "multi-select" : "single-select"
            } ${_vm.multiple ? "multi-select" : "single-select"}`,
            attrs: {
              options: _vm.options,
              reduce: (x) => x[_vm.reduceKey],
              label: _vm.labelKey,
              autocomplete: "nope",
              "append-to-body": "",
              multiple: _vm.multiple,
            },
            on: { "option:selected": _vm.selectChange },
            model: {
              value: _vm.context.model,
              callback: function ($$v) {
                _vm.$set(_vm.context, "model", $$v)
              },
              expression: "context.model",
            },
          },
          "vSelect",
          _vm.context.attributes,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "FormulateSlot",
        { attrs: { name: "suffix", context: _vm.context } },
        [
          _vm.context.slotComponents.suffix
            ? _c(_vm.context.slotComponents.suffix, {
                tag: "component",
                attrs: { context: _vm.context },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }