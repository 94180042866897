<template>
    <div>
        <FormulateForm
            ref="form"
            v-model="form"
            class="formulate-form--habitat overflow-hidden relative"
        >
            <FormulateInput
                ref="map"
                key="map"
                name="habitats"
                type="bngMap"
                :upload="true"
                @sidebarToggleChecked="sidebarToggleChecked"
                @submit-start="submitStart"
                @submit="submitForm"
            >
                <mapping-tool-schema
                    :schema="toSchema"
                />
            </FormulateInput>
        </FormulateForm>
    </div>
</template>


<script>
import mapMixin from 'Mixins/map';
import MetricMixin from 'Mixins/metric';

export default {
    name: 'HabitatFormComponent',
    mixins: [
        mapMixin,
        MetricMixin,
    ],
    props: {
        habitats: {
            type: Array,
            default: () => ([]),
        },
        saveId: {
            type: Number,
            required: true,
        },
        saveType: {
            type: String,
            required: true,
        },
        queryRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            data: [],
            filters: {},
            route: this.queryRoute ?? route('habitat.create'),
            form: {
                related_id: this.saveId,
                related_type: this.saveType,
                habitats: this.habitats && this.habitats.length ?
                    this.habitats.map(habitat => ({...habitat.associativeColumn, id: habitat.id}))
                    : [{
                        category: 'habitat',
                        photo: null,
                        location_data: [],
                    }],
            },
            version: 4.01,
        };
    },
    computed: {
        toSchema() {
            const plotsSchema = this.data.filter(data => data['data-type'] === 'plots').map(data => this.plotToSchema(data, {visible: this.filters.plots === true})).filter(data => data);
            const projectsSchema = this.data.filter(data => data['data-type'] === 'projects').map(data => this.projectToSchema(data, {visible: this.filters.projects === true})).filter(data => data);
            const otherLayersSchema = this.data.filter(data => data['data-type'] === 'vector').map(data => this.vectorToSchema({...data, visible: this.filters[data.name] === true})).filter(data => data);

            if (this.operatingIn) {
                const lpaLayer = this.data.filter(data => data['data-type'] === 'vector' && data.name === 'lpa');

                if (lpaLayer.length) {
                    const filter = this.operatingIn.map(lpa => {
                        return {
                            property: this.mapServiceProperties['lpa'].name,
                            compare: '=',
                            value: lpa.name.replace(' LPA', ''),
                        };
                    });

                    const feature = this.vectorToSchema({...lpaLayer[0], filter});

                    feature.properties = {
                        ...feature.properties,
                        visible: true,
                        sidebar: false,
                    };

                    otherLayersSchema.push(feature);
                }
            }

            const layers = {
                'BNG Partnership Layers': this.layerToSchema('BNG Partnership Layers', [], {visible: false}),
            };

            this.data.filter(data => data.section).forEach(data => {
                if (!layers[data.section]) layers[data.section] = this.layerToSchema(data.section, [], {visible: false});
            });

            layers['BNG Partnership Layers'].features.push(this.layerToSchema('Plots', plotsSchema, {visible: this.filters.plots === true}));
            layers['BNG Partnership Layers'].features.push(this.layerToSchema('Projects', projectsSchema, {visible: this.filters.projects === true}));
            layers['BNG Partnership Layers'].features = layers['BNG Partnership Layers'].features.concat(otherLayersSchema);

            return Object.keys(layers).sort().map(key => layers[key]);
        },
    },
    async mounted() {
        await this.getOtherLayers(this.route);
    },
    methods: {
        sidebarToggleChecked(feature) {
            feature.map.allLayers.forEach(data => {
                this.filters[data.name.toLowerCase().replace(/\s/g, '-')] = data.data.visible;
            });

            this.refreshPlots(this.route);
            this.refreshProjects(this.route);
        },

        submitStart(data) {
            this.$emit('submit-start', data);
        },

        submitComplete(data) {
            this.$emit('submit-complete', data);
        },

        submitError(data) {
            this.$emit('submit-error', data);
        },

        submitFormButton() {
            this.$refs.map.$children.forEach(child => {
                if (child.$refs && child.$refs.calculator) {
                    child.$refs.calculator.$refs.form.formSubmitted();
                }
            });
        },

        async submitForm(saveData) {
            const data = {...this.form};

            data.habitats = this.filterMetricOutput(data.habitats.map((data, index) => ({...data, photo: saveData.habitats[index].photo})));
            data.related_id = this.saveId;
            data.related_type = this.saveType;

            data.habitats = JSON.stringify(data.habitats);

            this.$inertia.put(this.$route('habitat.bulk'), data, {
                onSuccess: () => {
                    this.submitComplete(data);
                    window.onbeforeunload = null;
                },
                onError: (error) => {
                    this.submitError(error);
                },
            });
        },
    },
};

</script>
