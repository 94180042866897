var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "projects" } },
    [
      _c(
        "page-header",
        {
          attrs: {
            title: "Development Details",
            icon: "files",
            back: { url: _vm.$route("projects"), text: "All Developments" },
          },
          scopedSlots: _vm._u([
            {
              key: "page-header-bottom",
              fn: function () {
                return [
                  _c("tabs", {
                    attrs: { tabs: _vm.tabs },
                    model: {
                      value: _vm.selectedTab,
                      callback: function ($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.selectedTab === "information"
            ? _c(
                "div",
                { staticClass: "flex-center" },
                [
                  _vm.deletable
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-reset btn btn--red--secondary",
                          on: { click: _vm.deleteProject },
                        },
                        [
                          _c("SvgController", { attrs: { type: "trashcan" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Delete Development")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "inertia-link",
                    {
                      staticClass: "btn-reset btn btn--primary",
                      class: { disabled: !_vm.editable },
                      attrs: {
                        href: _vm.editable
                          ? _vm.$route("project.edit", _vm.project.id)
                          : "#",
                      },
                    },
                    [_vm._v("\n                Edit Development\n            ")]
                  ),
                ],
                1
              )
            : _vm.selectedTab === "metrics"
            ? _c(
                "div",
                { staticClass: "flex-center" },
                [
                  _vm.hasGroup(["sequest", "lpa", "agent", "landowner"]) &&
                  _vm.hasPermission("project_update")
                    ? _c(
                        "inertia-link",
                        {
                          staticClass: "btn-reset btn btn--primary",
                          attrs: {
                            href: _vm.$route(
                              "project.metric",
                              _vm.$page.props.project.id
                            ),
                          },
                        },
                        [_vm._v("\n                Edit Metrics\n            ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _vm.selectedTab === "information"
          ? _c("div", { staticClass: "details" }, [
              _c("h5", [_vm._v("Development Name")]),
              _vm._v(" "),
              _c("div", { staticClass: "details-header" }, [
                _c("h3", [_vm._v(_vm._s(_vm.project.name))]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "status-pill", class: _vm.statusFormat },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.project.status) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-flex--lap" }, [
                _c("div", [
                  _c("div", { staticClass: "details-block details-bg" }, [
                    _c("div", { staticClass: "formulate-heading" }, [
                      _c("h6", [_vm._v("Development Details")]),
                    ]),
                    _vm._v(" "),
                    _vm.project.unique_reference_number
                      ? _c("div", [
                          _c("h5", [_vm._v("Unique Reference Number")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.referenceNumber))]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.project.offset_type
                      ? _c("div", [
                          _c("h5", [_vm._v("Offset Type")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm._f("capitalize")(_vm.offsetTypeString))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$page.props.loggedUser.isSuperUser &&
                    (_vm.nationalCharacterAreas ||
                      (!_vm.nationalCharacterAreas &&
                        _vm.project.country_code === "GB"))
                      ? _c(
                          "div",
                          [
                            _c("h5", [_vm._v("National Character Areas")]),
                            _vm._v(" "),
                            _vm.nationalCharacterAreas
                              ? _c("p", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.nationalCharacterAreas) +
                                      "\n                            "
                                  ),
                                ])
                              : _c("Processing", [
                                  _vm._v(
                                    "\n                                Processing\n                            "
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.offsetType.includes("bng")
                    ? _c("div", { staticClass: "details-block details-bg" }, [
                        _c("div", { staticClass: "formulate-heading" }, [
                          _c("h6", [_vm._v("BNG Details")]),
                        ]),
                        _vm._v(" "),
                        _vm.project.local_planning_authority
                          ? _c("div", [
                              _c("h5", [_vm._v("Planning Authority")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.project.local_planning_authority.name
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-flex--tab" }, [
                          _c("div", [
                            _c("h5", [_vm._v("Size")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.floatOutput(_vm.project.size)) +
                                  " " +
                                  _vm._s(_vm.project.size_units)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("h5", [_vm._v("Development Type")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("capitalize")(
                                    _vm.project.development_type
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-flex--tab" }, [
                          _c("div", [
                            _c("h5", [_vm._v("Planning Reference Number")]),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.project.planning_ref))]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("h5", [_vm._v("Planning Status")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("capitalize")(
                                    _vm.project.planning_status
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-flex--tab" }, [
                          _vm.project.land_status
                            ? _c("div", [
                                _c("h5", [_vm._v("Land Status")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm.project.land_status
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.unit_price
                            ? _c("div", [
                                _c("h5", [_vm._v("Price Per Offset Unit")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(_vm._s(_vm.project.unit_price)),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "formulate-heading" }, [
                          _c("h6", [_vm._v("BNG Units")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-flex--tab" }, [
                          _vm.project.onsite_baseline_total_units
                            ? _c("div", [
                                _c("h5", [_vm._v("Total Baseline Units")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.floatOutput(
                                        _vm.project.onsite_baseline_total_units
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.onsite_post_intervention_total
                            ? _c("div", [
                                _c("h5", [
                                  _vm._v("Total Post Intervention Units"),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.floatOutput(
                                        _vm.project
                                          .onsite_post_intervention_total
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.onsite_desired_percentage
                            ? _c("div", [
                                _c("h5", [
                                  _vm._v("Desired Net Gain Percentage"),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.project.onsite_desired_percentage
                                    ) + "%"
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-flex--tab" }, [
                          _vm.project.desired_total
                            ? _c("div", [
                                _c("h5", [_vm._v("Desired Offsite Units")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.floatOutput(_vm.project.desired_total)
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.offsetType.includes("nutrient_offset")
                    ? _c("div", { staticClass: "details-block details-bg" }, [
                        _c("div", { staticClass: "formulate-heading" }, [
                          _c("h6", [_vm._v("Nutrient Offset Details")]),
                        ]),
                        _vm._v(" "),
                        _vm.project.river_basin_district
                          ? _c("div", [
                              _c("h5", [_vm._v("River basin district")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.project.river_basin_district.name)
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.project.nutrient_development_type
                          ? _c("div", [
                              _c("h5", [_vm._v("Type of Development")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.project.nutrient_development_type)
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.project.nutrient_development_amount
                          ? _c("div", [
                              _c("h5", [_vm._v(_vm._s(_vm.typeNumberAmount))]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.project.nutrient_development_amount
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.project.nutrient_planning_status
                          ? _c("div", [
                              _c("h5", [_vm._v("Planning Status")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.project.nutrient_planning_status)
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.project.nutrient_type &&
                        _vm.project.nutrient_type.length
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.project.nutrient_type,
                                function (type) {
                                  return _c("div", { key: type.id }, [
                                    _c("h5", [_vm._v(_vm._s(type.name))]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-flex--tab" },
                                      [
                                        type.pre
                                          ? _c("p", [
                                              _vm._v(
                                                "\n                                        Pre: " +
                                                  _vm._s(type.pre) +
                                                  "\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        type.post
                                          ? _c("p", [
                                              _vm._v(
                                                "\n                                        Post: " +
                                                  _vm._s(type.post) +
                                                  "\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.project.nutrient_study === 0 ||
                        _vm.project.nutrient_study === 1
                          ? _c("div", [
                              _c("h5", [
                                _vm._v(
                                  "Have you calculated nutrients associated with development?"
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.project.nutrient_study === 0
                                ? _c("p", [
                                    _vm._v(
                                      "\n                                No\n                            "
                                    ),
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("Files", {
                                        attrs: {
                                          files: _vm.nutrientCalculator,
                                          filetype: "document",
                                          deletable: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "details-block flex flex-wrap align-content-start",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-full order-2 lap-order-0 mb-3" },
                      [
                        _c("div", { staticClass: "formulate-heading" }, [
                          _c("h6", [_vm._v("Baseline Study")]),
                        ]),
                        _vm._v(" "),
                        _vm.project.study
                          ? _c("p", [
                              _vm._v(
                                "\n                            Baseline study has been completed.\n                        "
                              ),
                            ])
                          : !_vm.project.study &&
                            _vm.project.study_requested &&
                            !_vm.project.contacted
                          ? _c(
                              "p",
                              { staticClass: "alert-box alert-box-info" },
                              [
                                _vm._v(
                                  "\n                            You have requested a baseline survey, we will be in touch to organise a date.\n                        "
                                ),
                              ]
                            )
                          : !_vm.project.study &&
                            _vm.project.study_requested &&
                            _vm.project.contacted
                          ? _c("p", [
                              _vm._v(
                                "\n                            We have contacted you in regards to a baseline study.\n                        "
                              ),
                            ])
                          : _c("p", [
                              _vm._v(
                                "\n                            Baseline study has not been completed nor requested.\n                        "
                              ),
                            ]),
                        _vm._v(" "),
                        _vm.hasGroup(["sequest"]) &&
                        !_vm.project.study &&
                        _vm.project.study_requested
                          ? _c(
                              "a",
                              {
                                staticClass: "btn-reset btn btn--primary",
                                attrs: {
                                  href: "mailto:" + _vm.project.contact_email,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Email\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasGroup(["sequest"]) &&
                        !_vm.project.study &&
                        _vm.project.study_requested &&
                        !_vm.project.contacted
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-reset btn btn--primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.projectContacted()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Mark as Contacted\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w-full order-1 lap-order-1 mb-3" },
                      [
                        _c("div", { staticClass: "formulate-heading" }, [
                          _c("h6", [_vm._v("Location")]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "details-location" },
                          [
                            _c("SvgController", {
                              attrs: { type: "map-icon" },
                            }),
                            _vm._v(
                              _vm._s(_vm.project.address) +
                                "\n                        "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "details-map" },
                          [
                            _c(
                              "mapping-tool",
                              {
                                attrs: {
                                  zoom: 16,
                                  "fit-bounds": ["polygons", "markers"],
                                },
                              },
                              [
                                _c("mapping-tool-schema", {
                                  attrs: { schema: _vm.toSchema },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w-full order-0 lap-order-2" },
                      [
                        _c("file-label", {
                          attrs: {
                            id: "attachments",
                            label: "Documents",
                            text: _vm.upload ? "Upload a document" : "",
                            icon: _vm.upload ? "upload" : "",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openFileModal.apply(null, arguments)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("Files", {
                          attrs: {
                            files: _vm.attachments,
                            filetype: "document",
                            deletable: _vm.upload ? true : false,
                            "item-type": "development",
                          },
                        }),
                        _vm._v(" "),
                        _vm.metrics
                          ? [
                              _c("file-label", {
                                attrs: {
                                  id: "metrics",
                                  label: "Metrics",
                                  disabled: true,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openFileModal.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("Files", {
                                attrs: {
                                  files: _vm.metrics,
                                  filetype: "metric",
                                  deletable: false,
                                  "item-type": "development",
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("modal", {
                          ref: "fileModal",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [_c("h3", [_vm._v("Add Document")])]
                                },
                                proxy: true,
                              },
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _vm.upload
                                      ? _c("add-files", {
                                          attrs: {
                                            parent: {
                                              id: _vm.$page.props.project.id,
                                              type: "project",
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3841264903
                          ),
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm.selectedTab === "metrics" && _vm.hasBng
          ? _c(
              "div",
              [
                _c("metrics", {
                  ref: "metrics",
                  attrs: {
                    project: _vm.project,
                    baseline: _vm.onsiteBaseline,
                    creation: _vm.onsiteCreation,
                    enhancement: _vm.onsiteEnhancement,
                    "is-selected": _vm.selectedTab === "metrics",
                  },
                  on: {
                    "submit-start": function ($event) {
                      _vm.uploading = true
                    },
                    "submit-complete": function ($event) {
                      _vm.uploading = false
                    },
                  },
                  model: {
                    value: _vm.editingMetrics,
                    callback: function ($$v) {
                      _vm.editingMetrics = $$v
                    },
                    expression: "editingMetrics",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.selectedTab === "contracts" && _vm.showContractsTab
        ? _c(
            "div",
            [
              _c("contracts", {
                attrs: {
                  contracts: _vm.contracts,
                  parent: {
                    id: _vm.$page.props.project.id,
                    name: _vm.$page.props.project.name,
                    type: "project",
                  },
                },
              }),
            ],
            1
          )
        : _vm.selectedTab === "report"
        ? _c(
            "div",
            [_c("project-report", { attrs: { project: _vm.project } })],
            1
          )
        : _vm.selectedTab === "agreements"
        ? _c("div", [_c("rentals")], 1)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }