<!-- eslint-disable vue/no-v-html -->
<template>
    <modal
        ref="loadingModal"
        class="modal-confirm"
        :close-mask-click="false"
    >
        <template #header>
            <h3>{{ title }}</h3>
        </template>
        <template #body>
            <div
                class="flex-col justify-center items-center"
            >
                <loading-progress
                    :progress="progress / 100"
                    size="64"
                    rotate
                    fill-duration="2"
                    rotation-duration="1"
                />
                <h4 v-if="title">
                    {{ title }}
                </h4>
                <p v-if="message">
                    {{ message }}
                </p>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import { events } from './events';

export default {
    name: 'LoadingModel',
    components: {
        Modal,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        progress: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        events.$on('open', this.show);
        events.$on('close', this.close);
    },
    methods: {
        show() {
            if (this.$refs.loadingModal) {
                this.$refs.loadingModal.openModal();
            }
        },
        _confirm() {
            if (!this.opts.callback) {
                if (this.$refs.loadingModal) {
                    this.$refs.loadingModal.closeModal();
                }

                this.resolvePromise(true);
            } else {
                this.close(true);
            }
        },
        _cancel() {
            if (!this.opts.callback) {
                if (this.$refs.loadingModal) {
                    this.$refs.loadingModal.closeModal();
                }

                this.rejectPromise(new Error('User cancelled the dialog'));
            } else {
                this.close(false);
            }
        },

        close() {
            this.$refs.loadingModal.closeModal();
        },
    },

};
</script>
