<template>
    <div class="form-page-info">
        <h3>{{ welcomeText }}</h3>
        <p>
            {{ descriptionText }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'InviteFormDescription',

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            welcomeText: this.getWelcomeText(),
        };
    },

    computed: {
        typeName() {
            let prefix = 'a ';

            if (this.data.type === 'agent') {
                prefix = 'an ';
            } else if (this.data.type === 'propertyDeveloper') {
                return 'a property developer';
            } else if (this.data.type === 'localPlanningAuthority') {
                return 'an admin';
            }

            return `${prefix} ${this.data.type}`;
        },

        descriptionText() {
            let text = `${ this.data.invited_by_name } has invited you to be ${ this.typeName } on BNG Partnership platform`;

            if (this.data.type === 'agent') {
                text += ` to assist in managing the company ${ this.data.invited_by_company }`;
            } else if (this.data.type === 'localPlanningAuthority') {
                text += ` to manage the local planning authority ${ this.data.invited_by_company }`;
            }

            return `${text}.`;
        },
    },

    watch: {
        '$page.props.loggedUser'() {
            this.welcomeText = this.getWelcomeText();
        },
    },

    methods: {
        getWelcomeText() {
            const loggedUser = this.$page.props.loggedUser;

            if (loggedUser) {
                return `Welcome back ${ loggedUser.first_name } ${ loggedUser.last_name }. You've been invited.`;
            }

            return 'You\'ve been invited';
        },
    },
};
</script>
