var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        {
          ref: "form",
          staticClass: "formulate-form--habitat overflow-hidden relative",
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "FormulateInput",
            {
              key: "map",
              ref: "map",
              attrs: { name: "habitats", type: "bngMap", upload: true },
              on: {
                sidebarToggleChecked: _vm.sidebarToggleChecked,
                "submit-start": _vm.submitStart,
                submit: _vm.submitForm,
              },
            },
            [_c("mapping-tool-schema", { attrs: { schema: _vm.toSchema } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }