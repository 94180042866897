<template>
    <li :class="`gridlayout-card ${onClick ? ' clickable' : ''}`">
        <div class="gridlayout-card-wrap">
            <article @click="handleClick">
                <slot
                    v-if="includes.includes('header')"
                    name="card-header"
                    :item="item"
                >
                    <header
                        class="gridlayout-card-header flex-center"
                    >
                        <template v-if="showMap">
                            <mapping-tool-image
                                :width="400"
                                :height="200"
                            >
                                <mapping-tool-schema
                                    :schema="toSchema"
                                />
                            </mapping-tool-image>
                        </template>
                        <img
                            v-else-if="image"
                            :src="image"
                        >
                        <SvgController
                            v-else
                            type="logo"
                        />
                    </header>
                </slot>
                <section
                    v-if="includes.includes('info')"
                    class="gridlayout-card-info"
                >
                    <slot
                        v-if="title"
                        name="card-title"
                        :item="item"
                    >
                        <h2 class="gridlayout-card-info-title">
                            <v-clamp
                                autoresize
                                :max-lines="2"
                            >
                                {{ title }}
                            </v-clamp>
                        </h2>
                    </slot>
                    <slot
                        v-if="company"
                        name="card-company"
                        :item="item"
                    >
                        <p class="gridlayout-card-info-company">
                            {{ company.name }}
                        </p>
                    </slot>
                    <slot
                        v-if="description"
                        name="card-description"
                        :item="item"
                    >
                        <p class="gridlayout-card-info-description">
                            <v-clamp
                                autoresize
                                :max-lines="2"
                            >
                                {{ description }}
                            </v-clamp>
                        </p>
                    </slot>
                </section>
                <footer
                    v-if="includes.includes('footer')"
                    class="gridlayout-card-footer"
                >
                    <slot
                        name="card-footer"
                        :item="item"
                    >
                        <template
                            v-if="statuses"
                        >
                            <span
                                v-for="statusText in statuses"
                                :key="statusText"
                                class="status-pill"
                                :class="formatStatus(statusText)"
                            >
                                {{ statusText }}
                            </span>
                        </template>
                    </slot>
                </footer>
            </article>
            <DropdownMenu
                v-if="dropdown&&dropdown.length&&includes.includes('dropdown')"
                v-model="show"
            >
                <button class="btn-reset flex-center gridlayout-card-footer-dropdown">
                    <SvgController
                        type="three-dots"
                    />
                </button>
                <div slot="dropdown">
                    <DropdownMenuList :data="dropdownOptions" />
                </div>
            </DropdownMenu>
        </div>
    </li>
</template>

<script>
import VClamp from 'vue-clamp';
import DropdownMenuList from 'Utilities/dropdown/DropdownMenuList';
import { mapColors } from 'Lib/defaults';
import mapMixin from 'Mixins/map.js';

export default {
    name: 'Grid',
    components: {
        VClamp,
        DropdownMenuList,
    },
    mixins: [
        mapMixin,
    ],
    props: {
        includes: {
            type: Array,
            default() {
                return [
                    'header',
                    'info',
                    'footer',
                    'dropdown',
                ];
            },
        },
        index: {
            type: Number,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        company: {
            type: Object,
            default: () => ({}),
        },
        description: {
            type: String,
            default: '',
        },
        status: {
            type: [String, Array],
            default: '',
        },
        latLong: {
            type: Object,
            default: () => ({}),
        },
        mapType: {
            type: String,
            default: 'point',
        },
        path: {
            type: Array,
            default: () => ([]),
        },
        plots: {
            type: Array,
            default: () => ([]),
        },
        image: {
            type: String,
            default: '',
        },
        onClick: {
            type: Function,
            default: null,
        },
        dropdown: {
            type: Array,
            default() {
                return [];
            },
        },
        fullItem: {
            type: Object,
            default: () => ({}),
        },

        showMap: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: null,
        },
        fitBounds: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            show: false,
            item: {
                title: this.title,
                description: this.description,
                company: this.company,
                status: this.status,
                dropdown: this.dropdown,
            },
        };
    },
    computed: {
        statuses() {
            if (typeof this.status === 'string') {
                return [this.status];
            }

            return this.status;
        },
        dropdownOptions() {
            if (this.dropdown) {
                return this.dropdown.map((objItem, index) => {
                    const objPass = {...objItem};

                    if (objPass.show && (Object.prototype.hasOwnProperty.call(this.fullItem, objPass.show) || Object.prototype.hasOwnProperty.call(this.fullItem.permissions, objPass.show))) {
                        if (this.fullItem[objPass.show]) {
                            objPass.show = this.fullItem[objPass.show];
                        } else if(this.fullItem.permissions && this.fullItem.permissions[objPass.show]) {
                            objPass.show = this.fullItem.permissions[objPass.show];
                        } else {
                            objPass.show = false;
                        }
                    }

                    objPass.onClick = (event) => {
                        this.handleDropdownClick(event, index);
                    };

                    return objPass;
                });
            }

            return null;
        },

        toSchema() {
            const schema = [];

            if (this.fullItem.location_data) {
                if (this.type === 'plot') {
                    schema.push(this.plotToSchema(this.fullItem, {}));
                } else if (this.type === 'project') {
                    schema.push(this.projectToSchema(this.fullItem, {}));
                } else {
                    schema.push(this.projectToSchema(this.fullItem, {
                        fillColor: mapColors.default,
                        strokeColor: mapColors.default,
                    }));
                }
            }

            return schema;
        },
    },
    methods: {
        handleClick(event) {
            if (this.onClick) {
                this.onClick(event, this.returnObject(), this.index);
            }
        },
        handleDropdownClick(event, index) {
            if (this.dropdown[index].onClick) {
                this.dropdown[index].onClick(event, this.returnObject(), this.index);
            }
        },
        returnObject() {
            return {
                title: this.title,
                company: this.company,
                description: this.description,
                status: this.status,
                latLong: this.latLong,
                image: this.image,
            };
        },

        formatStatus(statusText) {
            return 'type-' + statusText.replace(/\s/g, '_').toLowerCase();
        },
        mapPointToLayers(project = this.$page.props.project) {
            let layer = false;

            if (project && project.location_data) {
                layer = {location_data : {
                    layer: {
                        shape: 'marker',
                        points: project.location_data,
                    },
                }, fillColor: mapColors.plot};
            }

            return layer;
        },
    },
};
</script>
