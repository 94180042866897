var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "settings" } },
    [
      _c("page-header", { attrs: { title: "Docusign" } }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "container", staticClass: "center" },
        [
          _c("TableGridLayout", {
            attrs: { columns: _vm.columns, rows: _vm.rows, output: ["table"] },
            on: { onRowClick: _vm.onRowClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }