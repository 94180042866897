<template>
    <div :class="`${columnFormat.rtl ? ' text-right' : ''}`">
        <span
            v-if="columnFormat.type==='dropdown' && dropdownOptions.length > 0"
        >
            <DropdownMenu
                v-model="show"
                :position="row.position||columnFormat.position"
            >
                <button class="btn-reset flex-center vgt-button-dropdown">
                    <SvgController
                        type="three-dots"
                    />
                    {{ rowFormat.label }}
                </button>
                <div slot="dropdown">
                    <DropdownMenuList :data="dropdownOptions" />
                </div>
            </DropdownMenu>
        </span>
        <span v-else-if="columnFormat.type==='checkbox'">
            <FormulateInput
                v-model="rowFormat.selected"
                :name="'item_' + fullRow.id"
                type="checkbox"
                value="true"
            />
        </span>
        <slot
            v-else-if="$scopedSlots[`cell(${columnFormat.field})`]"
            :name="`cell(${columnFormat.field})`"
            :row="fullRow"
        />
        <span
            v-else
            :class="[columnFormat.spanClass, {'hasIcon': columnFormat.icon, 'clickable': columnFormat.onClick}]"
            :style="columnStyle"
            @click="(event) => {
                if (columnFormat.onClick) columnFormat.onClick(event, columnFormat, index);
            }"
        >
            <SvgController
                v-if="rowFormat.icon||columnFormat.icon"
                :type="rowFormat.icon||columnFormat.icon"
            />
            {{ rowFormat.label }}
        </span>
    </div>
</template>

<script>
import DropdownMenuList from 'Utilities/dropdown/DropdownMenuList';
export default {
    name: 'TableRow',
    components: {
        DropdownMenuList,
    },
    props: {
        index: {
            type: Number,
            default: null,
        },
        column: {
            type: Object,
            default() {
                return {};
            },
        },
        row: {
            type: [Array, Object, String, Boolean, Number],
            default() {
                return {};
            },
        },
        allRows: {
            type: Object,
            default() {
                return {};
            },
        },
        fullRow: {
            type: Object,
            default: () => ({}),
        },
        initRow: {
            type: Object,
            default: () => ({}),
        },
        rtl: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            show: false,
        };
    },

    computed: {
        rowFormat() {
            let rowFormat = {};

            if (this.row) {
                rowFormat = this.row;
            }

            if (typeof rowFormat === 'string' || typeof rowFormat === 'number') {
                rowFormat = {
                    label: rowFormat.toString(),
                };
            }

            if (this.column.type === 'checkbox') {
                rowFormat = {
                    selected: this.fullRow[this.column.selected],
                };
            }

            if (!rowFormat.label) {
                rowFormat.label = '';
            }

            return rowFormat;
        },
        columnStyle() {
            const style = {};

            if (this.columnFormat.onClick) {
                style.cursor = 'pointer';
                style['user-select'] = 'none';
            }

            if (this.columnFormat.colour) {
                style.color = this.columnFormat.colour;
            }

            return style;
        },
        columnFormat() {
            const columnFormat = {...this.column};

            Object.keys(columnFormat).forEach((strKey) => {
                if (columnFormat[strKey] === null) {
                    columnFormat[strKey] = '';
                }

                if (typeof columnFormat[strKey] === 'string') {
                    Object.keys(this.initRow).forEach(rowKey => {
                        if (typeof this.initRow[rowKey] === 'string' || typeof this.initRow[rowKey] === 'number') {
                            columnFormat[strKey] = columnFormat[strKey].replace('{' + rowKey.replace(/\s/g, '_').toLowerCase() + '}', String(this.initRow[rowKey]).replace(/\s/g, '_').toLowerCase());
                        }
                    });
                }
            });

            return columnFormat;
        },

        dropdownOptions() {
            let dropdownOptions = [];

            if (this.column.type === 'dropdown') {
                if (this.column.options) {
                    this.column.options.forEach((objItem, index) => {
                        const objPass = {...objItem};

                        if (objPass.show && Object.prototype.hasOwnProperty.call(this.row, objPass.show)) {
                            if (this.row[objPass.show]) {
                                objPass.show = this.row[objPass.show];
                            } else {
                                return;
                            }
                        }

                        objPass.onClick = (event) => {
                            this.handleDropdownClick(event, index, 'column');
                        };

                        dropdownOptions.push(objPass);
                    });
                }

                if (this.row[this.column.type]) {
                    dropdownOptions = [];
                    this.row.options.forEach((objItem, index) => {
                        const objPass = {...objItem};

                        if (objPass.show && Object.prototype.hasOwnProperty.call(this.row, objPass.show)) {
                            if (this.row[objPass.show]) {
                                objPass.show = this.row[objPass.show];
                            } else {
                                return;
                            }
                        }

                        objPass.onClick = (event) => {
                            this.handleDropdownClick(event, index, 'row');
                        };

                        dropdownOptions.push(objPass);
                    });
                }
            }

            return dropdownOptions;
        },
    },

    methods: {
        handleDropdownClick(event, index, target) {
            if (target === 'row' && this.initRow.dropdown.options[index].onClick) {
                this.initRow.dropdown.options[index].onClick(event, this.allRows, this.index);
            } else {
                if (this[target].options[index].onClick) {
                    this[target].options[index].onClick(event, this.allRows, this.index);
                }
            }

            this.show = false;
        },
    },
};
</script>
