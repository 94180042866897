<template>
    <FormulateForm
        v-model="form"
        :errors="form.errors"
        class="flex-col"
        :schema="formSchema"
        @submit="submitForm()"
    >
        <div class="formulate-input btn--group">
            <a
                href="#"
                class="btn"
                @click="$emit('closeModal')"
            >
                Cancel
            </a>
            <button
                class="btn--reset btn btn--primary"
                type="submit"
            >
                <SvgController type="tick" />
                Save
            </button>
        </div>
    </FormulateForm>
</template>
<script>

export default {
    name: 'CompanyEdit',
    props: {
        company: {
            type: Object,
            required: true,
        },
        companyType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                name: this.company.name,
                bank_details: this.company.bank_details,
                commission: this.company.commission,
                address: this.company.address,
                company_number: this.company.company_number,
                country_code: this.company.country_code,
            }),
            formSchema: [{
                name: 'name',
                type: 'text',
                label: 'Name',
                required: true,
                validationName: 'Name',
                disabled: this.company.id === 1,
            },{
                name: 'company_number',
                type: 'text',
                label: 'Company Number',
                validationName: 'Company Number',
                disabled: this.company.id === 1,
            },{
                name: 'address',
                type: 'text',
                label: 'Address',
                validationName: 'Address',
            },{
                name: 'bank_details',
                type: 'rich-text',
                label: 'Bank Details',
                validationName: 'Bank Details',
            }],
        };
    },
    created() {
        window.axios.get(this.$route('countries'))
            .then((response) => {
                this.formSchema.push({
                    type: 'select-plugin',
                    name: 'country_code',
                    validation: 'required',
                    label: 'Country',
                    validationName: 'Country',
                    reduceKey: 'code',
                    labelKey: 'name',
                    options: response.data,
                    placeholder: 'Country',
                });
            });

        if (this.hasGroup(['sequest']) && this.companyType === 'agent') {
            this.formSchema.push({
                name: 'commission',
                type: 'text',
                label: 'Commission',
                validation: 'number',
                validationName: 'Commission',
            });
        }
    },
    methods: {
        submitForm() {
            this.form.patch(this.$route('company.update', this.company.id), {
                onSuccess: () => {
                    this.$emit('closeModal');
                },
            });
        },
    },
};
</script>
