var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        {
          ref: "form",
          class: `formulate-form--${_vm.modelType} flex-col`,
          attrs: { errors: _vm.$page.props.errors, "keep-model-data": true },
          on: { submit: _vm.submitForm },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "PagerContent",
            {
              ref: "pager",
              staticClass: "center",
              attrs: {
                errors: _vm.pageErrors,
                formulate: _vm.$refs.form,
                "formulate-errors": _vm.$page.props.errors,
                theme: "bng",
                validation: {
                  onsite_baseline_habitats: () =>
                    _vm.validateCustomHabitats("onsite_baseline_habitats"),
                  onsite_creation_habitats: () =>
                    _vm.validateCustomHabitats("onsite_creation_habitats"),
                  onsite_enhancement_habitats: () =>
                    _vm.validateCustomHabitats("onsite_enhancement_habitats"),
                },
                pagination: {
                  type: "nav",
                },
              },
              on: { change: _vm.pageChange },
              scopedSlots: _vm._u([
                {
                  key: "pagination-prev-button",
                  fn: function () {
                    return [
                      _vm.buttonsAllowed[_vm.currentPage]
                        ? [
                            _c("SvgController", {
                              attrs: { type: "arrow-left" },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Previous")]),
                          ]
                        : _c("Processing", [
                            _vm._v(
                              "\n                    Processing\n                "
                            ),
                          ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "pagination-next-button",
                  fn: function () {
                    return [
                      _vm.buttonsAllowed[_vm.currentPage]
                        ? [
                            _c("span", [_vm._v("Next")]),
                            _vm._v(" "),
                            _c("SvgController", {
                              attrs: { type: "arrow-right" },
                            }),
                          ]
                        : _c("Processing", [
                            _vm._v(
                              "\n                    Processing\n                "
                            ),
                          ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "pagination-next-last",
                  fn: function () {
                    return [
                      _vm.buttonsAllowed[_vm.currentPage]
                        ? _c(
                            "button",
                            {
                              staticClass: "btn-reset btn btn--primary",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitForm.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("SvgController", { attrs: { type: "tick" } }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.buttonText))]),
                            ],
                            1
                          )
                        : _c("Processing", [
                            _vm._v(
                              "\n                    Processing\n                "
                            ),
                          ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.hasMetric && _vm.model.study && !_vm.documentUploaded
                ? _c(
                    "PagerPage",
                    { attrs: { title: "Metric", name: "metric_message" } },
                    [
                      _c("p", [
                        _vm._v(
                          "\n                    It appears that you have already uploaded a metric. Are you interested in uploading a different one?"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    Please be aware that this course of action will lead to any metrics currently uploaded to be deleted.\n                "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "PagerPage",
                {
                  attrs: {
                    title: "On-Site Baseline",
                    name: "onsite_baseline_habitats",
                  },
                },
                [
                  !_vm.model.study
                    ? _c("FormulateInput", {
                        attrs: {
                          type: "toggle-checkbox",
                          name: "study",
                          label:
                            "Has an ecological study been carried out in the last 2 years?",
                        },
                        on: { input: _vm.handleSudyChange },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.documentUploaded && _vm.form.study
                    ? _c("ExcelConvert", {
                        key: "excel_convert_1",
                        attrs: { label: "Upload Biodiversity Metric document" },
                        on: {
                          init: function ($event) {
                            return _vm.$refs.loading.show()
                          },
                          processing: _vm.convertProgressEvent,
                          input: _vm.convertDocument,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.form.study || _vm.documentUploaded
                    ? [
                        _c("BNGCalculator", {
                          key: "baseline_calculator",
                          ref: "onsite_baseline_habitats",
                          attrs: {
                            disabled: _vm.documentUploaded,
                            "max-area": _vm.maxSize,
                            type: "baseline",
                            "form-type": "partial",
                            version: _vm.version,
                            "run-formula": !_vm.documentUploaded,
                          },
                          on: {
                            input: _vm.updateHabitatData,
                            fetching: function ($event) {
                              return _vm.setButtonAllowed(
                                "onsite_baseline_habitats",
                                false
                              )
                            },
                            fetched: function ($event) {
                              return _vm.setButtonAllowed(
                                "onsite_baseline_habitats",
                                true
                              )
                            },
                          },
                          model: {
                            value: _vm.form.onsite_baseline_habitats,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "onsite_baseline_habitats",
                                $$v
                              )
                            },
                            expression: "form.onsite_baseline_habitats",
                          },
                        }),
                        _vm._v(" "),
                        _vm.baselineRemainingSize < 0
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "status-pill type-red mb-5" },
                                [
                                  _vm._v(
                                    "\n                            You have gone over the remaining size. Would you like to use the metric size of " +
                                      _vm._s(
                                        _vm.floatOutput(_vm.form.size_user)
                                      ) +
                                      " hectares as the new area size?\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-flex--tab form-flex--vcenter",
                                },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      name: "metric_size",
                                      type: "toggle-checkbox",
                                      label: "Use Metric Size",
                                      "wrapper-class": ["tab-w-60"],
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "InputSuffix",
                                    {
                                      style: {
                                        flex: "10 0 auto",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "suffix",
                                            fn: function () {
                                              return [
                                                _vm.form.metric_size
                                                  ? _c("FormulateInput", {
                                                      attrs: {
                                                        type: "select",
                                                        name: "size_units",
                                                        validation: "required",
                                                        options: _vm.sizeUnits,
                                                        disabled: _vm.disabled,
                                                      },
                                                    })
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "formulate-input-info",
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.model
                                                                .size_units
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        659192355
                                      ),
                                    },
                                    [
                                      _c("FormulateInput", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.form.metric_size,
                                            expression: "!form.metric_size",
                                          },
                                        ],
                                        attrs: {
                                          type: "fake",
                                          label: "Size",
                                          disabled: true,
                                          "parse-value": () =>
                                            _vm.floatOutput(
                                              _vm.modelHectareSize
                                            ),
                                        },
                                        model: {
                                          value: _vm.modelHectareSize,
                                          callback: function ($$v) {
                                            _vm.modelHectareSize = $$v
                                          },
                                          expression: "modelHectareSize",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("FormulateInput", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.form.metric_size,
                                            expression: "form.metric_size",
                                          },
                                        ],
                                        attrs: {
                                          type: "fake",
                                          name: "size_user",
                                          label: "Size",
                                          disabled: true,
                                          "parse-value": () =>
                                            _vm.parseMetricSize,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-flex--tab form-flex--vcenter" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_baseline_habitat_units",
                                label: `Current habitat ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Current habitat ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_baseline_habitat_units
                                  ),
                              },
                            }),
                            _vm._v(" "),
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_baseline_hedgerow_units",
                                label: `Current hedgerow ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Current hedgerow ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_baseline_hedgerow_units
                                  ),
                              },
                            }),
                            _vm._v(" "),
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_baseline_river_units",
                                label: `Current river ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Current river ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_baseline_river_units
                                  ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            type: "fake",
                            name: "onsite_baseline_total_units",
                            label: `Current ${_vm.totalLabel}`,
                            validation: "required|number:float",
                            "validation-name": `Current ${_vm.totalLabel}`,
                            disabled: _vm.disabled ? true : false,
                            "parse-value": () =>
                              _vm.floatOutput(
                                _vm.form.onsite_baseline_total_units
                              ),
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "PagerPage",
                {
                  attrs: {
                    title: "On-Site Creation",
                    name: "onsite_creation_habitats",
                  },
                },
                [
                  _vm.form.study &&
                  _vm.documentUploaded &&
                  !_vm.form.onsite_creation_habitats.length
                    ? _c("p", [
                        _vm._v(
                          "\n                    We could not find any potential Habitats within the uploaded Metric document. Please return here and reupload the Metric document when the Creation pages are completed.\n                "
                        ),
                      ])
                    : !_vm.form.study || _vm.documentUploaded
                    ? [
                        _c("BNGCalculator", {
                          key: "creation_calculator",
                          ref: "onsite_creation_habitats",
                          attrs: {
                            disabled: _vm.documentUploaded,
                            "max-area": _vm.maxSize,
                            "form-type": "partial",
                            type: "creation",
                            version: _vm.version,
                            "run-formula": !_vm.documentUploaded,
                          },
                          on: {
                            input: _vm.updateHabitatData,
                            fetching: function ($event) {
                              return _vm.setButtonAllowed(
                                "onsite_creation_habitats",
                                false
                              )
                            },
                            fetched: function ($event) {
                              return _vm.setButtonAllowed(
                                "onsite_creation_habitats",
                                true
                              )
                            },
                          },
                          model: {
                            value: _vm.form.onsite_creation_habitats,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "onsite_creation_habitats",
                                $$v
                              )
                            },
                            expression: "form.onsite_creation_habitats",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-flex--tab form-flex--vcenter" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_creation_habitat_units",
                                label: `Potential habitat ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Potential habitat ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_creation_habitat_units
                                  ),
                              },
                            }),
                            _vm._v(" "),
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_creation_hedgerow_units",
                                label: `Potential hedgerow ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Potential hedgerow ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_creation_hedgerow_units
                                  ),
                              },
                            }),
                            _vm._v(" "),
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_creation_river_units",
                                label: `Potential river ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Potential river ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_creation_river_units
                                  ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            type: "fake",
                            name: "onsite_creation_total_units",
                            label: `Potential ${_vm.totalLabel}`,
                            validation: "required|number:float",
                            "validation-name": `Potential ${_vm.totalLabel}`,
                            disabled: _vm.disabled ? true : false,
                            "parse-value": () =>
                              _vm.floatOutput(
                                _vm.form.onsite_creation_total_units
                              ),
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "PagerPage",
                {
                  attrs: {
                    enabled: Boolean(
                      _vm.documentUploaded && _vm.hasMetric && _vm.form.study
                    ),
                    title: "On-Site Enhancement",
                    name: "onsite_enhancement_habitats",
                  },
                },
                [
                  !_vm.form.onsite_enhancement_habitats.length
                    ? _c("p", [
                        _vm._v(
                          "\n                    We could not find any enhancement Habitats within the uploaded Metric document. Please return here and reupload the Metric document when the Enhancement pages are completed.\n                "
                        ),
                      ])
                    : [
                        _c("BNGCalculator", {
                          key: "enhancement_calculator",
                          ref: "onsite_enhancement_habitats",
                          attrs: {
                            disabled: _vm.documentUploaded,
                            "max-area": _vm.maxSize,
                            "form-type": "partial",
                            type: "enhancement",
                            version: _vm.version,
                            "run-formula": !_vm.documentUploaded,
                          },
                          on: {
                            input: _vm.updateHabitatData,
                            fetching: function ($event) {
                              return _vm.setButtonAllowed(
                                "onsite_enhancement_habitats",
                                false
                              )
                            },
                            fetched: function ($event) {
                              return _vm.setButtonAllowed(
                                "onsite_enhancement_habitats",
                                true
                              )
                            },
                          },
                          model: {
                            value: _vm.form.onsite_enhancement_habitats,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "onsite_enhancement_habitats",
                                $$v
                              )
                            },
                            expression: "form.onsite_enhancement_habitats",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-flex--tab form-flex--vcenter" },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_enhancement_habitat_units",
                                label: `Enhancement habitat ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Enhancement habitat ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_enhancement_habitat_units
                                  ),
                              },
                            }),
                            _vm._v(" "),
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_enhancement_hedgerow_units",
                                label: `Enhancement hedgerow ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Enhancement hedgerow ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_enhancement_hedgerow_units
                                  ),
                              },
                            }),
                            _vm._v(" "),
                            _c("FormulateInput", {
                              attrs: {
                                type: "fake",
                                name: "onsite_enhancement_river_units",
                                label: `Enhancement river ${_vm.totalLabel}`,
                                validation: "required|number:float",
                                "validation-name": `Enhancement river ${_vm.totalLabel}`,
                                disabled: _vm.disabled ? true : false,
                                "parse-value": () =>
                                  _vm.floatOutput(
                                    _vm.form.onsite_enhancement_river_units
                                  ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("FormulateInput", {
                          attrs: {
                            type: "fake",
                            name: "onsite_enhancement_total_units",
                            label: `Enhancement ${_vm.totalLabel}`,
                            validation: "required|number:float",
                            "validation-name": `Enhancement ${_vm.totalLabel}`,
                            disabled: _vm.disabled ? true : false,
                            "parse-value": () =>
                              _vm.floatOutput(
                                _vm.form.onsite_enhancement_total_units
                              ),
                          },
                        }),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _vm.desired
                ? _c(
                    "PagerPage",
                    {
                      attrs: {
                        title: "On-Site Desired",
                        name: "onsite_desired",
                      },
                    },
                    [
                      _c("form-desired", {
                        attrs: {
                          baseline: _vm.form.onsite_baseline_habitats,
                          creation: _vm.form.onsite_creation_habitats,
                          enhancement: _vm.form.onsite_enhancement_habitats,
                          "desired-uplift-percent": _vm.desiredUpliftPercent,
                          context: _vm.disabled ? null : _vm.$refs.form,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("loading-model", {
        ref: "loading",
        attrs: { title: "Processing Metric", progress: _vm.overallProgress },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }