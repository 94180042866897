<template>
    <app-layout section="map">
        <page-header
            title="Map"
            icon="map-icon"
        >
            <inertia-link
                class="btn-reset btn btn--primary"
                :href="$route('habitat.create')"
            >
                <span>Edit Habitats</span>
            </inertia-link>
        </page-header>
        <div class="center map-page">
            <mapping-tool
                :center="center"
                :zoom="zoom"
                fit-bounds="all"
                class="map-withsidebar"
                :sidebar="true"
                sidebar-click-action="expand"
                :loading-animation="true"
                @sidebarToggleChecked="sidebarToggleChecked"
            >
                <mapping-tool-schema
                    :schema="schema"
                />
            </mapping-tool>
        </div>
    </app-layout>
</template>

<script>
import mapMixin from 'Mixins/map';
import { addAdditionalFields } from 'Lib/defaults/habitatData';

export default {
    mixins: [
        mapMixin,
    ],
    props: {
        lpas: {
            type: Array,
            default: () => ([]),
        },
        operatingIn: {
            type: Array,
            default: () => ([]),
        },
        rivers: {
            type: Array,
            default: () => ([]),
        },
        nationalCharacterAreas: {
            type: Array,
            default: () => ([]),
        },
        layers: {
            type: Array,
            default: () => ([]),
        },
        projects: {
            type: Object,
            default: () => ({}),
        },
        plots: {
            type: Object,
            default: () => ({}),
        },
        habitats: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            zoom: 6,
            center: {
                lat: 53.428720,
                lng: -1.659736,
            },
            data: [],
            filters: {
                projects: true,
                plots: true,
            },
            schema: [],
            lpaFilter: [],
            riverFilter: [],
            nationalCharacterAreasFilter: [],
        };
    },
    computed: {
        toSchema() {
            const plots = this.data.filter(data => data['data-type'] === 'plots');
            const projects = this.data.filter(data => data['data-type'] === 'projects');

            const plotsSchema = plots.map(data => this.plotToSchema(data, {visible: this.filters.plots === true})).filter(data => data);
            const projectsSchema = projects.map(data => this.projectToSchema(data, {visible: this.filters.projects === true})).filter(data => data);
            const habitatSchema = this.data.filter(data => data['data-type'] === 'habitats').map(data => this.habitatToSchema(data, {visible: this.filters[data.type] === true})).filter(data => data);

            const lpaShow = this.lpaFilter;
            const riverShow = this.riverFilter;
            const nationalCharacterAreaShow = this.nationalCharacterAreasFilter;

            const otherLayersSchema = this.data.filter(data => data['data-type'] === 'vector').sort((item1, item2) => {
                if (item1.label < item2.label) return -1;
                if (item1.label > item2.label) return 1;

                return 0;
            }).map(data => this.vectorToSchema({...data, name: data.label, visible: this.filters[data.name] === true})).filter(data => data);

            const layers = {
                'BNG Partnership Layers': this.layerToSchema('BNG Partnership Layers', [], {visible: false}),
                'Habitat Layers': this.layerToSchema('Habitat Layers', [], {visible: false}),
            };

            this.data.filter(data => data.section).forEach(data => {
                if (!layers[data.section]) layers[data.section] = this.layerToSchema(data.section, [], {visible: false});
            });

            if (lpaShow.length) {
                const lpaLayer = this.data.filter(data => data['data-type'] === 'vector' && data.name === 'lpa');

                if (lpaLayer.length) {
                    const filter = [{
                        property: this.mapServiceProperties['lpa'].properties.name,
                        compare: '=',
                        type: 'OR',
                        value: lpaShow,
                    }];

                    const feature = this.vectorToSchema({
                        ...lpaLayer[0],
                        id: 'lpa-persistant',
                        name: 'LPA',
                        url: 'lpa',
                        filter,
                        visible: true,
                        sidebar: true,
                    });

                    layers['BNG Partnership Layers'].features.push(feature);
                }
            }

            if (riverShow.length) {
                const filter = [{
                    property: this.mapServiceProperties['surface_water_operational_catchments'].properties.name,
                    compare: '=',
                    type: 'OR',
                    value: riverShow,
                }];

                const feature = this.vectorToSchema({
                    id: 'surface_water_operational_catchments-persistant',
                    name: 'Surface Water Operational Catchments',
                    url: 'surface_water_operational_catchments',
                    filter: filter,
                    color: 'river_basin_districts',
                    strokeColor: 'river_basin_districts',
                    sidebar: true,
                    visible: false,
                });

                layers['BNG Partnership Layers'].features.push(feature);
            }

            if (nationalCharacterAreaShow.length) {
                const filter = [{
                    property: this.mapServiceProperties['national_character_areas_england'].properties.name,
                    compare: '=',
                    type: 'OR',
                    value: nationalCharacterAreaShow,
                }];

                const feature = this.vectorToSchema({
                    id: 'national_character_areas_england-persistant',
                    name: 'National Character Areas',
                    url: 'national_character_areas_england',
                    filter: filter,
                    color: 'national_character_areas',
                    strokeColor: 'national_character_areas',
                    sideabr: true,
                    visible: true,
                });

                layers['BNG Partnership Layers'].features.push(feature);
            }

            layers['BNG Partnership Layers'].features.push(this.layerToSchema('Land Parcels', plotsSchema, {id: 'plots', visible: this.filters.plots === true}));
            layers['BNG Partnership Layers'].features.push(this.layerToSchema('Developments', projectsSchema, {id: 'projects', visible: this.filters.projects === true}));
            layers['Habitat Layers'].features = layers['Habitat Layers'].features.concat(this.habitatLayerToSchema(this.orderedHabitatData, habitatSchema, {visible:false}));

            otherLayersSchema.forEach(feature => {
                layers[feature.properties.section].features.push(feature);
            });

            return [this.clusterToSchema(Object.keys(layers).sort().map(key => layers[key]))];
        },
    },

    async mounted() {

        this.colors = this.mapColors;
        this.orderedHabitatData = addAdditionalFields(this.additionalFields);

        await this.getOtherLayers(this.$route('map'));
        await this.refreshProjects(this.$route('map'));
        await this.refreshPlots(this.$route('map'));

        this.combineData();
        this.nationalCharacterAreasFilter = this.nationalCharacterAreaList();
        this.lpaFilter = this.lpaList();
        this.riverFilter = this.riverList();
        this.schema = this.toSchema;
    },

    methods: {
        async sidebarToggleChecked(feature) {
            const runHabitats = feature.name === 'Habitat Layers' || feature.descendantOf('Habitat Layers', 'name');

            feature.map.allLayers.forEach(data => {
                this.$set(this.filters, data.id.toLowerCase().replace(/\s/g, '-'), data.data.visible);
            });

            if (runHabitats) {
                await this.refreshHabitats(this.$route('map'));
            }

            this.lpaFilter = this.lpaList();
            this.riverFilter = this.riverList();
            this.nationalCharacterAreasFilter = this.nationalCharacterAreaList();
            this.schema = this.toSchema;
        },

        nationalCharacterAreaList() {
            let nationalCharacterAreaShow = [];
            const plots = this.data.filter(data => data['data-type'] === 'plots');
            const projects = this.data.filter(data => data['data-type'] === 'projects');

            if (plots && this.filters.plots) {
                plots.map(plot => {
                    nationalCharacterAreaShow = nationalCharacterAreaShow.concat(
                        plot.national_character_areas.map(area => area.name),
                    );
                });
            }

            if (projects && this.filters.projects) {
                projects.map(project => {
                    nationalCharacterAreaShow = nationalCharacterAreaShow.concat(
                        project.national_character_areas.map(area => area.name),
                    );
                });
            }

            return nationalCharacterAreaShow;
        },

        lpaList() {
            const lpaShow = [];

            if (this.operatingIn) {
                this.operatingIn.forEach(lpa => {
                    lpaShow.push(lpa.name.replace(' LPA', ''), lpa.name, `${lpa.name} LPA`);
                });
            }

            const plots = this.data.filter(data => data['data-type'] === 'plots');
            const projects = this.data.filter(data => data['data-type'] === 'projects');

            if (plots && this.filters.plots) {
                plots.forEach(plot => {
                    const lpa = this.lpas.filter(lpa => plot.planning_authority_id === lpa.id);

                    if (lpa.length) {
                        lpaShow.push(lpa[0].name.replace(' LPA', ''), lpa[0].name, `${lpa[0].name} LPA`);
                    }
                });
            }

            if (projects && this.filters.projects) {
                projects.forEach(project => {
                    const lpa = this.lpas.filter(lpa => project.planning_authority_id === lpa.id);

                    if (lpa.length) {
                        lpaShow.push(lpa[0].name.replace(' LPA', ''), lpa[0].name, `${lpa[0].name} LPA`);
                    }
                });
            }

            return lpaShow;
        },

        riverList() {
            const riverShow = [];

            const plots = this.data.filter(data => data['data-type'] === 'plots');
            const projects = this.data.filter(data => data['data-type'] === 'projects');

            if (plots && this.filters.plots) {
                plots.forEach(plot => {
                    const rivers = this.rivers.filter(river => plot.river_basin_district_id === river.id);

                    if (rivers.length) {
                        riverShow.push(rivers[0].name);
                    }
                });
            }

            if (projects && this.filters.projects) {
                projects.forEach(project => {
                    const rivers = this.rivers.filter(river => project.river_basin_district_id === river.id);

                    if (rivers.length) {
                        riverShow.push(rivers[0].name);
                    }
                });
            }

            return riverShow;
        },
    },
};
</script>

