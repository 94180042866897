<template>
    <div>
        <FormulateForm
            ref="form"
            v-model="form"
            :errors="$page.props.errors"
            :class="`formulate-form--${modelType} flex-col`"
            :keep-model-data="true"
            @submit="submitForm"
        >
            <PagerContent
                ref="pager"
                class="center"
                :errors="pageErrors"
                :formulate="$refs.form"
                :formulate-errors="$page.props.errors"
                theme="bng"
                :validation="{
                    onsite_baseline_habitats: () => validateCustomHabitats('onsite_baseline_habitats'),
                    onsite_creation_habitats: () => validateCustomHabitats('onsite_creation_habitats'),
                    onsite_enhancement_habitats: () => validateCustomHabitats('onsite_enhancement_habitats'),
                }"
                :pagination="{
                    type: 'nav',
                }"
                @change="pageChange"
            >
                <PagerPage
                    v-if="hasMetric && model.study && !documentUploaded"
                    title="Metric"
                    name="metric_message"
                >
                    <p>
                        It appears that you have already uploaded a metric. Are you interested in uploading a different one?<br>
                        Please be aware that this course of action will lead to any metrics currently uploaded to be deleted.
                    </p>
                </PagerPage>
                <PagerPage
                    title="On-Site Baseline"
                    name="onsite_baseline_habitats"
                >
                    <FormulateInput
                        v-if="!model.study"
                        type="toggle-checkbox"
                        name="study"
                        label="Has an ecological study been carried out in the last 2 years?"
                        @input="handleSudyChange"
                    />
                    <ExcelConvert
                        v-if="!documentUploaded && form.study"
                        key="excel_convert_1"
                        label="Upload Biodiversity Metric document"
                        @init="$refs.loading.show()"
                        @processing="convertProgressEvent"
                        @input="convertDocument"
                    />
                    <template
                        v-if="!form.study || documentUploaded"
                    >
                        <BNGCalculator
                            ref="onsite_baseline_habitats"
                            key="baseline_calculator"
                            v-model="form.onsite_baseline_habitats"
                            :disabled="documentUploaded"
                            :max-area="maxSize"
                            type="baseline"
                            form-type="partial"
                            :version="version"
                            :run-formula="!documentUploaded"
                            @input="updateHabitatData"
                            @fetching="setButtonAllowed('onsite_baseline_habitats', false)"
                            @fetched="setButtonAllowed('onsite_baseline_habitats', true)"
                        />
                        <div v-if="baselineRemainingSize < 0">
                            <div class="status-pill type-red mb-5">
                                You have gone over the remaining size. Would you like to use the metric size of {{ floatOutput(form.size_user) }} hectares as the new area size?
                            </div>
                            <div class="form-flex--tab form-flex--vcenter">
                                <FormulateInput
                                    name="metric_size"
                                    type="toggle-checkbox"
                                    label="Use Metric Size"
                                    :wrapper-class="['tab-w-60']"
                                />
                                <InputSuffix
                                    :style="{
                                        flex: '10 0 auto',
                                    }"
                                >
                                    <FormulateInput
                                        v-show="!form.metric_size"
                                        v-model="modelHectareSize"
                                        type="fake"
                                        label="Size"
                                        :disabled="true"
                                        :parse-value="() => floatOutput(modelHectareSize)"
                                    />
                                    <FormulateInput
                                        v-show="form.metric_size"
                                        type="fake"
                                        name="size_user"
                                        label="Size"
                                        :disabled="true"
                                        :parse-value="() => parseMetricSize"
                                    />
                                    <template #suffix>
                                        <FormulateInput
                                            v-if="form.metric_size"
                                            type="select"
                                            name="size_units"
                                            validation="required"
                                            :options="sizeUnits"
                                            :disabled="disabled"
                                        />
                                        <div
                                            v-else
                                            class="formulate-input-info"
                                        >
                                            <p>{{ model.size_units }}</p>
                                        </div>
                                    </template>
                                </InputSuffix>
                            </div>
                        </div>
                        <div
                            class="form-flex--tab form-flex--vcenter"
                        >
                            <FormulateInput
                                type="fake"
                                name="onsite_baseline_habitat_units"
                                :label="`Current habitat ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Current habitat ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_baseline_habitat_units)"
                            />
                            <FormulateInput
                                type="fake"
                                name="onsite_baseline_hedgerow_units"
                                :label="`Current hedgerow ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Current hedgerow ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_baseline_hedgerow_units)"
                            />
                            <FormulateInput
                                type="fake"
                                name="onsite_baseline_river_units"
                                :label="`Current river ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Current river ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_baseline_river_units)"
                            />
                        </div>
                        <FormulateInput
                            type="fake"
                            name="onsite_baseline_total_units"
                            :label="`Current ${totalLabel}`"
                            validation="required|number:float"
                            :validation-name="`Current ${totalLabel}`"
                            :disabled="disabled ? true : false"
                            :parse-value="() => floatOutput(form.onsite_baseline_total_units)"
                        />
                    </template>
                </PagerPage>
                <PagerPage
                    title="On-Site Creation"
                    name="onsite_creation_habitats"
                >
                    <p v-if="form.study && documentUploaded && !form.onsite_creation_habitats.length">
                        We could not find any potential Habitats within the uploaded Metric document. Please return here and reupload the Metric document when the Creation pages are completed.
                    </p>
                    <template
                        v-else-if="(!form.study || documentUploaded)"
                    >
                        <BNGCalculator
                            ref="onsite_creation_habitats"
                            key="creation_calculator"
                            v-model="form.onsite_creation_habitats"
                            :disabled="documentUploaded"
                            :max-area="maxSize"
                            form-type="partial"
                            type="creation"
                            :version="version"
                            :run-formula="!documentUploaded"
                            @input="updateHabitatData"
                            @fetching="setButtonAllowed('onsite_creation_habitats', false)"
                            @fetched="setButtonAllowed('onsite_creation_habitats', true)"
                        />
                        <div
                            class="form-flex--tab form-flex--vcenter"
                        >
                            <FormulateInput
                                type="fake"
                                name="onsite_creation_habitat_units"
                                :label="`Potential habitat ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Potential habitat ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_creation_habitat_units)"
                            />
                            <FormulateInput
                                type="fake"
                                name="onsite_creation_hedgerow_units"
                                :label="`Potential hedgerow ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Potential hedgerow ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_creation_hedgerow_units)"
                            />
                            <FormulateInput
                                type="fake"
                                name="onsite_creation_river_units"
                                :label="`Potential river ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Potential river ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_creation_river_units)"
                            />
                        </div>
                        <FormulateInput
                            type="fake"
                            name="onsite_creation_total_units"
                            :label="`Potential ${totalLabel}`"
                            validation="required|number:float"
                            :validation-name="`Potential ${totalLabel}`"
                            :disabled="disabled ? true : false"
                            :parse-value="() => floatOutput(form.onsite_creation_total_units)"
                        />
                    </template>
                </PagerPage>
                <PagerPage
                    :enabled="Boolean(documentUploaded && hasMetric && form.study)"
                    title="On-Site Enhancement"
                    name="onsite_enhancement_habitats"
                >
                    <p v-if="!form.onsite_enhancement_habitats.length">
                        We could not find any enhancement Habitats within the uploaded Metric document. Please return here and reupload the Metric document when the Enhancement pages are completed.
                    </p>
                    <template
                        v-else
                    >
                        <BNGCalculator
                            ref="onsite_enhancement_habitats"
                            key="enhancement_calculator"
                            v-model="form.onsite_enhancement_habitats"
                            :disabled="documentUploaded"
                            :max-area="maxSize"
                            form-type="partial"
                            type="enhancement"
                            :version="version"
                            :run-formula="!documentUploaded"
                            @input="updateHabitatData"
                            @fetching="setButtonAllowed('onsite_enhancement_habitats', false)"
                            @fetched="setButtonAllowed('onsite_enhancement_habitats', true)"
                        />
                        <div
                            class="form-flex--tab form-flex--vcenter"
                        >
                            <FormulateInput
                                type="fake"
                                name="onsite_enhancement_habitat_units"
                                :label="`Enhancement habitat ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Enhancement habitat ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_enhancement_habitat_units)"
                            />
                            <FormulateInput
                                type="fake"
                                name="onsite_enhancement_hedgerow_units"
                                :label="`Enhancement hedgerow ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Enhancement hedgerow ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_enhancement_hedgerow_units)"
                            />
                            <FormulateInput
                                type="fake"
                                name="onsite_enhancement_river_units"
                                :label="`Enhancement river ${totalLabel}`"
                                validation="required|number:float"
                                :validation-name="`Enhancement river ${totalLabel}`"
                                :disabled="disabled ? true : false"
                                :parse-value="() => floatOutput(form.onsite_enhancement_river_units)"
                            />
                        </div>
                        <FormulateInput
                            type="fake"
                            name="onsite_enhancement_total_units"
                            :label="`Enhancement ${totalLabel}`"
                            validation="required|number:float"
                            :validation-name="`Enhancement ${totalLabel}`"
                            :disabled="disabled ? true : false"
                            :parse-value="() => floatOutput(form.onsite_enhancement_total_units)"
                        />
                    </template>
                </PagerPage>
                <PagerPage
                    v-if="desired"
                    title="On-Site Desired"
                    name="onsite_desired"
                >
                    <form-desired
                        :baseline="form.onsite_baseline_habitats"
                        :creation="form.onsite_creation_habitats"
                        :enhancement="form.onsite_enhancement_habitats"
                        :desired-uplift-percent="desiredUpliftPercent"
                        :context="disabled ? null : $refs.form"
                    />
                </PagerPage>
                <template #pagination-prev-button>
                    <template v-if="buttonsAllowed[currentPage]">
                        <SvgController type="arrow-left" />
                        <span>Previous</span>
                    </template>
                    <Processing v-else>
                        Processing
                    </Processing>
                </template>
                <template #pagination-next-button>
                    <template v-if="buttonsAllowed[currentPage]">
                        <span>Next</span>
                        <SvgController type="arrow-right" />
                    </template>
                    <Processing v-else>
                        Processing
                    </Processing>
                </template>
                <template #pagination-next-last>
                    <button
                        v-if="buttonsAllowed[currentPage]"
                        class="btn-reset btn btn--primary"
                        @click.prevent="submitForm"
                    >
                        <SvgController
                            type="tick"
                        />
                        <span>{{ buttonText }}</span>
                    </button>
                    <Processing v-else>
                        Processing
                    </Processing>
                </template>
            </PagerContent>
        </FormulateForm>
        <loading-model
            ref="loading"
            title="Processing Metric"
            :progress="overallProgress"
        />
    </div>
</template>

<script>

import { PagerContent, PagerPage } from 'vue2-pager';
import ExcelConvert from 'Utilities/file-convert/ExcelConvert';
import ExcelConvertMixin from 'Mixins/excelConvert';
import MetricMixin from 'Mixins/metric';
import BNGCalculator from 'Components/Metrics/Bng';
import Processing from 'Utilities/processing/processing';
import InputSuffix from 'Utilities/vue-formulate/InputSuffix';
import LoadingModel from 'Utilities/modal/Loading';
import FormDesired from 'Components/Metrics/Desired.vue';

export default {
    name: 'FormMetric',
    components: {
        PagerContent,
        PagerPage,
        ExcelConvert,
        BNGCalculator,
        Processing,
        InputSuffix,
        LoadingModel,
        FormDesired,
    },
    mixins: [
        ExcelConvertMixin,
        MetricMixin,
    ],

    props: {
        model: {
            type: Object,
            default: () => ({}),
        },
        modelType: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        desired: {
            type: Boolean,
            default: false,
        },
        desiredUpliftPercent: {
            type: Number,
            default: 10,
        },
    },
    data() {
        return {
            version: 4.01,
            convertedData: {},
            form: this.$inertia.form({
                onsite_baseline_total_units: this.model.onsite_baseline_total_units || null,
                onsite_baseline_habitat_units: this.model.onsite_baseline_habitat_units || null,
                onsite_baseline_hedgerow_units: this.model.onsite_baseline_hedgerow_units || null,
                onsite_baseline_river_units: this.model.onsite_baseline_river_units || null,

                onsite_creation_total_units: this.model.onsite_creation_total_units,
                onsite_creation_habitat_units: this.model.onsite_baseline_habitat_units || null,
                onsite_creation_hedgerow_units: this.model.onsite_baseline_hedgerow_units || null,
                onsite_creation_river_units: this.model.onsite_baseline_river_units || null,

                onsite_enhancement_total_units: this.model.onsite_enhancement_total_units || null,
                onsite_enhancement_habitat_units: this.model.onsite_enhancement_habitat_units || null,
                onsite_enhancement_hedgerow_units: this.model.onsite_enhancement_hedgerow_units || null,
                onsite_enhancement_river_units: this.model.onsite_enhancement_river_units || null,

                onsite_baseline_habitats: [],
                onsite_creation_habitats: [],
                onsite_enhancement_habitats: [],

                metric_size: false,
                size_user: null,
                size_units: this.model.size_units,

                biodiversity_metric_document: null,
                study: this.model.study || false,
            }),
            pageErrors: {},
            hasMetric: this.model.metrics ? this.model.metrics.length > 0 : false,
            documentUploaded: false,
            buttonsAllowed: {
                'metric_message': true,
                'onsite_baseline_habitats': true,
                'onsite_creation_habitats': true,
                'onsite_enhancement_habitats': true,
                'onsite_desired': true,
            },
            maxSize: this.model.size_hectares ? parseFloat(this.model.size_hectares) : null,
            baselineRemainingSize: 0,
            currentPage: 'onsite_baseline_habitats',
            allSizes: {
                'onsite_baseline_habitats': 0,
                'onsite_creation_habitats': 0,
                'onsite_enhancement_habitats': 0,
            },
            allRemaining: {
                'onsite_baseline_habitats': 0,
                'onsite_creation_habitats': 0,
                'onsite_enhancement_habitats': 0,
            },
            processing: false,
            progressConvertingPercent: {
                upload: 0,
                baseline: 0,
                creation: 0,
                enhancement: 0,
            },
            overallProgress: 0,
            progressConvertingFunctions: {
                baseline: (percent) => this.excelProgressEvent(percent, 'baseline'),
                creation: (percent) => this.excelProgressEvent(percent, 'creation'),
                enhancement: (percent) => this.excelProgressEvent(percent, 'enhancement'),
            },
        };
    },

    computed: {
        buttonText() {
            return 'Save Metric';
        },
        totalLabel() {
            if (!this.hasMetric && !this.study) {
                return 'Estimated Units';
            }

            return 'Total Units';
        },

        sizeUnits() {
            return [{
                value: 'm2',
                label: 'M2',
            },{
                value: 'km2',
                label: 'Km2',
            },{
                value: 'acres',
                label: 'Acres',
            },{
                value: 'hectares',
                label: 'Hectares',
            }];
        },

        modelHectareSize() {
            return this.model.size_hectares;
        },

        parseMetricSize() {
            let size = this.form.size_user;

            switch (this.form.size_units) {
                case 'm2':
                    size = this.hectaresToMetersSq(this.float(this.form.size_user));
                    break;

                case 'km2':
                    size = this.hectaresToKmSq(this.float(this.form.size_user));
                    break;

                case 'acres':
                    size = this.hectaresToAcres(this.float(this.form.size_user));
                    break;

                default:
                    break;
            }

            return this.floatOutput(size);
        },
    },

    watch: {
        'model.study'() {
            this.updateHabitatData();
        },

        'form.metric_size'() {
            this.maxSize = this.form.metric_size ? this.form.size_user : this.model.size_hectares;
        },

        'form.size_user'() {
            this.maxSize = this.form.metric_size ? this.form.size_user : this.model.size_hectares;
        },
    },

    created() {
        this.form.onsite_baseline_habitats = (this.model.onsite_baseline_habitats ? this.model.onsite_baseline_habitats : [])
            .map(habitat => habitat.associativeColumn);
        this.form.onsite_creation_habitats = (this.model.onsite_creation_habitats ? this.model.onsite_creation_habitats : [])
            .map(habitat => habitat.associativeColumn);
        this.form.onsite_enhancement_habitats = (this.model.onsite_enhancement_habitats ? this.model.onsite_enhancement_habitats : [])
            .map(habitat => habitat.associativeColumn);
    },

    methods: {
        handleSudyChange() {
            this.buttonsAllowed = {
                'metric_message': true,
                'onsite_baseline_habitats': true,
                'onsite_creation_habitats': true,
                'onsite_enhancement_habitats': true,
                'onsite_desired': true,
            };
        },

        disableLeaveRequest() {
            this.$confirmRequest.disable();
        },

        enableLeaveRequest() {
            this.$confirmRequest.enable();
        },

        reupload() {
            this.form.onsite_baseline_habitats = [];
            this.form.onsite_creation_habitats = [];
            this.form.onsite_enhancement_habitats = [];
            this.documentUploaded = false;
            this.hasMetric = false;
            this.study = true;
            this.form.biodiversity_metric_document = null;
        },

        async convertDocument(workbook, selectedFile) {
            this.pageErrors.onsite_baseline_habitats = '';
            this.pageErrors = {...this.pageErrors};
            this.convertedData.siteBaseline = await this.onSiteBaselineConvert(workbook);
            this.convertedData.siteCreated = await this.onSiteCreatedConvert(workbook);
            this.convertedData.siteEnhancement = await this.onSiteEnhancementConvert(workbook);

            this.form.onsite_baseline_habitats = this.convertedData.siteBaseline.habitats;
            this.form.onsite_creation_habitats = this.convertedData.siteCreated.habitats;
            this.form.onsite_enhancement_habitats = this.convertedData.siteEnhancement.habitats;

            this.version = this.convertedData.siteBaseline.version;
            this.documentUploaded = true;
            this.hasMetric = true;
            this.form.biodiversity_metric_document = selectedFile;
        },

        excelProgressEvent(percent, type) {
            this.$refs.loading.show();
            this.progressConvertingPercent[type] = percent;
            const values = Object.keys(this.progressConvertingPercent).map(key => this.progressConvertingPercent[key]).reduce((partialSum, accumulator) => partialSum + accumulator, 0);

            this.overallProgress = (values / (Object.keys(this.progressConvertingPercent).length * 100)) * 100;

            if (this.overallProgress === 100) {
                this.processing = false;
                setTimeout(() => {
                    this.$refs.loading.close();
                }, 500);
            }
        },

        convertProgressEvent(percent) {
            this.$refs.loading.show();
            this.processing = true;
            this.progressConvertingPercent.upload = percent;

            const values = Object.keys(this.progressConvertingPercent).map(key => this.progressConvertingPercent[key]).reduce((partialSum, accumulator) => partialSum + accumulator, 0);

            this.overallProgress = (values / (Object.keys(this.progressConvertingPercent).length * 100)) * 100;
        },

        updateHabitatData() {
            let onsite_baseline_habitats = 0,
                onsite_creation_habitats = 0,
                onsite_enhancement_habitats = 0,
                onsite_baseline_habitat_units = 0,
                onsite_baseline_hedgerow_units = 0,
                onsite_baseline_river_units = 0,
                onsite_creation_habitat_units = 0,
                onsite_creation_hedgerow_units = 0,
                onsite_creation_river_units = 0,
                onsite_enhancement_habitat_units = 0,
                onsite_enhancement_hedgerow_units = 0,
                onsite_enhancement_river_units = 0;

            if (this.$refs.onsite_baseline_habitats) {
                onsite_baseline_habitat_units = this.$refs.onsite_baseline_habitats.getUnits('habitat');
                onsite_baseline_hedgerow_units = this.$refs.onsite_baseline_habitats.getUnits('hedgerow');
                onsite_baseline_river_units = this.$refs.onsite_baseline_habitats.getUnits('watercourse');
                onsite_baseline_habitats = onsite_baseline_habitat_units + onsite_baseline_hedgerow_units + onsite_baseline_river_units;
            }

            if (this.$refs.onsite_creation_habitats) {
                onsite_creation_habitat_units = this.$refs.onsite_creation_habitats.getUnits('habitat');
                onsite_creation_hedgerow_units = this.$refs.onsite_creation_habitats.getUnits('hedgerow');
                onsite_creation_river_units = this.$refs.onsite_creation_habitats.getUnits('watercourse');
                onsite_creation_habitats = onsite_creation_habitat_units + onsite_creation_hedgerow_units + onsite_creation_river_units;
            }

            if (this.$refs.onsite_enhancement_habitats) {
                onsite_enhancement_habitat_units = this.$refs.onsite_enhancement_habitats.getUnits('habitat');
                onsite_enhancement_hedgerow_units = this.$refs.onsite_enhancement_habitats.getUnits('hedgerow');
                onsite_enhancement_river_units = this.$refs.onsite_enhancement_habitats.getUnits('watercourse');
                onsite_enhancement_habitats = onsite_enhancement_habitat_units + onsite_enhancement_hedgerow_units + onsite_enhancement_river_units;
            }

            this.form.onsite_baseline_total_units = onsite_baseline_habitats;
            this.form.onsite_creation_total_units = onsite_creation_habitats;
            this.form.onsite_enhancement_total_units = onsite_enhancement_habitats;
            this.form.onsite_baseline_habitat_units = onsite_baseline_habitat_units;
            this.form.onsite_baseline_hedgerow_units = onsite_baseline_hedgerow_units;
            this.form.onsite_baseline_river_units = onsite_baseline_river_units;
            this.form.onsite_creation_habitat_units = onsite_creation_habitat_units;
            this.form.onsite_creation_hedgerow_units = onsite_creation_hedgerow_units;
            this.form.onsite_creation_river_units = onsite_creation_river_units;
            this.form.onsite_enhancement_habitat_units = onsite_enhancement_habitat_units;
            this.form.onsite_enhancement_hedgerow_units = onsite_enhancement_hedgerow_units;
            this.form.onsite_enhancement_river_units = onsite_enhancement_river_units;
            this.enableLeaveRequest();
        },

        pageChange() {
            this.currentPage = this.$refs.pager.pageSelected;
        },

        setButtonAllowed(ref, allowed) {
            this.buttonsAllowed[ref] = allowed;

            if (allowed && ref === 'onsite_baseline_habitats') {
                this.baselineRemainingSize = this.$refs[ref].getRemainingSize;
            }

            if (allowed) {
                this.allSizes[ref] = this.$refs[ref].getUsedSize;
                this.allRemaining[ref] = this.$refs[ref].getRemainingSize;
                const valueSize = Object.values(this.allSizes);

                this.form.size_user = Math.max(...valueSize);

                const valueRemaining = Object.values(this.allRemaining);

                this.baselineRemainingSize = Math.min(...valueRemaining);
            }
        },

        validateCustomHabitats(ref) {
            delete this.pageErrors[ref];
            const refElement = this.$refs[ref];
            const errors = {...this.pageErrors};

            if (!this.buttonsAllowed[ref]) {
                return false;
            }

            if (ref === 'onsite_baseline_habitats' && this.form.study && !this.form.biodiversity_metric_document) {
                errors[ref] = 'Please upload a metric document';
                this.pageErrors = errors;

                return false;
            } else if (!refElement) {
                return true;
            }

            const refValue = this.form[ref];
            const sizeRemaining = refElement.getRemainingSize;
            let returnVal = true;

            if (sizeRemaining < 0) {
                errors[ref] = `The values entered exceeds the max area of ${this.floatOutput(this.form.size_user)} Hectares`;

                returnVal = false;
            }

            if (refValue) {
                const issues = refElement.runValidation();

                if (issues.length) {
                    errors[ref] = `Please fill out all values for: ${issues.map((habitat, index) => `Habitat ${index + 1}`).join(', ')}`;

                    returnVal = false;
                }
            }

            this.pageErrors = errors;

            return returnVal;
        },

        async submitForm() {
            this.$refs.pager.submitForm(async ({ validated }) => {
                if (validated) {
                    const data = {...this.form};

                    data.onsite_baseline_habitats = JSON.stringify(this.filterMetricOutput(data.onsite_baseline_habitats));
                    data.onsite_creation_habitats = JSON.stringify(this.filterMetricOutput(data.onsite_creation_habitats));
                    data.onsite_enhancement_habitats = JSON.stringify(this.filterMetricOutput(data.onsite_enhancement_habitats));

                    if (this.form.metric_size) {
                        this.form.size_user = null;
                    }

                    this.$inertia.post(this.$route(`${this.modelType}.metric.update`, this.model.id), {
                        _method: 'put',
                        ...data,
                    });
                    this.disableLeaveRequest();
                }
            });
        },
    },
};
</script>
