var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-layout",
    { attrs: { section: "map" } },
    [
      _c(
        "page-header",
        { attrs: { title: "Map", icon: "map-icon" } },
        [
          _c(
            "inertia-link",
            {
              staticClass: "btn-reset btn btn--primary",
              attrs: { href: _vm.$route("habitat.create") },
            },
            [_c("span", [_vm._v("Edit Habitats")])]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center map-page" },
        [
          _c(
            "mapping-tool",
            {
              staticClass: "map-withsidebar",
              attrs: {
                center: _vm.center,
                zoom: _vm.zoom,
                "fit-bounds": "all",
                sidebar: true,
                "sidebar-click-action": "expand",
                "loading-animation": true,
              },
              on: { sidebarToggleChecked: _vm.sidebarToggleChecked },
            },
            [_c("mapping-tool-schema", { attrs: { schema: _vm.schema } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }