<template>
    <modal
        ref="commentsModal"
        width="50em"
    >
        <template #header>
            <h3>
                Comments
            </h3>
        </template>
        <template #body>
            <CommentList
                class="contract-comments"
                :comments="comments"
                :allow-editing="false"
                :allow-post="true"
                :allow-reply="false"
                :current-user="user"
                button-text="Post"
                @comment="handleComment"
            />
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
import CommentList from 'vue2-comments';

export default {
    name: 'ContractComments',
    components: {
        Modal,
        CommentList,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        comments: {
            type: Array,
            default: () => ([]),
        },
        contract: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            user: this.$page.props.loggedUser,
        };
    },

    watch: {
        show(value) {
            if (value) {
                this.$refs.commentsModal.openModal();
            } else {
                this.$refs.commentsModal.closeModal();
            }
        },
    },
    methods: {
        openModal() {
            this.$refs.commentsModal.openModal();
            setTimeout(() => {
                document.querySelector('.contract-comments').addEventListener('click', this.handleContractClick);
            }, 500);
        },

        closeModal() {
            document.querySelector('.contract-comments').removeEventListener('click', this.handleContractClick);
            this.$refs.commentsModal.closeModal();
        },

        handleComment(content) {
            this.$inertia.post(this.$route('comment.store', this.contract.id), {
                'item_type': 'contract',
                'item_id': this.contract.id,
                'comment': content,
                'return_to_id': this.contract.item_id,
                'return_to_type': this.contract.item_type,
            });
        },

        handleContractClick(event) {
            if (event.target.getAttribute('data-contract-id')) {
                this.$emit('click', event.target.getAttribute('data-contract-id'));
            }
        },
    },
};
</script>
