<template>
    <div class="bng-summary">
        <ul class="bng-summary-lists">
            <li
                v-for="(chart, index) in charts"
                :key="`chart-${index}-${chart.title}`"
            >
                <div class="relative">
                    <GChart
                        :type="chart.type"
                        :data="chart.data"
                        :options="chart.options"
                    />
                    <Alert
                        v-if="!chart.error.success"
                        type="warning"
                        class="absolute top-0 right-0 m-0"
                    >
                        <p>{{ chart.error.message }}</p>
                    </Alert>
                </div>
            </li>
        </ul>
        <div class="form-flex--tab">
            <FormulateInput
                v-model="totals.habitat"
                type="fake"
                label="Onsite habitat total"
                :parse-value="() => floatOutput(totals.habitat)"
            />
            <FormulateInput
                v-model="totals.hedgerow"
                type="fake"
                label="Onsite baseline hedgerow total"
                :parse-value="() => floatOutput(totals.hedgerow)"
            />
            <FormulateInput
                v-model="totals.watercourse"
                type="fake"
                label="Onsite baseline watercourse total"
                :parse-value="() => floatOutput(totals.watercourse)"
            />
        </div>
        <div class="form-flex--tab">
            <FormulateInput
                v-model="totals.total"
                type="fake"
                label="Onsite baseline total"
                :parse-value="() => floatOutput(totals.total)"
            />
        </div>
    </div>
</template>

<script>
import { GChart, loadGoogleCharts } from 'vue-google-charts/legacy';
import MetricMixin from 'Mixins/metric';
import Alert from 'Utilities/alert/Alert';

loadGoogleCharts('current', { packages: ['corechart'] });

export default {
    name: 'BNGSummary',
    components: {
        GChart,
        Alert,
    },

    mixins: [
        MetricMixin,
    ],

    props: {
        baseline: {
            type: Array,
            default: () => ([]),
        },
        creation: {
            type: Array,
            default: () => ([]),
        },
        enhancement: {
            type: Array,
            default: () => ([]),
        },
        includeChart: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            chartData: [{
                id: 'creation-totals',
                title: 'Onsite Totals',
                type: 'PieChart',
                data: 'creation-totals',
                metric: 'creation',
            }, {
                id: 'creation-habitat-types',
                title: 'Onsite habitat types',
                type: 'PieChart',
                data: 'creation-habitat-types',
            }, {
                id: 'onsite-net-change',
                title: 'Onsite habitat net change',
                type: 'PieChart',
                data: 'onsite-net-change',
            }],
            totals: {
                total: 0,
                habitat: 0,
                hedgerow: 0,
                watercourse: 0,
            },
        };
    },

    computed: {
        charts() {
            return this.chartData.filter((chart) => this.includeChart === null || this.includeChart.includes(chart.id)).map(chart => this.getChartData(chart)).filter(chart => chart);
        },
    },

    created() {
        this.totals = {
            total: this.getMetricValueSum(['creation.Habitat units delivered', 'creation.Hedge units delivered', 'creation.Watercourse units delivered']),
            habitat: this.getMetricValueSum('creation.Habitat units delivered', 'habitat'),
            hedgerow: this.getMetricValueSum('creation.Hedge units delivered', 'hedgerow'),
            watercourse: this.getMetricValueSum('creation.Watercourse units delivered', 'watercourse'),
        };
    },
};

</script>
