var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "auth-layout",
    {
      attrs: { component: _vm.$options.name },
      scopedSlots: _vm._u(
        [
          _vm.formAllow.login
            ? {
                key: "button",
                fn: function () {
                  return [
                    _vm.page === "accept"
                      ? [
                          _vm.loggedUser
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn--primary",
                                    attrs: { type: "button" },
                                    on: { click: _vm.onLogout },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Logout\n                "
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _c("span", [
                                  _vm._v("Already have an account?"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn--primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        _vm.page = "login"
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Login\n                "
                                    ),
                                  ]
                                ),
                              ],
                        ]
                      : [
                          _vm.formAllow.user_creation
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn--primary",
                                  attrs: { type: "button" },
                                  on: { click: _vm.backPage },
                                },
                                [
                                  _vm._v(
                                    "\n                Go Back\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      !_vm.page
        ? _c("div", [
            _c("form", [
              _c("div", { staticClass: "form-page" }, [
                _c("p", [_vm._v("Unable to find invite information")]),
              ]),
            ]),
          ])
        : !_vm.formAllow.login && _vm.loggedUser
        ? _c("form", [
            _c("div", { staticClass: "form-page" }, [
              _c("div", { staticClass: "form-page-info" }, [
                _c("p", [
                  _vm._v(
                    "You are unable to accept this invite while logged in. Please Logout to accept this invite."
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary",
                    attrs: { type: "button" },
                    on: { click: _vm.onLogout },
                  },
                  [_vm._v("\n                    Logout\n                ")]
                ),
              ]),
            ]),
          ])
        : _c(
            "div",
            [
              _vm.page === "accept" || _vm.page === "login"
                ? _c(
                    "div",
                    { staticClass: "form-page bg-white" },
                    [
                      _c(
                        "FormulateForm",
                        {
                          attrs: { errors: _vm.$page.props.errors },
                          on: { submit: _vm.onLoginSubmit },
                          model: {
                            value: _vm.formLogin,
                            callback: function ($$v) {
                              _vm.formLogin = $$v
                            },
                            expression: "formLogin",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-page" },
                            [
                              _vm.page === "accept" || _vm.page === "login"
                                ? _c("description", {
                                    attrs: { data: _vm.data },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.page === "accept"
                                ? _c("accept", {
                                    on: {
                                      accept: () =>
                                        _vm.loggedUser
                                          ? _vm.onAccept()
                                          : _vm.nextPage(),
                                      reject: _vm.onReject,
                                    },
                                  })
                                : _vm.page === "login"
                                ? _c("login", {
                                    attrs: {
                                      email: !_vm.formAllow.user_creation
                                        ? _vm.data.email
                                        : null,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "FormulateInput",
                                { attrs: { type: "group" } },
                                [
                                  _vm.isLastPage
                                    ? _c("FormulateInput", {
                                        attrs: {
                                          type: "submit",
                                          label: "Login and accept invite",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "FormulateForm",
                    {
                      attrs: { errors: _vm.$page.props.errors },
                      on: {
                        validation: function ($event) {
                          _vm.validation = $event
                        },
                        submit: _vm.onRegisterSubmit,
                      },
                      model: {
                        value: _vm.formRegister,
                        callback: function ($$v) {
                          _vm.formRegister = $$v
                        },
                        expression: "formRegister",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-page" },
                        [
                          _c("create-account", {
                            attrs: { "form-allow": _vm.formAllow },
                          }),
                          _vm._v(" "),
                          _c("create-account-password", {
                            attrs: { "form-allow": _vm.formAllow },
                          }),
                          _vm._v(" "),
                          _c(
                            "FormulateInput",
                            { attrs: { type: "group" } },
                            [
                              _vm.isLastPage && _vm.formAllow.user_creation
                                ? _c("FormulateInput", {
                                    attrs: {
                                      type: "submit",
                                      label: "Create account",
                                    },
                                  })
                                : !_vm.isLastPage
                                ? _c("FormulateInput", {
                                    attrs: { type: "button", label: "Next" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.nextPage.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }