import { mapColors, mapServiceProperties } from 'Lib/defaults';
import { addAdditionalFields } from 'Lib/defaults/habitatData';
import InfoBox from 'Utilities/maps/InfoBox.vue';
import Vue from 'vue';

export default {
    data() {
        return {
            mapStyle: [{'featureType': 'poi','elementType': 'labels.icon','stylers': [{'visibility': 'off'}]}],
            mapColors: mapColors,
            mapServiceProperties: mapServiceProperties,
        };
    },

    methods: {
        parsePaths(originalPaths) {
            let paths = null;

            if (originalPaths) {
                paths = originalPaths;

                if (paths.layer) {
                    paths = paths.layer;

                    if (paths.paths) {
                        paths = paths.paths;
                    }
                }
            }

            if (Array.isArray(paths)) {
                return paths.map(latLng => {
                    let returnArray = latLng;

                    if (latLng.lat && (latLng.lng || latLng.lon)) {
                        returnArray = [
                            returnArray[0] = latLng.lat,
                            returnArray[1] = latLng.lng ?? latLng.lon,
                        ];

                    }

                    return returnArray;
                });
            }

            return [];
        },

        getMapIcon(type = 'project') {
            let fillColor = this.mapColors[type];

            if (type.indexOf('#') === 0) {
                fillColor = type;
            }

            return {
                path: 'M12.8,35.9C15.5,32.1,24,19.3,24,12c0-6.6-5.4-12-12-12s-12,5.4-12,12c0,7.3,8.5,20.1,11.2,23.9C11.6,36.5,12.5,36.5,12.8,35.9z M12.1,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.5,20,12.1,20z',
                fillColor: fillColor,
                fillOpacity: 1,
                strokeWeight: 0,
                scaledSize: {
                    width: 40,
                    height: 42,
                },
                origin: {
                    x: 0,
                    y: 0,
                },
                anchor: {
                    x: 12,
                    y: 39,
                },
            };
        },

        setMapStyle(objLayers, objData, strInfowindow) {
            if (strInfowindow) {
                objLayers.addListener('click', (event) => {
                    let strTemplate = strInfowindow;

                    if (this.mapInfoWindow) {
                        this.mapInfoWindow.close();
                    }

                    const matches = strTemplate.match(/\((.*?)\)/g);

                    if (matches) {
                        matches.forEach((match) => {
                            strTemplate = strTemplate.replace(match, this.templateMatch(match, objData, event));
                        });
                    }

                    strTemplate = this.templateMatch(strTemplate, objData, event);

                    if (strTemplate) {
                        this.mapInfoWindow = new google.maps.InfoWindow({
                            position: event.latLng,
                            content: strTemplate,
                            map: this.map,
                        });
                    }
                });

                objLayers.addListener('mouseover', function(event) {
                    objLayers.revertStyle();
                    objLayers.overrideStyle(event.feature, {strokeWeight: 2});
                });

                objLayers.addListener('mouseout', function() {
                    objLayers.revertStyle();
                });
            }

            objLayers.setStyle((feature) => {
                let strColor = objData.color;

                if (objData.api) {
                    strColor = this.returnColours(objData.api)[feature.getProperty('A_pred')];
                }

                return {
                    fillColor: strColor,
                    strokeColor: strColor,
                    strokeWeight: 1,
                };
            });
        },

        runAPICalls(objQuery) {
            clearTimeout(this.apiTimeout);

            this.apiTimeout = setTimeout(() => {
                const blnZoomedIn = this.map.getZoom() > this.currentZoomDelayed;
                const blnPanned = this.map.getZoom() === this.currentZoomDelayed;

                if (this.map.getZoom() >= this.apiZoomEnabled && (!blnZoomedIn || blnPanned || (this.currentZoomDelayed <= this.apiZoomEnabled - 1 && this.map.getZoom() >= this.apiZoomEnabled))) {
                    if (Object.keys(objQuery).length) {
                        Object.entries(objQuery).forEach(([key, value]) => {
                            this.retrunGeoData(this.map, key, {
                                outFields: 'ID,A_pred,B_pred,Shape__Area',
                                where: value,
                            }).then((data) => {
                                if (this.formattedLayers[key]) {
                                    this.formattedLayers[key].setMap(null);
                                }

                                this.formattedLayers[key] = new google.maps.Data();

                                this.formattedLayers[key].addGeoJson(data.data);

                                this.setMapStyle(this.formattedLayers[key], {
                                    api: key,
                                }, '{property_A_pred}({property_B_pred}? &#8212; {property_B_pred}:)<br>({property_Shape__Area}/10000) Hectares');

                                this.formattedLayers[key].setMap(this.map);
                            });
                        });
                    }
                } else if (this.map.getZoom() < this.apiZoomEnabled) {
                    if (Object.keys(objQuery).length) {
                        Object.entries(objQuery).forEach(([key]) => {
                            if (this.formattedLayers[key]) {
                                this.formattedLayers[key].setMap(null);
                            }
                        });
                    }
                }

                if (this.map.getZoom() >= this.apiZoomEnabled) {
                    this.currentZoomDelayed = this.map.getZoom();
                }
            }, 1000);
        },

        getColor(objLayer) {
            if (objLayer.api) {
                return this.returnColours(objLayer.api)[objLayer.name];
            } else if (mapColors[objLayer.color]) {
                return mapColors[objLayer.color];
            }

            return objLayer.color;
        },

        templateMatch(strTemplate, objData, event) {
            strTemplate = strTemplate.replace(/^\(|\)$/g, '');
            strTemplate.match(/\{(.*?)\}/g).forEach((match) => {
                let strProperty = '';

                if (strTemplate.indexOf('layer_') === 1) {
                    strProperty = match.replace(match, objData[match.replace('{layer_', '').replace(/\}$/, '')]);
                } else {
                    strProperty = match.replace(match, event.feature.getProperty(match.replace('{property_', '').replace(/\}$/, '')));
                }

                strProperty = strProperty && strProperty !== 'undefined' ?
                    strProperty.replace(/\//g, '&#47;')
                        .replace(/\+/g, '&#43;')
                        .replace(/\*/g, '&#42;')
                        .replace(/-/g, '&#45;')
                        .trim()
                    : '';

                strTemplate = strTemplate.replace(match, strProperty);
            });

            if (strTemplate.indexOf('?') > -1 && strTemplate.indexOf(':') > -1) {
                const arrIf = strTemplate.split('?');
                const arrOutcome = arrIf[1].split(':');
                const arrAll = [arrIf[0], arrOutcome[0], arrOutcome[1]];

                strTemplate = arrAll[0] ? arrAll[1] : arrAll[2];
            }

            if (strTemplate.indexOf('/') > -1) {
                const arrMath = strTemplate.split('/');

                strTemplate = parseInt(arrMath[0]) / parseInt(arrMath[1]);
            } else if (strTemplate.indexOf('+') > -1) {
                const arrMath = strTemplate.split('+');

                strTemplate = parseInt(arrMath[0]) + parseInt(arrMath[1]);
            } else if (strTemplate.indexOf('*') > -1) {
                const arrMath = strTemplate.split('*');

                strTemplate = parseInt(arrMath[0]) * parseInt(arrMath[1]);
            } else if (strTemplate.indexOf('-') > -1) {
                const arrMath = strTemplate.split('-');

                strTemplate = parseInt(arrMath[0]) - parseInt(arrMath[1]);
            }

            return strTemplate;
        },

        parseCircleList(layer) {
            if (layer.points) {
                return layer.points.map(circle => this.parseCircle(circle));
            }

            if (layer.shape) {
                if (layer.lat && layer.lng) {
                    layer = this.parseCircle(layer);
                }

                return [layer];
            }

            return null;
        },

        parseCircle(point) {
            return {
                shape: 'circle',
                radius: point.radius || 50,
                center: {
                    lat: point.lat,
                    lng: point.lng,
                },
            };
        },

        clusterToSchema(data) {
            return {
                type: 'cluster',
                features: data,
            };
        },

        layerToSchema(name, data, options = {}) {
            return {
                type: 'layer',
                properties: {
                    name,
                    ...options,
                },
                features: data,
            };
        },

        async refreshProjects(route) {
            return new Promise(resolve => {
                if (this.filters.projects) {
                    this.$inertia.get(route, {}, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['projects'],
                        replace: true,
                        onSuccess: (data) => {
                            this.projectsAmount = data.props.projects.last_page;
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        async refreshPlots(route) {
            return new Promise(resolve => {
                if (this.filters.plots) {
                    this.$inertia.get(route, {}, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['plots'],
                        replace: true,
                        onSuccess: (data) => {
                            this.plotsAmount = data.props.plots.last_page;
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        async refreshHabitats(route) {
            return new Promise(resolve => {
                const fields = addAdditionalFields();
                const fieldKeys = Object.keys(fields);
                const checked = [];

                fieldKeys.forEach(key => {
                    if (this.filters[key]) {
                        checked.push(key);
                    }

                    fields[key].forEach(field => {
                        if (this.filters[field.value]) {
                            checked.push(field.value);
                        }
                    });
                });

                if (checked.length) {
                    this.$inertia.get(route, {}, {
                        preserveState: true,
                        preserveScroll: true,
                        only: ['habitats'],
                        replace: true,
                        onSuccess: () => {
                            this.combineData();
                            resolve();
                        },
                    });
                } else {
                    resolve();
                }
            });
        },

        async getOtherLayers(route) {
            return new Promise(resolve => {
                this.$inertia.get(route, {}, {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['layers'],
                    replace: true,
                    onSuccess: () => {
                        this.combineData();

                        resolve();
                    },
                });
            });
        },

        habitatLayerToSchema(habitatInformation, data, options = {}, optionsInner = {}) {
            if (habitatInformation) {
                return Object.keys(habitatInformation).map(key => {
                    const habitatList = habitatInformation[key];

                    return {
                        type: 'layer',
                        properties: {
                            id: `habitat-${key}`,
                            name: (key.charAt(0).toUpperCase() + key.slice(1)).replace('-', ' '),
                            visible: true,
                            ...options,
                        },
                        features: habitatList.map(habitat => {
                            return {
                                type: 'layer',
                                properties: {
                                    id: habitat.value,
                                    name: habitat.label,
                                    visible: true,
                                    sidebar: habitatList.length > 1,
                                    color: habitat.theme ? habitat.theme.fill : null,
                                    pattern: habitat.theme && habitat.theme.theme ? habitat.theme.theme.type : null,
                                    patternColor: habitat.theme && habitat.theme.theme ? habitat.theme.theme.fill : null,
                                    ...optionsInner,
                                },
                                features: data.filter(dataHabitat => dataHabitat.properties.name === habitat.value).map(dataHabitat => ({
                                    ...dataHabitat,
                                    properties: {
                                        ...dataHabitat.properties,
                                        color: habitat.theme && habitat.theme.fill ? habitat.theme.fill : null,
                                        strokeColor: habitat.theme && habitat.theme.fill ? habitat.theme.fill : null,
                                        pattern: habitat.theme && habitat.theme.theme ? habitat.theme.theme.type : null,
                                        patternColor: habitat.theme && habitat.theme.theme ? habitat.theme.theme.fill : null,
                                        patternColorAlt: habitat.theme && habitat.theme.theme ? habitat.theme.theme.fill : null,
                                    },
                                })),
                            };
                        }),
                    };
                });
            }

            return [];
        },

        plotToSchema(data, options = {}, infoBox = true) {
            return this.polygonToSchema({
                ...data,
                id: data.id ? `plot-${data.id}` : undefined,
            }, {
                name: `Plot - ${data.name}`,
                fillColor: this.mapColors.plot,
                strokeColor: this.mapColors.plot,
                ...options,
            }, infoBox);
        },

        projectToSchema(data, options = {}, infoBox = true) {
            return this.polygonToSchema({
                ...data,
                id: data.id ? `project-${data.id}` : undefined,
            }, {
                name: `Project - ${data.name}`,
                color: this.mapColors.project,
                strokeColor: this.mapColors.project,
                ...options,
            }, infoBox);
        },

        rentalToSchema(data, options = {}, infoBox = true) {
            return this.polygonToSchema({
                ...data,
                id: data.id ? `rental-${data.id.name} - ${data.project.name}` : undefined,
            }, {
                name: `Rental - ${data.plot.name} - ${data.project.name}`,
                color: this.mapColors.lease,
                strokeColor: this.mapColors.lease,
                ...options,
            }, infoBox);
        },

        polygonToSchema(data, options = {}, infoBox = true) {
            if (data.location_data) {
                let id = `map-polygon-${Math.floor(Math.random() * 10000)}-${Date.now()}`;

                if (data.id) {
                    id = `${data.id}`;
                }

                // Type of layout
                const scheaPoints = this.pointsToSchema(data.location_data);
                const returnData = {
                    properties: {
                        name: `Polygon - ${Math.floor(Math.random() * 10000)}`,
                        markerZoom: 13,
                        sidebar: false,
                        visible: true,
                        opacity: scheaPoints.type === 'polygon' ? 0.6 : 1,
                        strokeOpacity: 0.6,
                        ...options,
                        id,
                    },
                    infoBox: infoBox ? this.infoBoxToSchema(data) : null,
                    ...scheaPoints,
                };

                if (
                    (returnData.type === 'marker' && returnData.coordinate.lat && returnData.coordinate.lng) ||
                    (returnData.type === 'polygon' && Array.isArray(returnData.coordinates)) ||
                    (returnData.type === 'polygon' && returnData.center && returnData.radius)
                ) {
                    return returnData;
                }
            }

            return null;
        },

        vectorToSchema(data, infoBox = true) {
            let id = `map-vector-${Math.floor(Math.random() * 10000)}-${Date.now()}`;

            if (data.id) {
                id = `${data.id}`;
            }

            if (!data.attributes && data.url && mapServiceProperties[data.url]) {
                data.attributes = mapServiceProperties[data.url].attributes;
            } else if (!data.attributes && data.name && mapServiceProperties[data.name]) {
                data.attributes = mapServiceProperties[data.name].attributes;
            }

            if (data.url && mapServiceProperties[data.url]) {
                data.url = mapServiceProperties[data.url].url;
            } else if (!data.url && data.name && mapServiceProperties[data.name]) {
                data.url = mapServiceProperties[data.name].url;
            }

            if (data.url) {
                const url = mapServiceProperties[data.url] ? mapServiceProperties[data.url].url : data.url;
                const attributes = mapServiceProperties[data.url] ? mapServiceProperties[data.url].attributes : data.attributes;
                const color = this.mapColors[data.color] ?? data.color;
                let stroke = color,
                    filter = data.filter ? data.filter.map(filter => {
                        return {...filter, property: filter.property.replace(/^\${|}$/g, '')};
                    }) : undefined;

                if (data.strokeColor) {
                    stroke = this.mapColors[data.strokeColor] ?? data.strokeColor;
                }

                if (this.mapServiceProperties[data.name] && this.mapServiceProperties[data.name].properties && data.filter) {
                    filter = filter.map(filter => {
                        if (this.mapServiceProperties[data.name].properties[filter.property]) {
                            filter.property = this.mapServiceProperties[data.name].properties[filter.property].replace(/^\${|}$/g, '');
                        }

                        return filter;
                    });
                }

                return {
                    type: 'vector',
                    properties: {
                        name: data.label ?? data.name,
                        url: url,
                        attributes: attributes,
                        sidebar: true,
                        visible: true,
                        opacity: 0.2,
                        strokeOpacity: 0.5,
                        ...data,
                        filter: filter ?? null,
                        id,
                        color: color,
                        strokeColor: stroke,
                    },
                    infoBox: infoBox ? this.infoVectorToSchema(data) : null,
                };
            }

            return null;
        },

        parseVectorByID(key, id, options) {
            let vectors = null;

            if (this[key]) {
                const lpa = this[key].filter(lpa => parseInt(lpa.id) === parseInt(id));
                let lpaNames = null;

                if (lpa.length) {
                    lpaNames = lpa.map(lpa => [lpa.name.replace(' LPA', ''), lpa.name, `${lpa.name} LPA`]).flat();
                }

                vectors = {
                    name: options.name,
                    color: options.color,
                    strokeColor: options.strokeColor,
                    filter: [{
                        property: 'name',
                        compare: '=',
                        type: 'OR',
                        value: lpaNames,
                    }],
                };
            }

            return vectors;
        },

        habitatToSchema(data, options = {}) {
            if (data.location_data) {
                const returnData = {
                    properties: {
                        name: data.type,
                        markerZoom: 13,
                        visible: true,
                        sidebar: false,
                        ...options,
                    },
                    infoBox: {
                        type: 'infoBox',
                        properties: {},
                        content: `${data.category}<br>${data.broad_habitat}<br>${data.type}<br>`,
                    },
                    ...this.pointsToSchema(data.location_data),
                };

                if (
                    (returnData.type === 'polygon' && Array.isArray(returnData.coordinates)) ||
                    (returnData.type === 'polygon' && returnData.center && returnData.radius)
                ) {
                    return returnData;
                }
            }

            return null;
        },

        infoBoxToSchema(data) {
            const appendTo = document.createElement('div');
            const vueHolder = new Vue({
                render: h => h(InfoBox, {
                    props: {
                        item: data,
                    },
                }),
            }).$mount(appendTo);

            if (data.location_data) {
                return {
                    type: 'infoBox',
                    properties: {},
                    content: vueHolder.$el.outerHTML,
                };
            }

            return null;
        },

        infoVectorToSchema(data) {
            const nameID = data.name.toLowerCase().replace(/\(\w+\)/g, '').trim().replaceAll(' ', '_');

            if (this.mapServiceProperties[nameID]) {
                return {
                    type: 'infoBox',
                    properties: {},
                    content: this.mapServiceProperties[nameID].properties.name,
                };
            }

            return {};
        },

        pointsToSchema(points) {
            let objectReturn = {};

            if (!points) points = [];

            if (!Array.isArray(points)) {
                if ((points.layer && points.layer.radius && points.layer.center) || (points.center && points.radius)) {
                    // points.center
                    // points.radius
                    objectReturn = {
                        type: 'polygon',
                        radius: points.layer ? points.layer.radius : points.radius,
                        center: points.layer ? points.layer.center : points.center,
                    };
                } else if (points.layer && points.layer.paths) {
                    // {layer: paths: [{"lat":Number,"lng":Number}]}
                    const pointReturn = points.layer.paths;

                    objectReturn = {
                        type: 'polygon',
                        coordinates: pointReturn,
                    };
                } else if (points.lat && points.lng) {
                    // {"lat":Number,"lng":Number}
                    objectReturn = {
                        type: 'marker',
                        coordinate: points,
                    };
                }
            } else {
                objectReturn = {
                    type: 'polygon',
                    coordinates: points,
                };
            }

            if (
                (objectReturn.type === 'marker' && objectReturn.coordinate.lat && objectReturn.coordinate.lng) ||
                (objectReturn.type === 'polygon' && Array.isArray(objectReturn.coordinates)) ||
                (objectReturn.type === 'polygon' && objectReturn.center && objectReturn.radius)
            ) {
                return objectReturn;
            }

            return {};
        },

        combineData() {
            let {
                operatingIn,
                projects,
                plots,
                habitats,
                layers,
            } = this.$page.props;

            if (operatingIn && !Array.isArray(operatingIn) && operatingIn.data) operatingIn = operatingIn.data;
            if (projects && !Array.isArray(projects) && projects.data) projects = projects.data;
            if (plots && !Array.isArray(plots) && plots.data) plots = plots.data;
            if (habitats && !Array.isArray(habitats) && habitats.data) habitats = habitats.data;
            if (layers && !Array.isArray(layers) && layers.data) layers = layers.data;

            if (!Array.isArray(operatingIn)) operatingIn = [];
            if (!Array.isArray(projects)) projects = [];
            if (!Array.isArray(plots)) plots = [];
            if (!Array.isArray(habitats)) habitats = [];
            if (!Array.isArray(layers)) layers = [];

            this.data = [
                ...operatingIn.map(data => ({...data, 'data-type': 'lpa'})),
                ...projects.map(data => ({...data, 'data-type': 'projects'})),
                ...plots.map(data => ({...data, 'data-type': 'plots'})),
                ...habitats.map(data => ({...data, 'data-type': 'habitats'})),
                ...layers.map(data => ({...data, 'data-type': data.type, 'data-layer': data.section})),
            ];
        },

        validateMap(value) {
            if (value.value.layer && ((value.value.layer.paths && value.value.layer.paths.length && value.value.inputGeo) || (value.value.layer.shape && value.value.layer.radius))) {
                return true;
            }

            return false;
        },
    },
};
