var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.pill
        ? _c("div", {
            class: `status-pill type-${_vm.pillClass}`,
            domProps: { innerHTML: _vm._s(_vm.sanitisedPill) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "FormulateForm",
        {
          key: "form",
          ref: "form",
          staticClass: "flex-col margin-0",
          attrs: { name: "calculator" },
          on: { submit: _vm.submitForm },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "p",
            { staticClass: "align-right" },
            [
              _vm.showUnits
                ? [
                    _vm._v(
                      "\n                Total Units: " +
                        _vm._s(_vm.numberCommas(_vm.getUnits("all"))) +
                        "\n            "
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.maxArea
                ? [
                    _c("br"),
                    _vm._v(
                      "Area used: " +
                        _vm._s(_vm.numberCommas(_vm.getUsedSize)) +
                        " Hectares\n                "
                    ),
                    _c("br"),
                    _vm._v(
                      "Area remaining: " +
                        _vm._s(_vm.numberCommas(_vm.getRemainingSize)) +
                        " Hectares\n            "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "habitats",
            attrs: {
              name: "habitats",
              "add-label": "+ Add Additional Habitat",
              type: "group",
              repeatable: !_vm.disabled && _vm.repeatable,
              "element-class":
                "formulate-input-element formulate-input-element--collapsable-group",
              "outer-class": "formulate-input collapsable-group",
            },
            on: {
              repeatableAdded: function ($event) {
                _vm.showIndex = $event.length - 1
              },
              repeatableRemoved: (event) => _vm.removeRow(event),
            },
            scopedSlots: _vm._u([
              {
                key: "repeatable",
                fn: function (context) {
                  return [
                    _c(
                      "collapsable-group-item",
                      {
                        key: `group-collapse-${context.index}`,
                        attrs: {
                          context: context,
                          title: _vm.habitatGroupLabel(context.index),
                          "show-index": _vm.showIndex,
                        },
                        on: {
                          open: function ($event) {
                            _vm.showIndex = $event
                          },
                        },
                      },
                      [
                        _c("FormulateInput", {
                          key: `category-${context.index}`,
                          attrs: {
                            type: "select-button",
                            name: "category",
                            options: _vm.categoryOptions,
                            "element-class":
                              "formulate-input-element formulate-input-element--select-button formulate-input-element-habitat-btn",
                            disabled:
                              _vm.disabled || _vm.formLoading[context.index],
                          },
                          on: {
                            click: (event) =>
                              _vm.disabled
                                ? {}
                                : _vm.setCategory(
                                    context.index,
                                    event.target.value
                                  ),
                          },
                        }),
                        _vm._v(" "),
                        _vm.formLoading[context.index] || _vm.formLoading["all"]
                          ? _c("processing", [
                              _vm._v(
                                "\n                        Loading\n                    "
                              ),
                            ])
                          : context.model[context.index].category
                          ? [
                              _c("FormulateInput", {
                                key: `id-${context.index}`,
                                attrs: { name: "id", type: "hidden" },
                              }),
                              _vm._v(" "),
                              _vm._l(
                                _vm.getFormFieldFiltered(
                                  context.model[context.index].category
                                ),
                                function (field) {
                                  return _c("FormulateInput", {
                                    key: `field-${context.index}-${
                                      context.model[context.index].category
                                    }-${field.id}`,
                                    ref: "input",
                                    refInFor: true,
                                    attrs: {
                                      type: _vm.fieldType(context.index, field),
                                      name: _vm.fieldNameID(field),
                                      label: _vm.fieldLabelName(
                                        context.index,
                                        field
                                      ),
                                      disabled:
                                        _vm.disabled ||
                                        field.hasFormula ||
                                        _vm.isEnabled[_vm.fieldName(field)] ===
                                          false,
                                      validation: "required",
                                      "validation-name": field.header,
                                      placeholder: `Select ${field.header}`,
                                      options: field.options ?? [],
                                    },
                                    on: {
                                      change: () =>
                                        _vm.formChange(context.index),
                                    },
                                    model: {
                                      value:
                                        _vm.form.habitats[context.index][
                                          _vm.fieldNameID(field)
                                        ],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.habitats[context.index],
                                          _vm.fieldNameID(field),
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.habitats[context.index][fieldNameID(field)]",
                                    },
                                  })
                                }
                              ),
                              _vm._v(" "),
                              _vm.photo && _vm.photoUploadUrl
                                ? _c("FormulateInput", {
                                    key: `photo-${context.index}`,
                                    attrs: {
                                      name: "photos",
                                      label: "Photo",
                                      type: "image",
                                      required: true,
                                      validation:
                                        "mime:image/jpeg,image/png,image/gif",
                                      "upload-url": _vm.photoUploadUrl,
                                      "upload-behavior": "delayed",
                                    },
                                    on: {
                                      input: _vm.changePhoto,
                                      "file-removed": _vm.changePhoto,
                                    },
                                    model: {
                                      value:
                                        _vm.form.habitats[context.index].photos,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.habitats[context.index],
                                          "photos",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.habitats[context.index].photos",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : [
                              _vm._v(
                                "\n                        No Category Set\n                    "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }