var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasMetric
      ? _c(
          "div",
          { staticClass: "form-flex--tab" },
          [
            _c("FormulateInput", {
              key: "onsite_baseline_total_units",
              attrs: {
                type: "fake",
                label: "Onsite baseline total",
                "validation-name": "Onsite baseline total",
                "parse-value": () =>
                  _vm.floatOutput(_vm.onsite_baseline_total_units),
              },
              model: {
                value: _vm.onsite_baseline_total_units,
                callback: function ($$v) {
                  _vm.onsite_baseline_total_units = $$v
                },
                expression: "onsite_baseline_total_units",
              },
            }),
            _vm._v(" "),
            _c("FormulateInput", {
              key: "onsite_post_intervention_total",
              attrs: {
                type: "fake",
                label: "Onsite Post Intervention total",
                "validation-name": "Onsite Post Intervention total",
                "parse-value": () =>
                  _vm.floatOutput(_vm.onsite_post_intervention_total),
              },
              model: {
                value: _vm.onsite_post_intervention_total,
                callback: function ($$v) {
                  _vm.onsite_post_intervention_total = $$v
                },
                expression: "onsite_post_intervention_total",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-flex--tab" },
      [
        _c("FormulateInput", {
          key: "onsite_desired_percentage",
          attrs: {
            type: _vm.context ? "number" : "fake",
            name: "onsite_desired_percentage",
            label: "Desired net gain percentage",
            placeholder: "Desired net gain percentage (%)",
            validation: "required|number|between:9,201",
            "validation-name": "Desired net gain percentage",
          },
          on: { input: _vm.calculatePercentages },
          model: {
            value: _vm.onsite_desired_percentage,
            callback: function ($$v) {
              _vm.onsite_desired_percentage = $$v
            },
            expression: "onsite_desired_percentage",
          },
        }),
        _vm._v(" "),
        _vm.hasMetric
          ? _c("FormulateInput", {
              key: "desired_total",
              attrs: {
                type: "fake",
                label: "Desired offsite BNG units",
                "validation-name": "Desired offsite BNG units",
                "parse-value": () => _vm.floatOutput(_vm.desired_total),
              },
              model: {
                value: _vm.desired_total,
                callback: function ($$v) {
                  _vm.desired_total = $$v
                },
                expression: "desired_total",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }