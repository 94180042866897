<template>
    <app-layout section="project.metric">
        <page-header
            :title="pageTitle"
            icon="projects"
            :background="false"
        >
            <inertia-link
                class="btn-reset btn"
                :href="cancelRoute"
            >
                Cancel
            </inertia-link>
        </page-header>

        <bng-metric-form
            :model="project"
            model-type="project"
            :disabled="disabled"
            :desired="true"
            :desired-uplift-percent="project.onsite_desired_percentage"
        />
    </app-layout>
</template>

<script>
import BngMetricForm from 'Components/Metrics/BngMetricForm.vue';

export default {
    name: 'ProjectFormMetric',

    components: {
        BngMetricForm,
    },

    props: {
        project: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        pageTitle() {
            return 'BNG Metrics';
        },
        cancelRoute() {
            return this.$route('project.show', this.project.id);
        },
    },
};
</script>
