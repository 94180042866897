var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "header-small" }, [
    _c("div", [
      _vm.title
        ? _c("h2", [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.buttonArray.length
      ? _c(
          "div",
          _vm._l(_vm.buttonArray, function (buttonItem, index) {
            return _c(
              "button",
              {
                key: `${buttonItem.svg}-${index}`,
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return buttonItem.click.apply(null, arguments)
                  },
                },
              },
              [_c("SvgController", { attrs: { type: buttonItem.svg } })],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }