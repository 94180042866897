var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.context
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "form-flex--tab form-flex--vcenter" },
            [
              _c("FormulateInput", {
                key: "nutrient_development_type_2",
                attrs: {
                  type: "select-plugin",
                  name: "nutrient_development_type",
                  label: "Type of Development",
                  "validation-name": "Type of Development",
                  options: _vm.nutrientOptions,
                  disabled: _vm.disabled,
                },
              }),
              _vm._v(" "),
              _c("FormulateInput", {
                key: "nutrient_development_amount_2",
                attrs: {
                  type: "number",
                  name: "nutrient_development_amount",
                  label: _vm.typeNumberAmount,
                  "validation-name": "Development Amount",
                  disabled: _vm.disabled,
                },
              }),
              _vm._v(" "),
              _vm.value.planning_status &&
              _vm.value.planning_status !== "pre-application"
                ? _c("FormulateInput", {
                    key: "planning_ref_2",
                    attrs: {
                      type: "text",
                      name: "planning_ref",
                      label: "Planning reference number",
                      validation: "required",
                      "validation-name": "Planning reference number",
                      disabled: !_vm.editable,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "nutrient_planning_status_2",
            attrs: {
              type: "select-plugin",
              name: "nutrient_planning_status",
              label: "Planning Status",
              "validation-name": "Planning Status",
              options: _vm.nutrientStatusOptions,
              disabled: _vm.disabled,
            },
          }),
          _vm._v(" "),
          _c("FormulateInput", {
            key: "nutrient_types_2",
            attrs: {
              type: "select-plugin",
              name: "nutrient_types",
              label: "Nutrient Types",
              "validation-name": "Nutrient Types",
              multiple: true,
              options: _vm.nutrientTypeOptions,
              disabled: _vm.disabled,
            },
          }),
          _vm._v(" "),
          _vm.value.nutrient_types && _vm.value.nutrient_types.length
            ? _c("FormulateInput", {
                key: "nutrient_study_2",
                attrs: {
                  type: "toggle-checkbox",
                  name: "nutrient_study",
                  label:
                    "have you calculated nutrients associated with development?",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.value.nutrient_study
            ? [
                _c(
                  "div",
                  { staticClass: "form-flex--tab form-flex--vcenter" },
                  [
                    _vm._l(_vm.value.nutrient_types, function (type) {
                      return [
                        _c("div", { key: `nutrient_types_${type}` }, [
                          _c("div", { staticClass: "formulate-heading" }, [
                            _c("h6", [_vm._v(_vm._s(type))]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form-flex--tab form-flex--vcenter",
                            },
                            [
                              _c("div", { staticClass: "form-flex--tab" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "formulate-size formulate-size--page-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "formulate-input-wrapper",
                                      },
                                      [
                                        _c("FormulateInput", {
                                          key: `nutrient_types_pre_${type}_type`,
                                          attrs: {
                                            type: "number",
                                            name: `nutrient_types_pre_${type}_type`,
                                            label: "Pre AMP7",
                                            "validation-name": "Pre AMP7",
                                            options: _vm.nutrientTypeOptions,
                                            disabled: _vm.disabled,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm._m(0, true),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-flex--tab" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "formulate-size formulate-size--page-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "formulate-input-wrapper",
                                      },
                                      [
                                        _c("FormulateInput", {
                                          key: `nutrient_types_post_${type}_type`,
                                          attrs: {
                                            type: "number",
                                            name: `nutrient_types_post_${type}_type`,
                                            label: "Post AMP7",
                                            "validation-name": "Post AMP7",
                                            options: _vm.nutrientTypeOptions,
                                            disabled: _vm.disabled,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm._m(1, true),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.value.nutrient_study
                  ? _c("FormulateInput", {
                      key: "nutrient_calculator_2",
                      attrs: {
                        type: "file",
                        name: "nutrient_calculator",
                        label: _vm.value.nutrient_calculator.length
                          ? "Nutrient Calculator Document"
                          : "",
                        accept: ".xls,.xlsx,.xlsm,.xlsb",
                        "upload-url": "/attachments/upload",
                        "upload-behavior": "live",
                      },
                      on: { "file-upload-complete": _vm.uploadComplete },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "uploadAreaMask",
                            fn: function ({ hasFiles }) {
                              return [
                                !hasFiles
                                  ? _c("DropZone", [
                                      _vm._v(
                                        "\n                    Attach Nutrient Calculator\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2405136053
                      ),
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formulate-input-info" }, [
      _c("p", [_vm._v("kg")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formulate-input-info" }, [
      _c("p", [_vm._v("kg")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }