<template>
    <div ref="modal">
        <div
            v-show="(selectedContract.interface_type === 'upload' || editor) && !loading"
            id="document-contract-toolbar"
            class="contract-toolbar"
        >
            <DocumentToolbar
                :disabled="disabled"
                :refs="documentRefs"
                :editor="editor"
            >
                <template #toolbar-enabled-right>
                    <template v-if="selectedContract.interface_type === 'editor'">
                        <DocumentToolbarButton
                            icon="fa-solid fa-floppy-disk"
                            title="Save"
                            text="Save"
                            @click="submitForm"
                        />
                        <DocumentToolbarButton
                            icon="fa-solid fa-trash-can"
                            title="Discard"
                            text="Discard"
                            @click="handleDiscardChanges"
                        />
                    </template>
                </template>
                <template #toolbar-disabled>
                    <DocumentToolbarButton
                        icon="fa-regular fa-folder-open"
                        title="Open Document"
                        text="Open"
                        @click="openFiles"
                    />
                    <DocumentToolbarButton
                        v-if="selectedContract.interface_type === 'editor'"
                        icon="fa-solid fa-layer-group"
                        title="Compare Versions"
                        text="Compare"
                        @click="openCompare"
                    />
                    <template v-if="!allAccepted && isLatest && isNextToSign">
                        <DocumentToolbarButton
                            v-if="selectedContract.interface_type === 'editor'"
                            icon="fa-regular fa-pen-to-square"
                            title="Edit"
                            text="Edit"
                            @click="handleMakeChanges"
                        />
                        <DocumentToolbarButton
                            v-else-if="selectedContract.interface_type === 'upload' && !upload"
                            icon="fa-solid fa-upload"
                            title="Upload"
                            text="Upload"
                            @click="handleUpload"
                        />
                    </template>
                    <DocumentToolbarButton
                        icon="fa-solid fa-book-open"
                        title="Audit Log"
                        text="Audit Log"
                        @click="openAuditLog"
                    />
                </template>
                <template
                    v-if="allowChanges"
                    #toolbar-disabled-right
                >
                    <template v-if="selectedContract.interface_type === 'upload' && upload">
                        <DocumentToolbarButton
                            icon="fa-solid fa-floppy-disk"
                            title="Save"
                            text="Save"
                            @click="submitUploadForm"
                        />
                        <DocumentToolbarButton
                            icon="fa-solid fa-trash-can"
                            title="Discard"
                            text="Discard"
                            @click="handleDiscardChanges"
                        />
                    </template>

                    <template v-else>
                        <DocumentToolbarButton
                            icon="fa-solid fa-signature"
                            title="Show Signatures"
                            text="Show Signatures"
                            @click="openSignatures"
                        />
                        <DocumentToolbarButton
                            v-if="contract.status === 'contract signed' && contract.docusign_id"
                            icon="fa-solid fa-file-pdf"
                            title="View PDF"
                            text="View PDF"
                            @click="handlePdfView"
                        />
                        <DocumentToolbarButton
                            v-if="contract.status === 'contract signed' && contract.docusign_id && selectedContract.interface_type === 'editor' && user.isSuperUser"
                            icon="fa-solid fa-cloud-arrow-down"
                            title="Fetch PDF"
                            text="Fetch PDF"
                            @click="handleFetchPdf"
                        />
                        <template v-if="isNextToSign">
                            <DocumentToolbarButton
                                v-if="!hasUserAccepted"
                                icon="fa-regular fa-circle-check"
                                title="Accept Contract"
                                text="Accept Contract"
                                @click="handleAcceptChanges"
                            />
                            <DocumentToolbarButton
                                v-if="!hasUserAccepted"
                                icon="fa-regular fa-circle-xmark"
                                title="Reject Contract"
                                text="Reject Contract"
                                @click="handleRejectChanges"
                            />
                        </template>
                    </template>
                </template>
            </DocumentToolbar>
        </div>
        <DocumentEditor
            ref="editor"
            v-model="documentValue"
            :loading="loading"
            :prefix="prefix"
            :prefix-height="quillHeights"
            :prefix-watermark="quillPrefixWatermark"
            :prefix-hover-text="quillPrefixHoverText"
            :suffix="suffix"
            :suffix-height="quillHeights"
            :suffix-watermark="quillSuffixWatermark"
            :suffix-hover-text="quillSuffixHoverText"
            :compare="selectedCompareContent"
            :current-user="user"
            :disabled="disabled"
            :allow-zoom="false"
            :allow-comments="!allAccepted && isLatest"
            toolbar-container="#document-contract-toolbar"
            @load="handleLoad"
            @comment="submitComment"
            @clickChange="handleClickChange"
        >
            <template #footer-left>
                <div
                    v-if="hasUserAccepted"
                    class="status-pill type-green"
                >
                    You have accepted this contract version.
                </div>
                <component
                    :is="isNextToSign ? 'button' : 'div'"
                    v-else-if="hasUserRejected"
                    class="status-pill type-red"
                    @click="handleNextTo"
                >
                    You have rejected this contract version.
                </component>
                <div
                    v-else-if="!isLatest"
                    class="status-pill type-green"
                    @click="handleNextTo"
                >
                    {{ notLatestText }}
                </div>
            </template>
            <template #footer-right>
                <component
                    :is="isNextToSign && nextAfterGroup() && nextAfterGroup().id !== selectedContract.nextToSign.id ? 'button' : 'div'"
                    v-if="nextToText && !allAccepted"
                    class="status-pill type-blue"
                    @click="handleNextTo"
                >
                    {{ nextToText }}
                </component>
                <button
                    v-else-if="!isLatest"
                    class="status-pill type-blue"
                    @click="loadContract({ id: contract.id, item_id: contract.item_id })"
                >
                    Click to view the latest document.
                </button>
            </template>
            <template
                v-if="selectedContract.interface_type === 'upload'"
                #content
            >
                <div v-if="upload">
                    <div class="document-contract-upload-center">
                        <div class="document-contract-upload-center-document">
                            <FormulateForm
                                v-model="form"
                                :schema="scemaForm"
                                :errors="form.errors"
                                class="flex-col"
                                @submit="submitForm"
                            />
                        </div>
                    </div>
                </div>
                <object
                    v-else-if="documentUrl"
                    :data="documentUrl"
                    type="application/pdf"
                    width="100%"
                    height="700px"
                />
                <div
                    v-else
                    class="document-contract-upload-center document-contract-upload-empty"
                >
                    <div class="document-contract-upload-center-document">
                        <p>
                            {{ nextToText }}
                        </p>
                    </div>
                </div>
            </template>
        </DocumentEditor>
        <modal
            ref="fileModal"
            width="1024px"
        >
            <template #header>
                <h3>{{ capitalizeFirstLetter(fileType) }} {{ contract.type }} Version</h3>
            </template>
            <template #body>
                <TableGridLayout
                    :columns="columnsList"
                    :rows="rowList"
                    :output="['table']"
                    mode="remote"
                    :total-records="rowList.length"
                    @onRowClick="onRowClick"
                />
            </template>
        </modal>
        <modal
            ref="changeModal"
            width="1024px"
        >
            <template #header>
                <h3>Detailed Change</h3>
            </template>
            <template #body>
                <DocumentDetailedChange
                    :changes="currentChanges.changes"
                    :ending-content="currentChanges.endingContent"
                    :starting-content="currentChanges.startingContent"
                />
            </template>
        </modal>
        <Signatures
            ref="signaturesModal"
            :signatures="signed"
            :required="required"
        />
        <Comments
            ref="commentsModal"
            :comments="comments"
            :contract="contract"
            @click="handleCommentClick"
        />
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import DocumentEditor, { DocumentToolbar, DocumentDetailedChange, DocumentToolbarButton } from 'vue2-word-processor';
import Modal from 'Utilities/modal/Modal';
import Comments from './Comments';
import Signatures from './Signatures';

export default {
    name: 'ContractEditor',
    components: {
        Modal,
        Comments,
        Signatures,
        DocumentEditor,
        DocumentToolbar,
        DocumentDetailedChange,
        DocumentToolbarButton,
    },
    props: {
        contract: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            prefix: [],
            suffix: [],
            loading: true,
            contentUpdated: false,
            selectedContract: this.contract,
            selectedCompareContent: null,
            allowChanges: true,
            editor: null,
            documentRefs: {},
            user: this.$page.props.loggedUser,
            stopLeave: false,
            disabled: true,
            upload: false,
            documentValue: null,
            documentUrl: null,
            columnsList: [{
                label: 'Edited by',
                field: 'name',
                sortable: false,
            }, {
                label: 'Company',
                field: 'company',
                sortable: false,
            }, {
                label: 'Status',
                field: 'status',
                spanClass: 'status-pill type-{status}',
                sortable: false,
            }, {
                label: 'Date',
                field: 'date',
                sortable: false,
            }],
            loadingHeight: 0,
            fileType: '',
            currentChanges: {},
            quillHeights: 'auto',
            quillPrefixWatermark: null,
            quillPrefixHoverText: null,
            quillSuffixWatermark: null,
            quillSuffixHoverText: null,

            form: this.form = this.$inertia.form({
                document: null,
            }),
        };
    },
    computed: {
        rowList() {
            return this.contract.edited.filter(edited => this.fileType === 'files' || (this.fileType === 'compare' && edited.id !== this.selectedContract.id)).map(edited => {
                return {
                    ...edited,
                    name: `${edited.user.first_name} ${edited.user.last_name}`,
                    company: edited.company.name,
                    date: DateTime.fromISO(edited.updated_at).toFormat('dd/LL/yyyy hh:mm a'),
                };
            });
        },

        isLatest() {
            return this.contract.id === this.selectedContract.id;
        },

        allAccepted() {
            return this.required.length === this.signed.filter(signature => signature.accepted).length;
        },

        required() {
            if (this.selectedContract && this.selectedContract.signaturesRequired) {
                return this.selectedContract.signaturesRequired;
            }

            return [];
        },

        signed() {
            if (this.selectedContract && this.selectedContract.signatures) {
                return this.selectedContract.signatures;
            }

            return [];
        },

        userSigned() {
            if (this.selectedContract && this.selectedContract.signatures) {
                const userSign = this.selectedContract.signatures.filter(signature => signature.user.id === this.user.id);

                if (userSign.length) {
                    return userSign.at(-1);
                }
            }

            return false;
        },

        hasUserSigned() {
            const userSign = this.userSigned;

            if (userSign) {
                return true;
            }

            return false;
        },

        hasUserAccepted() {
            const userSign = this.userSigned;

            if (userSign) {
                return userSign.accepted;
            }

            return false;
        },

        hasUserRejected() {
            const userSign = this.userSigned;

            if (userSign) {
                return !userSign.accepted;
            }

            return false;
        },

        isNextToSign() {
            if (this.isLatest && this.selectedContract.nextToSign && this.hasGroup(this.selectedContract.nextToSign.name)) {
                return true;
            }

            return false;
        },

        nextToText() {
            if (this.isLatest && this.selectedContract.nextToSign) {
                const name = `${this.selectedContract.nextToSign.name !== 'sequest' ? 'the ' : ''}${this.selectedContract.nextToSign.label}`;

                if (this.hasGroup(this.selectedContract.nextToSign.name)) {
                    return `The document is currently with ${name} (current user)`;
                } else {
                    return `The document is currently with ${name}`;
                }
            }

            return '';
        },

        notLatestText() {
            if (!this.isLatest) {
                const date = DateTime.fromISO(this.selectedContract.updated_at).toFormat('dd/MM/yyyy HH:mm');

                return `Viewing document from ${date}.`;
            }

            return '';
        },

        comments() {
            let comments = [];

            if (this.selectedContract.all_comments) {
                comments = comments.concat(this.selectedContract.all_comments);
            }

            if (this.selectedContract.all_signatures) {
                comments = comments.concat(this.selectedContract.all_signatures.map(signature => {
                    const content = `<p class="status-pill type-${signature.accepted ? 'green' : 'red'} cursor-pointer" data-contract-id="${signature.contract_id}">Contract ${signature.accepted ? 'accepted' : 'rejected'}. Click to see the contract.</p>${signature.reason ? signature.reason : '<br>'}`;

                    return {
                        'company': signature.company,
                        'id': signature.id,
                        'comment': content,
                        'user': signature.user,
                        'created_at': signature.updated_at,
                        'updated_at': signature.updated_at,
                    };
                }));
            }

            if (this.selectedContract.edited) {
                comments = comments.concat(this.selectedContract.edited.map(edited => {
                    const content = `<p class="status-pill type-green cursor-pointer" data-contract-id="${edited.id}">New contract version created. Click to see the contract.</p>`;

                    return {
                        'company': edited.company,
                        'id': edited.id,
                        'comment': content,
                        'user': edited.user,
                        'created_at': edited.updated_at,
                        'updated_at': edited.updated_at,
                    };
                }));
            }

            comments.sort((sortA, sortB) => {
                const dateA = DateTime.fromISO(sortA.updated_at);
                const dateB = DateTime.fromISO(sortB.updated_at);

                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;

                return 0;
            });

            return comments;
        },

        scemaForm() {
            let schema = [{
                'component': 'p',
                'children': 'Something went wrong. Incorrect interface type',
            }];

            if (this.selectedContract.interface_type === 'upload') {
                schema = [{
                    type: 'file',
                    name: 'document',
                    label: 'Select your PDF document to upload',
                    help: 'Only PDF documents are currently supported',
                    uploadUrl: route('contract.upload'),
                    uploadBehavior: 'delayed',
                    validation: ['required', 'mime:application/pdf'],
                    validationName: 'Document',
                    accept: '.pdf',
                }];
            }

            return schema;
        },
    },
    watch: {
        disabled() {
            if (this.disabled) {
                this.stopLeave = false;
            } else {
                this.stopLeave = true;
            }

            this.$emit('toggleEditor', !this.disabled);
        },
        stopLeave() {
            if (this.stopLeave) {
                this.beforeLeaveActive();
            } else {
                this.beforeLeaveDeactive();
            }
        },
    },
    mounted() {
        this.refresh();
    },
    methods: {
        disableStopLeave() {
            this.stopLeave = false;
            this.beforeLeaveDeactive();
        },

        beforeLeaveActive() {
            window.onbeforeunload = function() {
                return 'Are you sure you want to leave?';
            };
        },

        beforeLeaveDeactive() {
            window.onbeforeunload = null;
        },

        handleLoad(editor, prefix, suffix, refs) {
            this.editor = editor;
            this.documentRefs = refs;
        },

        handleMakeChanges() {
            this.disabled = false;
            this.selectedCompareContent = null;

            this.quillHeights = '420px';
            this.quillPrefixWatermark = 'Prefix';
            this.quillPrefixHoverText = 'You are unable to edit the Prefix';
            this.quillSuffixWatermark = 'Suffix';
            this.quillSuffixHoverText = 'You are unable to edit the Suffix';
        },

        handleUpload() {
            this.upload = true;
        },

        handleDiscardChanges() {
            this.$confirm(
                {
                    title: 'Discard Changes',
                    message: 'Are you sure you wish to discard your changes?',
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Discard',
                    callback: confirm => {
                        if (confirm) {
                            this.quillHeights = 'auto';
                            this.quillPrefixWatermark = null;
                            this.quillPrefixHoverText = null;
                            this.quillSuffixWatermark = null;
                            this.quillSuffixHoverText = null;
                            this.disabled = true;
                            this.upload = false;
                            this.selectedCompareContent = null;
                            this.form.document = null;

                            this.refresh();
                        }
                    },
                },
            );
        },

        handleClickChange(placeholder, changes, startingContent, endingContent) {
            this.currentChanges = {placeholder, changes, endingContent, startingContent};
            this.$refs.changeModal.openModal();
        },

        async handleCommentClick(contractID) {
            this.$refs.commentsModal.closeModal();
            const previous = this.selectedContract.content;

            await this.refresh();

            if (contractID !== this.contract.id) {
                this.selectedCompareContent = previous;
                this.$refs.editor.openCompare();
            }
        },

        handlePdfView() {
            window.open(this.$route('contract.pdf', this.selectedContract.id), '_blank', 'noreferrer');
        },

        handleFetchPdf() {
            this.$confirm(
                {
                    title: 'Fetch Pdf',
                    message: 'You are requesting to fetch the PDF from docusign again. Please confirm to continue..',
                    confirmBtnClass: 'btn--primary',
                    confirmBtnText: 'Fetch',
                    callback: (confirm) => {
                        if (confirm) {
                            this.loading = true;
                            this.$inertia.get(this.$route('contract.fetchPdf', this.selectedContract.id), {
                                onSuccess: () => {
                                    this.loading = false;
                                    this.handlePdfView();
                                },
                            });
                        }
                    },
                },
            );
        },

        handleNextTo() {
            const nextAfterGroup = this.nextAfterGroup();

            if (this.isNextToSign && !this.allAccepted && nextAfterGroup && nextAfterGroup.id !== this.selectedContract.nextToSign.id) {
                const name = `${this.selectedContract.nextToSign.name !== 'sequest' ? 'a ' : ''}${this.selectedContract.nextToSign.label}`;
                const nextName = `${nextAfterGroup.name !== 'sequest' ? 'the ' : ''}${nextAfterGroup.label}`;

                this.$confirm(
                    {
                        title: 'Document State',
                        message: `As ${name} you are are currently in control of this document. This means you can "Accept", "Reject", "Edit" or pass to ${nextName} to make any changes.`,
                        confirmBtnClass: 'btn--primary',
                        confirmBtnText: `Pass to ${nextName}`,
                        callback: confirm => {
                            if (confirm) {
                                this.$inertia.post(this.$route('contract.pass', this.selectedContract.id), {}).then(() => {
                                    this.refresh();
                                });
                            }
                        },
                    },
                );
            }
        },

        openFiles() {
            this.fileType = 'files';
            this.$refs.fileModal.openModal();
        },

        openCompare() {
            this.fileType = 'compare';
            this.$refs.fileModal.openModal();
        },

        openSignatures() {
            this.$refs.signaturesModal.openModal();
        },

        openAuditLog() {
            this.$refs.commentsModal.openModal();
        },

        onRowClick({ row }) {
            if (this.fileType === 'files') {
                this.loadContract(row);
            } else if (this.fileType === 'compare') {
                this.onRowClickCompare(row);
            }
        },

        loadContract({ id, item_id }) {
            return new Promise(resolve => {
                this.loading = true;
                this.loadingHeight = this.$refs.modal.offsetHeight;
                this.documentValue = null;
                this.$refs.fileModal.closeModal();
                this.form.document = null;
                const item_type = this.contract.item_type;

                this.$inertia.get(this.$route(`${item_type}.show.contracts`, item_id), {contractID: id}, {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['contract'],
                    replace: true,
                    onSuccess: () => {
                        this.loading = false;
                        this.selectedContract = this.$page.props.contract;

                        if (this.selectedContract.interface_type === 'upload') {
                            this.documentUrl = this.selectedContract.document;
                            this.upload = this.documentUrl === null && this.isNextToSign ? true : false;
                        } else {
                            this.documentValue = this.setDocumentValue(this.selectedContract);
                        }

                        resolve();
                    },
                });
            });
        },

        onRowClickCompare({ id, item_id }) {
            this.$refs.fileModal.closeModal();
            const item_type = this.contract.item_type;

            this.$inertia.get(this.$route(`${item_type}.show.contracts`, item_id), {contractID: id}, {
                preserveState: true,
                preserveScroll: true,
                only: ['contract'],
                replace: true,
                onSuccess: () => {
                    const compare = this.$page.props.contract;

                    this.selectedCompareContent = compare.content;
                    this.$refs.editor.openCompare();
                },
            });
        },

        setDocumentValue(contract) {
            this.prefix = contract.prefix;
            this.suffix = contract.suffix;
            this.allowChanges = contract.id === this.contract.id;

            return {
                content: contract.content,
                comments: contract.annotations.map(annotation => ({
                    index: annotation.index,
                    length: annotation.length,
                    comments: this.formatComment(annotation.comment),
                })),
            };
        },

        formatComment(comment) {
            return {
                id: comment.id,
                comment: comment.comment,
                user: comment.user,
                company: comment.company,
                replies: comment.replies ? comment.replies.map(reply => this.formatComment(reply)) : [],
            };
        },

        submitForm() {
            if (this.isNextToSign) {
                const nextAfterGroup = this.nextAfterGroup(false);
                let nextName = 'the next user';

                if (nextAfterGroup) {
                    nextName = `${nextAfterGroup.name !== 'sequest' ? 'the ' : ''}${nextAfterGroup.label}`;
                }

                this.$confirm(
                    {
                        title: 'Document Save',
                        message: `Saving this document will pass control to ${nextName} to make any changes. You will still be able to comment during this time.`,
                        confirmBtnClass: 'btn--primary',
                        confirmBtnText: `Save and pass to ${nextName}`,
                        callback: confirm => {
                            if (confirm) {
                                this.quillHeights = 'auto';
                                this.quillPrefixWatermark = null;
                                this.quillPrefixHoverText = null;
                                this.quillSuffixWatermark = null;
                                this.quillSuffixHoverText = null;
                                this.$emit('save', this.documentValue, 'published');
                            }
                        },
                    },
                );
            }
        },

        submitUploadForm() {
            if (this.isNextToSign) {
                const nextAfterGroup = this.nextAfterGroup(false);
                let nextName = 'the next user';

                if (nextAfterGroup) {
                    nextName = `${nextAfterGroup.name !== 'sequest' ? 'the ' : ''}${nextAfterGroup.label}`;
                }

                this.$confirm(
                    {
                        title: 'Document Save',
                        message: `Saving this document will pass control to ${nextName} to make any changes. You will still be able to comment during this time.`,
                        confirmBtnClass: 'btn--primary',
                        confirmBtnText: `Save and pass to ${nextName}`,
                        callback: confirm => {
                            if (confirm) {
                                this.$emit('save', this.form, 'published');
                            }
                        },
                    },
                );
            }
        },

        submitComment() {
            if (this.disabled) {
                this.$emit('comment', this.documentValue);
            }
        },

        handleAcceptChanges() {
            this.$confirm(
                {
                    title: 'Accept Contract',
                    message: 'By accepting this contract we will ask all other parties included to also accept. The document will then be sent over to an offical signing service for last approval. Are you sure you wish to accept this version of the contract?',
                    confirmBtnClass: 'btn--primary',
                    confirmBtnText: 'Accept',
                    callback: confirm => {
                        if (confirm) {
                            this.loading = true;
                            this.$inertia.post(this.$route('contract.sign', this.contract.id), {
                                'accepted': true,
                            }).then(() => {
                                this.refresh();
                            });
                        }
                    },
                },
            );
        },

        handleRejectChanges() {
            this.$confirm(
                {
                    title: 'Refuse Contract',
                    message: 'By rejecting this contract we will inform the all other parties inluded. Below you can add any additional reasons.',
                    reason: true,
                    confirmBtnClass: 'btn--red',
                    confirmBtnText: 'Reject',
                    callback: (confirm, reason) => {
                        if (confirm) {
                            this.loading = true;
                            this.$inertia.post(this.$route('contract.sign', this.contract.id), {
                                'reason': reason,
                                'accepted': false,
                            }).then(() => {
                                this.refresh();
                            });

                        }
                    },
                },
            );
        },

        nextAfterGroup(ignoreSigned = true) {
            let next = false,
                signaturesRequired = this.selectedContract.signaturesRequired;

            if (ignoreSigned) {
                const signedIds = this.selectedContract.signatures.map(signature => signature.group.id);

                signaturesRequired = signaturesRequired.filter(signature => !signedIds.includes(signature.id));
            }

            signaturesRequired.forEach((group, index) => {
                if (group.id === this.selectedContract.nextToSign.id) {
                    if (signaturesRequired[index + 1]) {
                        next = signaturesRequired[index + 1];
                    } else {
                        next = signaturesRequired[0];
                    }
                }
            });

            return next;
        },

        async refresh(id = this.contract.id) {
            return new Promise((resolve) => {
                this.disabled = true;
                this.upload = false;
                this.selectedCompareContent = null;
                this.loadContract({
                    id: id,
                    item_id: this.contract.item_id,
                });

                resolve();
            });
        },

        setLoading() {
            this.loading = true;
        },

        setLoaded() {
            this.loading = false;
        },
    },
};
</script>
