<template>
    <div
        class="reportMap"
        :class="{'reportMap--download' : isDownload}"
    >
        <div
            v-if="label"
            class="dashboard-heading flex-space-between"
        >
            <h6>{{ label }}</h6>
        </div>
        <div class="map">
            <mapping-tool
                fit-bounds="all"
            >
                <mapping-tool-schema
                    :schema="toSchema"
                />
            </mapping-tool>
        </div>
    </div>
</template>

<script>
import mapMixin from 'Mixins/map';

export default {
    name: 'ReportMap',

    mixins: [
        mapMixin,
    ],

    props: {
        label: {
            typs: String,
            default: null,
        },
        type: {
            type: String,
            required: true,
            validator: function(value) {
                return ['plots', 'projects', 'rental'].indexOf(value) !== -1;
            },
        },

        zoom: {
            type: Number,
            default: 10,
        },

        lpa: {
            type: Object,
            default: null,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },

        plots: {
            type: Array,
            default: () => ([]),
        },
        projects: {
            type: Array,
            default: () => ([]),
        },
        rental: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            mapLoaded: false,
        };
    },

    computed: {
        toSchema() {
            const schema = [
                ...this.planningAuthorities,
                ...this.riverBasinDistricts,
                ...(this.$page.props.loggedUser.isSuperUser ? this.nationalCharacterAreas : []),
                ...this.computedProjects,
                ...this.computedPlots,
                ...this.computedLeases,
            ];

            return schema;
        },

        computedProjects() {
            if (this.type === 'rental' && this.projects.length === 0) {
                return [this.projectToSchema(this.rental.project)];
            }

            return this.projects.map(project => this.projectToSchema(project));
        },
        computedPlots() {
            if (this.type === 'rental' && this.plots.length === 0) {
                return [this.projectToSchema(this.rental.plot)];
            }

            return this.plots.map(plot => this.projectToSchema(plot));
        },
        computedLeases() {
            if (this.type === 'rental' && Object.keys(this.rental).length > 0) {
                return [this.rentalToSchema(this.rental)];
            }

            return [];
        },
        planningAuthorities() {
            const lpas = [
                ...this.plots.filter(plot => plot.local_planning_authority).map(plot => plot.local_planning_authority),
                ...this.projects.filter(project => project.local_planning_authority).map(project => project.local_planning_authority),
            ];

            if (this.lpa) {
                lpas.push(this.lpa);
            }

            const vectorData = this.mapServiceProperties['lpa'];

            const filter = [{
                property: vectorData.properties.name,
                compare: '=',
                type: 'OR',
                value: lpas.map(lpa => [lpa.name.replace(' LPA', ''), lpa.name, `${lpa.name} LPA`]).flat(),
            }];

            const vector = this.vectorToSchema({
                name: 'lpa',
                filter: filter,
                color: 'lpa',
                strokeColor: 'lpa',
            });

            if (filter[0].value.length) {
                return [vector];
            }

            return [];
        },
        riverBasinDistricts() {
            const rivers = [
                ...this.plots.filter(plot => plot.river_basin_district).map(plot => plot.river_basin_district),
                ...this.projects.filter(plot => plot.river_basin_district).map(plot => plot.river_basin_district),
            ];

            const vectorData = this.mapServiceProperties['surface_water_operational_catchments'];

            const filter = [{
                property: vectorData.properties.name,
                compare: '=',
                type: 'OR',
                value: rivers.map(lpa => lpa.name),
            }];

            const vector = this.vectorToSchema({
                name: 'surface_water_operational_catchments',
                filter: filter,
                color: 'river_basin_districts',
                strokeColor: 'river_basin_districts',
            });

            if (filter[0].value.length) {
                return [vector];
            }

            return [];
        },
        nationalCharacterAreas() {
            const rivers = [
                ...this.computedPlots.filter(plot => plot.national_character_areas).map(plot => plot.national_character_areas),
                ...this.computedProjects.filter(plot => plot.national_character_areas).map(plot => plot.national_character_areas),
            ];

            const vectorData = this.mapServiceProperties['national_character_areas'];

            const filter = [{
                property: vectorData.properties.name,
                compare: '=',
                type: 'OR',
                value: rivers.map(lpa => lpa.name),
            }];

            const vector = this.vectorToSchema({
                name: 'national_character_areas',
                filter: filter,
                color: 'national_character_areas',
                strokeColor: 'national_character_areas',
            });

            if (filter[0].value.length) {
                return vector;
            }

            return [];
        },
    },

    methods:{},
};
</script>
