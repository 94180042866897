var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    ref: "confirmModal",
    staticClass: "modal-confirm",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h3", [_vm._v(_vm._s(_vm.title))])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
            _vm._v(" "),
            _vm.reason
              ? _c(
                  "div",
                  [
                    _c("quillEditor", {
                      ref: "quilEditor",
                      attrs: {
                        options: {
                          modules: {
                            toolbar: false,
                          },
                        },
                      },
                      model: {
                        value: _vm.reasonText,
                        callback: function ($$v) {
                          _vm.reasonText = $$v
                        },
                        expression: "reasonText",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "btn--group" }, [
              _c(
                "button",
                {
                  staticClass: "btn-reset btn",
                  class: _vm.cancelBtnClass,
                  attrs: { id: "cancel" },
                  on: { click: _vm._cancel },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.cancelBtnText) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-reset btn",
                  class: _vm.confirmBtnClass,
                  attrs: { id: "confirm" },
                  on: { click: _vm._confirm },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.confirmBtnText) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }