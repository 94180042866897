var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-page-info" }, [
    _c("h3", [_vm._v(_vm._s(_vm.welcomeText))]),
    _vm._v(" "),
    _c("p", [_vm._v("\n        " + _vm._s(_vm.descriptionText) + "\n    ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }