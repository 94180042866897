class confirmRequest {
    constructor(inertia) {
        this.inertia = inertia;
        this.allowLeave = true;
        this.message = null;
        this.previousURL = null;

        window.onbeforeunload = () => this.handleBeforeUnload();
        this.inertia.on('before', (event) => this.handleInertia(event));
    }

    enable(message = 'Are you sure you want to navigate away and lose any changes you have made?') {
        this.message = message;
        this.allowLeave = false;
    }

    disable() {
        this.message = null;
        this.allowLeave = true;
    }

    check(confirmCallback, cancelCallback) {
        if (this.allowLeave || confirm(this.message)) {
            if (typeof confirmCallback === 'function') {
                confirmCallback();
                this.disable();
            }
        } else if (typeof cancelCallback === 'function') {
            cancelCallback();
        }
    }

    handleInertia(event) {
        if (
            ['','get'].includes(event.detail.visit.method) &&
            event &&
            event.detail.visit.replace === false &&
            this.previousURL !== event.detail.visit.url.pathname
        ) {
            this.previousURL = event.detail.visit.url.pathname;

            if (!this.allowLeave) {
                if (confirm(this.message)) {
                    this.disable();
                } else {
                    event.preventDefault();
                }
            }
        }
    }

    handleBeforeUnload() {
        if(!this.allowLeave) {
            return this.message;
        }
    }
}

export default confirmRequest;
