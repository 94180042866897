<!-- eslint-disable vue/no-v-html -->
<template>
    <div>
        <div v-if="contracts.length > 0 || contractsPlot.length > 0 || contractsProject.length > 0">
            <contracts-table
                title="Agreement Contract"
                new-text="New Contract"
                :allow-upload="$page.props.loggedUser.isSuperUser"
                :allow-delete="$page.props.loggedUser.isSuperUser"
                :contracts="contracts"
                :parent="parent"
                @onRowClick="openEditorModal"
            />
            <contracts-table
                v-if="contractsProject.length"
                title="Related Development Contracts"
                :columns="linkedColumns"
                :allow-upload="false"
                :allow-delete="false"
                :contracts="contractsProject"
                :parent="parent"
                @onRowClick="goToContract"
            />
            <contracts-table
                v-if="contractsPlot.length"
                title="Related Land Parcel Contracts"
                :columns="linkedColumns"
                :allow-upload="false"
                :allow-delete="false"
                :contracts="contractsPlot"
                :parent="parent"
                @onRowClick="goToContract"
            />
        </div>
        <empty
            v-else
            type="contracts"
        >
            <button
                v-if="$page.props.loggedUser.isSuperUser"
                class="btn-reset btn btn--primary"
                @click="openFileModal"
            >
                New Contract
            </button>
        </empty>
        <modal
            ref="editor"
            width="1300px"
            padding="0"
            :confirm-close="confirmClose"
            confirm-close-header="Close Contract"
            :show-header="false"
            :close-floating="true"
            @close="handleModalClose"
        >
            <template #header />
            <template #body>
                <ContractEditor
                    ref="contractEditor"
                    :contract="selectedContract"
                    @comment="submitComment"
                    @toggleEditor="handleToggleEditor"
                    @save="submitForm"
                />
            </template>
        </modal>
        <contract-upload
            ref="fileModal"
            :parent="parent"
        />
        <modal ref="payment">
            <template #header>
                <h3>Payment Required</h3>
            </template>
            <template #body>
                <p>Thank you for uploading your signed contract. In order to complete the transaction you now need to make the agreed payment to our account.</p>
                <p>Our bank details are:</p>
                <span
                    v-html="settings['bank-details'].value"
                />
            </template>
        </modal>
    </div>
</template>

<script>
import Empty from 'Utilities/empty/Empty';
import ContractUpload from 'Utilities/contract/Upload';
import Modal from 'Utilities/modal/Modal';
import ContractEditor from 'Utilities/contract/Editor';
import ContractsTable from 'Utilities/contract/Table';
import { nextTick } from 'vue';

export default {
    name: 'Contracts',
    components: {
        Empty,
        ContractUpload,
        Modal,
        ContractEditor,
        ContractsTable,
    },
    props: {
        contracts: {
            type: Array,
            default: () => ([]),
        },
        contractsPlot: {
            type: Array,
            default: () => ([]),
        },
        contractsProject: {
            type: Array,
            default: () => ([]),
        },
        parent: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            selectedContract: null,
            confirmClose: null,
            settings: this.$page.props.settings,
            linkedColumns: [{
                label: 'Type',
                field: 'type',
            },{
                label: 'Status',
                field: 'status',
                spanClass: 'status-pill type-{status}',
                sortable: false,
            }],
        };
    },
    methods: {
        openFileModal() {
            this.$refs.fileModal.openModal();
        },

        goToContract({ row }) {
            this.$inertia.visit(route(`${row.item_type}.show`, row.item_id) + '#contracts');
        },

        openEditorModal({ row }) {
            row = {...row};
            this.selectedContract = row;
            this.$refs.editor.openModal();
        },

        handleToggleEditor(editable) {
            if (editable) {
                this.confirmClose = 'Are you sure you want to close? Any unsaved changes will be lost';
            } else {
                this.confirmClose = null;
            }
        },

        handleModalClose() {
            this.confirmClose = null;
            this.$refs.contractEditor.disableStopLeave();
        },

        async submitForm(value, saveType) {
            const data = {
                type: this.selectedContract.type,
                item_id: this.selectedContract.item_id,
                item_type: this.selectedContract.item_type,
                comments: value.comments,
                content_status: saveType,
            };

            if (this.selectedContract.interface_type === 'upload') {
                if (value.document) data.document = await value.document.upload();
            } else {
                data.content = value.content;
            }

            this.$inertia.post(this.$route('contract.store'), data, {
                onSuccess: async (response) => {
                    const type = this.selectedContract.type.replace(/\s/g, '');
                    const contract = response.props.rental[type.charAt(0).toLowerCase() + type.slice(1)];

                    if (contract) {
                        this.handleToggleEditor(false);
                        this.$refs.contractEditor.disableStopLeave();
                        this.selectedContract = contract;
                        this.$refs.contractEditor.refresh(contract.id);
                    } else {
                        this.confirmClose = null;
                        await nextTick();
                        this.$refs.editor.closeModal();
                    }
                },
            });
        },

        submitComment(value) {
            const data = {
                item_id: this.selectedContract.item_id,
                item_type: this.selectedContract.item_type,
                comments: value.comments,
            };

            this.$inertia.put(this.$route('contract.update', this.selectedContract.id), data, {
                onSuccess: () => {
                    this.$refs.fileModal.closeModal();
                },
            });
        },
    },
};
</script>
