var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.formAllow.user_creation
        ? [
            _vm._m(0),
            _vm._v(" "),
            _c("FormulateInput", {
              attrs: {
                type: "text",
                name: "first_name",
                label: "First Name",
                validation: "required",
                "validation-name": "First Name",
              },
            }),
            _vm._v(" "),
            _c("FormulateInput", {
              attrs: {
                type: "text",
                name: "last_name",
                label: "Surname",
                validation: "required",
                "validation-name": "Surname",
              },
            }),
            _vm._v(" "),
            _c("FormulateInput", {
              attrs: {
                type: "email",
                name: "email",
                label: "Email Address",
                validation: "required|email",
                "validation-name": "Email address",
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.formAllow.company_creation
        ? _c("FormulateInput", {
            attrs: {
              type: "text",
              name: "company_id",
              label: "Company Name",
              validation: "required",
              "validation-name": "Company Name",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-page-info" }, [
      _c("p", [
        _vm._v(
          "\n                Please enter new user details below.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }