<template>
    <div>
        <template v-if="formAllow.user_creation">
            <div class="form-page-info">
                <p>
                    Please enter new user details below.
                </p>
            </div>
            <FormulateInput
                type="text"
                name="first_name"
                label="First Name"
                validation="required"
                validation-name="First Name"
            />
            <FormulateInput
                type="text"
                name="last_name"
                label="Surname"
                validation="required"
                validation-name="Surname"
            />
            <FormulateInput
                type="email"
                name="email"
                label="Email Address"
                validation="required|email"
                validation-name="Email address"
            />
        </template>
        <FormulateInput
            v-if="formAllow.company_creation"
            type="text"
            name="company_id"
            label="Company Name"
            validation="required"
            validation-name="Company Name"
        />
    </div>
</template>

<script>
export default {
    name: 'InviteFormAccount',

    props: {
        formAllow: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
