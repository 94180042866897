var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "reportMap",
      class: { "reportMap--download": _vm.isDownload },
    },
    [
      _vm.label
        ? _c("div", { staticClass: "dashboard-heading flex-space-between" }, [
            _c("h6", [_vm._v(_vm._s(_vm.label))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "map" },
        [
          _c(
            "mapping-tool",
            { attrs: { "fit-bounds": "all" } },
            [_c("mapping-tool-schema", { attrs: { schema: _vm.toSchema } })],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }