<template>
    <div v-if="context">
        <div class="form-flex--tab form-flex--vcenter">
            <FormulateInput
                key="nutrient_development_type_2"
                type="select-plugin"
                name="nutrient_development_type"
                label="Type of Development"
                validation-name="Type of Development"
                :options="nutrientOptions"
                :disabled="disabled"
            />
            <FormulateInput
                key="nutrient_development_amount_2"
                type="number"
                name="nutrient_development_amount"
                :label="typeNumberAmount"
                validation-name="Development Amount"
                :disabled="disabled"
            />
            <FormulateInput
                v-if="value.planning_status && value.planning_status !== 'pre-application'"
                key="planning_ref_2"
                type="text"
                name="planning_ref"
                label="Planning reference number"
                validation="required"
                validation-name="Planning reference number"
                :disabled="!editable"
            />
        </div>
        <FormulateInput
            key="nutrient_planning_status_2"
            type="select-plugin"
            name="nutrient_planning_status"
            label="Planning Status"
            validation-name="Planning Status"
            :options="nutrientStatusOptions"
            :disabled="disabled"
        />
        <FormulateInput
            key="nutrient_types_2"
            type="select-plugin"
            name="nutrient_types"
            label="Nutrient Types"
            validation-name="Nutrient Types"
            :multiple="true"
            :options="nutrientTypeOptions"
            :disabled="disabled"
        />
        <FormulateInput
            v-if="value.nutrient_types && value.nutrient_types.length"
            key="nutrient_study_2"
            type="toggle-checkbox"
            name="nutrient_study"
            label="have you calculated nutrients associated with development?"
        />
        <template v-if="value.nutrient_study">
            <div class="form-flex--tab form-flex--vcenter">
                <template v-for="type in value.nutrient_types">
                    <div :key="`nutrient_types_${type}`">
                        <div class="formulate-heading">
                            <h6>{{ type }}</h6>
                        </div>
                        <div class="form-flex--tab form-flex--vcenter">
                            <div class="form-flex--tab">
                                <div class="formulate-size formulate-size--page-2">
                                    <div class="formulate-input-wrapper">
                                        <FormulateInput
                                            :key="`nutrient_types_pre_${type}_type`"
                                            type="number"
                                            :name="`nutrient_types_pre_${type}_type`"
                                            label="Pre AMP7"
                                            validation-name="Pre AMP7"
                                            :options="nutrientTypeOptions"
                                            :disabled="disabled"
                                        />
                                        <div class="formulate-input-info">
                                            <p>kg</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-flex--tab">
                                <div class="formulate-size formulate-size--page-2">
                                    <div class="formulate-input-wrapper">
                                        <FormulateInput
                                            :key="`nutrient_types_post_${type}_type`"
                                            type="number"
                                            :name="`nutrient_types_post_${type}_type`"
                                            label="Post AMP7"
                                            validation-name="Post AMP7"
                                            :options="nutrientTypeOptions"
                                            :disabled="disabled"
                                        />
                                        <div class="formulate-input-info">
                                            <p>kg</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <FormulateInput
                v-if="value.nutrient_study"
                key="nutrient_calculator_2"
                type="file"
                name="nutrient_calculator"
                :label="value.nutrient_calculator.length ? 'Nutrient Calculator Document' : ''"
                accept=".xls,.xlsx,.xlsm,.xlsb"
                upload-url="/attachments/upload"
                upload-behavior="live"
                @file-upload-complete="uploadComplete"
            >
                <template #uploadAreaMask="{ hasFiles }">
                    <DropZone v-if="!hasFiles">
                        Attach Nutrient Calculator
                    </DropZone>
                </template>
            </FormulateInput>
        </template>
    </div>
</template>

<script>
import DropZone from 'Utilities/file-convert/DropZone';

export default {
    name: 'ProjectFormPage2',
    components: {
        DropZone,
    },
    props: {
        context: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => {},
        },
        planningAuthorities: {
            type: Array,
            default: () => [],
        },
        riverBasinDistricts: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            pageShowing: false,
            nutrientCalculator: {},
        };
    },
    computed: {
        nutrientOptions() {
            return ['Residential', 'Care homes'];
        },
        nutrientTypeOptions() {
            return ['Phosphate', 'Nitrate'];
        },
        nutrientStatusOptions() {
            return ['Pre-application', 'Submitted', 'Resolution to approve', 'Approved'];
        },
        typeNumberAmount() {
            return this.value.nutrient_development_type === 'Residential' ? 'Number of residential units' : 'Number of rooms';
        },
    },
    watch: {
        'nutrientCalculator.path'(value) {
            if (value) {
                this.$emit('file-upload', 'nutrient_calculator', this.nutrientCalculator);
            }
        },
        '$parent.showElement'() {
            this.pageShowing = this.$parent.showElement;
        },
    },
    methods: {
        uploadComplete(event) {
            this.nutrientCalculator = event;
        },
    },
};
</script>

<style>
    .formulate-size--page-2 .formulate-input-wrapper .formulate-input[data-classification=text] input {
        padding-right: 4em;
    }
</style>
