var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { class: `convert-file convert-file--${_vm.state}` }, [
    _vm.label
      ? _c("label", [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "convert-file-input" },
      [
        _c(
          "DropZone",
          { attrs: { "document-name": _vm.documentName } },
          [
            _vm.state !== "done" && _vm.state !== "waiting"
              ? _c(
                  "processing",
                  { attrs: { spinner: true, dots: true, tag: "div" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.state) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "file", accept: ".xls,.xlsx,.xlsm,.xlsb" },
          on: { change: _vm.documentChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }