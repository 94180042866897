var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "formulate-heading flex-space-between" }, [
        _c("h6", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _vm.allowUpload
          ? _c(
              "button",
              {
                staticClass:
                  "btn-reset btn--link btn--link--green btn--link--icon--left",
                on: {
                  click: function ($event) {
                    return _vm.openFileModal()
                  },
                },
              },
              [
                _c("SvgController", { attrs: { type: "plus" } }),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.newText || `New ${_vm.title}`) +
                    "\n        "
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("TableGridLayout", {
        attrs: {
          columns: _vm.columnsList,
          rows: _vm.contractList,
          output: ["table"],
          "total-records": _vm.contractList.length,
          "grid-layout-for-table": _vm.gridLayout,
        },
        on: { onRowClick: _vm.onRowClick },
      }),
      _vm._v(" "),
      _c("contract-upload", {
        ref: "fileModal",
        attrs: { parent: _vm.parent },
        on: { afterUpdate: _vm.afterUpdate },
      }),
      _vm._v(" "),
      _c("contract-upload", {
        ref: "signedModal",
        attrs: {
          parent: _vm.parent,
          "is-signed": true,
          contract: _vm.contract,
        },
        on: { afterUpdate: _vm.afterUpdate },
      }),
      _vm._v(" "),
      _c("contract-delete", {
        ref: "deleteModal",
        attrs: {
          parent: _vm.parent,
          contract: _vm.contract,
          attachments: _vm.attachmentHistory,
        },
        on: { afterUpdate: _vm.afterUpdate },
      }),
      _vm._v(" "),
      _c("contract-edit", {
        ref: "editModal",
        attrs: { contract: _vm.contract },
        on: { afterUpdate: _vm.afterUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }