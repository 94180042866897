// Inputs
import FormulateVueDatetime from './Inputs/Datetime';
import FormulateVueSelect from './Inputs/Select';
import FormulateVueMap from './Inputs/Map';
import FormulateVueBngMap from './Inputs/BngMap';
import FormulateTogglebutton from './Inputs/ToggleButton';
import FormulateCheckboxButton from './Inputs/ToggleCheckbox';
import FormulateDateRange from './Inputs/Daterange';
import FormulateRichText from './Inputs/RichText';
import FormulateSelectButton from './Inputs/SelectButton';
import FormulateRangeBetween from './Inputs/RangeBetween';
import FormulateUserSearch from './Inputs/UserSearch';
import FormulateFake from './Inputs/Fake';

// Slots
import FormulateDescription from './slots/ToggleDescription';

export default function FormulateVueInputPlugins(formulateInstance) {
    formulateInstance.extend({
        components: {
            FormulateVueDatetime,
            FormulateVueSelect,
            FormulateTogglebutton,
            FormulateDescription,
            FormulateVueMap,
            FormulateVueBngMap,
            FormulateCheckboxButton,
            FormulateDateRange,
            FormulateRichText,
            FormulateSelectButton,
            FormulateRangeBetween,
            FormulateUserSearch,
            FormulateFake,
        },
        library: {
            'datetime-plugin': {
                classification: 'text',
                component: 'FormulateVueDatetime',
                slotProps: {
                    component: [
                        'hideYear',
                        'format',
                        'flow',
                        'valueZone',
                    ],
                },
            },
            'select-plugin': {
                classification: 'select',
                component: 'FormulateVueSelect',
                slotProps: {
                    component: ['reduceKey', 'labelKey', 'multiple'],
                },
            },
            'map': {
                classification: 'hidden',
                component: 'FormulateVueMap',
                slotProps: {
                    component: [
                        'returnAdress',
                        'color',
                        'opacity',
                        'stroke',
                        'strokeColor',
                        'strokeOpacity',
                        'pattern',
                        'patternColor',
                        'patternColorAlt',
                        'sidebarWidth',
                        'sidebar',
                        'upload',
                        'vectors',
                        'schema',
                        'fitBounds',
                        'fitBoundsUpdate',
                    ],
                },
            },
            'bngMap': {
                classification: 'hidden',
                component: 'FormulateVueBngMap',
                slotProps: {
                    component: [
                        'sidebarWidth',
                        'sidebar',
                        'upload',
                    ],
                },
            },
            'toggle-button': {
                classification: 'button',
                component: 'FormulateTogglebutton',
                slotComponents: {
                    label: 'FormulateDescription',
                },
                slotProps: {
                    component: ['color', 'disabled', 'tooltipHelp', 'svgPattern'],
                    label: ['description'],
                },
            },
            'toggle-checkbox': {
                classification: 'checkbox',
                component: 'FormulateCheckboxButton',
                slotProps: {
                    component: ['disabled'],
                },
            },
            'daterange-plugin': {
                classification: 'text',
                component: 'FormulateDateRange',
                slotProps: {
                    component: [
                        'showClear',
                        'pickerType',
                        'disabled',
                    ],
                },
                slotComponents: {
                    test: 'FormulateDescription',
                },
            },
            'rich-text': {
                classification: 'select',
                component: 'FormulateRichText',
                slotProps: {
                    component: ['editorOptions'],
                },
            },
            'select-button': {
                classification: 'button',
                component: 'FormulateSelectButton',
                slotProps: {
                    component: ['disabled'],
                },
            },
            'range-between': {
                classification: 'range',
                component: 'FormulateRangeBetween',
                slotProps: {
                    component: [
                        'min',
                        'max',
                        'barColor',
                        'activeBarColor',
                        'maxBetween',
                        'editValues',
                        'allowOverMax',
                        'disabled',
                    ],
                },
            },
            'user-search': {
                classification: 'text',
                component: 'FormulateUserSearch',
                slotProps: {
                    component: [
                        'endpoint',
                        'allowCustom',
                        'customValidation',
                        'customString',
                    ],
                },
            },
            'fake': {
                classification: 'text',
                component: 'FormulateFake',
                slotProps: {
                    component: ['disabled', 'parseValue'],
                },
            },
        },
    });
}
