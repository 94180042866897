<template>
    <auth-layout :component="$options.name">
        <template #button>
            <span>Already have an account?</span>
            <button
                type="button"
                class="btn btn--primary"
                @click="$inertia.visit($route('login'))"
            >
                Login
            </button>
        </template>
        <terms
            :show="showTerms"
            @close="showTerms = false"
        />
        <div v-show="!showTerms">
            <FormulateForm
                :errors="form.errors"
                @submit="onSubmit"
            >
                <div class="form-page bg-green">
                    <h3><span>1</span> Select your account type</h3>
                    <div class="account-select">
                        <FormulateInput
                            v-for="group in groups"
                            :id="group.label"
                            :key="group.id"
                            v-model="form.group_id"
                            :value="group.id"
                            type="radio"
                            name="group"
                            class="account-select-item"
                        >
                            <template #label>
                                <GroupLabel
                                    :group="group"
                                    :selected="group.id === form.group_id"
                                />
                            </template>
                        </FormulateInput>
                    </div>
                </div>
                <div class="form-page bg-white">
                    <h3><span>2</span> Tell us about yourself</h3>
                    <FormulateInput
                        v-model="form.company_id"
                        type="text"
                        name="company"
                        label="Company Name"
                        validation="required"
                        validation-name="Company Name"
                    />
                    <FormulateInput
                        v-model="form.company_number"
                        type="text"
                        name="company_number"
                        label="Company Number"
                        validation-name="Company Number"
                    />
                    <FormulateInput
                        v-model="form.company_address"
                        type="text"
                        name="company_address"
                        label="Company Address"
                        validation-name="Company Address"
                    />
                    <FormulateInput
                        v-model="form.country_code"
                        type="select-plugin"
                        name="country_code"
                        validation="required"
                        label="Country"
                        validation-name="Country"
                        reduce-key="code"
                        label-key="name"
                        :options="countries"
                        placeholder="Country"
                    />
                    <FormulateInput
                        v-if="form.country_code === 'GB' && (form.group_id === 4 || form.group_id === 3)"
                        v-model="form.associated_lpa"
                        type="select-plugin"
                        name="associated_lpa"
                        label="Associated Local Planning Authorities"
                        validation-name="Local Planning Authority"
                        reduce-key="auth_id"
                        label-key="name"
                        :options="planningAuthoritiesSelect"
                        :multiple="true"
                        placeholder="Associated LPA's"
                    />
                    <FormulateInput
                        v-model="form.first_name"
                        type="text"
                        name="first_name"
                        label="First Name"
                        validation="required"
                        validation-name="First Name"
                    />
                    <FormulateInput
                        v-model="form.last_name"
                        type="text"
                        name="last_name"
                        label="Surname"
                        validation="required"
                        validation-name="Surname"
                    />
                    <FormulateInput
                        v-model="form.email"
                        type="email"
                        name="email"
                        label="Email Address"
                        validation="required|email"
                        validation-name="Email address"
                    />
                    <FormulateInput
                        v-model="form.password"
                        type="password"
                        name="password"
                        label="Password"
                        validation="required|min:8,length"
                    />
                    <FormulateInput
                        v-model="form.password_confirmation"
                        type="password"
                        name="password_confirmation"
                        label="Password Confirmation"
                        validation="required|min:8,length|confirm:password"
                        validation-name="Password confirmation"
                    />
                    <FormulateInput
                        id="terms"
                        v-model="form.terms"
                        :value="form.terms"
                        type="checkbox"
                    >
                        <template #label>
                            <label for="terms">
                                I agree to the terms of the
                                <a
                                    href="#"
                                    @click="showTerms = true"
                                >
                                    BNG Partnership Customer Agreement
                                </a>
                            </label>
                        </template>
                    </FormulateInput>
                    <FormulateInput
                        type="submit"
                        label="Sign Up"
                    />
                </div>
            </FormulateForm>
        </div>
        <template v-if="form.errors.email_exists">
            <p>An account with that email already exists.</p>
            <p v-if="form.errors.email_exists === 'verified'">
                <button
                    type="button"
                    class="btn-reset"
                    @click="$inertia.visit($route('login'))"
                >
                    Login
                </button>
            </p>
            <p v-else-if="form.errors.email_exists === 'unverified'">
                The account has not been verified.
                <br>
                You can verify your email address by clicking on the link we just emailed to you.
            </p>
        </template>
    </auth-layout>
</template>
<script>
import AuthLayout from 'Components/Layouts/AuthLayout';
import GroupLabel from 'Utilities/vue-formulate/GroupLabel';
import Terms from './Terms';
export default {
    name: 'Register',
    components: {
        AuthLayout,
        GroupLabel,
        Terms,
    },
    props: {
        groups: {
            type: Array,
            required: true,
        },
        countries: {
            type: Array,
            required: true,
        },
        planningAuthorities: {
            type: Array,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.$inertia.form({
                group_id: null,
                country_code: null,
                company_id: null,
                company_number: null,
                company_address: null,
                associated_lpa: null,
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                password_confirmation: null,
                terms: null,
                email_exists: null,
                invite: false,
            }),
            showTerms: false,
        };
    },
    computed: {
        planningAuthoritiesSelect() {
            return this.planningAuthorities.map(auth => ({auth_id: auth.id, name: auth.name}));
        },
    },
    methods: {
        onSubmit() {
            this.form.post(this.$route('register'));
        },
    },
};
</script>
