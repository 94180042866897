<template>
    <modal ref="fileModal">
        <template #header>
            <h3>
                <template v-if="isSigned">
                    Upload signed document for {{ contract.attachments[0].display_name }}
                </template>
                <template v-else>
                    Add Document
                </template>
            </h3>
        </template>
        <template #body>
            <FormulateForm
                v-model="form"
                :schema="scemaForm"
                :errors="form.errors"
                class="flex-col"
                @submit="submitForm"
                @focus-display="displayNameFocus = true"
                @blur-display="displayNameFocus = false"
            />
        </template>
    </modal>
</template>

<script>
import Modal from 'Utilities/modal/Modal';
export default {
    name: 'ContractUpload',
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        parent: {
            type: Object,
            default: () => {},
        },
        contract: {
            type: Object,
            default: () => {},
        },
        isSigned: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: null,
            displayNameFocus: false,
        };
    },
    computed: {
        scemaForm() {
            const options = [];

            switch (this.parent.type) {
                case 'project':
                    options.push({
                        label: 'Developer Contract',
                        value: 'Developer Contract',
                    });

                    break;

                case 'plot':
                    options.push({
                        label: 'Option Agreement',
                        value: 'Option Agreement',
                    });

                    break;

                case 'rental':
                    options.push({
                        label: 'Management Agreement',
                        value: 'Management Agreement',
                    }, {
                        label: 'Section 106 Agreement',
                        value: 'Section 106 Agreement',
                    });

                    break;

                default:
                    break;
            }

            let uploadBox = [];

            if (this.form.interface_type === 'upload') {
                uploadBox = [{
                    type: 'file',
                    name: 'document',
                    label: 'Select your PDF document to upload',
                    help: 'Only PDF documents are currently supported',
                    uploadUrl: route('contract.upload'),
                    uploadBehavior: 'delayed',
                    validation: 'mime:application/pdf',
                    validationName: 'Document',
                    accept: '.pdf',
                }];
            }

            const schema = [
                {
                    name: 'type',
                    label: 'Contract Type',
                    type: 'select-plugin',
                    placeholder: 'Please Select',
                    options: options,
                    validation: 'required',
                    validationName: 'Contract Type',
                }, {
                    name: 'interface_type',
                    label: 'Interface Type',
                    type: 'select-plugin',
                    placeholder: 'Please Select',
                    options: [{
                        label: 'Upload Document',
                        value: 'upload',
                    }, {
                        label: 'Document Editor',
                        value: 'editor',
                    }],
                    validation: 'required',
                    validationName: 'Interface Type',
                }, {
                    name: 'docusign_enabled',
                    label: 'Docusign Enabled',
                    type: 'select-plugin',
                    placeholder: 'Please Select',
                    options: [{
                        label: 'Disabled',
                        value: 'false',
                    }, {
                        label: 'Enabled',
                        value: 'true',
                    }],
                    validation: 'required',
                    validationName: 'Docusign Enabled',
                }, ...uploadBox, {
                    type: 'submit',
                },
            ];

            return schema;
        },
    },
    watch: {
        show(value) {
            if (value) {
                this.$refs.fileModal.openModal();
            } else {
                this.$refs.fileModal.closeModal();
            }
        },
    },
    mounted() {
        this.form = this.$inertia.form({
            contract_type: null,
            item_id: this.parent.id,
            item_type: this.parent.type,
            content_status: 'published',
        });
    },
    methods: {
        openModal() {
            this.$refs.fileModal.openModal();
        },
        closeModal() {
            this.$refs.fileModal.closeModal();
        },
        displayNameDefualt() {
            if (this.form.display_name || this.displayNameFocus) {
                return 'Display Name';
            }

            return `${this.parent.name}-BNG_Partnership${this.form.contract_type ? '-' + this.form.contract_type : ''}`;
        },
        submitForm(data) {
            this.$inertia.post(this.$route('contract.store'), {
                ...data,
                docusign_enabled: data.docusign_enabled === 'true',
            }, {
                onSuccess: () => {
                    this.$refs.fileModal.closeModal();
                    this.$emit('afterUpdate');

                    if (this.parent.type === 'project' && this.isSigned) {
                        this.$emit('afterUpload');
                    }
                },
            });
        },
    },
};
</script>
